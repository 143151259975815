import React from "react";
import { Button, Card, Input, Form, message } from "antd";
import "./ChangePassword.scss";
import APIClient from "../../helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const { user } = useAuthContext();
  const onFinish = (values) => {
    let obj = {
      UserID: user.UserID,
      OldPassword: values.oldpassword,
      NewPassword: values.newPassword,
    };
    APIClient.post("admin/changePassword", obj).then((response) => {
      if (response.status === 200) {
        message.success("Your password has been changed successfully", 3);
        form.resetFields();
      } else if (response.status === 400) {
        form.setFields([
          {
            name: "oldpassword",
            errors: ["Invalid Password"],
          },
        ]);
      } else if (response.status === 409) {
        message.error(response.message, 3);
        form.setFields([
          {
            name: "oldpassword",
            errors: [response.message],
          },
        ]);
      }
    });
  };

  return (
    <div id="changePwd">
      <div className="change-pwd-container">
        <h2>Change Password</h2>
        <Card
          title="Choose a strong Password"
          bordered={false}
          className="change-password-user"
        >
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              label="Old Password"
              name="oldpassword"
              rules={[
                { required: true, message: "Please enter your oldpassword!" },
              ]}
            >
              <Input.Password placeholder="Enter oldpassword" />
            </Form.Item>
            {/* <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please enter your newpassword!" },
              ]}
            >
              <Input.Password placeholder="Enter newpassword" />
            </Form.Item> */}
             <Form.Item
  name="Password"
  label="New Password"
  rules={[
    { 
      required: true, 
      message: "Password is required" 
    },
    {
      validator: (_, value) => {
        if (value.length < 7 || value.length > 24) {
          return Promise.reject(new Error('Password greater than 8 characters'));
        }
        if (!/\d/.test(value)) {
          return Promise.reject(new Error('Password must contain at least one number'));
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          return Promise.reject(new Error('Password must contain at least one special character'));
        }
        return Promise.resolve();
      },
    },
  ]}
>
  <Input.Password placeholder="Enter Password" />
</Form.Item>
            <div className="change-pwd-btn">
              <Button htmlType="submit">Change Password</Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;
