import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../BusSearch/BusSearch.scss";
import Banner from "../../../components/banner/Banner";
import Buses from "../../../components/buses-search/Buses";
import * as ANTD from "antd";
import TopBuses from "../TopBuses/TopBuses";
import { useSytContext } from "../../../common/providers/SytProvider";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import queryString from "query-string";
import BusCarousal from "./BusCarousal";

import moment from "moment";
import Combinesearchhotel from "../../../components/CombinedSearch/Combinesearchhotel";
import TopDomesticRoutes from "../../../components/home/TopDomesticRoutes/topDomesticRoutes";
import ApiClient from "../../../helpers/ApiClient";
function BusSearch() {
  const { busBanners, hotelBanners, promoDataSource } = useSytContext();

  const [dataSource, setDataSource] = useState([]);

  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const [dealsList, setDealsList] = useState([]);

  useEffect(() => {
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 3);
      setDataSource(data);
    }
  }, [promoDataSource]);

  useEffect(() => {
    getDeals();
    let data = sessionStorage.getItem("busRecentSearchesResults");

    if (data && data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);
  const getDeals = () => {
    ApiClient.get("admin/getAllServicesTopCities")
      .then((res) => {
        //console.log("gh",res)
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Book Bus Tickets Online- {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="Book bus ticket with OutcDemo and get huge discounts. Save more on your online bus booking. Volvo bus booking, sleeper, AC, NON AC, Business Class, Semi Sleeper."
        />
      </Helmet>
      <div className="bus-block">
        <div className="bus-banner">
          {/* <Banner banner={busBanners} /> */}
          <Banner banner={hotelBanners} />
        </div>
        <div className="bus-search-header">
          <h2>Book Bus Tickets With Cancellation Option</h2>
        </div>
        <div className="buses-search">
          <Buses />
          {/* <Combinesearchhotel activetab={1} /> */}
          {/* <Link
            className="text-right text-white w-100 d-block links  m-0 h6"
            exact={true}
            to={"/bus-hire"}
            target="_blank"
          >
            HIRE A BUS
          </Link> */}
        </div>
      </div>

      {/* {recentSearchResults.length ? (
        <section className="home-best-24   recentSearchesRow">
          <div className="container">
            <Row gutter={16} className="justify-content-end align-items-center">
              <Col md={4} xs={24}>
                <p className="m-0 text-right">Recent Searches: </p>
              </Col>
              {recentSearchResults.map((item, i) => {
                let newstringObj = {
                  ...item,
                  journeyDate: moment(item.journeyDate, "DD-MM-YYYY")
                    .add(1, "days")
                    .format("DD-MM-YYYY"),
                };
                let newString = queryString.stringify(newstringObj);

                return (
                  <Col md={5} xs={24}>
                    <div key={i + "recentresults"}>
                      <Link to={`bus/results?${newString}`}>
                        <div
                          style={{
                            boxShadow: "0 1px 5px black",
                            borderRadius: "6px",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div className="ducarosuel">
                            <div className="city-due">{item.sourceName}</div>
                            <div className="iconsDiv city-due">
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </div>

                            <div className="city-due">
                              {item.destinationName}
                            </div>
                            <div className="time-due">
                              {moment(item.journeyDate, "DD-MM-YYYY").format(
                                "DD, MMM YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>
      ) : (
        ""
      )} */}

      <section className="bus-support-24">
        <div className="container">
          <ANTD.Row>
            <ANTD.Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts.png").default
                  }
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </ANTD.Col>
            <ANTD.Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts1.png").default
                  }
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </div>
      </section>
      <section className="top-route-bb new-bus-top">
        <OffersSlider dataSource={dataSource} serviceType="Bus" />
      </section>
      {/* <section className="top-route-bb new-bus-top">
        <TopBuses />
      </section> */}
      <section>
        <TopDomesticRoutes deals={dealsList} type={"Buses"} />
      </section>
      <div className="container bus-inner-trvels">
        {/* <ANTD.Card style={{ border: "1px solid grey" }}>
          <ANTD.Row>
            <ANTD.Col md={24} xs={24} className="">
              <h5 className="tickets-book-trv">
                Book SRTC (State Road Transport Corporation) Bus Tickets
              </h5>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-1.png").default}
                alt=""
              />
              <p>APSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-2.png").default}
                alt=""
              />
              <p>TSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-3.png").default}
                alt=""
              />
              <p>KERELA RTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-4.png").default}
                alt=""
              />
              <p>HRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-5.png").default}
                alt=""
              />
              <p>GSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-6.png").default}
                alt=""
              />
              <p>OSRTC</p>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-7.png").default}
                alt=""
              />
              <p>MSRTC</p>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-8.png").default}
                alt=""
              />
              <p>RSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-9.png").default}
                alt=""
              />
              <p>OSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-10.png").default}
                alt=""
              />
              <p>BSRTC</p>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="img-text-center">
              <img
                className="buses-rtc-img"
                src={require("../../../assets/images/bus-trv-11.png").default}
                alt=""
              />
              <p>UPSRTC</p>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Card> */}
        <ANTD.Card style={{ border: "1px solid grey" }}>

          <BusCarousal />
        </ANTD.Card>
        <ANTD.Row className="travel-locations">
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-1.png").default}
              alt=""
            />
            <p>100000</p>
            <p className="text-case-font">ROUTES</p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-2.png").default}
              alt=""
            />
            <p>2500</p>
            <p className="text-case-font">BUS OPERATORS</p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-3.png").default}
              alt=""
            />

            <p className="text-case-font">INSTANT E-TICKET & CANCELLATION </p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-4.png").default}
              alt=""
            />

            <p className="text-case-font">HAPPY CUSTOMERS</p>
          </ANTD.Col>
          <ANTD.Col md={4} xs={24} className="img-text-center-loc">
            <img
              className="buses-rtc-img"
              src={require("../../../assets/images/bus-loc-5.png").default}
              alt=""
            />
            <p>24/7</p>
            <p className="text-case-font">CUSTOMER CARE</p>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Card className="bdr-trv-bus">
          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <h3 className="booking-seletion">
                Why OutcDemo For Bus Ticket Booking
              </h3>
              <h5>Affordable Travel</h5>
              <p className="mt-3-p">
                The bus is an excellent low-cost choice. On average, you can
                find the best bus ticket prices by booking in advance; the
                earlier you book your bus trip generally the cheaper your
                tickets will be!
              </p>
              <h5>Hassle-Free Travel</h5>
              <p className="mt-3-p">
                The bus is one of the easiest modes of transportation for
                city-to-city travel, enjoy the hassle-free travel experience the
                bus offers. It’s simplicity means that you can pick the best
                route for your trip and travel at your own pace.
              </p>

              <h5>Sustainability</h5>
              <p className="mt-3-p">
                Buses have the lowest CO2 emissions of any mode of city-to-city
                transportation. This makes the bus a very sustainable way to
                travel! Each time you take the bus instead of flying you are
                making the planet a greener place
              </p>

              <h3 className="booking-seletion">
                Benefits Of Booking Bus Tickets Online
              </h3>
              <p className="mt-3-p">
                Booking bus tickets online with OutcDemo has solved many problems
                that people face while booking their tickets at offline counters
                or travel agents.
              </p>
              <ul className="offline-bus-bokk">
                <li>
                  Avoid standing in long queues at offline bus ticket counters
                </li>
                <li>Avoid travel agents</li>
                <li>Choose from multiple bus operators</li>
                <li>Book both Private and SRTC bus tickets online</li>
                <li>Check bus ticket availability online</li>
                <li>
                  Get bus timings, ticket price, boarding & dropping point
                  details online
                </li>
                <li>Access to payment partner discounts and cashback offers</li>
                <li>Free cancellation feature</li>
                <li>24/7 customer support</li>
                <li>Each and every transaction is simple, safe and secure.</li>
              </ul>

              <h3 className="booking-seletion">
                How To Book Bus Tickets Online On OutcDemo
              </h3>
              <p className="mt-3-p">
                Enter your departure city, arrival city, and travel dates.{" "}
                <b>Click Search!</b>
              </p>
              <p>
                Compare bus schedules and companies. Use the filters (cheapest
                bus, fastest, earliest, latest) to sort your results.
              </p>
              <p>Select the bus trip that fits your travel needs.</p>
              <p>Pay securely with your preferred method of payment.</p>
              <p>Download your mobile ticket or print your paper ticket.</p>
              {/* <p>
                <b>Happy travels!</b>
              </p> */}
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Card>
      </div>

      <section className="top-route-bb new-bus-top">
        <Reviews serviceType={3} />
      </section>
    </div>
  );
}

export default BusSearch;
