import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Switch,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Upload,
  DatePicker,
  Select,
  Table,
  Image,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
  EyeOutlined,
  IdcardOutlined,
  UserOutlined,
} from "@ant-design/icons";
import search from "../../assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import pdf from "../../assets/vector-icons/pdf.png";
import ApiClient from "../../../helpers/ApiClient";
import moment from "moment";
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const { Option } = Select;

const ActivitiesImageUpload = ({
  category,
  fileList,
  setFileList,
  form,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }
    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }
    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={form}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          // onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};
const PassportDetails = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = React.useState(false);

  const [passportList, setPassportList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [photograph, setPhotograph] = useState([]);
  const [uploadVisa, setUploadVisa] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewImageVisible, setPeviewImageVisible] = useState(false);
  const [personalDetails, setPersonalDetails] = useState([]);
  const [formPassPortDetails] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentID, setCurrentID] = useState(-1);

  const handleCancel = () => {
    setPeviewImageVisible(false);
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const handleAddClick = () => {
    formPassPortDetails.resetFields();
    setUploadVisa([]);
    setPhotograph([]);
    showModal();
    setIsEditMode(false);
  };
  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdatePassPortDetails(formvalues);
    } else {
      submitNewPassportDetails(formvalues);
    }
  };
  const submitNewPassportDetails = (formvalues) => {
    let photographPath = "";
    let uploadVisaPath = "";
    if (photograph.length > 0) {
      photographPath = photograph[0].response.data.filepaths[0];
    }
    if (uploadVisa.length > 0) {
      uploadVisaPath = uploadVisa[0].response.data.filepaths[0];
    }
    formvalues = {
      ...formvalues,
      Photograph: photographPath ? photographPath : "",
      UploadVisa: uploadVisaPath ? uploadVisaPath : "",
      BirthDate: moment(formvalues.BirthDate).format("YYYY-MM-DD"),
    };
    ApiClient.post("CorporateExtranet/CreateVisaDetails", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("VisaDetails created successfully");
          getAllVisaDetails();
          formPassPortDetails.resetFields();
        } else {
          message.error(response.message);
        }
      }
    );
  };
  useEffect(() => {
    getAllVisaDetails();
    getAllPersonalDetails();
  }, []);
  const getAllPersonalDetails = () => {
    ApiClient.get("CorporateExtranet/GetEmployees")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setPersonalDetails(data);
        }
      })
      .catch((error) => {});
  };
  const handelName = (data) => {
    return `${data.Employee.FirstName}${data.Employee.LastName}`;
  };
  const columns = [
    {
      title: "Employee Name",
      render: (currentItem) => handelName(currentItem),
    },
    // { title: "Given Name", dataIndex: "GivenName" },
    // {
    //   title: "Issuing Post Name",
    //   dataIndex: "IssuingPostName",
    // },
    // {
    //   title: "Sur Name",
    //   dataIndex: "Surname",
    // },
    // {
    //   title: "Visa",
    //   dataIndex: "Visa",
    // },
    // {
    //   title: "Visa Number",
    //   dataIndex: "VisaNumber",
    // },
    {
      title: "Sex",
      dataIndex: "Sex",
      render: (Sex) => (Sex === 1 ? "Male" : "Female"),
    },
    {
      title: "Birth Date",
      dataIndex: "BirthDate",
    },
    {
      title: "Control Number",
      dataIndex: "ControlNumber",
    },
    {
      title: "Passport Number",
      dataIndex: "PassportNumber",
    },
    {
      title: "Visa Type",
      dataIndex: "VisaType",
      render: (VisaType) => handleVisa(VisaType),
    },
    {
      title: "Nationality",
      dataIndex: "Nationality",
    },
    {
      title: "UploadVisa",
      dataIndex: "UploadVisa",
      render: (UploadVisa) => ImageView(UploadVisa),
    },
    {
      title: "Photograph",
      dataIndex: "Photograph",
      render: (Photograph) => ImageView(Photograph),
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const ImageView = (data) => {
    return (
      <div className="edit-icon" style={{ textAlign: "center" }}>
        <EyeOutlined
          onClick={() => showCarImage(data)}
          style={{ color: "red", fontSize: "20px", textAlign: "center" }}
        />
      </div>
    );
  };
  const showCarImage = (data) => {
    if (data) {
      setPreviewImage(data);
      setPeviewImageVisible(true);
    }
  };
  const handleVisa = (VisaType) => {
    if (VisaType === 1) {
      return "Work Visa";
    }
    if (VisaType === 2) {
      return "Student Visa";
    }
    if (VisaType === 3) {
      return "Tourist Visa";
    }
    if (VisaType === 4) {
      return "Business Visa";
    }
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setCurrentID(rec.Id);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteVisaDetails(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const deleteVisaDetails = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteVisaDetails/" + id).then(
      (res) => {
        if (res.status == 200) {
          message.success("VisaDetail Deleted  successfully", 3);
          getAllVisaDetails();
        }
      }
    );
  };
  const handleEditClick = (rec) => {
    const PhotoGraphlist = [];
    const uploadVisalist = [];
    let data = {
      EmployeeId: rec.EmployeeId ?? "",
      GivenName: rec.GivenName ?? "",
      ControlNumber: rec.ControlNumber ?? "",
      IssuingPostName: rec.IssuingPostName ?? "",
      BirthDate: rec.BirthDate ? moment(rec.BirthDate, "YYYY-MM-DD") : null,
      Nationality: rec.Nationality ?? "",
      PassportNumber: rec.PassportNumber ?? "",
      Photograph: rec.Photograph ?? "",
      UploadVisa: rec.UploadVisa ?? "",
      Sex: rec.Sex ?? "",
      Visa: rec.Visa ?? "",
      VisaNumber: rec.VisaNumber ?? "",
      VisaType: rec.VisaType ?? "",
      Surname: rec.Surname ?? "",
      Status: rec.Status ?? "",
    };
    PhotoGraphlist.push({
      name: "slide.jpg",
      status: "done",
      url: BASE + rec.Photograph.substring(1),
      response: {
        status: 200,
        data: {
          filepaths: [rec.Photograph],
        },
      },
    });
    uploadVisalist.push({
      name: "slide.jpg",
      status: "done",
      url: BASE + rec.UploadVisa.substring(1),
      response: {
        status: 200,
        data: {
          filepaths: [rec.UploadVisa],
        },
      },
    });
    setPhotograph(PhotoGraphlist);
    setUploadVisa(uploadVisalist);
    formPassPortDetails.setFieldsValue(data);

    setIsEditMode(true);

    showModal();
  };
  const submitUpdatePassPortDetails = (formvalues) => {
    let photographPath = "";
    let uploadVisaPath = "";
    if (photograph.length > 0) {
      photographPath = photograph[0].response.data.filepaths[0];
    }
    if (uploadVisa.length > 0) {
      uploadVisaPath = uploadVisa[0].response.data.filepaths[0];
    }
    const updatedFormData = {
      ...formvalues,
      Photograph: photographPath ? photographPath : "",
      UploadVisa: uploadVisaPath ? uploadVisaPath : "",
      BirthDate: moment(formvalues.BirthDate).format("YYYY-MM-DD"),
    };
    ApiClient.put(
      "CorporateExtranet/UpdateVisaDetails/" + currentID,
      {},
      updatedFormData
    ).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        message.success("VisaDetail updated successfully");
        getAllVisaDetails();
        formPassPortDetails.resetFields();
      } else {
        message.error(" Error Updating VisaDetail");
      }
    });
  };
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  const getAllVisaDetails = () => {
    ApiClient.get("CorporateExtranet/VisaDetails")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDataSource(data);
          setPassportList([data.length]);
        }
      })
      .catch((error) => {});
  };
  return (
    <div>
      <div className="promo providerapi">
        <Layout>
          <div className="manage-markup-section">
            <Content className="admin-container">
              <Row>
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>Visa Details</h5>
                            <p>
                              {passportList.length > 0 ? passportList : 0} rows
                              found !
                            </p>
                          </div>
                        </div>
                        <div
                          className="exlpdf"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={search}
                            alt="search"
                            style={{ maxHeight: "35px", maxWidth: "35px" }}
                          />
                          <img
                            src={excel}
                            alt="excel"
                            style={{ maxHeight: "35px", maxWidth: "35px" }}
                          />
                          {/* <img
                            src={pdf}
                            alt="pdf"
                            style={{ maxHeight: "35px", maxWidth: "35px" }}
                          /> */}
                          <p
                            className="add-icon"
                            onClick={() => handleAddClick()}
                          >
                            <PlusOutlined />
                          </p>
                        </div>
                      </div>
                      <Table
                        className="table-scroll-none"
                        style={{ color: "red" }}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </Card>
                  </div>
                </Col>
              </Row>
            </Content>
          </div>
        </Layout>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Visa Details</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => formPassPortDetails.submit()}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              danger
              className="cancel-btn"
              onClick={() => formPassPortDetails.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
        width={"902px"}
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} md={24}>
            <p>
              Please note that middle names are a standard requirement for
              immigration authorities.Moreover make sure your enter information
              exactly as printed in your passport.
            </p>
          </Col>
        </Row>

        <Form
          form={formPassPortDetails}
          layout={"vertical"}
          onFinish={handleSubmit}
        >
          <Row gutter={[32, 16]}>
            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="EmployeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please Select Your Employee",
                  },
                ]}
              >
                <Select showSearch>
                  {personalDetails.length > 0 ? (
                    personalDetails.map((i) => (
                      <Option
                        value={i.EmployeeId}
                      >{`${i.FirstName}${i.LastName}`}</Option>
                    ))
                  ) : (
                    <Option>No Employee Details</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="Surname"
                label="Surname"
                rules={[{ required: true, message: "Please Enter Your Name!" }]}
              >
                <Input placeholder="Sur Name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="GivenName"
                label="Given Name"
              >
                <Input placeholder="Given Name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="PassportNumber"
                label="Passport Number"
                rules={[
                  { required: true, message: "Please input your Last Name" },
                ]}
              >
                <Input placeholder="Passport Number" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="Sex"
                label="Sex"
                rules={[{ required: true, message: "Please Select your Sex" }]}
                style={{ marginBottom: "0px" }}
              >
                <Select>
                  <Option value={1}>Male</Option>
                  <Option value={2}>Female</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="BirthDate"
                label="Birth Date"
                rules={[
                  {
                    required: true,
                    message: "Please input your Place Of Birth",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={"DD-MM-YYYY"}
                  placeholder="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="VisaType"
                label="Visa Type"
                rules={[
                  {
                    required: true,
                    message: "Please input your Visa Type",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Select>
                  <Option value={1}>Work Visa</Option>
                  <Option value={2}>Student Visa</Option>
                  <Option value={3}>Tourist Visa</Option>
                  <Option value={4}>Business Visa</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="Visa"
                label="Visa"
                rules={[{ required: true, message: "Please input your Visa" }]}
              >
                <Input placeholder="Visa" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="VisaNumber"
                label="Visa Number"
                rules={[
                  { required: true, message: "Please Enter Your Visa Number" },
                ]}
              >
                <Input placeholder="Visa Number" type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="IssuingPostName"
                label="Issuing Post Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your IssuingPostName",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input placeholder="IssuingPostName" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="Nationality"
                label="Natoionality"
                rules={[
                  { required: true, message: "Please input your Nationality" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input placeholder="Nationality" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="ControlNumber"
                label="Control Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your Control Number",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input placeholder="Control Number" type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="Status"
                label="Status"
                rules={[
                  { required: true, message: "Please Select your Status" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Select>
                  <Option value={1}>Yes</Option>
                  <Option value={0}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Photograph" name="Photograph">
                <ActivitiesImageUpload
                  category="phptograph"
                  fileList={photograph}
                  setFileList={setPhotograph}
                  form="Photograph"
                  limit={true}
                  limitSize={1}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="UploadVisa" name="UploadVisa">
                <ActivitiesImageUpload
                  category="uploadVisa"
                  fileList={uploadVisa}
                  setFileList={setUploadVisa}
                  form="UploadVisa"
                  limit={true}
                  limitSize={1}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        destroyOnClose={true}
        visible={previewImageVisible}
        title={null}
        footer={null}
        onCancel={handleCancel}
      >
        <div>
          {previewImage ? (
            <Image
              src={`${BASE}${previewImage.substring(1)}`}
              alt="imagePreview"
              style={{ width: "100%" }}
            />
          ) : (
            <p>No Image Found</p>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default PassportDetails;
