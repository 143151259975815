import React, { useState, useEffect } from "react";
import { Button, Col, Table, Row, Skeleton, message } from "antd";

import ApiClient from "../../helpers/ApiClient";

import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import "./CouponWallet.scss";

const OfferSkeleton = () => {
  return (
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="coupon-card">
        <div className="coupon-content">
          <Skeleton active />
        </div>
      </div>
    </Col>
  );
};

const OffersBox = ({ coupon, getCouponWalletByUserId, userID }) => {
  const currentDate = moment().startOf("day");
  const validfrom = moment(coupon.validFrom, "DD-MM-YYYY");
  const validtill = moment(coupon.validTill, "DD-MM-YYYY");

  let isdateValid = moment(currentDate).isSameOrAfter(validfrom) && moment(currentDate).isSameOrBefore(validtill) ? true : false;
  if (isdateValid && coupon.RedeemStatus == 1) {
    isdateValid = false;
  }
  const updateRedeem = (value) => {
    delete value.SNo;
    let id = value.ID;
    let obj = {
      ...value,
      RedeemStatus: 1,
      couponAmount: parseFloat(value.couponAmount),
      ModifiedDate: new Date(),
    };

    RedeemCoupon(id, obj);
  };

  const RedeemCoupon = (id, obj) => {
    ApiClient.put("admin/redeemCoupon/" + id, {}, obj).then((res) => {
      if (res.status == 200) {
        message.success("Coupan Amount Redeem  successfully", 3);

        if (userID) {
          getCouponWalletByUserId(userID);
        }
      } else if (res.status == 400) {
        message.error(res.message, 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  return (
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      {isdateValid ? (
        <div className="coupon-card ">
          <div className="coupon-content">
            <div className="coupon-code">
              <div className="codes">

                <h2>{coupon.couponName}</h2>

              </div>
              <p className="amt_text">
                Get <span>INR {coupon.couponAmount}</span> in your wallet.
              </p>
              <p className="red_text mb-0">Code : {coupon.couponCode}</p>
            </div>
          </div>

          {coupon?.RedeemStatus === 0 ? (
            <div className="redeem-btn">
              <Button type="text" onClick={() => updateRedeem(coupon)}>
                Redeem Now
              </Button>
            </div>
          ) : (
            <div className="redeem-btn">
              <Button type="text" disabled={true}>
                Redeem Now
              </Button>
            </div>
          )}
        </div>) : null}
    </Col>
  );
};

const Offers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [couponList, setCouponList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalRedeemedAmount, setTotalRedeemedAmount] = useState(0);

  const { user } = useAuthContext();

  let userId = 1;
  if (user) {
    if (user.UserID) {
      userId = user.UserID;
    }
  }

  useEffect(() => {
    if (user && user?.UserID) {
      getCouponWalletByUserId(user?.UserID);
    }
  }, [user]);

  const getCouponWalletByUserId = (id) => {
    ApiClient.get(`admin/getCouponsWalletByUserId/${id}`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          let filteredList = resp.data.filter(
            (item) => item.RedeemStatus == 1
          );
          setTableData(filteredList);
          let redeemedAmount = resp.data.reduce((acc, current) => (acc + Number(current.TotalCouponAmount)), 0);
          setTotalRedeemedAmount(redeemedAmount);

          setCouponList(data);
          setIsLoading(false);
        } else {
          setCouponList([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
       });
  };

  const columns = [
    {
      title: "CouponType",
      dataIndex: "couponType",
      key: "couponType",
      render: (item) => item == 1 ? "REGISTERATION" : item == 2 ? "BOOKING" : "EVENTS"
    },
    {
      title: "CouponCode",
      dataIndex: "couponCode",
      key: "couponCode",
    },
    {
      title: "CouponName",
      dataIndex: "couponName",
      key: "couponName",
    },
    {
      title: "Coupon Amount",
      dataIndex: "couponAmount",
      key: "couponAmount",
    },
    {
      title: "ValidFrom",
      dataIndex: "validFrom",
      key: "validFrom",
    },
    {
      title: "ValidTill",
      dataIndex: "validTill",
      key: "validTill",
    },

    {
      title: "Redeem Status",
      dataIndex: "RedeemStatus",
      key: "RedeemStatus",
      render: (item) => item == 1 ? "YES" : "NO"
    },
  ];

  return (
    <div className="coupon-wallet">
      <div className="coupon-wallet-wrapper">
        <div className="container mt-3">
          <div className="tab1-content">
            <Row gutter={[32, 32]}>
              {isLoading ? (
                <OfferSkeleton />
              ) : couponList.length > 0 ? (
                couponList.map((coupon, i) => (
                  <OffersBox
                    coupon={coupon}
                    getCouponWalletByUserId={getCouponWalletByUserId}
                    userID={user?.UserID}
                    key={i}
                  />
                ))
              ) : (
                <p>No Coupons Available</p>
              )}
            </Row>
          </div>
        </div>
      </div>
      <div className="container my-3 wallect-ac-21">
        <div className="row py-2">
          <div className="col-6">Coupons Redeemed</div>
          <div className="col-6 text-right">
            Total Amount Redeemed :INR {Number(totalRedeemedAmount).toFixed(2)}
          </div>
        </div>
        <Table
          className="table-scroll-none"
          bordered
          dataSource={tableData}
          columns={columns}
          pagination={{
            defaultPageSize: 25,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100", "125"],
          }}
        />
      </div>
    </div>
  );
};
export default Offers;
