import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"

const CustomFliImg = ({ src, className }) => {
  const [showImage, setShowImage] = useState(true);

//   {console.log(src,"ima")}
  return (
    <img
      className="logo-fli"
      src={showImage ? src : fli8}
      alt={className}
      style={{height:"50px"}}
      onError={() => setShowImage(false)}
    />
  );
};
export default CustomFliImg;