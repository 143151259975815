import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Collapse,
} from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";



import Invoice from "./Model/UserInvoice";
import FlightDoc from "../PdfDocuments/Flight/FlightDoc";
import HotelDoc from "../PdfDocuments/Hotel/HotelDoc";

import FlightInvoiceDoc from "../PdfDocuments/Flight/FlightInvoice";

import HotelNewInvoiceDoc from "../PdfDocuments/Hotel/HotelInvoice";

import PaymentGateway from "../../helpers/PaymentGateway";
import { useSytContext } from "../../common/providers/SytProvider";
import HolidayPackageCheckoutDoc from "../PdfDocuments/BuildPackage/HolidayPackageCheckoutDoc";
import HolidayInvoice from "../PdfDocuments/BuildPackage/HolidayInvoice";
const TicketSidebar = ({
  ticketData,
  type,
  onCancelTicket,
  getTicketDetails = () => { },
  cmsFareRules = {},
  fareRulesResp = null,
  onResheduleTicket,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const { logo, agentLogo } = useSytContext();
  const [smsForm] = Form.useForm();
  const [pstMrkForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const { Panel } = Collapse;
  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [pgData, setPgData] = useState({
    data: {},
    visible: false,
  });
  const [invoiceData, setInvoiceData] = useState({});
  const [creditNoteData, setCreditNoteData] = useState({});
  const [withFare, setWithFare] = useState(-1);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);
  const [pstMrkModalVisible, setPstMrkModalVisible] = useState(false);

  const toggleEmailModal = () => {
    emailForm.resetFields();
    if (!emailModalVisible) loadpassengerData("Email");
    setEmailModalVisible((prev) => !prev);
  };
  const toggleSmsModal = () => {
    smsForm.resetFields();
    if (!smsModalVisible) loadpassengerData("SMS");
    setSmsModalVisible((prev) => !prev);
  };

  const togglePstMrkModal = () => {
    pstMrkForm.resetFields();
    setPstMrkModalVisible((prev) => !prev);
  };


  const loadpassengerData = (type) => {
    if (user && user?.UserID) {
      getPassengerData(user?.UserID).then((data) => {
        if (data.status) {
          if (type === "SMS") smsForm.setFieldsValue({ Mobile: data.Mobile });

        }
      });
    }
  };

  const addPostMarkup = (req) => {
    ApiClient.post("admin/postmarkup", req).then((res) => {
      if (res.status === 200) {
        if (res?.message) message.success(res?.message, 3);
        getTicketDetails();
        setPstMrkModalVisible(false);
      } else if (res.status === 400) {
        if (res?.message) message.success(res?.message, 3);
      } else {
        if (res?.message) message.success(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  useEffect(() => {
    getInvoiceData();
    getCreditNoteData();
  }, []);
  const getCreditNoteData = () => {
    let formData = {};

    if (type === "Holiday") {
      formData.serviceType = 6;
      formData.refNo = ticketData.ReferenceNo;
    }

    ApiClient.post("admin/creditnotes", formData)
      .then((res) => {
        if (res.status == 200) {
          setCreditNoteData(res.data);
        } else {
          setCreditNoteData({});
        }
      })
      .catch((error) => {
        setCreditNoteData({});
      });
  };

  const getInvoiceData = () => {
    let formData = {};
    if (type === "Flight") {
      formData.serviceType = 1;
      formData.refNo = ticketData.referenceNumber;
    }

    if (type === "Hotel") {
      formData.serviceType = 2;
      formData.refNo = ticketData.RefNumber;
    }
    if (type === "Holiday") {
      formData.serviceType = 6;
      formData.refNo = ticketData.ReferenceNo;
    }



    if (formData) {
      ApiClient.post("admin/invoice", formData)
        .then((res) => {
          console.log(res,"res")
          if (res.status == 200) {
            setInvoiceData(res.data);
          } else {
            setInvoiceData({});
          }
        })
        .catch((error) => {
          setInvoiceData({});
        });
    }
  };

  const submitPostMarkup = (val) => {
    if (user) {
      let formData = {
        amount: val.amount,
        userId: user?.UserID,
      };

      if (type === "Flight") {
        formData.serviceType = 1;
        formData.refNumber = ticketData.referenceNumber;
      }

      if (type === "Hotel") {
        formData.serviceType = 2;
        formData.refNumber = ticketData.RefNumber;
      }

      addPostMarkup(formData);
    }
  };
  const HolidaysendEmailSmsETicket = (val) => {

    ApiClient.post("email/emailpdf", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        if (res?.message) message.error(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  const sendEmailSmsETicket = (val) => {
    ApiClient.post("CommonUtility/notification", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        if (res?.message) message.error(res?.message, 3);
        else message.error("Failed");
      }
    });
  };
  const submitEmailForm = (val) => {
    if (limitCount.emailCount > 2) {
      message.error("Limit Exceeded");
      setEmailModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({
        ...prev,
        emailCount: limitCount.emailCount + 1,
      }));
    }


    if (type === "Flight") {
      let formData = {
        phoneNumber: "string",
        travelType: 1,
        trigger: 1,
        bookingReference: ticketData.referenceNumber,
        email: val.Email,
        withFare: withFare,
      };

      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        travelType: 6,
        email: val?.Email,
        // PDFContent:k(ticketData?.Price),
        bookingReference: ticketData.RefNumber,
        packageName: ticketData?.TourName,
      };
      HolidaysendEmailSmsETicket(formData);
    }


  };


  const sendSms = (val) => {
    if (limitCount.smsCount > 2) {
      message.error("Limit Exceeded");
      setSmsModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({ ...prev, smsCount: limitCount.smsCount + 1 }));
    }
    if (type === "Holiday") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 3,
        trigger: 2,
        bookingReference: ticketData.bookingRefNo,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 1,
        trigger: 2,
        bookingReference: ticketData.id,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 2,
        trigger: 2,
        bookingReference: ticketData.RefNumber,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
  };

  const handleCancel = (cancelTicketType) => {
    onCancelTicket(cancelTicketType);
  };
  const handleReshedule = (ticketType) => {
    onResheduleTicket(ticketType);
  };
  const printTicket = () => {
    window.print();
  };

  const confirmHotelBooking = () => {
    if (type === "Hotel") {
      let name = "";
      if (ticketData.guests.length > 0) {
        name = `${ticketData?.guests[0]?.FirstName} ${ticketData?.guests[0]?.LastName}`;
      }

      let data = {
        OrderId: ticketData.RefNumber,
        OrderAmount: Number(ticketData.totalPrice),
        OrderCurrency: "INR",
        OrderNote: "Hotel booking",
        CustomerName: name,
        CustomerEmail: ticketData.email,
        CustomerPhone: ticketData.phoneNo,
        TDS: 0,
        GST: 0,
        Commission: ticketData.Commission, // for agent only
        AdminMarkup: ticketData.AdminMarkup,
        AgentMarkup: ticketData.AgentMarkup, // for agent only
        Conveniencefee: ticketData.Conveniencefee,
        AdminCommission: ticketData.AdminCommission, // for agent only
      };

      setPgData({
        data,
        visible: true,
      });
    }
  };

  return (
    <div className="actionable-buttons">
      <div className="mb-3">


        <div className="trip-type-selector"
          style={{ fontWeight: "600" }}>
          {type === "Holiday" ? (
            <PDFDownloadLink
              document={
                <HolidayPackageCheckoutDoc buildPackage={ticketData} price={ticketData?.Price}
                  Logo={agent ? agentLogo : logo} />
              }
              fileName="HolidayTicket.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <Button>Loading document... </Button>
                ) : (
                  <Button className="mb-1" style={{ fontWeight: "600" }}> <DownloadOutlined />
                    {agent ? "E-Ticket" : "E-Ticket"}
                  </Button>
                )
              }

            </PDFDownloadLink>
          ) : null}


          {type === "Flight" ? (
            <PDFDownloadLink
              document={
                <FlightDoc
                  ticketData={ticketData}
                  fareRulesResp={fareRulesResp}
                  cmsFareRules={cmsFareRules}
                  withFare={true}
                  Logo={agent ? agentLogo : logo}
                />
              }
              fileName="flightTicket.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <Button>Loading document... </Button>
                ) : (
                  <Button className="mb-1">
                    {agent ? "E-Ticket" : "Download Now"}
                  </Button>
                )
              }
            </PDFDownloadLink>
          ) : null}

          {type === "Hotel" ? (
            <PDFDownloadLink
              document={
                <HotelDoc
                  ticketData={ticketData}
                  cmsFareRules={cmsFareRules}
                  withFare={true}
                  Logo={agent ? agentLogo : logo}
                />
              }
              fileName="hotelTicket.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <Button>Loading document... </Button>
                ) : (
                  <Button className="mb-1">
                    {agent ? "E-Ticket" : "Download Now"}
                  </Button>
                )
              }
            </PDFDownloadLink>
          ) : null}

          {invoiceData.bookingStatus === 3 && agent ? (
            type === "Flight" ? (
              <PDFDownloadLink
                document={
                  <FlightInvoiceDoc
                    invoiceData={invoiceData}
                    logo={agent ? agentLogo : logo}
                    type={"invoice"}
                  />
                }
                fileName="Flight_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn">Invoice</Button>
                  )
                }
              </PDFDownloadLink>
            ) : null
          ) : null}
          {[4, 5, 6].includes(invoiceData.bookingStatus) && agent ? (
            type === "Flight" ? (
              <PDFDownloadLink
                document={
                  <FlightInvoiceDoc
                    invoiceData={invoiceData}
                    logo={agent ? agentLogo : logo}
                    type={"credit_note"}
                  />
                }
                fileName="Flight_Credit_Note.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn">Credit Note</Button>
                  )
                }
              </PDFDownloadLink>
            ) : null
          ) : null}
          {invoiceData.BookingStatus === 2 && agent ? (
            type === "Hotel" ? (
              <PDFDownloadLink
                document={
                  <HotelNewInvoiceDoc
                    invoiceData={invoiceData}
                    logo={agent ? agentLogo : logo}
                    type={"invoice"}
                  />
                }
                fileName="Hotel_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn">Invoice</Button>
                  )
                }
              </PDFDownloadLink>
            ) : null
          ) : null}
          {[3, 9].includes(invoiceData.BookingStatus) && agent ? (
            type === "Hotel" ? (
              <PDFDownloadLink
                document={
                  <HotelNewInvoiceDoc
                    invoiceData={invoiceData}
                    logo={agent ? agentLogo : logo}
                    type={"credit_note"}
                  />
                }
                fileName="Hotel_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn">Credit Note</Button>
                  )
                }
              </PDFDownloadLink>
            ) : null
          ) : null}
          {invoiceData.BookingStatus === 3 && agent ? (
            type === "Holiday" ? (
              <PDFDownloadLink
                document={
                  <HolidayInvoice
                    invoiceData={invoiceData}
                    logo={agent ? agentLogo : logo}
                    type={"invoice"}
                  />
                }
                fileName="Holiday_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn">Invoice</Button>
                  )
                }
              </PDFDownloadLink>
            ) : null
          ) : null}
          {[4, 5, 6].includes(invoiceData.BookingStatus) && agent ? (
            type === "Holiday" ? (
              <PDFDownloadLink
                document={
                  <HolidayInvoice
                    invoiceData={invoiceData}
                    logo={agent ? agentLogo : logo}
                    type={"credit_note"}
                  />
                }
                fileName="Holiday_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn">Credit Note</Button>
                  )
                }
              </PDFDownloadLink>
            ) : null
          ) : null}

        </div>


      </div>

      <div className="trip-type-selector">
        <div className="ticket-options">
          <p onClick={() => printTicket()}>
            <PrinterOutlined /> Print E-Ticket
          </p>
        </div>
      </div>



      <div className="trip-ty-selector"
        style={{ fontWeight: "600" }}>
        <Button
          block
          style={{ marginBottom: 10, fontWeight: "600" }}
          onClick={() => {
            toggleEmailModal();

            setWithFare(1);
          }}
        >
          <MailOutlined />
          Send to Email
        </Button>
      </div>



      {ticketData.cancellable && (
        <div className="ticket-options"
          style={{ fontWeight: "600" }}>
          <p onClick={() => toggleSmsModal()}>
            <MessageOutlined /> Sms E-Ticket
          </p>
        </div>
      )}


      {type === "Holiday"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {type === "Holiday"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleReshedule(type)}>
              <FileExcelOutlined /> Reshedule E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "Flight"
        ? ticketData.BookingStatus !== "CANCELLED" || ticketData.BookingStatus !== "CANCELLATIONREQUESTED" &&
        ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel("Flight")}>
              <FileExcelOutlined /> Cancel / Reschedule E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "Hotel"
        ? ticketData.BookingStatus != 3 &&
        ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "Activities"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "BuildPackage"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {agent ? (
        <>


          {type === "Hotel" &&
            ticketData?.BookingStatus === 6 &&
            ticketData?.PurchaseType === "Block" ? (
            <div className="ticket-options">
              {pgData.visible ? (
                <PaymentGateway data={pgData.data} />
              ) : (
                <p onClick={() => confirmHotelBooking()}>
                  <DollarOutlined /> Confirm Booking
                </p>
              )}
            </div>
          ) : null}


        </>
      ) : null}

      <Modal
        title="User Invoice"
        visible={userInvoiceVisible}
        onOk={() => setUserinvoiceVisible(false)}
        onCancel={() => setUserinvoiceVisible(false)}
        width={"75%"}
      >
        <Invoice />
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Enter The Email Address</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={emailModalVisible}
        onOk={toggleEmailModal}
        onCancel={toggleEmailModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleEmailModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={emailForm.submit}
            >
              Send
            </Button>
          </div>,
        ]}
      >
        <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Email is not a valid email" },
                ]}
              >
                <Input placeholder="Enter The Email Address" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>SMS E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={smsModalVisible}
        onOk={toggleSmsModal}
        onCancel={toggleSmsModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleSmsModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={smsForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form form={smsForm} layout="vertical" onFinish={sendSms}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Mobile No."
                name="Mobile"
                autoFocus
                rules={[
                  {
                    required: true,
                    message: "Mobile Number Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="number-specing"
                  placeholder="Enter Mobile number"
                  autoComplete="off"
                  autoFocus
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default TicketSidebar;
