import { Button, Col, Divider, List, Popover, Row, Modal, Tabs } from "antd";
import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import carDoor from "../../../../assets/images/svg/car-door.png";
import "../CarsResultCards/CarsResultCards.scss";
import ReactHtmlParser from "react-html-parser";
import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";

const { TabPane } = Tabs;

const BASE = process.env.REACT_APP_BASE_URL;
const CarsCard = ({ carObj, updateSelectedCarData, updateCarBookingData }) => {
  // console.log(carObj, "obj-f");
  let [canclePolicy, setCanclePolicy] = useState(false);
  let [TotalNetFare, setTotalNetFare] = useState(carObj.BaseFare);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let history = useHistory();
  const goToCarCheckout = (carObj) => {
    updateSelectedCarData(carObj);
    history.push("/cars/checkout");
  };
  // setTotalNetFare(totalFare);
  const totalFare = Number(carObj.BaseFare) +
    Number(carObj.StateTollTax) +
    Number(carObj.GST) +
    Number(carObj.DriverAllowance);
  useEffect(() => {
    setTotalNetFare(totalFare);
  }, [totalFare]);
  const content = (Data) => {
    const dataChargeSource = [
      `Base Fare : ${Data.BaseFare ?? "00"} Rs.`,
      `StateToll Tax : ${Data.StateTollTax ?? "00"} Rs.`,
      `GST Amount : ${Data.GST ?? "00"} Rs.`,
      `Driver Allowance : ${Data.DriverAllowance ?? "00"} Rs.`,
    ];
    const dataExtraChargeSource = [
      `Charge Per Extra KM : ${Data.ChargePerExtraKM ?? "00"} Rs./km`,
      `Late Night Charge : ${Data.NightCharges ?? "00"} Rs./KM`,
      `Late Pickup Charge : ${Data.LatePickupCharges ?? "00"} Rs./km`,
      `Early Pickup Charge : ${Data.EarlyPickupCharges ?? "00"} Rs./km`,
    ];
    return (
      <div>
        <Row className="text-center">
          <Col md={12} xs={24}>
            <Divider>Fare Charges</Divider>
            <b>
              <p>Base Fare : {currencyValue(Data.BaseFare ?? "00")} {activeCurrency}</p>
            </b>
            {Data.StateTollTax > 0 ?
              <b>
                <p>StateToll Tax : {currencyValue(Data.StateTollTax ?? "00")} {activeCurrency}</p>
              </b> : null}
            {Data.GST > 0 ?
              <b>
                <p>GST Amount : {currencyValue(Data.GST ?? "00")} Rs</p>
              </b> : null}
            <b>
              <p>Driver Allowance : {currencyValue(Data.DriverAllowance ?? "00")} {activeCurrency}</p>
            </b>
          </Col>
          <Col md={12} xs={24}>
            <Divider>Extra Fare Charges</Divider>
            <b>
              <p>
                Charge Per Extra KM : {currencyValue(Data.ChargePerExtraKM ?? "00")} {activeCurrency}/km
              </p>
            </b>
            <b>
              <p>Late Night Charge : {currencyValue(Data.NightCharges ?? "00")} {activeCurrency}/km</p>
            </b>
            <b>
              <p>
                Late Pickup Charge : {currencyValue(Data.LatePickupCharges ?? "00")} {activeCurrency}/km
              </p>
            </b>
            <b>
              <p>
                Early Pickup Charge : {currencyValue(Data.EarlyPickupCharges ?? "00")} {activeCurrency}/km
              </p>
            </b>
          </Col>
          <h6
            className="d-flex justify-content-end"
            style={{ color: "Red", width: "100%" }}
          >
            {" "}
            TOTAL PRICE:{" "}
            {currencyValue(Number(Data.BaseFare ? Data.BaseFare : 0) +
              Number(Data.StateTollTax ? Data.StateTollTax : 0) +
              Number(Data.GST ? Data.GST : 0) +
              Number(Data.DriverAllowance ? Data.DriverAllowance : 0))}{" "}
            {activeCurrency}
          </h6>
        </Row>
      </div>
    );
  };
  const cancePolicy = () => {
    setCanclePolicy(true);
    // setShowCancleModal(true);
  };

  return (
    <div>
      <Row className="cars-ratings-act align-items-center ">
        <Col md={5} xs={24} className="overflow-hidden py-3">
          {carObj.car.CarImages ? (
            <img
              className="car-image"
              src={BASE + carObj.car.CarImages.substring(1)}
              alt=""
            />
          ) : (
            <img
              className="car-image"
              src={
                require("../../../../assets/images/carrental/car-rental-re-1.jpg")
                  .default
              }
              alt=""
            />
          )}
        </Col>

        <Col md={10} xs={24} className="py-3" style={{ paddingLeft: '2%' }}>
          <div>
            {" "}
            <p className="nisha-cars" style={{ color: '#bd0c21' }}>
              {carObj.car.CarName ? carObj.car.CarName : ""},{" "}
              {carObj.carType.CarType ?? ""}
            </p>
            <div className="sub-header-info mb-2">
              <b style={{ color: '#bd0c21' }}>{carObj.car.SeatCapacity} Seat(s) </b>|{" "}
              {carObj.car.ACAvailable ? "A/C" : "Non A/C"} |{" "}
              {`${carObj.car.HowManyDoors ?? 0} ${"dr's"}`}
            </div>
          </div>
          <p className="mb-0">
            <b style={{ color: '#bd0c21' }}>Spacious Car</b>
          </p>
          <div className="extra-params d-flex ">
            <ul>
              <li>{carObj.IncludedKMs} kms Included</li>
              <li>
                ₹{carObj.ChargePerExtraKM}/km after {carObj.IncludedKMs} kms
              </li>
              <li>{carObj.car.LuggageCapacity ?? ""} Luggage Bags</li>
            </ul>
            <ul>
              <li>{carObj.car.GPSAvailable ? "GPS Included" : "No GPS"}</li>
              <li>
                {carObj.car.FuelType === 0
                  ? "Petrol Car"
                  : carObj.car.FuelType === 1
                    ? "Diesel Car"
                    : carObj.car.FuelType === 2
                      ? "Electric Vehicle"
                      : null}
              </li>
            </ul>
          </div>
          <div className="seats-chair   w-100">
            <div>
              <ul>
                {/* <li>
                  <i className="fa fa-bullseye pr-2" aria-hidden="true"></i>
                  Auto Transmission
                </li> */}
              </ul>
            </div>
          </div>
        </Col>
        <Col md={4} xs={12} className="overflow-hidden py-3">
          <div className="seats-chair-certi">
            <img
              className=""
              src={
                require("../../../../assets/images/certified-car.png").default
              }
              alt=""
            />
            <p>Top Rated Cabs & Chauffeurs</p>
          </div>
          <div className="cancleDetails">
            <a onClick={() => cancePolicy()}>Details</a>
          </div>
        </Col>


        <Col md={5} xs={12} className="cars-btn-price">
          <div>
            <div className="price-drops">

              <h5 className="cost-of-values">
                {/* <i className="fa fa-inr" aria-hidden="true"></i> */}
                {/* {activeCurrency} {currencyValue(TotalNetFare ?? carObj.BaseFare)} */}
                {activeCurrency} {currencyValue(Number(carObj.BaseFare ? carObj.BaseFare : 0) +
              Number(carObj.StateTollTax ? carObj.StateTollTax : 0) +
              Number(carObj.GST ? carObj.GST : 0) +
              Number(carObj.DriverAllowance ? carObj.DriverAllowance : 0))}
              </h5>
              {/* {console.log(TotalNetFare,carObj.BaseFare, "car")} */}
            </div>


            <div className="debtndrops">
              <Button
                style={{ background: "linear-gradient(90deg,#320063,#0f76bb)", color: "White", padding: '6px 40px', paddingLeft: 44 }}
                onClick={() => {
                  goToCarCheckout(carObj);
                }}
              >
                Book
              </Button>


            </div>
            <div>
              <Popover
                placement="bottom"
                overlayClassName="CarFare"
                content={content(carObj)}
              >
                <p className="packages-num">Fare Charges</p>
              </Popover>
            </div>
          </div>
        </Col>

      </Row>
      <Row>
        <Col md={24}>
          <Modal
            title="Other Details"
            className="promo-modal-header modal-header-bg12"
            visible={canclePolicy}
            onOk={(e) => setCanclePolicy(false)}
            onCancel={(e) => setCanclePolicy(false)}
            footer={null}
            width={"1100px"}
          >
            <div>
              <Tabs>
                <TabPane tab="Cancellation Policy" key="1">
                  {ReactHtmlParser(carObj.CancelPolicy ?? "No Policy")}
                </TabPane>
                <TabPane tab="Car Pickup Procedure " key="2">
                  {ReactHtmlParser(
                    carObj.CarPickupProcedure ?? "No Information Found"
                  )}
                </TabPane>
                <TabPane tab="Car Inspection" key="3">
                  {ReactHtmlParser(
                    carObj.HowToInspectTheCar ?? "No Information Found"
                  )}
                </TabPane>
                <TabPane tab="Terms & Conditions" key="4">
                  {ReactHtmlParser(
                    carObj.TermsAndConditions ?? "No  Information Found"
                  )}
                </TabPane>
                <TabPane tab="Required Documents" key="5">
                  {ReactHtmlParser(
                    carObj.RequiredDocumentsForPickup ?? "No  Information Found"
                  )}
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default CarsCard;
