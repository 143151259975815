import { useEffect, useState } from "react";
import queryString from "query-string";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
import CarSkeleton from "../../Cars/CarsResults/Skeleton/CarSkeleton";
import ApiClient from "../../../helpers/ApiClient";
import VisaFilter from "../VisaFilter/VisaFilter";
import { GlobalStatesContext } from "../../../common/providers";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import { useContext } from "react";
import { Button, Col, Collapse, Modal, Row, Grid, Form } from "antd";
import VisaCard from "./VisaCard";
import { useSytContext } from "../../../common/providers/SytProvider";
import VisaSort from "../VisaSort/VisaSort";
import VisaTypeSlider from "../../../components/VisaTypeSlider/VisaTypeSlider";
import VisaSearchPanel from "../Visasearch/visaSearchPanel";
import "./VisaCard.scss";
const { Panel } = Collapse;
const { useBreakpoint } = Grid;
export const VisaResults = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState([]);
  const [VisaList, setVisaList] = useState([]);
  const [visaTypeList, setVisaTypeList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const { md } = useBreakpoint();
  const {
    state: { visaSearchResultObj },
    updateVisaSearchResultObj,
  } = useContext(GlobalStatesContext);

  const getAllVisaTypes = () => {
    setVisaTypeList([]);
    let param = queryString.parse(document.location.search);

    ApiClient.get("visa/getVisaType").then((res) => {
      if (res.status == 200) {
        let data = res.data.filter((item) => item.countryName == param.country);

        setVisaTypeList(data);
      }
    });
  };
  useEffect(() => {
    getSearchresults();
    getAllVisaTypes();
  }, [window.location.search]);

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };
  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  let VisaInitial = {
    Visas: [],
    filtersObj: {
      price: {
        minPrice: 0,
        maxPrice: 0,
        maxPriceRange: 0,
        minPriceRange: 0,
      },
      visaType: [],
      processingTime: [],
      Validity: [],
    },
  };
  const getSearchresults = () => {
    let param = queryString.parse(document.location.search);
    fetchVisaDetails(param);
  };
  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const fetchVisaDetails = (Country) => {
    Country = Country.country;

    setVisaList(VisaInitial);
    setIsLoading(true);

    ApiClient.get("visa/visaSearch/" + Country).then((res) => {
      if (res.status == 200) {
        if (res.data.length) {
          let filtersObj = { ...VisaInitial.filtersObj };

          let Visas = res.data.map((Visa, i) => {
            let startingFare = Number(Visa.Fees);

            if (i === 0) {
              filtersObj.price.minPrice = startingFare;
            }
            let minFare = startingFare;
            if (startingFare > filtersObj.price.maxPrice) {
              filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
                startingFare;
            }

            if (minFare < filtersObj.price.minPrice) {
              filtersObj.price.minPrice = minFare;
            }
            filtersObj.price.minPriceRange = filtersObj.price.minPrice;

            let VisaType = Visa.TypeofVisa;
            checkIfExist(filtersObj.visaType, VisaType) &&
              filtersObj.visaType.push({
                id: VisaType,
                label: VisaType,
                isChecked: false,
              });

            let ProcessingTime = Visa.ProcessingTime;
            checkIfExist(filtersObj.processingTime, ProcessingTime) &&
              filtersObj.processingTime.push({
                id: ProcessingTime,
                label: `${ProcessingTime} Day(s)`,
                isChecked: false,
              });
            let Validity = Visa.Validity;
            checkIfExist(filtersObj.Validity, Validity) &&
              filtersObj.Validity.push({
                id: Validity,
                label: `${Validity} Month(s)`,
                isChecked: false,
              });

            return {
              ...Visa,
              isVisible: true,
            };
          });
          
          res = {
            data: {
              ...res.data,
              Visas: Visas,
              filtersObj: filtersObj,
            },
          };

          updateVisaSearchResultObj(res);
          setVisaList(res.data);
        }
        setIsLoading(false);
      } else if (res.status === 404) {
        setIsLoading(false);
      }
    });
  };

  const { visaSearchBanners } = useSytContext();
  let bannreIndex = -1;

  return (
    <>
      <div className="combined-results-container car-body-res">
        <section className="hotels_modify  buses-div-bg">
          <div className="hotel-modify-container">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={
                  <span className="hotels-hide-search">Modify Search</span>
                }
                key="1"
              >
                <div className="search-modify-block bus-res-cc2 visaSearch visa-modify-data">
                  <VisaSearchPanel
                    modifySearch={true}
                    getSearchresults={getSearchresults}
                    getAllVisaTypes={getAllVisaTypes}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        </section>

        {visaTypeList.length > 0 && (
          <VisaTypeSlider visaTypeList={visaTypeList} />
        )}

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container filters-number-tab">
            <Row
              gutter={16}
              className="margins-alignments"
              style={{ padding: "14px 14px", display:"flex",flexFlow:"row" }}
            >
              {isLoading ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <FilterSkeleton />
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    <CarSkeleton />
                  </Col>
                </>
              ) : VisaList.Visas.length > 0 ? (
                <>
                  <Col className="filter-section-bus flight-filters-demooutc-fun">
                    <VisaFilter
                      visaSearchResultObj={visaSearchResultObj}
                      setVisaList={setVisaList}
                    />
                  </Col>
                  <Col className="flight-resut-demooutc-fun">
                    <Row className="sorting-car-v1">
                      <Col md={24} xs={24}>
                        <VisaSort setVisaList={setVisaList} />
                      </Col>
                    </Row>
                    {VisaList.Visas.length > 0 ? (
                      VisaList.Visas.map((VisaObj, key) => {
                        return (
                          <div key={"visaresults" + key}>
                            {key != 0 &&
                              key % 3 == 0 &&
                              visaSearchBanners[++bannreIndex] && (
                                <div className="combine-roundtrip-view">
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "90px",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      visaSearchBanners[
                                        bannreIndex
                                      ].path.substring(1)
                                    }
                                    data={
                                      visaSearchBanners[bannreIndex + 1]
                                        ? null
                                        : (bannreIndex = -1)
                                    }
                                  />{" "}
                                </div>
                              )}
                            <VisaCard key={key} VisaObj={VisaObj} />
                          </div>
                        );
                      })
                    ) : (
                      <Col md={24} sm={24} xs={24}>
                        <CustomNoResultFound title={"No Visas Available"} />
                      </Col>
                    )}
                  </Col>
                </>
              ) : (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Visas Available"} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>

      <Row className="fiters-value-hotel">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalflight}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalsort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
      </Row>

      <Modal
        title={[
          <div>
            <h5 style={{ marginBottom: "0px", color:"white" }}>
              <strong>Filters</strong>
            </h5>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <VisaFilter
              visaSearchResultObj={visaSearchResultObj}
              setVisaList={setVisaList}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px", color:"white" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-hotel-show12 visa-menu-12"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisiblenew(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              <VisaSort setVisaList={setVisaList} />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default VisaResults;

