import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Radio,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
  Image,
  Select,
  DatePicker,
  Input,
} from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
//import LogoImg from "../../../assets/images/logos/trip-logo.png";
import { useSytContext } from "../../providers/SytProvider";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
import TicketSidebar from "../TicketSidebar";
import { LoadingOutlined } from "@ant-design/icons";
import TicketHoliday from "./TicketHoliday";
import HolidayCancellationPolicy from "../../../pages/Holidays/HolidayCancellationPolicy";
import moment from "moment";

const { Panel } = Collapse;

const HolidayTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const ticketSearchParams = queryString.parse(window.location.search);
  const [form] = Form.useForm();
  const [resForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [resModalVisible, setResModalVisible] = useState(false);
  const BASE = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [cmsFareRules, setCmsFareRules] = useState({});
  const initialState = {
    TourDetails: [],
    Passengers: {},
    PartPayment: {},
  };

  const [ticketData, setTicketData] = useState(initialState);
  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const mapActivitiesData = (res) => {

    if (res.data?.ReferenceNo) {


      setTicketData(res.data);
    } else {
      setTicketData(initialState);
    }
  };

  const getTicketDetails = () => {
    setLoadingTicket(true);
    APIClient.get("HolidayExtranet/holiday/ticket/" + ticketSearchParams.ref)
      .then((res) => {
        if (res.status == 200) {
          if (mode === "ADMIN") {
            mapActivitiesData(res);
          } else {
            let userId = user?.UserID ?? 1;

            if (res.data.UserId === userId) {
              mapActivitiesData(res);
            } else {
              setTicketData(initialState);
            }
          }
        } else {
          setTicketData(initialState);
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData(initialState);
        setLoadingTicket(false);
      });
  };


  const cancelTicket = () => {
    setModalVisible(true);
  };
  const resheduleTicket = () => {
    setResModalVisible(true);
  };

  useEffect(() => {
    getTicketDetails();

  }, [ticketSearchParams.ref]);

  const submitCancelForm = (val) => {
    setLoading(true);

    let data = {
      CancellationType: 1,
      ReferenceNo: ticketData.ReferenceNo,
      HolidayPackageId: val.packageId,
      isOnlineRefund: ticketData.isOnlineCancellation,
      Remarks: "Test cancellation",
    };
    APIClient.post("HolidayExtranet/Holidaycancellation/request", data)
      .then((res) => {
        setLoading(false);

        if (res.status == 200) {
          message.success(res.message, 10);
          getTicketDetails();
          setModalVisible(false);

          form.resetFields();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const submitResheduleForm = (val) => {

    setLoading(true);

    let data = {
      ...val,
      ReferenceNo: ticketData.ReferenceNo,
    };

    APIClient.post("HolidayExtranet/Holidayreschedule/request", data)
      .then((res) => {
        setLoading(false);

        if (res.status == 200) {
          message.success(res.message, 10);
          getTicketDetails();
          setResModalVisible(false);

          resForm.resetFields();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const disabledPastDate = (currentDate) => {
    return currentDate <= moment();
  };

  return (
    <div className="flight-ticket-collapse">
      <div className="print-agentlogo">
        {agent ? (
          agentLogo ? (
            <Image
              className="printImageStyle"
              src={BASE + agentLogo.substring(1)}
            ></Image>
          ) : logo ? (
            <Image
              className="printImageStyle"
              src={BASE + logo.substring(1)}
            ></Image>
          ) : (
            <Image className="printImageStyle" src={""}></Image>
          )
        ) : logo ? (
          <Image
            className="printImageStyle"
            src={BASE + logo.substring(1)}
          ></Image>
        ) : (
          <Image className="printImageStyle" src={""} alt="OutcDemo"></Image>
        )}
      </div>

      <Card>
        <div className="fligh-ticket-container">
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : ticketData.ReferenceNo ? (
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18}>
                  <TicketHoliday
                    ticketData={ticketData}
                    cmsFareRules={cmsFareRules}
                  />
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Holiday"
                      onCancelTicket={cancelTicket}
                      getTicketDetails={getTicketDetails}
                      onResheduleTicket={resheduleTicket}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Holiday"
                          onCancelTicket={cancelTicket}
                          getTicketDetails={getTicketDetails}
                          onResheduleTicket={resheduleTicket}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p>No Ticket Found </p>
          )}
        </div>
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {ticketData?.ReferenceNo ? (
          <div className="wrapper">

            <div className="d-flex justify-content-between">
              <p>
                <b>Reference No</b> : {ticketData?.ReferenceNo}
              </p>

              <p>
                <b>Total Fare</b> : Rs {ticketData?.PaidPrice}
              </p>
            </div>

            <p className="text-center">
              <b>Cancellation Policy</b>
            </p>
            <div className="cancellation-wrapper">
              <HolidayCancellationPolicy
                data={ticketData.CancellationPolicy}
                cancellation={ticketData?.partialCancellationAllowed ?? false}
              />
            </div>

            <Form layout="vertical" form={form} onFinish={submitCancelForm}>
              <Form.Item
                label=" Choose Package Name:"
                name="packageId"
                className="font-weight-bold"
                rules={[
                  { required: true, message: "Please Choose Package Name" },
                ]}
              >
                <Radio.Group>
                  <Row>
                    {ticketData?.ReferenceNo ? (
                      ticketData?.BookingStatus === 3 ? (
                        <Radio
                          value={ticketData?.HolidayPackageId}
                          className="ml-0"
                        >
                          {ticketData?.TourName}
                        </Radio>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        ) : null}
      </Modal>

      <Modal
        title="Reshedule Ticket"
        className="promo-modal-header"
        visible={resModalVisible}
        onOk={(e) => setResModalVisible(false)}
        onCancel={(e) => setResModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={resForm.submit}
              loading={loading}
            >
              Reshedule Ticket
            </Button>
          </div>,
        ]}
        width={"800px"}
      >
        {ticketData?.ReferenceNo ? (
          <div className="wrapper">
            <div className="d-flex justify-content-between">
              <p>
                <b>Reference No</b> : {ticketData?.ReferenceNo}
              </p>

              <p>
                <b>Total Fare</b> : Rs {ticketData?.PaidPrice}
              </p>
            </div>

            <Form
              layout="vertical"
              form={resForm}
              onFinish={submitResheduleForm}
            >
              <Row gutter={16}>
                {ticketData?.ReferenceNo ? (
                  ticketData?.BookingStatus === 3 ? (
                    <>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label=" Choose Package Name:"
                          name="HolidayPackageId"
                          className="font-weight-bold"
                          rules={[
                            {
                              required: true,
                              message: "Please Choose Package Name",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio
                              value={ticketData?.HolidayPackageId}
                              className="ml-0"
                            >
                              {ticketData?.TourName}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <Form.Item
                          label=" Passengers Count:"
                          name="Pax"
                          className=""
                          rules={[
                            {
                              required: true,
                              message: "Please Choose Package Name",
                            },
                          ]}
                        >
                          <Select placeholder="Select passengers">
                            {ticketData.TotalPax > 0
                              ? [...Array(ticketData.TotalPax)].map(
                                (_, index) => {
                                  return (
                                    <Select.Option value={index + 1}>
                                      {index + 1} Passenger(s)
                                    </Select.Option>
                                  );
                                }
                              )
                              : null}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Reschedule Date"
                          name="RescheduleDate"
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%" }}

                            disabledDate={disabledPastDate}

                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Other Info"
                          name="OtherInfo"
                          rules={[{ required: true }]}
                        >
                          <Input.TextArea placeholder="Other Info" />
                        </Form.Item>
                      </Col>
                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Remarks"
                          name="Remarks"
                          rules={[{ required: true }]}
                        >
                          <Input.TextArea placeholder="Remarks" />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Row>
            </Form>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
export default HolidayTicketScreen;
