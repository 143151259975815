import React, { useContext, useState, useEffect, useRef, } from "react";
import {
  Button,
  Radio,
  Row,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";
import moment from "moment/moment";
import { Link, useHistory } from "react-router-dom";
import CountryList from "../../../common/CountryList";
// import CarsCard from "../CarsResults/CarsResultCards/CarsCard";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CarDetails, TransferDetails } from "./CarDetails";
import FareDetails from "./FareDetails";
import "./CarsCheckout.scss";
// import { useHistory } from "react-router-dom";
import { getPassengerData } from "../../../helpers/PassegerData";
const { Option } = Select;
const disabledPastDate = (currentDate) => {
  return currentDate <= moment();
};
const CarCheckout = () => {
  let history = useHistory();
  const [gstRequired, setGSTRequired] = useState(false);
  const [onlyPassengersDetails, setOnlyPassengersDetails] = useState({});
  const {
    state: { carBookingData, carSearchObj },
    updateCarPassengerData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  // console.log(carBookingData,carSearchObj, "carBookingData");
  const [form] = Form.useForm();
  const mobileNo = useRef();
  // const areaCode = useRef();
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
 
  const updatePassengersData = () => {
    form
      .validateFields()
      .then((data) => {
        // console.log(data,"d2");
        let carPaxArr = [];
        carPaxArr.push({
          name: data?.FirstName ,
          lastName:data?.LastName,
          tittle: data.Title_,
          pickUpAddress: data.PickUpAddress_
            ? data.PickUpAddress_
            : carBookingData.pickUpLocation,
          droppingAddress: data.DroppingAddress_
            ? data.DroppingAddress_
            : carBookingData.dropLocation,
          additionalRequests: data.AdditionalRequests_ || "",

          emailId: data.emailId,
          mobileNo: data.mobileNo,
          countryCode: data.areaCode,
          panCard:data.PanDetails || "",
          gstDetails: {
            gstNumber: data.gstNumber,
            gstEmailId: data.gstEmailId,
            gstPhoneNo: data.gstPhoneNo,
            gstAddressLine1: data.gstAddressLine1,
            gstCompanyName: data.gstCompanyName,
          },
        });
        updateCarPassengerData(carPaxArr);
        // console.log(carPaxArr, "carPaxArr");
        // return;
        history.push("/cars/review");
      })
      .catch((e) => { });
  };
  const handleAreaCodeChange = (value) => {
    // console.log(value, "isd");
    setSelectedCountryCode(value);
    // When the area code changes, set the focus on the Mobile Number input field
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };
  useEffect(() => {
    RemovePromoConvFee();
  }, []);
  return (
    <div className="buscheckout_bg">
      <div className="checkout-heading">
        <div  fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Select Other Car</span>
            </Link>
          </div>
          <h3>Fill Out the Form Below, and Book right now!</h3>
        </div>
      </div>
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]} className="cartoppp">
          <Col md={24} sm={24} xs={24}>
            <Form form={form} layout="vertical" scrollToFirstError={true}>
              <h3 className="title" style={{ color: "#000000" }}>Car Details</h3>
              <Row gutter={[16, 16]}>
                {/* {console.log(carBookingData, "car22")} */}
                <Col className="car-checkout-detail-part" >
                  <div className="left_wrapper">
                    {carBookingData.type === "transfer" ? (
                      <TransferDetails carBookingData={carBookingData} />
                    ) : (
                      <CarDetails carBookingData={carBookingData} />
                      // <CarsCard carBookingData={carBookingData} />
                      
                    )}
                    {/*  */}
                  </div>
                  <div className="paxinfo_wrapper">
                    <h3 className="title" style={{ color: "#010101" }}>Passenger Details</h3>
                    <Card>
                      <div className="paxcontact_wrapper mt-2">
                        <h3 className="title">Passenger</h3>
                        {carBookingData ? (
                          <div>
                            <Row gutter={[16, 16]} className="main-row-chec-page">
                              <Col md={4} sm={12} xs={24}>
                                <Form.Item
                                  name={"Title_"}
                                  label="Title"
                                  rules={[
                                    { required: true, message: "Required" },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    onSelect={(val) => {
                                      setOnlyPassengersDetails({
                                        ...onlyPassengersDetails,
                                        title: val,
                                      });
                                    }}
                                    placeholder="Title"
                                    className="inputbg"
                                  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Ms">Ms</Option>
                                    <Option value="Mrs">Mrs</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  name="FirstName"
                                  label="First Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    className="inputbg"
                                    autoComplete="off"
                                    placeholder="Enter Your First Name"
                                    onChange={(e) =>
                                      form.setFieldsValue({
                                        [`FirstName`]: e.target.value.trimStart(),
                                      })
                                    }
                                    onBlur={(e) => {
                                      if (e.target.value.trim()) {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          name: e.target.value.trim(),
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  name="LastName"
                                  label="Last Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="inputbg"
                                    autoComplete="off"
                                    placeholder="Enter your Last Name"
                                    size="large"
                                    onChange={(e) =>
                                      form.setFieldsValue({
                                        [`LastName`]: e.target.value.trimStart(),
                                      })
                                    }
                                    onBlur={(e) => {
                                      if (e.target.value.trim()) {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          name: e.target.value.trim(),
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="car-mobile col-main-check-page" md={8} sm={12} xs={24}>
                                <Form.Item
                                
                                  label="Phone number"
                                  name="mobileNo"
                                  className="phno"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                    // {
                                    //   minLength: 10,
                                    //   maxLength: 10,
                                    //   pattern: "^[0-9]{10}$",
                                    //   message: "Must be 10 digits",
                                    // },
                                  ]}
                                >
                                  <Input
                                  ref={mobileRef}
                                    addonBefore={
                                      <Form.Item
                                        style={{ width: "30px"}}
                                        name="areaCode"
                                        className="area"
                                        rules={[
                                          {
                                            required: true,
                                            message: "",
                                          },
                                        ]}
                                        placeholder="Select ISD"
                                        noStyle
                                      >
                                        <Select
                                       
                                          showSearch
                                          showArrow={false}
                                          placeholder="Select ISD"
                                          style={{ width:"auto" }}
                                          onChange={handleAreaCodeChange}
                                          ref={areaCodeRef}
                                          focusRef={mobileNo}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {CountryList.map((item) => (
                                            <Option
                                            className="car-opt"
                                            // style={{width:"50px", overflow:"visible" }}
                                              key={item.dial_code}
                                              value={item.dial_code}
                                            >
                                              {item.dial_code}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    }
                                    className="inputbg"
                                    placeholder="Enter Mobile Number"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    size="large"
                                    width={"70px"}
                                  />
                                  {/* <Input size="large" placeholder="Enter Mobile Number" addonBefore="+91" className="inputbg" /> */}
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Email"
                                  name="emailId"
                                  rules={[
                                    { required: true, message: "Required" },
                                    { type: "email", message: "Invalid Email" },
                                  ]}
                                >
                                  <Input placeholder="Enter Email-Id" size="large" className="inputbg" />
                                </Form.Item>
                              </Col>
                              {/* {(carBookingData?.IsPANMandatory || selectedCountryCode === "+91") && ( */}
                              {carBookingData?.IsPANMandatory &&
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name="PanDetails"
                                    label="Pan Number"
                                    rules={[
                                      { required: true,
                                         //message: "Required" 
                                        },
                                      {
                                        pattern:
                                          "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
                                        message:
                                          "Please Enter A Valid PAN No",
                                      },
                                    ]}
                                  >
                                    <Input
                                    onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                     size="large" placeholder="Enter PAN Number" className="inputbg" />
                                  </Form.Item>
                                </Col>
                              }

                              {/* <Col md={7} sm={12} xs={24}>
                                <Form.Item
                                  name={"AdditionalRequests_"}
                                  label="Additional Requests"
                                >
                                  <Input
                                    size="large"
                                    className="inputbg"
                                    onBlur={(e) => {
                                      setOnlyPassengersDetails({
                                        ...onlyPassengersDetails,
                                        additionalRequests: e.target.value,
                                      });
                                    }}
                                    placeholder="Mention Additional info"
                                  />
                                </Form.Item>
                              </Col> */}
                              
                            </Row>
                          </div>
                        ) : null}
                      </div>

                      {/* <div style={{ marginTop: "30px" }} className="paxcontact_wrapper">
                        <h3 className="title">GST Details</h3>

                        <Row gutter={16}>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Company Name"
                              name="gstCompanyName"
                              rules={[
                                {
                                  required: gstRequired,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input size="large" placeholder="Enter Company Name" className="contacts-value-de" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="GST Number"
                              name="gstNumber"
                              rules={[
                                {
                                  required: gstRequired,
                                  message: "Required",
                                },
                                {
                                  pattern:
                                    "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",

                                  message: "Please Enter a Valid GST Number",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                className="contacts-value-de"
                                placeholder="GST Number"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Contact Number"
                              name="gstPhoneNo"
                              rules={[
                                {
                                  required: gstRequired,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                className="contacts-value-de"
                                placeholder="Contact Number"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Email"
                              name="gstEmailId"
                              rules={[
                                {
                                  required: gstRequired,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                className="contacts-value-de"
                                placeholder="Company Email"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Address"
                              name="gstAddressLine1"
                              rules={[
                                {
                                  required: gstRequired,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                className="contacts-value-de"
                                placeholder="Company Address"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                      </div> */}
                    </Card>
                  </div>
                  {/* {insuranceData.status === 1 &&
                  insuranceData?.serviceType === 3 ? (
                    <div className="paxinfo_wrapper">
                      <Card className="insurance-section">
                        <h5>THE SMART INSURANCE COVER</h5>
                        <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                        <Row gutter={[16, 16]} align="stretch" className="mt-4">
                          <Col md={8} sm={24} xs={24}>
                            <div className="insurance-box">
                              {insuranceData.description_box_1}
                            </div>
                          </Col>
                          <Col md={8} sm={24} xs={24}>
                            <div className="insurance-box">
                              {insuranceData.description_box_2}
                            </div>
                          </Col>
                          <Col md={8} sm={24} xs={24}>
                            <div className="insurance-box">
                              {insuranceData.description_box_3}
                            </div>
                          </Col>
                        </Row>

                        <div className="insurance-coverage">
                          <SafetyCertificateOutlined />
                          <span>
                            Insurance Coverage Amount :{" "}
                            {insuranceData.insuranceCoverage}
                          </span>
                        </div>

                        <Form.Item
                          name="insuranceRequired"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Insurance",
                            },
                          ]}
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) =>
                              handleInsuranceChange(e.target.value)
                            }
                            className="insurance-radio-wrapper"
                          >
                            <Row gutter={[16, 16]}>
                              <Col md={8} sm={24} xs={24}>
                                <Radio
                                  className={`radio-btn btn-insure  ${
                                    insuranceRequired === 1
                                      ? "btn-insure-active"
                                      : ""
                                  }`}
                                  value={1}
                                >
                                  Insure For ₹{insuranceData.amount}/pax
                                </Radio>
                              </Col>

                              <Col md={8} sm={24} xs={24}>
                                <Radio
                                  value={0}
                                  className={`radio-btn btn-risk ${
                                    insuranceRequired === 0
                                      ? "btn-risk-active"
                                      : ""
                                  }`}
                                >
                                  I'll Risk it
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                    </div>
                  ) : null} */}
                  {/* {carBookingData?.TripType != 4 ? null :
                    <div className="paxinfo_wrapper">
                      <h3 className="title" style={{ color: "#bd0c21" }}>Journey Details</h3>
                      <Card>
                        <div style={{ marginBottom: "30px" }} className="paxcontact_wrapper">
                          <h3 className="title" >Pick Up Details</h3>

                          <Row gutter={16}>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Pick Up From"
                                name="pickupType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Select placeholder="Pick Up From" size="large">
                                  <Option value={1}>Airport</Option>
                                  <Option value={2}>Port</Option>
                                  <Option value={3}>Railway Station</Option>
                                  <Option value={4}>Accomodation/Hotel</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Pick Up Address"
                                name="pickupAddress"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Pick Up Address"
                                  className="inputbg"
                                  size="large"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Pick Up Date"
                                name="pickupDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  placeholder="Pick Up Date"
                                  className="inputbg"
                                  size="large"
                                  disabledDate={disabledPastDate}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                        </div>
                        <div className="paxcontact_wrapper">
                          <h3 className="title">Drop Off Details</h3>

                          <Row gutter={16}>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Drop Off From"
                                name="dropOffType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Select placeholder="Drop Off From" size="large">
                                  <Option value={1}>Airport</Option>
                                  <Option value={2}>Port</Option>
                                  <Option value={3}>Railway Station</Option>
                                  <Option value={4}>Accomodation/Hotel</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Drop Off Address"
                                name="dropOffAddress"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Drop Off Address"
                                  className="inputbg"
                                  size="large"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Drop Off Date"
                                name="dropOffDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  size="large"
                                  placeholder="Drop Off Date"
                                  className="inputbg"
                                  disabledDate={disabledPastDate}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                        </div>
                        {/* <div className="paxcontact_wrapper mt-2">
                      <h3 className="title">GST Details</h3>

                      <Row gutter={16}>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Company Name"
                            name="gstCompanyName"
                            rules={[
                              {
                                required: gstRequired,
                                message: "Required",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Enter Company Name" className="contacts-value-de" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="GST Number"
                            name="gstNumber"
                            rules={[
                              {
                                required: gstRequired,
                                message: "Required",
                              },
                              {
                                pattern:
                                  "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",

                                message: "Please Enter a Valid GST Number",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              className="contacts-value-de"
                              placeholder="GST Number"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Contact Number"
                            name="gstPhoneNo"
                            rules={[
                              {
                                required: gstRequired,
                                message: "Required",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              className="contacts-value-de"
                              placeholder="Contact Number"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Company Email"
                            name="gstEmailId"
                            rules={[
                              {
                                required: gstRequired,
                                message: "Required",
                              },label
                            ]}
                          >
                            <Input
                              size="large"
                              className="contacts-value-de"
                              placeholder="Company Email"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Company Address"
                            name="gstAddressLine1"
                            rules={[
                              {
                                required: gstRequired,
                                message: "Required",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              className="contacts-value-de"
                              placeholder="Company Address"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                    </div> *
                      </Card>
                    </div>} */}
                </Col>

                <Col className="car-checkout-price-part">
                  <Card>
                    <div className="right_wrapper">
                      <h3 className="title1"> Fare Details</h3>
                      <FareDetails
                        selectedCarData={carBookingData}
                        isPromoVisible={true}
                      />
                    </div>
                  </Card>
                </Col>

                <Col style={{textAlign:"center"}} md={17} sm={16} xs={24}>

                  <Form.Item
                    name="agreement"
                    className="chk-boc-car"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject("Should accept agreement"),
                      },
                    ]}
                  >
                    <Checkbox>
                      I Agree To All The{" "}
                      <a
                        href="/termsofconditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {"  Terms & Conditions"}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacypolicy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Privacy Policy.
                      </a>
                    </Checkbox>
                  </Form.Item>

                  <div className="btn_wrapper">
                    <Button
                      type="primary"
                      onClick={() => {
                        updatePassengersData();
                      }}
                      style={{ backgroundColor: '#bd0c21', borderRadius: '4px', border: '#bd0c21' }}
                    >
                      Continue to Payment
                    </Button>
                  </div>

                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CarCheckout;
