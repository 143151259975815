import React, { useState, useEffect, useContext } from "react";
import { Button, Radio } from "antd";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../../common/providers/AuthProvider";
const CarSort = ({ carResultObj, setCarResultsList, type = "" }) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: { carSearchResultObj },
    updateCarSearchResultObj,
  } = useContext(GlobalStatesContext);

  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortCars(selSorting, "asc");
  };

  const getMinumumTimeFromCriteria = (selectedSort) => {
    let cars = [];
    let fare = 0;
    if (type === "transfer") {
      cars = carResultObj.data.cars[0];
      fare = carResultObj.data.cars[0].Vehicles[0].TransferPrice.PublishedFares;
    } else {
      cars = carResultObj.data.cars[0];
      fare = carResultObj.data.cars[0].BaseFare;
    }

    switch (selectedSort) {
      case "price":
        return fare;

      case "origin":
        return cars?.Source;

      case "destination":
        return cars?.Destination;
      case "carname":
        return cars.car.CarName;
    }
    return " ";
  };

  const { activeCurrency, currencyValue } = useCurrencyContext();

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  let Cars = carResultObj.data.cars;

  const sortCars = (selSorting, dir) => {
    let sortedList = [];

    sortedList = Cars.sort((CarA, CarB) => {
      if (selSorting == "price") {
        if (type === "transfer") {
          return dir === "asc"
            ? compareCars(
                CarA.Vehicles[0].TransferPrice.PublishedFares,
                CarB.Vehicles[0].TransferPrice.PublishedFares,
                selSorting
              )
            : compareCars(
                CarB.Vehicles[0].TransferPrice.PublishedFares,
                CarA.Vehicles[0].TransferPrice.PublishedFares,
                selSorting
              );
        } else {
          return dir === "asc"
            ? compareCars(CarA.BaseFare, CarB.BaseFare, selSorting)
            : compareCars(CarB.BaseFare, CarA.BaseFare, selSorting);
        }
      }
      if (selSorting == "carname") {
        return dir === "asc"
          ? compareCars(CarA.car.CarName, CarB.car.CarName, selSorting)
          : compareCars(CarB.car.CarName, CarA.car.CarName, selSorting);
      }
    });

    if (selSorting == "price" || selSorting == "carname") {
      if (sortedList.length) {
        setCarResultsList({ ...carSearchResultObj.data, cars: sortedList });
      }
    }
  };

  const compareCars = (a, b, selSorting) => {
    if (selSorting === "carname" || selSorting === "price") {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortCars(selSorting, dir);
    }
  };

  return (
    <div className="sort-block sort-bus-block-22">
      <div className="results-sort-block p-3">
        <p className="sort-header mobile-sort-22">Sort by:</p>
        <Radio.Group onChange={handleChange} value={sorting} className="ml-3" style={{display:"flex",width:"90%", justifyContent:"flex-end"}}>
          <Radio value={"price"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Fare {sorting === "price" ? getSortIcon(sortDirection) : ""}
                </p>
                <span>{activeCurrency} {currencyValue(getMinumumTimeFromCriteria("price"))}</span>
              </div>
            </span>
          </Radio>
          {!type === "transfer" && (
            <>
              <Radio value={"origin"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      Origin{" "}
                      {sorting === "origin" ? getSortIcon(sortDirection) : ""}
                    </p>
                    <span>{getMinumumTimeFromCriteria("origin")}</span>
                  </div>
                </span>
              </Radio>
              <Radio value={"destination"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      Destination{" "}
                      {sorting === "destination"
                        ? getSortIcon(sortDirection)
                        : ""}
                    </p>
                    <span>{getMinumumTimeFromCriteria("destination")}</span>
                  </div>
                </span>
              </Radio>
              <Radio value={"carname"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      Car Name{" "}
                      {sorting === "carname" ? getSortIcon(sortDirection) : ""}
                    </p>
                    <span>{getMinumumTimeFromCriteria("carname")}</span>
                  </div>
                </span>
              </Radio>
            </>
          )}

          {/* <Radio value={"duration"}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">Duration</p>
              </div>
            </span>
          </Radio> */}
        </Radio.Group>
      </div>
    </div>
  );
};

export default CarSort;
