import { Button, Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import ApiClient from "../../helpers/ApiClient";
import { handelAirCraftType } from "../Fleetmaster/Fleetmaster";
import ReactHtmlParser from "react-html-parser";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";


import "./visaPopulerDestination.scss";
const BASE = process.env.REACT_APP_BASE_URL;

const VisaPopulerDestinations = (props) => {
  const { promoType } = props;
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";

  const { activeCurrency, currencyValue } = useCurrencyContext();

  const [DataSource, setDataSource] = useState([]);
  const getallVisaRecords = () => {
    ApiClient.get("visa/getAllManageMasterVisa").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };

  useEffect(() => {
    getallVisaRecords();
  }, []);
  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {DataSource.length > 0 ? (
        <div className="offer-wrapper  mt-5 mb-4 popularDestinationsVisa">
          <div className="mb-5">
            <h3 className="title text-center " style={{color:"#bd0c21", fontSize:"30px"}}>Popular Visa's</h3>
          </div>

          <Slider {...MobileSlidersettings}>
            {DataSource.length
              ? DataSource.filter((item, index) => index <= 5).map(
                  (item, ind) => {
                    return (
                      <Col
                        key={"popularDest" + ind}
                        md={24}
                        xs={24}
                        className="mb-3"
                      >
                        <Link to={`/visa/result?country=${item.Country}`}>
                          <Card
                            className="visa-card orange-color"
                            bordered={false}
                          >
                            {item.Images.length ? (
                              <div className="visImage">
                                <img
                                  src={BASE + item.Images[0].Image.substring(1)}
                                />
                              </div>
                            ) : (
                              <div className="visImage">
                                <img src="" />{" "}
                              </div>
                            )}
                            <div className="visa-content mt-2">
                              <h3 className="visa-pkg-name">
                                {item.VisaName}
                                <span>{item.Continent}</span>
                              </h3>
                              <p className="visa-pkg-price">{activeCurrency} {currencyValue(item.Fees)}/-</p>
                            </div>
                          </Card>
                        </Link>
                      </Col>
                    );
                  }
                )
              : ""}
          </Slider>
        </div>
      ) : null}
    </>
  );
};

export const FleetMasterCarausal = () => {
  const history = useHistory();
  const handleRedirect = (item) => {
    history.push(`availableFleet?fleetId=${item.FleetId}`);
  };

  const [DataSource, setDataSource] = useState([]);
  const getallVisaRecords = () => {
    ApiClient.get("CharterFlights/FleetMaster").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };

  useEffect(() => {
    getallVisaRecords();
  }, []);
  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {DataSource.length > 0 ? (
        <div className="offer-wrapper1  mt-5 mb-4 fleerMasterCarausal">
          <div className="mb-3">
            <h3 className="title text-center ">Our Fleet</h3>
          </div>

          <Slider {...MobileSlidersettings}>
            {DataSource.length
              ? DataSource.filter((item, index) => index <= 5).map(
                  (item, ind) => {
                    return (
                      <Col key={"fleet" + ind} md={24} xs={24} className="mb-3">
                        <Card
                          className="fleet-card"
                          bordered={false}
                          onClick={() => {
                            handleRedirect(item);
                          }}
                        >
                          {item.Images.length ? (
                            <div className="fleetImage">
                              <img src={BASE + item.Images[0].substring(1)} />
                            </div>
                          ) : (
                            <div className="fleetImage">
                              <img src="" />{" "}
                            </div>
                          )}
                          <div className="fleet-content mt-2">
                            <h3
                              className="fleet-name"
                              onClick={() => {
                                handleRedirect(item);
                              }}
                            >
                              {item.AircraftModel}
                            </h3>
                            <p className="m-0 font-weight-bold aircraft-type">
                              {handelAirCraftType(item)}
                            </p>
                            <p>
                              {item.Description.length > 100
                                ? ReactHtmlParser(
                                    item.Description.substring(0, 100)
                                  )
                                : ReactHtmlParser(item.Description)}
                            </p>
                          </div>
                        </Card>
                      </Col>
                    );
                  }
                )
              : ""}
          </Slider>

          <div className="w-100 hidden-xs text-center fleet-btn-wrapper">
            <Button
              className="fleet-btn"
              size="large"
              onClick={() => {
                // history.push("/FleetMaster");
                window.open("/FleetMaster", "_blank");
              }}
            >
              Show More{" "}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VisaPopulerDestinations;
