import React, { useState, useEffect, useRef } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Collapse } from "antd";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import { useHistory } from "react-router-dom";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CountryList from "../../common/CountryList";
import moment from "moment";
import queryString from "query-string";
import { SearchOutlined } from "@ant-design/icons";
import "../Hotels/Hotels.scss";
import { useSytContext } from "../../common/providers/SytProvider";
import HotelAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/HotelAutoCompleteSelectByCategory";
import { useLocalStorage, useSessionStorage } from "../../helpers/useStorage";
import ActiveTabs from "../../pages/Hotels/HotelSearch/ActiveTabs";
const { RangePicker } = DatePicker;
const { Option } = Select;
const Hotels = (props) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";
  const [HotelsHide, setHotelsHide] = useState(true);
  const returnDateBox = useRef();
  const [showDate, setShowDate] = useState(false);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [checkInDate, setCheckInDate] = useState(moment());
  const [checkOutDate, setCheckOutDate] = useState(moment().add(1, "days"));
  const { Panel } = Collapse;
  const city = useRef(null);
  const dateBox = useRef(null);
  const ccode = useRef(null);
  const roomLimit = 6;
  const defHotelPaxInfo = [
    {
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    },
  ];

  const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
  const paxInfo = [...hotelPaxInfo];
  const validateMessages = {
    required: "",
  };

  const [rooms, setRooms] = useState(false);
  const searchBtn = useRef();
  const toggleCount = () => {
    setRooms((prev) => !prev);
  };
  const [roomlength, setroomlength] = useState(1);
  const addRoom = () => {
    paxInfo.push({
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    });
    setroomlength(paxInfo?.length);
    setHotelPaxInfo(paxInfo);
  };


  let updatePaxInfoFromChild = (paxInfo) => {
    setHotelPaxInfo(paxInfo);
  };

  let ondelete = (i) => {
    paxInfo.splice(i, 1)
    setHotelPaxInfo(paxInfo);
    setroomlength(i)
  }
  let getPaxCount = () => {
    return paxInfo.reduce(
      (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
      0
    );
  };

  const submitForm = (values) => {
    let formData = {
      checkInDate: moment(values.checkInDate).format(oriDateFormat),
      checkOutDate: moment(values.checkOutDate).format(oriDateFormat),
      hotelCityCode: values.hotelCityCode,
      roomGuests: JSON.stringify(hotelPaxInfo),
      nationality: values.nationality,
      countryCode: values.nationality,
      currency: "INR",
      traceId: "string",
    };

    const query = queryString.stringify(formData);

    recentSearches(query);

    history.push("/hotels/listing?" + query);

    if (props.modifySearch) {
      props.hotelSearchAPI();
      setHotelsHide(false);
    }
  };

  const [HotelRecentSearchesResults, setHotelRecentSearchesResults] =
    useSessionStorage("hotelRecentSearchesResults", []);

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);

      if (HotelRecentSearchesResults.length > 0) {
        let array = [];
        array = [...HotelRecentSearchesResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setHotelRecentSearchesResults([
            searchObj,
            ...array.filter(
              (item) => item.hotelCityCode !== searchObj.hotelCityCode
            ),
          ]);
        }

        return;
      }
    }

    setHotelRecentSearchesResults([searchObj]);
  };

  useEffect(() => {
    if (props.modifySearch) {
      setHotelsHide(false);
      const hotelSearchParams = queryString.parse(window.location.search);

      if (hotelSearchParams?.roomGuests) {
        var roomDetails = JSON.parse(hotelSearchParams?.roomGuests);
      } else {
        return;
      }
      form.setFieldsValue({
        hotelCityCode: hotelSearchParams.hotelCityCode,
        checkInDate: moment(hotelSearchParams.checkInDate, "YYYY-MM-DD"),
        checkOutDate: moment(hotelSearchParams.checkOutDate, "YYYY-MM-DD"),
        nationality: hotelSearchParams.nationality,
      });
      setHotelPaxInfo(roomDetails);
    }

  }, [window.location.search]);

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return ((currentDate < moment(checkInDate).add(1, 'days')) || (currentDate.valueOf() > moment(checkInDate).add(30, 'days')));
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = "";

    if (momentdate) {
      originDate = momentdate ? moment(momentdate).startOf("day") : "";
      setCheckInDate(momentdate);
    }
    setCheckOutDate(momentdate);
    const toDate = form.getFieldValue("checkOutDate");
    if (toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays <= 0) {
        let newTodate = moment(momentdate).add(1, "days");
        form.setFieldsValue({
          checkOutDate: newTodate,
        });
      }
    }
  };

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };

  const { topCities } = useSytContext();
  const [HotelTopCities, setHotelTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 2);

      if (data.length) {
        setHotelTopCities(data);
      } else {
        setHotelTopCities([]);
      }
    }
  }, [topCities]);
  const onupdate = (i) => {
    setroomlength(i);
  }


  return (
    <div className="hotels_search_box">
      <div className="hotels-wrapper-results hotelsearch-upper-mar-work"
        style={{ marginTop: "50px" }}>
        <Form
          form={form}
          initialValues={{
            nationality: "IN",
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
          }}
          className="hotel-search-form hotel-bg-panel-all"
          validateMessages={validateMessages}
          onFinish={submitForm}
        >
          <Row className="search-row">
            <Col md={7} xs={24} className="from-to-inputs hotel-select-jun">
              <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif", }}> Where</span>

              <HotelAutoCompleteSelectByCategory
                className="autosearch-hotel"
                formItemProps={{
                  name: "hotelCityCode",
                  rules: [
                    { required: true, message: "Please Specify The City" },
                  ],
                }}
                selectProps={{ size: "large", placeholder: "Enter City Name" }}
                api={"hotels-v2/searchhotelcity/"}
                refName={city}
                focusRef={dateBox}
                handleOnSubmit={handleOnSubmit}
                modifySearch={props.modifySearch ? props.modifySearch : false}
                topCities={HotelTopCities}
                recentKey="HotelRecentSearches"
              />
            </Col>

            <Col md={3} xs={24} className="hotel-select-jun">
              <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif", }}>Check In</span>

              <Form.Item
                name="checkInDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledOriginDate}
                  onChange={(date, dateString) => {
                    setShowDate((prev) => !prev);
                    if (date) {
                      onChangeOriginDate(date, dateString);
                      setShowReturnDate(true);
                      returnDateBox.current.focus();
                    }
                  }}
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => {
                    setShowDate((prev) => !prev);
                  }}
                  placeholder="Check In Date"
                  dropdownClassName="custom-mob-calendar"
                  inputReadOnly={true}
                  panelRender={(originalPanel) => {
                    return (
                      <div className="original-panel">
                        <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                          Check In Date
                        </p>
                        {originalPanel}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={3} xs={24} className="hotel-select-jun" >
              <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif" }}>Check Out</span>

              <Form.Item
                className="returnDate"
                name="checkOutDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear={false}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledDestDate}
                  ref={returnDateBox}
                  open={showReturnDate}
                  onOpenChange={() => {
                    setShowReturnDate((prev) => !prev);
                  }}
                  defaultPickerValue={checkOutDate}
                  placeholder="Check Out Date"
                  dropdownClassName="custom-mob-calendar"
                  inputReadOnly={true}
                  panelRender={(originalPanel) => {
                    return (
                      <div className="original-panel">
                        <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                          Check Out Date
                        </p>
                        {originalPanel}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>


            <Col md={3} xs={24} className="from-to-inputs hotel-select-jun">
              <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif" }}>Guests</span>

              <Form.Item>
                <Input
                  value={
                    "Rooms " + paxInfo.length + ", Guests " + getPaxCount()
                  }
                  size="large"
                  onClick={toggleCount}
                />
                <div className="add-room-block">
                  <div
                    className="pax-modal"
                    id="pax-modal"
                    style={{
                      display: rooms ? "block" : "none",
                    }}
                  >
                    <div className="pax-modal-wrapper">
                      <div className="pax-modal-arrow"></div>
                      <ul className="first-item">
                        <Collapse bordered={false} activeKey={(roomlength)} destroyInactivePanel={true}  >
                          {paxInfo.map((pax, index) => (

                            <Panel showArrow={false} collapsible={"header"} header={<div><span style={{ fontSize: "15px", fontWeight: "bold" }}>Room {index + 1}</span>    <br /><span>{pax.noOfAdults} Adults  </span><span>  {pax.noOfChilds} Children </span></div>} key={index + 1} extra={(index + 1) != 1 ? <div className="icons-hotel-addes"> <EditOutlined onClick={() => onupdate(index + 1)} style={{ marginLeft: "-10px", color: "blue" }} /><DeleteOutlined style={{ color: "red" }} onClick={() => ondelete(index)} /></div> : <div className="icons-hotel-addes" ><EditOutlined style={{ color: "blue" }} onClick={() => onupdate(index + 1)} /></div>}>
                              <HotelPassengers
                                pax={pax}
                                index={index}
                                updatePaxInfoFromChild={updatePaxInfoFromChild}
                                paxInfo={paxInfo}

                              /></Panel>
                          ))}

                        </Collapse>
                      </ul>

                      <div>
                        <div className="div-for-button-alignmernt" >
                          {roomlength < roomLimit ? (<a style={{ color: "red", fontWeight: "700", fontSize: "14px" }} onClick={addRoom} >+ Add Room</a>) : null}

                        </div>

                      </div>


                      <div>
                        <Button
                          block
                          className="pax-ready-btn"
                          onClick={() => {
                            toggleCount();
                            if (rooms) searchBtn.current.focus();
                          }}
                        >
                          Ready
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </Col>
            <Col md={3} xs={24} className="hotel-select-jun">
              <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif" }}>Nationality</span>
              <Form.Item
                name="nationality"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please Select Nationality",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Nationality"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {CountryList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Button
                size="large"
                className="primary-btn"
                ref={searchBtn}
                htmlType="submit"
              >
                <SearchOutlined /> Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {HotelsHide ?
        <ActiveTabs /> : null}
    </div >
  );
};

export default Hotels;
