import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./AccessPermissions.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { Modal, message } from "antd";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

const AccessPermissions = () => {
  const [form] = ANTD.Form.useForm();
  const { Content } = ANTD.Layout;
  const { Option } = ANTD.Select;
  const [modalVisible, setModalVisible] = useState(false);
  const [screenList, setScreenList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);

  const [formType, setFormType] = useState(-1);

  const [dataSource, setDataSource] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [accessID, setAccessID] = useState(-1);

  const { user } = useAuthContext();

  const getRolesList = () => {
    ApiClient.get("admin/role")
      .then((res) => {
        if (res.status == 200) {
          let filteredRoles = res.data?.filter(
            (item) => item.RoleID != 2 && item.RoleID != 5
          );
          setRolesList(filteredRoles);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getAccessList = () => {
    ApiClient.get("admin/accessPermissions")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ScreenArray: item.ScreenArray.split(",").map((i) => parseInt(i)),
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          let filteredAccessPermissions = data.filter(
            (item) => item.RoleID != 2 && item.RoleID != 5
          );
          setDataSource(filteredAccessPermissions);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getScreens = () => {
    ApiClient.get("admin/screen")
      .then((res) => {
        if (res.status == 200) {
          let filterbyCat = res.data.reduce((obj, v) => {
            obj[v.Category] = obj[v.Category] || [];
            obj[v.Category].push(v);
            return obj;
          }, {});
          setScreenList(filterbyCat);
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    getRolesList();
    getAccessList();
    getScreens();
  }, []);

  const showModal = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateRoleAccess(rec)}
            style={{ color: "#2e0000", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            onConfirm={() => {
              setVerifyModalVisible(true);
              setFormType(2);
              setAccessID(rec.ID);
            }}
            title="Are you sure to delete?"
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const columnsserviceoper = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "Role",
      dataIndex: "RoleID",
      render: (data) => getRoleName(data),
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
    },

    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
    },

    {
      title: "Actions",
      render: (item, rec) => tableActions(rec),
    },
  ];

  const submitData = (val) => {
    let data = {
      RoleID: val.RoleID,
      ScreenArray: JSON.stringify(val.checkboxGroup),
      CreatedBy: 0,
      Status: 0,
      ModifiedBy: 1,
    };

    isEditMode
      ? submitUpdateAccessPermissions(data)
      : submitAccessPermissions(data);
  };

  const submitAccessPermissions = (data) => {
    ApiClient.post("admin/accessPermissions", data)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Role Permission Added SuccessFully", 3);
          getAccessList();
          form.resetFields();
          setModalVisible(false);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else if (response.status == 409) {
          ANTD.message.error(response.message, 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const submitUpdateAccessPermissions = (data) => {
    ApiClient.put(`admin/accessPermissions/${accessID}`, {}, data)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Role Permission Updated SuccessFully", 3);
          setModalVisible(false);
          getAccessList();
          form.resetFields();
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else if (response.status == 409) {
          ANTD.message.error(response.message, 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateRoleAccess = (rec) => {
    form.resetFields();

    setIsEditMode(true);
    setAccessID(rec.ID);

    form.setFieldsValue({
      RoleID: rec.RoleID,
      checkboxGroup: rec.ScreenArray,
    });
    setModalVisible(true);
  };

  const getRoleName = (data) => {

    let roleName = data;
    rolesList.forEach((role) => {

      if (role.RoleID === data) {
        roleName = role.Name;
      }
    });
    return roleName;
  };

  function deleteRoleAccess(id) {
    ApiClient.delete("admin/accessPermissions/" + id)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Role Deleted  successfully", 3);
          getAccessList();
        }
      })
      .catch(() => { });
  }

  const onCheckAllChange = (e) => {
    e.target.checked
      ? setFormValues()
      : form.setFieldsValue({
        checkboxGroup: [],
      });
  };

  const setFormValues = () => {
    let screenIds = [];
    Object.keys(screenList).map((item) =>
      screenList[item].map((item) => {
        screenIds.push(Number(item.ScreenID));
      })
    );

    form.setFieldsValue({
      checkboxGroup: screenIds,
    });
  };

  const handleVerifyPassword = (values) => {
    ApiClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            deleteRoleAccess(accessID);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>
                            View Access Permissions{" "}
                            <HelpInfoHelper
                              screenName={"/admin/accesspermissions"}
                            />
                          </h5>
                          <p>{dataSource.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        <img src={search} alt="search" />
                        <img src={excel} alt="excel" />

                        {/*  <img src={exchange} alt="exchange" /> */}
                        <p className="add-icon" onClick={showModal}>
                          <PlusOutlined />
                        </p>
                      </div>
                    </div>

                    <div>
                      <ANTD.Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columnsserviceoper}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>
                  {isEditMode
                    ? "Update Access Permissions"
                    : "Add Access Permissions"}
                </strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header permissions-modal access-row-bc-21 modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <ANTD.Button
                key="add"
                type="primary"
                // onClick={form.submit}
                onClick={() => {
                  setVerifyModalVisible(true);
                  setFormType(1);
                }}
              >
                {isEditMode ? "Update" : "Add"}
              </ANTD.Button>
              <ANTD.Button key="submit" type="danger">
                Clear
              </ANTD.Button>
            </div>,
          ]}
        >
          <ANTD.Form layout="vertical" form={form} onFinish={submitData}>
            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Role"
                  name="RoleID"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter your Role",
                    },
                  ]}
                >
                  <ANTD.Select placeholder="Select Role">
                    {rolesList.map((item) => (
                      <Option key={item.RoleID} value={item.RoleID}>
                        {item.Name}
                      </Option>
                    ))}
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item name=" Pages">
                  <div className="checkbox-flex">
                    <ANTD.Checkbox onChange={onCheckAllChange}>
                      Select All
                    </ANTD.Checkbox>
                  </div>
                </ANTD.Form.Item>
              </ANTD.Col>
            </ANTD.Row>

            {Object.keys(screenList).length > 0 && (
              <>
                <ANTD.Form.Item
                  name="checkboxGroup"
                  rules={[
                    {
                      required: true,
                      message: "Check",
                    },
                  ]}
                >
                  <ANTD.Checkbox.Group>
                    {Object.keys(screenList).map((item) => (
                      <div key={item} className="mt-2">
                        <div>
                          <h5>{item}</h5>
                        </div>

                        <ANTD.Row gutter={[0, 16]}>
                          {screenList[item].map((cat) => (
                            <ANTD.Col key={cat.ScreenID} md={6} xs={24}>
                              <ANTD.Checkbox value={cat.ScreenID}>
                                {cat.DisplayName}
                              </ANTD.Checkbox>
                            </ANTD.Col>
                          ))}
                        </ANTD.Row>
                      </div>
                    ))}
                  </ANTD.Checkbox.Group>
                </ANTD.Form.Item>
              </>
            )}
          </ANTD.Form>
        </Modal>

        {verifyModalVisible && (
          <VerifyPasswordModal
            visible={verifyModalVisible}
            setVerifyModalVisible={setVerifyModalVisible}
            handleVerifyPassword={handleVerifyPassword}
          />
        )}
      </div>
    </div>
  );
};

export default AccessPermissions;
