import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";

import queryString from "query-string";
import { useLocalStorage } from "../../helpers/useStorage";
const { Option, OptGroup } = Select;
const BusAutoCompleteSelectByCategory = (props) => {
  const[topCities,settopCities]=useState([]);
  useEffect(() => {
    let value = props?.refName?.current?.props?.value;
    const citySearchParams = queryString.parse(value);

    if (!value || value == "" || value == " ") {
      return;
    }
    fetchData(citySearchParams?.cityName, citySearchParams?.cityId);
  }, [props?.refName?.current, props?.dataFlip]);

  const onSelect = () => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }
  };

  const [details, setDetails] = useState({
    data: [],
    fetching: false,
  });

  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityId) => {
    let arr = [];

    if (cityId != null) {
      results = results.filter((item) => item.Id == cityId);
    }
    results.forEach((result) => {
      arr.push({
        cityId: `cityName=${result.Name}&cityId=${result.Id}`,
        cityName: result.Name,
      });
    });

    return arr;
  };

  function fetchData(value, cityId = null) {
    if (value) {
      setDetails({ data: [], fetching: true });
      APIClient.get(`${props.api}${value}`)
        .then((res) => {
          if (res.status === 200 && res) {
            setDetails({
              data: createOptions(res.data, cityId),
              fetching: false,
            });
            let copy=[];
            if(props?.formItemProps?.name === "destination"){
           copy= res.topCities.filter((ele,ind)=>ind===res.topCities.findIndex(elem=>elem.destinationId===ele.destinationId))              
            }else{
              copy= res.topCities.filter((ele,ind)=>ind===res.topCities.findIndex(elem=>elem.sourceId===ele.sourceId)) 
            }
            settopCities(copy)

            return;
          }
          setDetails({ data: [], fetching: false });
        })
        .catch((error) => {
          console.error(error);
          setDetails({ data: [], fetching: false });
        });
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  const [BusRecentSearches, setBusRecentSearches] = useLocalStorage(
    props.recentKey,
    []
  );

  const recentSearches = (e) => {
    if (e) {
      const optionObj = details.data.find((item) => item.cityId == e);
      if (optionObj) {
        if (BusRecentSearches.length > 0) {
          let array = [];
          array = [...BusRecentSearches];
          if (array.length > 4) {
            array.pop();
          }

          if (optionObj) {
            setBusRecentSearches([
              optionObj,
              ...array.filter((item) => item.cityId !== e),
            ]);
          }

          return;
        }

        setBusRecentSearches([optionObj]);
      }
    }
  };

  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={(e) => {
          onSelect();
          recentSearches(e);
        }}
      >
        {/* {BusRecentSearches.length && (
          <OptGroup label="Recent Searches">
            {BusRecentSearches.map((d) => (
              <Option value={d.cityId} key={"recent" + d.cityId}>
                {d.cityName}
              </Option>
            ))}
          </OptGroup>
        )} */}
        {topCities?.length && (
          <OptGroup label="Top Cities">
            {topCities?.map((item, index) => {
              if (props?.formItemProps?.name === "destinationId") {
                return (
                  <Option value={item.destinationId} key={"topbusdest" + index}>
                    <div className="d-flex align-items-center justify-content-between  ">
                      <div>
                        <p className="m-0">
                          {item.destinationId.split("&")[0].split("=")[1]}
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              } else {
                return (
                  <Option value={item.sourceId} key={"topbus" + index}>
                    <div className="d-flex align-items-center justify-content-between  ">
                      <div>
                        <p className="m-0">
                          {item.sourceId.split("&")[0].split("=")[1]}
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              }
            })}
          </OptGroup>
        )}

        {details.data.length && (
          <OptGroup label="Search Results">
            {details.data.map((d) => (
              <Option value={d.cityId} key={"City" + d.cityId}>
                {d.cityName}
              </Option>
            ))}
          </OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default BusAutoCompleteSelectByCategory;
