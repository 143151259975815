import { Button, Col, Divider, List, Popover, Row, Modal, Tabs } from "antd";
import { React, useState } from "react";
import { useHistory } from "react-router-dom";
import carDoor from "../../../../assets/images/svg/car-door.png";
import "../CarsResultCards/CarsResultCards.scss";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";

const { TabPane } = Tabs;

const BASE = process.env.REACT_APP_BASE_URL;
const TransferCard = ({
  carObj,
  updateSelectedCarData,
  updateCarBookingData,
}) => {
  let [canclePolicy, setCanclePolicy] = useState(false);
  // console.log(carObj, "obj-f");
  let history = useHistory();
  const goToCarCheckout = (carObj) => {
    let transferObj = {
      ...carObj,
      pickUpLocation: carObj.PickUp.PickUpDetailName,
      dropLocation: carObj.DropOff.DropOffDetailName,
      type: "transfer",
    };
    updateSelectedCarData(transferObj);
    history.push("/cars/checkout");
  };
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const content = (Data) => {
    return Data.Vehicles.length > 0 ? (
      <div>
        <Row className="text-center">
          <Col xs={24}>
            <Divider>Fare Charges</Divider>
            <b>
              <p>
                Base Fare : {currencyValue(Data.Vehicles[0].TransferPrice?.totalBasePrice) ?? "00"}{" "}
                {activeCurrency}
              </p>
            </b>
            <b>
              <p>
                Tax : {currencyValue(Data.Vehicles[0].TransferPrice?.Tax) ?? "00"}{" "}
                {activeCurrency}
              </p>
            </b>
            {/* <b>
              <p>
                GST Amount : {currencyValue(Data.Vehicles[0].TransferPrice?.Gst )?? "00"} {activeCurrency}
              </p>
            </b> */}
            {/* <b>
              <p>
                Driver Allowance :{" "}
                {currencyValue(Data.Vehicles[0].TransferPrice?.NetFares) ?? "00"} {activeCurrency}
          </p> */}

            <h6
              className="d-flex justify-content-start"
              style={{ color: "Red" }}
            >
              {" "}
              TOTAL PRICE: {activeCurrency}{" "}
              {currencyValue(Data.Vehicles[0].TransferPrice?.PublishedFares ?? 0)}
            </h6>
          </Col>
          {/* <Col md={12} xs={24}>
            <Divider>Extra Fare Charges</Divider>
            <b>
              <p>
                Charge Per Extra KM : {price?.BasePrice ?? "00"} rs./km
              </p>
            </b>
            <b>
              <p>Late Night Charge : {price?.BasePrice ?? "00"} rs./km</p>
            </b>
            <b>
              <p>
                Late Pickup Charge : {price?.BasePrice ?? "00"} rs./km
              </p>
            </b>
            <b>
              <p>
                Early Pickup Charge : {price?.BasePrice ?? "00"} rs./km
              </p>
            </b>
          </Col> */}
          {/* <h6
            className="d-flex justify-content-start"
            style={{ color: "Red", width: "100%" }}
          >
            {" "}
            TOTAL PRICE: {activeCurrency}{" "}
            {currencyValue(Data.Vehicles[0].TransferPrice?.PublishedFares ?? 0)}
          </h6> */}
        </Row>
      </div>
    ) : null;
  };
  const cancePolicy = () => {
    setCanclePolicy(true);
    // setShowCancleModal(true);
  };
  // console.log(carObj.Description,'hemu')
  return carObj.Vehicles.length > 0 ? (
    <div>
      <Row className="cars-ratings-act align-items-center ">
        {/* <Col md={5} xs={24} className="overflow-hidden py-3">
          {carObj?.car?.CarImages ? (
            <img
              className="car-image"
              src={BASE + carObj?.car?.CarImages.substring(1)}
              alt=""
            />
          ) : (
            <img
              className="car-image"
              src={
                require("../../../../assets/images/carrental/car-rental-re-1.jpg")
                  .default
              }
              alt=""
            />
          )}
        </Col> */}

        <Col md={14} sm={24} xs={24} className="py-3">
          <div className="tran-hdr">
            {" "}
            <p className="nisha-cars">{carObj.Vehicles[0].Vehicle}</p>
            {/* <div className="sub-header-info mb-2">
            {" "}|{" "}<b>{carObj.Vehicles[0].VehicleMaximumPassengers} Max Pax(s) </b>{" "}
            </div> */}
          </div>
          <p className="mb-0">
            <b>Vehicle Includes</b>
          </p>
          <div className="extra-params d-flex ">
            <ul>
              {/* <li>{carObj.Vehicles[0]?.IncludedKMs} kms Included</li> */}
              <li>
                {carObj.Vehicles[0]?.VehicleMaximumPassengers ?? ""} Max Pax(s)              </li>
              <li>
                {carObj.Vehicles[0]?.VehicleMaximumLuggage ?? ""} Max Luggage
                Bags
              </li>
            </ul>
            {/* <ul>
              <li>{carObj.car.GPSAvailable ? "GPS Included" : "No GPS"}</li>
              <li>
                {carObj.car.FuelType === 0
                  ? "Petrol Car"
                  : carObj.car.FuelType === 1
                  ? "Diesel Car"
                  : carObj.car.FuelType === 2
                  ? "Electric Vehicle"
                  : null}
              </li>
            </ul> */}
          </div>
          <div className="seats-chair   w-100">
            <div>
              <ul>
                {/* <li>
                  <i className="fa fa-bullseye pr-2" aria-hidden="true"></i>
                  Auto Transmission
                </li> */}
              </ul>
            </div>
          </div>
        </Col>
        <Col md={5} sm={24} xs={24} className="overflow-hidden py-3">
          <div className="seats-chair-certi">
            <img
              className=""
              src={
                require("../../../../assets/images/certified-car.png").default
              }
              alt=""
            />
            <p>Top Rated Cabs & Chauffeurs</p>
          </div>
          <div className="cancleDetails">
            <a onClick={() => cancePolicy()}>Details</a>
          </div>
        </Col>
        <Col md={5} sm={24} xs={24} className="cars-btn-price">
          <div>
            <div className="price-drops">

              <h5 className="cost-of-values">
                {/* <i className="fa fa-inr" aria-hidden="true"></i> */}
                {activeCurrency} {currencyValue(carObj.Vehicles[0]?.TransferPrice.PublishedFares)}
              </h5>
            </div>

            <div className="debtndrops">
              <Button
                style={{ background: "linear-gradient(to right, #68f392, #0ab1f6)", color: "White", padding: '6px 40px', paddingLeft: 44 }}
                onClick={() => {
                  goToCarCheckout(carObj);
                }}
                className="button-wid-work"
              >
                Book
              </Button>
            </div>
            <div>
              <Popover
                placement="bottom"
                overlayClassName="CarFare1"
                content={content(carObj)}
              >
                <p className="packages-num">Fare Charges</p>
              </Popover>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={24} sm={24} xs={24}>
          <Modal
            title="Car Details"
            className="promo-modal-header modal-header-bg12"
            visible={canclePolicy}
            onOk={(e) => setCanclePolicy(false)}
            onCancel={(e) => setCanclePolicy(false)}
            footer={null}
            width={"1100px"}
          >
            <div className="car-data-mo">

              <Tabs>
                <TabPane tab="Car Pickup " key="1">
                  {carObj?.Description ?
                    <div className="info">
                      <p>{ReactHtmlParser(carObj?.Description)}</p>
                      <p><i class="fa fa-asterisk" aria-hidden="true"></i>{" "}{carObj?.Condition[0]}</p>
                    </div> :
                    <div className="info">
                      <span style={{ color: "#bd0c21" }}>Remember : </span>{carObj?.Condition[0]}
                    </div>}
                </TabPane>
                <TabPane tab="Cancellation Policy" key="2">
                  <div className="table-container">
                    <table className="my-table">
                      <thead>
                        <tr>
                          {/* <th>Charge</th>
                          <th>Charge Type</th>
                          <th>Currency</th> */}
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Charge</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carObj.Vehicles[0].TransferCancellationPolicy?.map((item, index) => (
                          <tr key={index}>
                            {/* <td>{item.Charge}</td> */}
                            {/* <td>{item.ChargeType}</td> */}
                            {/* <td>{item.Currency}</td> */}
                            <td>{moment(item.FromDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(item.ToDate).format("DD-MM-YYYY")}</td>
                            {/* <td>{item.ToDate}</td> */}
                            <td>{activeCurrency} {currencyValue(item.Charge)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div style={{ marginTop: "5px" }}>
                      <span style={{ color: "#bd0c21" }}>Remember : </span>Cancellation will be as per Company Policy, Refer to <span style={{ color: "#35459c" }}>Policy</span> for more info
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Policy" key="3">
                  <div>
                    <h6 style={{ color: "#35459c" }}>Cancellation Policy :</h6>
                    <p>Please be advised that our cancellation policy is currently in accordance with IST (Indian Standard Time) and follows the guidelines set forth by our company. However, it is important to note that the cancellation policy may be subject to change prior to any given transaction or reservation.
                      Our company reserves the right to modify, amend, or update the cancellation policy at its sole discretion. Any changes made to the cancellation policy will be effective immediately upon posting on our website or notification through other communication channels.
                      We understand the importance of transparency and will make reasonable efforts to communicate any modifications to the cancellation policy in a timely manner. We recommend reviewing our cancellation policy regularly to stay informed of any updates.
                      If you have any questions or concerns regarding our cancellation policy, please contact our customer support team for clarification.
                      Thank you for your understanding and cooperation.</p>
                    <p style={{ color: "#bd0c21" }}>demooutc</p>
                  </div>
                </TabPane>
                {/* <TabPane tab="Car Inspection" key="3">
                  {ReactHtmlParser(
                    carObj.HowToInspectTheCar ?? "No Information Found"
                  )}
                </TabPane>
                <TabPane tab="Terms & Conditions" key="4">
                  {ReactHtmlParser(
                    carObj.TermsAndConditions ?? "No  Information Found"
                  )}
                </TabPane>
                <TabPane tab="Required Documents" key="5">
                  {ReactHtmlParser(
                    carObj.RequiredDocumentsForPickup ?? "No  Information Found"
                  )}
                </TabPane> */}
              </Tabs>
            </div>
          </Modal>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default TransferCard;
