import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useSytContext } from "../providers/SytProvider";
import { AppConstants } from "../../helpers/constants";
import rightfingure from "../../assets/images/right-fingure.png";
import downfingure from "../../assets/images/down-fingure.png";
import APIClient from "../../helpers/ApiClient";
import Tumblr from "../../assets/images/footer/Tumblr.png";
import Pinterest from "../../assets/images/footer/Pinterest.png";
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined
} from "@ant-design/icons";
import "../footer/Footer.scss";


const Footer = () => {


  const { siteDetails } = useSytContext();
  const [socialmedialinks, setsocialmedialinks] = useState({});
  useEffect(() => {
    getSettingsData();
  }, [])
  const getSettingsData = () => {
    let userID = 1;
    APIClient.get(`admin/settings/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;
          setsocialmedialinks(settings.userSocialDetails);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const {
    isLogin: { flag, agent, isGuest },
    user,
    setUser,
    resetIsLogin,
  } = useAuthContext();
  let services = user?.Services ? user?.Services : [];
  return (
    <div id="footer">
      <div className="footer_links_wrapper e-hide">
        <div className="links_wrapper">
          <Row>
            <Col md={24}>
              <div className="social_links">
                <a href="#!">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-google"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </Col>
            <Col className="d-none" md={8}>
              <div className="social_fb_text">
                <Button variant="primary" size="sm">
                  <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                  Like
                </Button>
                <Button variant="primary" size="sm">
                  Share
                </Button>
                <small>
                  721k peope like this.<a href="#!">Sign Up</a>to see what your
                  friends like.
                </small>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer_bottom_section bottom-postion-ft">
        <div className="bg-ft-col-22">
          <Row className="add-logos-ft">
            <Col md={12} xs={24} className="ft-col-1">
              <Row>
                <Col md={24} xs={24}>
                  <h3 className="our-affi-c1-tr">
                    Our<span> Happy Travellers</span>
                  </h3>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>25000+</h3>
                  <p>Happy Travellers</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>5000+</h3>
                  <p>Memorable Tours</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>100+</h3>
                  <p>Awards</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>100%</h3>
                  <p>Reliable</p>
                </Col>
              </Row>
            </Col>
            <Col md={12} xs={24} className="ft-col-2">
              <Row>
                <Col md={24} xs={24}>
                  <h3 className="our-affi-c1">
                    Our <span>Affiliations</span>
                  </h3>
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-7.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-8.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-9.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-10.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-11.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-12.png").default}
                    alt=""
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer_social_info e-hide">
          <div className="antd-container">
            <ul>
              <li>
                <i className="fa fa-birthday-cake cake-icon"></i>
                <p>
                  <strong> 15 years</strong>
                  <br />
                  of experience
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong>50</strong>
                  <br />
                  countries
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong> 150 M</strong>
                  <br />
                  customers
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong>950</strong>
                  <br />
                  airlines
                </p>
              </li>
              <li>
                <i className="hotels-icon">H</i>
                <p>
                  <strong>1.3 M</strong>
                  <br />
                  hotels
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_bottom_selection1">
          <div >
            <div className="footer-icons-andpayments">
              <div className="footer-icons-andpayments3">
                <p style={{ color: "black", fontSize: "22px", margin: "0px 0px 0px", fontWeight: "600" }}><span style={{ marginLeft: "6%" }}> FOLLOW US ON</span></p>
                <div className="social_wrapper">
                  <div className="social_icons">
                    <a href={socialmedialinks.FacebookUrl} target="_blank">
                      <img
                        className=""
                        src={
                          require("../../assets/images/facebook.png")
                            .default
                        }
                        alt=""
                      />                    </a>

                    <a href={socialmedialinks.Twitter} target="_blank">
                      <img
                        className=""
                        src={
                          require("../../assets/images/twitter.png")
                            .default
                        }
                        alt=""
                      />
                    </a>
                    <a href={socialmedialinks.Instagram} target="_blank">
                      <img
                        className=""
                        src={
                          require("../../assets/images/insta.png")
                            .default
                        }
                        alt=""
                      />
                    </a>
                    <a href={socialmedialinks.Youtube} target="_blank">
                      <img
                        className=""
                        src={
                          require("../../assets/images/youtube.png")
                            .default
                        }
                        alt=""
                      />
                    </a>

                  </div>
                </div>
                <div className="footer-icons-andpayments1">
                  <p style={{ color: "black", fontSize: "22px", margin: "0px 0px 0px", fontWeight: "600" }}><span style={{ marginLeft: "6%" }}> PAYMENT & SECURITY</span></p>

                  <div className="pay-ment-img">
                    <ul className="footer-visa-images">
                      <li>
                        {" "}
                        <img
                          className=""
                          src={
                            require("../../assets/images/visa-footer-1.jpg")
                              .default
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        {" "}
                        <img
                          className=""
                          src={
                            require("../../assets/images/visa-footer-2.jpg")
                              .default
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        {" "}
                        <img
                          className=""
                          src={
                            require("../../assets/images/visa-footer-3.jpg")
                              .default
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        {" "}
                        <img
                          className=""
                          src={
                            require("../../assets/images/visa-footer-4.png")
                              .default
                          }
                          alt=""
                        />
                      </li>
                    </ul>


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_countries countries-top" style={{ borderTop: "1px solid grey" }}>
          <div className="antd-container">
            <div className="countries-top-section">
              <div className="countries_links">
                <div className="footr-inter-sites">
                  <Row>
                    <Col md={5} xs={24}>
                      <p>Customer Support</p>
                      <ul className="cms-pages-links">



                        <li>
                          <Link to="/"> <img className="img-fingures-css" src={rightfingure} /><a href="tel:+91">+91 9704201143</a></Link>
                        </li>

                       
                      </ul>
                    </Col>
                    <Col md={5} xs={24}>
                      <p>outcbeta.com</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/about-us">About Us </Link>
                        </li>

                        <li>
                          <Link to="/careers">Careers </Link>
                        </li>
                        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                        <li>
                          <Link to="/web-check-in">Web Check In </Link>
                        </li>:null}
                         {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                        <li>
                          <Link to="/flightenquiry">Flight Enquiry</Link>
                        </li>:null}

                        
                      </ul>
                    </Col>

                    <Col md={5} xs={24}>
                      <p>Help</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/terms&conditions">
                            Terms & Conditions{" "}
                          </Link>
                        </li>

                        <li>
                          <Link to="/privacypolicy">Privacy Policy </Link>
                        </li>


                        <li>
                          <Link to="/Refundpolicy">Refund Policy </Link>
                        </li>
                        <li>
                          <Link to="/faqs">FAQ's </Link>
                        </li>

                      </ul>
                    </Col>

                    <Col md={5} xs={24}>
                      <p>Our Products</p>
                      <ul className="cms-pages-links">
                       {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                        <li>
                          <Link to="/">Flights </Link>
                        </li>:null}
                         {((user?.Role?.Name === "Agent" && user?.Services.includes("2")) || agent === false) ?
                        <li>
                          <Link to="/Hotels">Hotels </Link>
                        </li>:null}
                         {((user?.Role?.Name === "Agent" && user?.Services.includes("3")) || agent === false) ?
                        <li>
                          <Link to="/holidays">Holiday Packages</Link>
                        </li>:null}
                        <li>
                          <Link to="/offers">Best Offers </Link>
                        </li>

                      </ul>
                    </Col>
                    <Col md={4} xs={24}>
                     
                      <p>OUTCBETA</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/contact_us_form">Contact Us</Link>
                        </li>
                      </ul>
                    </Col>



                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright" style={{ color: "black" }}>
            Copyright &copy;2024 {AppConstants.DOMAIN_NAME}. All rights
            reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
