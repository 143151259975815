import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../../components/banner/Banner";
import imgimg from '../../assets/images/banner-img-wor.webp';
import Homesearch from "../../components/search-box/SearchBox";
import "./Home.scss";
import TopHotels from "../../pages/Hotels/TopHotels/TopHotels";
import { useSytContext } from "../../common/providers/SytProvider";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import AgentHomePage from "../../components/Agenthomepage/Agenthomepage";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Subscribe from "../subscribe/Subscribe";
import Reviews from "../../common/Reviews/Reviews";
import FooterBanner from "./FooterBanner/FooterBanner";
import HomeSlider from "./HomeSlider/HomeSlider";
import TopDomesticRoutes from "./TopDomesticRoutes/topDomesticRoutes";
import OffersSlider from "../../common/LandingPageOffers/LandingPageOffers";
//import CombineServLink from "../../common/CombineServicesLink/CombineServLink";
const Home = () => {
  const {
    blog,
    flightBanners,
    footerBanner,
    bigHomeSliderBanners,
    smallHomeSliderBanners,
    promoDataSource,
  } = useSytContext();

  const [dataSource, setDataSource] = useState([]);
  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();

  const [dealsList, setDealsList] = useState([]);

  const getDeals = () => {
    ApiClient.get("admin/getAllServicesTopCities")
      .then((res) => {
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };


  useEffect(() => {
    getDeals();
    let data = sessionStorage.getItem("recentSearchesResults");
    getDeal()
    if (data?.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 1);

      setDataSource(data);
    }

  }, [promoDataSource]);

  return (
    <div id="home-page" >
      <section>
      {(agent && user?.Role?.RoleId === 5) || user?.Role?.RoleId === 7 ? (
        <>
          <div className="work-agent-baer">
            <Banner banner={flightBanners} />
          </div>
          {(isGuest || user?.Services.includes("1")) && (
            <AgentHomePage />
          )}
        </>
      ) : (
        <section className="hero-banner">
          {/* <h2 className='upperheading-of-searchlinkbar' >The world’s best escapes, handpicked by experts</h2> */}

          <Banner banner={flightBanners} />
          {/* <CombineServLink activetab={0}
          /> */}
           <Homesearch />
        </section>
      )}
      {!agent ? (
      <div className="heading-for-over-banner">
        <h1>Rest Journey in Single step, in the breathtaking</h1>
      </div>
      ) : null}
      </section>
      {/* <section  className="main-box-dis-work-section1">

      </section> */}

      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Sig up & save upto 70%</strog></h3>
        <div className="main-box-dis-work3" >
          <div className="main-box-dis-work" >
            <div className="main-box-dis-work1" >
              <i class='fa fa-tag'  className=" "style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
            <div className="main-box-dis-work1" >
              <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
            <div className="main-box-dis-work1" >
              <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
            <div className="main-box-dis-work1" >
              <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
          </div>
          <div className="main-box-dis-work2" >
            <img src={imgimg} style={{width:'100%'}} />
          </div>
        </div>


        <div className="liks-about-sigup">
          <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
          {flag === true || agent === true ? (null) : (
            <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>

      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto', backgroundColor:'white', borderRadius:'10px',padding:'1%' }}>
        <TopHotels />
      </section>

      {/* <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      <section className="container" >
        {footerBanner.length > 0 && (
          <div className="domestic-airsearch faqs-flight-sec">
            <FooterBanner footerBanner={footerBanner} />
          </div>
        )}
      </section>

      {/* {agent ? (null) : (
        <section>
          {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
            <OffersSlider dataSource={dataSource} serviceType="Flight" /> : null}
        </section>)} */}

      <section style={{width:'80%', backgroundColor:'white', margin:"auto"}}>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <HomeCarousel deal={deal} />
          : null}
      </section>
      {/* <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <TopDomesticRoutes deals={dealsList} type={"Flights"} />
          : null}
      </section> */}

     

      <section className="top-route-bb">
        <Reviews serviceType={1} />
      </section>


      <Subscribe />


    </div >
  );
};

export default Home;
