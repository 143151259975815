// import { getConvFee, getPromoDiscount } from "../promoConvhelper";
// import moment from "moment";

// export const formatFlightTime = (flightTime) => {
//   let [date, time] = flightTime.split("T");
//   let [hour, minute, seconds] = time.split(":");
//   return `${hour}:${minute}`;
// };

// export function getFlightPrice(ticketData) {
//   let flightFares = ticketData?.oneWayflightFare?.fares || [];

//   let baseAmount = 0;
//   let taxAmount = 0;
//   let convienenceFee = 0;
//   let discount = 0;
//   let RefundAmount = 0;
//   let insuranceTotal = 0;
//   let grandTotal = 0;
//   let postMarkup = 0;

//   if (ticketData?.postMarkup) {
//     postMarkup = Number(ticketData?.postMarkup);
//   }

//   if (
//     ticketData.tripType == "roundTrip" &&
//     ticketData.mappingType === "SEPARATE"

//   ) {
//     flightFares = [
//       ...ticketData.oneWayflightFare.fares,
//       ...ticketData.returnflightFare.fares,
//     ];
//   }


//   let base = flightFares
//     .filter((item) => item.FareTag == "Base")
//     .reduce((total, item) => {
//       if (item.PaxType == "ADT") {
//         return total + Number(Number(item.Amount) * ticketData.pax.adults);
//       } else if (item.PaxType == "CHD") {
//         return total + Number(Number(item.Amount) * ticketData.pax.childCount);
//       } else if (item.PaxType == "INF") {
//         return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
//       }
//     }, 0);

//   baseAmount = Number(base) + postMarkup;


//   let tax = flightFares
//     .filter((item) => item.FareTag == "Tax")
//     .reduce((total, item) => {
//       if (item.PaxType == "ADT") {
//         return total + Number(Number(item.Amount) * ticketData.pax.adults);
//       } else if (item.PaxType == "CHD") {
//         return total + Number(Number(item.Amount) * ticketData.pax.childCount);
//       } else {
//         return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
//       }
//     }, 0);
//   taxAmount = Number(tax);

//   let totalwithtax = Number(baseAmount) + Number(taxAmount);

//   if (ticketData.convienenceData) {
//     convienenceFee = getConvFee(ticketData.convienenceData, totalwithtax);
//   }

//   let totalAmount = totalwithtax + Number(convienenceFee);

//   if (ticketData.promoData) {
//     let discountValue = getPromoDiscount(ticketData.promoData, totalAmount);
//     discount = Number(discountValue).toFixed(2);
//   }
//   RefundAmount = ticketData.RefundAmount ?? 0;

//   if (
//     ticketData?.insuranceRequired === 1 &&
//     ticketData?.insuranceData?.serviceType === 1
//   ) {
//     insuranceTotal =
//       ticketData.passengers.length * Number(ticketData?.insuranceData.amount);
//   }

//   grandTotal = Number(
//     baseAmount +
//     taxAmount +
//     Number(convienenceFee) +
//     Number(insuranceTotal) -
//     discount
//   ).toFixed(2);

//   return {
//     baseAmount: Number(baseAmount).toFixed(2),
//     taxAmount: Number(taxAmount).toFixed(2),
//     convienenceFee: Number(convienenceFee).toFixed(2),
//     discount: Number(discount).toFixed(2),
//     RefundAmount: Number(RefundAmount).toFixed(2),
//     grandTotal,
//     insuranceTotal: Number(insuranceTotal).toFixed(2),
//   };
// }

// export const calculateDuration = (from, to) => {
//   let FromDate = moment(from);
//   let ToDate = moment(to);
//   let numberOfHours = ToDate.diff(FromDate, "hours");
//   let numerOfMinutes = ToDate.diff(FromDate, "minutes");
//   let days = Math.floor(numberOfHours / 24);
//   let Remainder = numberOfHours % 24;
//   let hours = Math.floor(Remainder);
//   let minutes = Math.floor(numerOfMinutes - 60 * numberOfHours);
//   let difference = "";
//   if (days > 0) {
//     difference += days === 1 ? `${days} d, ` : `${days} ds, `;
//   }

//   difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

//   difference +=
//     minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

//   return difference;
// };
import { getConvFee, getPromoDiscount } from "../promoConvhelper";
import moment from "moment";

export const formatFlightTime = (flightTime) => {
  let [date, time] = flightTime.split("T");
  let [hour, minute, seconds] = time.split(":");
  return `${hour}:${minute}`;
};

export function getFlightPrice(ticketData) {
  let flightFares = ticketData?.oneWayflightFare?.fares || [];
  let flightFar =ticketData?.returnflightFare?.fares || [];  
  let baseAmount = 0;
  let taxAmount = 0;
  let convienenceFee = 0;
  let discount = 0;
  let RefundAmount = 0;
  let insuranceTotal = 0;
  let grandTotal = 0;
  let postMarkup = 0;
  let totalmeal = 0;
  let totalbagg = 0;
  let totalseat = 0;


  if (ticketData?.postMarkup) {
    postMarkup = Number(ticketData?.postMarkup);
  }
  if (ticketData?.tripType.toUpperCase() === "MULTIDESTINATION") {
    baseAmount = ticketData?.multicityflightFare.baseFare;
    taxAmount = ticketData?.multicityflightFare.tax;
    convienenceFee = Number(ticketData?.convienenceData.amount);
    discount = Number(ticketData?.promoData.Discount);
    totalmeal = Number(ticketData?.multicityflightFare.totalMealAmount);
    totalbagg = Number(ticketData.multicityflightFare.totalBaggageAmount);
    totalseat = Number(ticketData?.multicityflightFare.totalSeatAmount);
    grandTotal = baseAmount + taxAmount + convienenceFee - discount + postMarkup + totalmeal + totalbagg;
  }
  else {
    if (
      ticketData?.tripType.toUpperCase() == "ROUNDTRIP" &&
      ticketData?.mappingType.toUpperCase() === "SEPERATE"
    ) {
      flightFares = [
        ...ticketData.oneWayflightFare.fares,
        ...ticketData.returnflightFare.fares
      ];
     // console.log(flightFares,"ghgh")
      flightFar = [ticketData.oneWayflightFare,
      ticketData.returnflightFare];
      
      }
    // else {
    //   flightFares = [...ticketData?.oneWayflightFare?.fares];
    // }
   
    

    // console.log([ticketData.returnflightFare], [ticketData.oneWayflightFare], "far");
    let base = flightFares
      .filter((item) => item.FareTag == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
      let base1 = flightFar
      .filter((item) => item.FareTag == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
    baseAmount = Number(base) + Number(base1) + postMarkup;

    let tax = flightFares
      .filter((item) => item.FareTag == "Tax")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
      let tax1 = flightFar
      .filter((item) => item.FareTag == "Tax")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
      
    taxAmount = Number(tax) + Number(tax1);


    let totalwithtax = Number(baseAmount) + Number(taxAmount);

    if (ticketData.convienenceData) {
      convienenceFee = getConvFee(ticketData.convienenceData, totalwithtax);
    }

    let totalAmount = totalwithtax ;

    if (ticketData.promoData) {
      let discountValue = getPromoDiscount(ticketData.promoData, totalAmount);
      discount = Number(discountValue).toFixed(2);
    }
    RefundAmount = ticketData.RefundAmount ?? 0;

    if (
      ticketData?.insuranceRequired === 1 &&
      ticketData?.insuranceData?.serviceType === 1
    ) {
      insuranceTotal =
        ticketData.passengers.length * Number(ticketData?.insuranceData.amount);
    }


    // grandTotal = Number(
    //   baseAmount +
    //   taxAmount +
    //   Number(convienenceFee) +
    //   Number(insuranceTotal) -
    //   discount
    // ).toFixed(2);
    totalmeal = Number(ticketData?.oneWayflightFare?.totalMealAmount ).toFixed(2);
    totalbagg = Number(ticketData?.oneWayflightFare?.totalBaggageAmount ).toFixed(2);
    totalseat = Number(ticketData?.oneWayflightFare?.totalSeatAmount).toFixed(2);
    grandTotal = Number(
      baseAmount +
      taxAmount +
      Number(convienenceFee) +
      Number(insuranceTotal) -
      discount+Number(totalbagg)+Number(totalmeal)+Number(totalseat)
    ).toFixed(2);
    // totalmeal = Number(meal);
    // totalbagg = Number(bagg);
    
  }
  return {
    baseAmount: Number(baseAmount).toFixed(2),
    taxAmount: Number(taxAmount).toFixed(2),
    convienenceFee: Number(convienenceFee).toFixed(2),
    discount: Number(discount).toFixed(2),
    RefundAmount: Number(RefundAmount).toFixed(2),
    grandTotal,
    totalmeal,
    totalbagg,
    totalseat,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
  }
}


export function getFlightPrices(invoiceData) {
  let flightFares = invoiceData?.oneWayflightFare?.fares || [];
  let flightFar =invoiceData?.returnflightFare?.fares || [];  
  let baseAmount = 0;
  let taxAmount = 0;
  let convienenceFee = 0;
  let discount = 0;
  let RefundAmount = 0;
  let insuranceTotal = 0;
  let grandTotal = 0;
  let postMarkup = 0;
  let totalmeal = 0;
  let totalbagg = 0;
  let totalseat=0;


  if (invoiceData?.postMarkup) {
    postMarkup = Number(invoiceData?.postMarkup);
  }
  if (invoiceData?.tripType.toUpperCase() === "MULTIDESTINATION") {
    baseAmount = invoiceData?.multicityflightFare.baseFare;
    taxAmount = invoiceData?.multicityflightFare.tax;
    convienenceFee = Number(invoiceData?.convienenceData.amount);
    discount = Number(invoiceData?.promoData.Discount);
    totalmeal = Number(invoiceData?.multicityflightFare.totalMealAmount);
    totalbagg = Number(invoiceData.multicityflightFare.totalBaggageAmount);
    grandTotal = baseAmount + taxAmount + convienenceFee - discount + postMarkup + totalmeal + totalbagg;
  }
  else {
    // if (
    //   invoiceData?.tripType.toUpperCase() == "ROUNDTRIP" &&
    //   invoiceData?.mappingType.toUpperCase() === "SEPERATE"
    // ) {
    //   flightFares = [
    //     ...invoiceData.oneWayflightFare.fares,
    //     ...invoiceData.returnflightFare.fares
    //   ];
    //   console.log(flightFares,"ghgh")
    //   flightFar = [invoiceData.oneWayflightFare,
    //   invoiceData.returnflightFare];
      
    //   }
    // else {
    //   flightFares = [...invoiceData?.oneWayflightFare?.fares];
    // }
   
    

    // console.log([invoiceData.returnflightFare], [invoiceData.oneWayflightFare], "far");
    let base = flightFares
      .filter((item) => item.FareTag == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * invoiceData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * invoiceData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * invoiceData.pax.infantCount);
        }
      }, 0);
      let base1 = flightFar
      .filter((item) => item.FareTag == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * invoiceData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * invoiceData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * invoiceData.pax.infantCount);
        }
      }, 0);
    baseAmount = Number(base) + Number(base1) + postMarkup;

    let tax = flightFares
      .filter((item) => item.FareTag == "Tax")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * invoiceData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * invoiceData.pax.childCount);
        } else {
          return total + Number(Number(item.Amount) * invoiceData.pax.infantCount);
        }
      }, 0);
      let tax1 = flightFar
      .filter((item) => item.FareTag == "Tax")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * invoiceData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * invoiceData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * invoiceData.pax.infantCount);
        }
      }, 0);
      
    taxAmount = Number(tax) + Number(tax1);


    let totalwithtax = Number(baseAmount) + Number(taxAmount);

    if (invoiceData.convienenceData) {
      convienenceFee = getConvFee(invoiceData.convienenceData, totalwithtax);
    }

    let totalAmount = totalwithtax ;

    if (invoiceData.promoData) {
      let discountValue = getPromoDiscount(invoiceData.promoData, totalAmount);
      discount = Number(discountValue).toFixed(2);
    }
    RefundAmount = invoiceData.RefundAmount ?? 0;

    if (
      invoiceData?.insuranceRequired === 1 &&
      invoiceData?.insuranceData?.serviceType === 1
    ) {
      insuranceTotal =
        invoiceData.passengers.length * Number(invoiceData?.insuranceData.amount);
    }


    // grandTotal = Number(
    //   baseAmount +
    //   taxAmount +
    //   Number(convienenceFee) +
    //   Number(insuranceTotal) -
    //   discount
    // ).toFixed(2);
    totalmeal = Number(invoiceData?.oneWayflightFare?.totalMealAmount ).toFixed(2);
    totalbagg = Number(invoiceData?.oneWayflightFare?.totalBaggageAmount ).toFixed(2);
    grandTotal = Number(
      baseAmount +
      taxAmount +
      Number(convienenceFee) +
      Number(insuranceTotal) -
      discount+Number(totalbagg)+Number(totalmeal)
    ).toFixed(2);
    // totalmeal = Number(meal);
    // totalbagg = Number(bagg);

    taxAmount= taxAmount + Number(convienenceFee) + Number(totalbagg) + Number(totalmeal) - Number(discount)
    
  }
  return {
    baseAmount: Number(baseAmount).toFixed(2),
    taxAmount: Number(taxAmount).toFixed(2),
    convienenceFee: Number(convienenceFee).toFixed(2),
    discount: Number(discount).toFixed(2),
    RefundAmount: Number(RefundAmount).toFixed(2),
    grandTotal,
    totalmeal,
    totalbagg,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
  }
}

export const calculateDuration = (from, to) => {
  let FromDate = moment(from);
  let ToDate = moment(to);
  let numberOfHours = ToDate.diff(FromDate, "hours");
  let numerOfMinutes = ToDate.diff(FromDate, "minutes");
  let days = Math.floor(numberOfHours / 24);
  let Remainder = numberOfHours % 24;
  let hours = Math.floor(Remainder);
  let minutes = Math.floor(numerOfMinutes - 60 * numberOfHours);
  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} d, ` : `${days} ds, `;
  }

  difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

  return difference;
};
