import React from "react";
import { Button, Card, Checkbox, Form } from "antd";
import PayGateway from "../../helpers/PayGateway";
import "./FlightsCheckout.scss";

const BookPayCard = (props) => {
  const [form] = Form.useForm();

  const goTo = () => {
    if (props.bookpaycardinfo === "flight-checkout") {
      props.redirectToPreview();
    }

  };
  const { Insurance } = props;

  return (
    <div className="book-pay-section">

      {props.pgDisplay ? (
        <PayGateway
          blockApiReq={props.blockApiReq}
          loadingSpin={props.loadingSpin}
        />
      ) : (
        <Form form={form} onFinish={goTo}>
          <div className="book-pay-tc">
            {props.bookpaycardinfo === "flight-checkout" ? (
              <>
                <Form.Item
                  name="remember"
                  rules={[
                    {
                      validator: (rule, value) => {
                        return value
                          ? Promise.resolve()
                          : Promise.reject("Accept Terms & Conditions");
                      },
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    I Understand and Agree To All The Rules, {" "}
                    <a
                      href="/terms&conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" Terms & Conditions"}{" "}
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacypolicy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    of OutcDemo. {" "}
                  </Checkbox>
                </Form.Item>
              </>
            ) : null}
          </div>
          <div className="book-fli-pay-btn">
            <Button
              disabled={props.isLoading}
              className="btn btn-md"
              htmlType="submit"

            >
              Continue to Pay
            </Button>
          </div>
        </Form>
      )}

    </div>
  );
};

export default BookPayCard;
