import React, { useState, useEffect } from "react";
import "./VisaFilter.scss";
import { Card, Checkbox, Collapse, Slider } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

const { Panel } = Collapse;

const VisaFilter = ({ visaSearchResultObj, setVisaList }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let {
    data: { Visas, filtersObj },
  } = visaSearchResultObj;
  let count = Visas?.length;

  const [resultCount, setResultCount] = useState(count);
  const [filters, setFilters] = useState({});

  const [priceRange, setPriceRange] = useState([]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    // document.getElementsByClassName("card-results-block")[0].scrollIntoView({
    //   behavior: "smooth",
    // });
  };

  const setDynamicFilters = () => {
    if (Object.keys(filtersObj).length > 0) {
      setFilters(filtersObj);
      setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
    }
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const applyFilters = () => {
    let visibleCount = 0;

    const visaTypeChecked = checkedFilters("visaType");
    const processingTimeChecked = checkedFilters("processingTime");
    const validityPeriodChecked = checkedFilters("Validity");

    let filteredList = Visas.map((Visa) => {
      let isVisible = true;

      const startingFare = Number(Visa.Fees);
      if (
        !(
          startingFare >= filters.price.minPrice &&
          startingFare <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }

      if (
        visaTypeChecked.length &&
        !visaTypeChecked.map((vType) => vType.id).includes(Visa.TypeofVisa)
      ) {
        isVisible = false;
      }

      if (
        processingTimeChecked.length &&
        !processingTimeChecked
          .map((vType) => vType.id)
          .includes(Visa.ProcessingTime)
      ) {
        isVisible = false;
      }

      if (
        validityPeriodChecked.length &&
        !validityPeriodChecked.map((vType) => vType.id).includes(Visa.Validity)
      ) {
        isVisible = false;
      }
      isVisible && visibleCount++;

      return { ...Visa, isVisible: isVisible };
    });

    filteredList = filteredList.filter((item) => item.isVisible);
    setVisaList({ ...visaSearchResultObj.data, Visas: filteredList });
    setResultCount(visibleCount);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  useEffect(() => {
    setDynamicFilters();
  }, []);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );

  return (
    <div className="side-bar">
      <Card>
        <div className="flight-filters slider-icon-1">
          <div className="flight-result-indicator">
            <p>
              {/* Showing {resultCount} Of {count} Visa */}
              We Found {resultCount} Visa Services
            </p>
          </div>
          <div className="overall-filter-container">
            <div className="overall-filter-header">
              <p className="filter-text">Filters</p>
              <p className="clear-text" onClick={setDynamicFilters}>
                Clear all
              </p>
            </div>
            <div className="overall-filter-body">
              <div className="stops-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel header="Price" key="1" extra={extraPanel("price")}>
                    <div className="slider-label">
                      <span>
                        {activeCurrency} {currencyValue(priceRange[0])}
                      </span>
                      <span>
                        {activeCurrency} {currencyValue(priceRange[1])}
                      </span>
                    </div>
                    {filters.price && (
                      <Slider
                        range
                        // step={10}
                        defaultValue={[
                          filters.price.minPriceRange,
                          filters.price.maxPriceRange,
                        ]}
                        value={priceRange}
                        min={filters.price.minPriceRange}
                        max={filters.price.maxPriceRange}
                        onChange={priceChangeHandler}
                        onAfterChange={priceChangeCompleteHandler}
                      />
                    )}
                  </Panel>
                </Collapse>
              </div>
              <div className="stops-filter">
                <Collapse
                  defaultActiveKey={["2", "3", "4"]}
                  expandIconPosition={"right"}
                >
                  <Panel
                    header={"Visa Type"}
                    key="2"
                    extra={extraPanel("visaType")}
                  >
                    {filters.visaType &&
                      filters.visaType.map((vType) => (
                        <p>
                          <Checkbox
                            checked={vType.isChecked}
                            onChange={(e) => onChange(e, "visaType", vType.id)}
                          >
                            {vType.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>

                  <Panel
                    header={"Processing Time"}
                    key="3"
                    extra={extraPanel("processingTime")}
                  >
                    {filters.processingTime &&
                      filters.processingTime.map((processtime) => (
                        <p>
                          <Checkbox
                            checked={processtime.isChecked}
                            onChange={(e) =>
                              onChange(e, "processingTime", processtime.id)
                            }
                          >
                            {processtime.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                  <Panel
                    header={"Validity Period"}
                    key="4"
                    extra={extraPanel("Validity")}
                  >
                    {filters.Validity &&
                      filters.Validity.map((validityPeriod) => (
                        <p>
                          <Checkbox
                            checked={validityPeriod.isChecked}
                            onChange={(e) =>
                              onChange(e, "Validity", validityPeriod.id)
                            }
                          >
                            {validityPeriod.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VisaFilter;
