import React, { useState, useEffect } from "react";
import "../Dashboard/Dashboard.scss";
import { Row, Col, Card, Skeleton, Collapse, Form, Button, DatePicker, Select, Flex, } from "antd";
import { Link } from "react-router-dom";
import Flightsalescount from "../../admin/Flightsalescount/Flightsalescount";
import DashboardNew from "../DasboardData/DashboardNew";
import HotelSalescount from "../../admin/Hotelsalescount/Hotelsalescount";
import Activitysalescount from "../../admin/Acvitiessalescount/Acvitiessalescount";
import FlightSalesCountSkeleton from "../Flightsalescount/FlightSalesCountSkeleton";

import HotelSalesCountSkeleton from "../Hotelsalescount/HotelSalesCountSkeleton";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import moment from "moment";

const { Panel } = Collapse;
const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    Holiday: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    Flights: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    Hotels: {
      recent_bookings: [],
      upcoming_bookings: [],
    },

    // userCounts: {},
  });
  const onChangeFromDate = (current, dateString) => {
    // Can not select days before today and toda
    setArrivalDate(dateString);
  };
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  const [dashboardNewData, setDashboardNewData] = useState({});
  useEffect(() => {
    getDashboardData();
    let initialSearchRoutes = {
      serviceType: "2",
      fromDate: null,
      toDate: null,
      option: "2",
      // api: null,
      // userId: (user.id == 1) ? null : user.id,
      // svc: "Search"
    };
    getDashboardNewData(initialSearchRoutes)
  }, []);
  const getDashboardData = () => {
    ApiClient.get("admin/dashboard")
      .then((res) => {
        if (res && res.status == 200) {
          setDashboardData((prev) => ({ ...prev, ...res.data }));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { Option } = Select;
  const [form] = Form.useForm();
  const handleTimeAndDatelist = (value) => {

    if (value === "5") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const getDashboardNewData = (obj) => {
    // console.log(obj, "obbb");
    ApiClient.get("admin/dashboardNewReports", obj)
      .then((res) => {
        if (res && res.status == 200) {
          setDashboardNewData(res?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onBusBookingSummarySubmit = (val) => {
    // console.log(val, moment(val.fromDate), "req")

    let SearchRoutesObj = {
      serviceType: val.serviceType ?? "-",
      fromDate: moment(val.fromDate).format("YYYY-MM-DD") ?? null,
      toDate: moment(val.toDate).format("YYYY-MM-DD") ?? null,
      options: val.searchType ?? "2"
      // api: (val.api == 'NONE' || val.api == undefined) ? null : val.api,
      // userId: val.userId ?? null,
      // userId: user.role.toUpperCase() === "ADMIN" ? (val.userId ?? null) : user.id,

    }

    getDashboardNewData(SearchRoutesObj)
    //console.log("bookingchecking",busBookingSummaryObj)
    // getDashboardNewData(busBookingSummaryObj);
  };


  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
    // return current && current > moment().endOf('day');

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
    // Can not select days before today and today
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };
  return (
    <div>
      {isLoading ? (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>
                Dashboard <HelpInfoHelper screenName={"/admin/dashboard"} />
              </h4>
            </div>
          </div>

          <div>
            <Row gutter={[16, 16]}>
              {/* card--1--open */}
              <Col xs={24} md={6}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>

              {/* card--1--close */}

              {/* card--4--open */}
              <Col xs={24} md={6}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>

              {/* card--4--close */}

              {/* card--5--open */}
              <Col xs={24} md={6}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>

              {/* card--5--close */}
            </Row>
          </div>
          {/* first--order--close--- */}

          {/* flight--sales--open */}

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <FlightSalesCountSkeleton />
              </Col>
            </Row>
          </div>

          {/* flight--sales--close */}



          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <HotelSalesCountSkeleton />
              </Col>
            </Row>
          </div>

        </div>
      ) : (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>Dashboard</h4>
            </div>

            <div className="dash-board-header-btn"></div>
          </div>

          <div className="order-count-cards">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <Card className="order-card card-bg-1">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">
                        Site Partners Count
                      </h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.agents_count}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-desktop dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>
                  <div className="bottom-dashboad-bdr">
                    <Link to="/admin/agentregistration">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>

              {/* card--1--close */}

              {/* card--4--open */}
              <Col xs={24} md={6}>
                <Card className="order-card card-bg-4">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Subscribers </h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.subscribers}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-clock-o dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>
                  <div className="bottom-dashboad-bdr-3">
                    <Link to="/admin/subscriberreport">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>



              <Col xs={24} md={6}>
                <Card className="order-card card-bg-b2">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Site Users Count</h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.users_count}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-user dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>

                  <div className="bottom-dashboad-bdr-4">
                    <Link to="/admin/siteusers">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {/* first--order--close--- */}


          {/* flight--sales--open */}
          <Collapse accordion className="my-2" defaultActiveKey={["overall"]}>
            <Panel header="Outc-Demo" key="overall" className="">
              <div className='form-for-dashboard'>
                <Form
                  form={form}
                  layout={"vertical"}
                  onFinish={onBusBookingSummarySubmit}
                >
                  <Row gutter={8} align="right" >



                    <Col md={6} sm={12} xs={24} >
                      <Form.Item

                        name="searchType"

                      >
                        <Select placeholder="select Status" onChange={handleTimeAndDatelist} defaultValue="2">
                          <Option value="1">Last One hour </Option>
                          <Option value="2">Today </Option>
                          <Option value="3">Yesterday </Option>
                          <Option value="4">Lastweek </Option>
                          <Option value="5">Custom Dates  </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {isDateDisplay ? (<>
                      <Col md={5} sm={12} xs={24}>
                        <Form.Item
                          // label=" "
                          name="fromDate"
                        >

                          <DatePicker
                            format="YYYY-MM-DD"
                            placeholder="From Date"
                            disabledDate={disabledFromDate}
                            onChange={(date, dateString) =>
                              onChangeFromDate(date, dateString)

                            }
                            style={{ width: "100%" }}

                          />
                        </Form.Item>
                      </Col>
                      <Col md={5} sm={12} xs={24}>
                        <Form.Item
                          name="toDate"

                        >

                          <DatePicker
                            format="YYYY-MM-DD"
                            placeholder="To Date"
                            disabledDate={disabledToDate}
                            onChange={(date, dateString) =>
                              onChangeToDate(date, dateString)

                            }
                            style={{ width: "100%" }}


                          />
                        </Form.Item>
                      </Col>
                    </>) : null}
                    <Col md={4} sm={12} xs={24}  >
                      <Button
                        key="add"
                        className="site-admin-btn-11"
                        type="primary"
                        onClick={form.submit}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col md={4} sm={12} xs={24} >

                      <Form.Item
                        // label="Service"
                        name="serviceType"

                      >
                        <Select placeholder="select Status" defaultValue="-">
                          <Option value="-">All</Option>
                          <Option value="1">Activities</Option>
                          <Option value="2">Flights </Option>
                          <Option value="3">Hotels </Option>
                          {/* <Option value="4">Lastweek </Option>
                          <Option value="5">Custom Dates  </Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

              </div>
              {/* <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <DashboardNew dashboardData={dashboardNewData} />
                  </Col>
                </Row>
              </div> */}
            </Panel>
            <Panel header="Flight Sales" key="flight" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                  <Flightsalescount flightsData={dashboardData.Flights} />
                    {/* <Flightsalescount flightsData={dashboardData.Flights} dashboardData={dashboardNewData} /> */}
                  </Col>
                </Row>
              </div>
            </Panel>
            <Panel header="Hotel Sales" key="hotels" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <HotelSalescount hotelData={dashboardData.Hotels} />
                  </Col>
                </Row>
              </div>
            </Panel>

          </Collapse>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
