import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"

const CustomFlightImg = ({ src, alt }) => {
  const [showImage, setShowImage] = useState(true);

  const [img, setimg] = useState(alt === 'IX1' ||alt === 'I51' || alt === 'YY' ? 'I5' : alt=== "6E" ?  "6E" : alt);
   console.log(alt,img,"hhh")
  return (
      <img
          src={ `http://www.gstatic.com/flights/airline_logos/70px/${img}.png`}
          //   style={{ height: "100%" }}
          alt="image"
          //className="airline-img"
          style={{width:25}}
          //onError={() => setShowImage(false)}
      />
  );
};
export default CustomFlightImg;