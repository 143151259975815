import React from "react";
import "./FlightsCheckout.scss";
import { Card, Col, Row, Modal, Tabs, Tooltip } from "antd";
import moment from "moment";
import travel from "../../assets/images/travel.png";
import plane from "../../assets/images/plane.png";
import ReactHtmlParser from "react-html-parser";

import ApiClient from "../../helpers/ApiClient";
import CustomFliImg from "../../common/CustomImg/CusFliLogo";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import chair from "../../assets/images/chair.png"
import Flight from "../SearchResults/Flight/Flight";

import { FlightTimings } from "../SearchResults/Flight/Flight";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useState } from "react";
import CustomFlightImg from "../../common/CustomImg/CustomFlightImg";
const DetailsCard = (props) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  let travelType = localStorage.getItem("airTravelType");
  let history = useHistory();
  const { TabPane } = Tabs;
  const goTo = () => {
    history.goBack();
  };
  const [baggage, setBaggage] = useState(false);
  const toggleBaggage = () => {
    setBaggage((prevState) => !prevState);
  };
  const [fareRules, setFareRules] = useState(false);

  const FlightCard = ({ flightInfo, flightSearchObj, type, travelType }) => {
    const [fareRulesResp, setFareRulesResp] = useState("");
    const [cmsFareRules, setCmsFareRules] = useState({});
    const [showFareRules, setShowFareRules] = useState(false);
    //console.log(flightInfo,flightSearchObj,"hhhhh")
    const showModal = () => {
      let obj = {
        airlineCode: flightInfo.airLine,
        fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
        roleID: user?.Role?.RoleId ?? 4,
        membershipID: user?.Membership ?? 1,
      };
      setShowFareRules(true);
      fetchFlightFares(obj);
    };
    const getCmsFareRules = (obj) => {
      ApiClient.get("admin/getFlightFareRules", obj)
        .then((res) => {
          if (res.status == 200) {
            setCmsFareRules(res.data);
          } else {
            setCmsFareRules({});
          }
        })
        .catch((e) => {
          setCmsFareRules({});
        });
    };
    const fetchFlightFares = (obj) => {
      let FareRuleObj = {
        flowType: "SEARCH",
        traceId: flightSearchObj?.traceId,
        flightId: flightInfo?.flightId,
        airTravelType: flightSearchObj?.airTravelType,
        priceId: flightInfo?.fareFamilies?.fareFamilies[0]?.fareId,
        mappingType: flightSearchObj?.resultsType,
        itineraryViewType: "1",
      };
      ApiClient.post("flights/airFareRules", FareRuleObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp.data.fareRules) {
                setFareRulesResp(resp);
              }
            }

          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
          }
        })
        .catch((err) => console.log(err));

    };


    return (
      <Card className="flight-details-card checkout_details_card">
        <Card className="inner-card" >
          <div className="type-flight"><p>{type === 1 && travelType != "multidestination" ? "RETURN" : "DEPART"}</p></div>
          <div className="inner-flight-card">
            <Row>
              <div className="travel-info">
                {type === 0 ?
                <img src={plane} alt="" /> : (travelType != "multidestination" && type === 1 ? <img className="return-plane" src={plane} alt="" /> : <img src={plane} alt="" />)}
                <span className="flightcheckout-mob-elepsis" >{flightInfo.originCity}-</span>
                <span className="flightcheckout-mob-elepsis">{flightInfo.destinationCity}</span>
                <span className="status">{flightInfo.isRefundable === true ? "Refundable" : "NonRefundable"}</span>
                {/* <div className="travel-date">
                  <span>{dateFormat(
                    flightInfo.flightSegments[0].departureDateTime,
                    "ddd-ddmmm"
                  )}{" . "} {flightInfo.flightSegments.length-1} Stops </span>
                </div> */}
                <div className="travel-date">
  <span style={{fontWeight:"bolder"}}>
    {dateFormat(
      flightInfo.flightSegments[0].departureDateTime,
      "ddd,ddmmm"
    )}
   <span style={{ fontWeight: 'bolder', margin: '0 4px',fontSize:"18px" }}>·</span>
    <span style={{fontWeight:"lighter"}}>{flightInfo.flightSegments.length - 1} Stops</span>  <span style={{ fontWeight: 'bolder', margin: '0 4px',fontSize:"18px" }}>·</span> <span style={{fontWeight:"lighter"}}>{flightInfo.duration}</span>
  </span>
</div>
                <div className="travel-date1">
                  <br /> <span>|{dateFormat(
                    flightInfo.flightSegments[0].departureDateTime,
                    "ddd-ddmmmyyyy"
                  )}{" "}</span>
                </div>
              </div>
            </Row>
            {flightInfo.flightSegments.map((flight, id) => {
              return (
               <>
                  <Row className="row-of-det-flight">
                  <Col className="col-icos-flight" >
                    <div className="flight-logo">
                    <CustomFlightImg
                        style={{width:12}}
                        // style={{ width: "50px", height: "50px" }}
                        src={flightInfo.airLineLogo}
                        alt={flightInfo.airLine}
                      />
                      {/* <img style={{ width: "35px", height: "35px" }} src={flightInfo.airLineLogo} alt=""></img> */}
                    </div>
                    <div className="flight-name">
                      <Tooltip title={flight.airLineName}>
                        <div className="airlinename">
                         <span style={{fontWeight:"bolder" ,color:"#000"}}>{flight.airLineName} </span>| {flight.marketingAirline}-{flight.flightNumber}
                        </div>
                      </Tooltip>
                      {/* <div className="airlinenumber">
                        {flight.marketingAirline}-{flight.flightNumber}
                      </div> */}
                    </div>

                  </Col>
                  </Row>
                  <Row className="row-of-det-flight" >
                  <Col className="mobmobmobmobmob" >
                    <div className="departure-start">
                      <div className="departure-time">{moment(flight.departureDateTime).format("HH:mm")}</div>
                      <div className="departure-info"><h4>{flight.originCity}({flight.origin})</h4>
                        <div>{dateFormat(
                          flight.departureDateTime,
                          "ddd-ddmmmyyyy"
                        )}{" "}</div>
                        <div><p>Terminal {-flight.departureTerminal}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-flight-det-pointsfromto">
                    <div className="travel-duration">
                      <div className="time">
                        <p>
                          {/* {flight.journeyDuration} */}
                         
                          </p> 
                        {/* <img src={plane} alt="" /> */}
                        <div className="line-l">
                          {/* <div className="cir1"></div>
                          <div className="cir2"></div> */}
                        </div>
                        {/* <div className="status">{flightInfo.isRefundable === true ? "Refundable" : "NonRefundable"} */}
                        {/* </div> */}

                      </div>
                    </div>
                  </Col>
                  <Col className="arrival-col mobmobmobmobmob">
                    <div className="departure-start">
                      <div className="departure-time">{moment(flight.arrivalDateTime).format("HH:mm")}</div>
                      <div className="departure-info"><h4>{flight.destinationCity}({flight.destination})</h4>
                        <div>{dateFormat(
                          flight.arrivalDateTime,
                          "dd mmm (ddd)"
                        )}{" "}</div>
                        <div><p>Terminal {-flight.arrivalTerminal}</p>
                        </div>
                      </div>
                    </div>


                  </Col>

                  <Col className="arrival-col mobmobmobmobmob">
                    <div className="departure-start">
                      <div className="departure-time1">Baggage</div>
                      <div className="departure-info1"><h4>per Traveller</h4>
                        
                      </div>
                    </div>


                  </Col>
                  <Col className="arrival-col mobmobmobmobmob">
                    <div className="departure-start">
                      <div className="departure-time1">Cabin</div>
                      <div className="departure-info1"><h4>{flight.cabinBaggage}</h4>
                       
                      </div>
                    </div>


                  </Col>
                  <Col className="arrival-col mobmobmobmobmob">
                    <div className="departure-start">
                      <div className="departure-time1">Check-in</div>
                      <div className="departure-info1"><h4>{flight.checkInBaggage}</h4>
                        
                      </div>
                    </div>


                  </Col>
                  
                 


                  <div className="layOver-time">

                    {id === flightInfo.flightSegments.length - 1 ? null : <p>
                      {moment.utc(moment(flightInfo.flightSegments[id + 1]?.departureDateTime).diff(moment(flight.arrivalDateTime))).format('HH:mm')} hr's LayOver in {flight.destinationCity}({flight.destination})</p>}
                  </div>
                  {id === flightInfo.flightSegments.length - 1 ? null : <div className="line-layOver"></div>}

                  </Row>
                
                </>

              )

            })}

            <div className="policy" style={{display:"flex"}}>
              <div className="fares"  style={{display:"flex"}}>
                <h4 onClick={showModal}>
                  <a>Flight Details</a>
                </h4>
                {flightInfo.flightSegments.map((flight, id) => (
        <div key={id} className="seat-info">
            <img src={chair} alt="Seat Icon" style={{width:20,marginLeft:10}} />
            {flight.noOfSeatAvailable}
        </div>
    ))}
              </div>
              <div>
                <Modal
                  title="Flight Details"
                  visible={showFareRules}
                  footer={[null]}
                  onOk={(e) => setShowFareRules(false)}
                  onCancel={(e) => setShowFareRules(false)}
                  className="flight-details-modal"
                >
                  <div className="all-times-local">
                    <Tabs
                      defaultActiveKey="1"
                      onTabClick={(str, e) => fetchFlightFares(str, e)}
                    >
                      <TabPane tab="Baggage" key="1">

                        <div className="baggage-table">

                          <div className="headerbg">
                            <h4>AirLine</h4>
                            <h4>Check-in Baggage</h4>
                            <h4>Cabin Baggage</h4>
                          </div>

                           {flightInfo?.fareFamilies?.fareFamilies?.map(
                            (flight, id) => {
                              return (
                                <Row className="baggage-info">

                                  <Col md={8} xs={24}>
                                    <div className="airtype">
                                      <div className="flight-logo-1">
                                        {/* <img
                                          style={{ width: "35px", height: "35px" }}
                                          src={flightInfo?.airLineLogo}
                                          alt=""
                                        ></img> */}
                                        <CustomFliImg
                        src={flightInfo.airLineLogo} alt=""
                      />
                                      </div>
                                      <div className="flight-name-bagg">
                                        <div className="airname">
                                          <p>{flightInfo.airLineName}</p>
                                        </div>
                                        <div className="airnumber">
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={8} xs={24}>
                                    <div className="Check-in-Bag">
                                      <p>{flight?.baggage[0]?.baggageInfo}</p>
                                    </div>
                                  </Col>
                                  <Col md={8} xs={24}>
                                    <div className="Cabin-in-Bag">
                                      <p>{flight?.baggage[0]?.cabinBaggageInfo}</p>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                        </div>

                      </TabPane>
                      {cmsFareRules?.cancelPolicyDescription ? (
                        <TabPane tab="Fare Rules" key="2">
                          <div className="fareRule-container">
                            <p>
                              {ReactHtmlParser(
                                cmsFareRules.cancelPolicyDescription
                              )}
                            </p>
                          </div>
                        </TabPane>
                      ) : (
                        <TabPane tab="Fare Rules" key="3">
                          <div className="fareRule-container">
                            {!fareRulesResp ? (
                              <p>Loading...</p>
                            ) : (
                              fareRulesResp.data.fareRules.map((rule, i) => {
                                return (
                                  <p key={i}>
                                    {ReactHtmlParser(rule.ruleDetails)}
                                  </p>
                                );
                              })
                            )}
                          </div>
                        </TabPane>
                      )}
                      {cmsFareRules?.reschedulePolicyDescription && (
                        <TabPane tab="Reschedule Policy" key="4">
                          <div className="fareRule-container">
                            <pre>
                              {ReactHtmlParser(
                                cmsFareRules.reschedulePolicyDescription
                              )}
                            </pre>
                          </div>
                        </TabPane>
                      )}

                      {cmsFareRules?.covidRulesDescription && (
                        <TabPane tab="Covid Rules Policy" key="5">
                          <div className="fareRule-container">
                            <pre>
                              {ReactHtmlParser(
                                cmsFareRules.covidRulesDescription
                              )}
                            </pre>
                          </div>
                        </TabPane>
                      )}
                      {cmsFareRules?.otherRulesDescription && (
                        <TabPane tab="Other Rules Policy" key="6">
                          <div className="fareRule-container">
                            <pre>
                              {ReactHtmlParser(
                                cmsFareRules.otherRulesDescription
                              )}
                            </pre>
                          </div>
                        </TabPane>
                      )}
                    </Tabs>
                  </div>
                </Modal>
              </div>
            </div>
          </div>

        </Card>
      </Card>
    );
  };

  return (
    <div className="flight-details-container">

      {/* <div className="change-flight-details">
        <h3><img src={travel} height={"35px"} width={"35px"} alt="" /> Flight Details</h3>
        <p
          onClick={() => {
            goTo();
          }}
        >
          <i class="fa fa-repeat" aria-hidden="true"></i> Change
        </p>
      </div> */}
      {props.selectedFlight.length > 0 ? (
        travelType === "oneWay" ? (
          <FlightCard flightInfo={props.selectedFlight[0]} travelType={props.travelType}
            flightSearchObj={props.flightSearchObj} />
        ) : (
          props.selectedFlight.map((flight, id) => (
            <FlightCard flightInfo={flight} type={id} travelType={props.travelType}
              flightSearchObj={props.flightSearchObj} />

          ))
        )
      ) : null}

    </div>
  );
};

export default DetailsCard;
