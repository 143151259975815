import React, { createContext, useContext, useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import { useLocalStorage } from "../../helpers/useStorage";

const CurrencyContext = createContext();

export function useCurrencyContext() {
  return useContext(CurrencyContext);
}

const CurrencyProvider = ({ children }) => {
  const [activeCurrency, setActiveCurrency] = useLocalStorage(
    "currency",
    "INR"
  );

  const [currencies, setCurrencies] = useState({ INR: 1 });

  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
         
        }
      })
      .catch((e) => {
        
      });
  }, []);

  const getCurrencyValue = (amount) => {
     
    return Number(
      parseFloat(amount) / parseFloat(currencies[activeCurrency])
    ).toFixed(2);
  };

  return (
    <CurrencyContext.Provider
      value={{
        activeCurrency: activeCurrency,
        currencyValue: (a) => getCurrencyValue(a),
        setActiveCurrency: (a) => setActiveCurrency(a),
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
