import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./VisaTypeSlider.scss";
import { Row, Col, Card } from "antd";

import moment from "moment";
import { Link } from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";
import noVisaImage from "../../assets/images/image-not-found-scaled-1150x647.png";
const BASE = process.env.REACT_APP_BASE_URL;

const VisaTypeSlider = ({ visaTypeList }) => {
  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: visaTypeList.length >= 4 ? 4 : visaTypeList.length,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* {visaTypeList.length > 0 ? (
        // <div className="mt-4    visa-Type-Slider ">
        //   <Slider {...MobileSlidersettings}>
        //     {visaTypeList.map((item, ind) => {
        //       // console.log(item,"lost");
        //       return (
        //         <div key={"visatypelist" + ind} className="mb-3">
        //           <div
        //             className="visatype-slider-card"
        //             style={{
        //               backgroundImage: `url(${
        //                 item.Images.length > 0
        //                   ? BASE + item.Images[0].substring(1)
        //                   : noVisaImage
        //               })`,
        //             }}
        //           >
        //             <p>{item.visaType}</p>
        //           </div>
        //         </div>
        //       );
        //     })}
        //   </Slider>
        // </div>
      ) : null} */}
    </>
  );
};

export const BlogCategoryCarousel = ({
  BlogCategoryList,
  filterBlogsByCategory,
}) => {
  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: BlogCategoryList.length >= 5 ? 5 : BlogCategoryList.length,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* {BlogCategoryList.length > 0 ? (
        // <div className="mt-4    visa-Type-Slider ">
        //   <Slider {...MobileSlidersettings}>
        //     {BlogCategoryList.map((item, ind) => {
        //       return (
        //         <div
        //           key={"visatypelist" + ind}
        //           className="mb-3"
        //           onClick={() => {
        //             filterBlogsByCategory(item.CategoryId);
        //           }}
        //         >
        //           <div
        //             className="visatype-slider-card"
        //             style={{
        //               backgroundImage: `url(${
        //                 item.CategoryImages
        //                   ? BASE + item.CategoryImages[0].substring(1)
        //                   : noVisaImage
        //               })`,
        //             }}
        //           >
        //             <p>{item.CategoryName}</p>
        //           </div>
        //         </div>
        //       );
        //     })}
        //   </Slider>
        // </div>
      ) : null} */}
    </>
  );
};
export default VisaTypeSlider;
