import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Modal,
  Upload,
  Collapse,
  Popover,
} from "antd";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useContext, useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import CountryList from "../../../common/CountryList";
// import { useHistory, Link } from "react-router";
import { GlobalStatesContext } from "../../../common/providers";
import "./visaCheckout.scss";
import VisaDetailsCard from "./visaDetailsCard";
import VisaFareDetails from "./visaFareDetails";
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";
const { Option } = Select;
const { Panel } = Collapse;
const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  // console.log(fileList, limit,
  // limitSize, "list2");
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, pdf,jpeg and png file!");
    }

    const checkFileSize = file.size < 1226400;
    if (!checkFileSize) {
      message.error("Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        className="img-upload"
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

// const uploadLogoButton = !isUploading ? (
//   <div>
//     <PlusOutlined />
//     <div className="ant-upload-text">Upload </div>
//   </div>
// ) : (
//   <div className="ant-upload-text">Loading... </div>
// );

// function beforeUpload(file) {
//   const checkJpgOrPng =
//     file.type === "application/pdf" ||
//     file.type === "image/png" ||
//     file.type === "image/jpeg" ||
//     file.type === "image/jpg";
//   if (!checkJpgOrPng) {
//     message.error("You can only upload jpg, pdf,jpeg and png file!");
//   }

//   const checkFileSize = file.size < 1126400;
//   if (!checkFileSize) {
//     message.error(" Image must be smaller than 1Mb!");
//   }

//   return checkJpgOrPng && checkFileSize;
// }

// function getImages(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

// function handleChange(info) {
//   setFileList(info.fileList);
//   if (info.file.status === "uploading") {
//     setIsUploading(true);
//     return;
//   }
//   if (info.file.status === "removed") {
//     setIsUploading(false);
//   }
//   if (info.file.status === "error") {
//     setIsUploading(false);
//     return;
//   }
//   if (info.file.status === "done") {
//     setIsUploading(false);
//   }
// }

// const handlePreview = async (file) => {
//   if (!file.url && !file.preview) {
//     file.preview = await getImages(file.originFileObj);
//   }
//   setPreviewVisible(true);
//   setPreviewImage(file.url || file.preview);

//   setPreviewTitle(file.name);
// };

// const handleCancel = () => {
//   setPreviewVisible(false);
// };

// return (
//   <>
//     <Form.Item name={formName}>
//       <Upload
//         name="image"
//         listType="picture-card"
//         className="avatar-uploader"
//         showUploadList={true}
//         fileList={fileList}
//         action={uploadActionUrl}
//         onChange={handleChange}
//         onPreview={handlePreview}
//         data={{ category: category }}
//         beforeUpload={beforeUpload}
//       >
//         {limit
//           ? fileList
//             ? fileList.length >= limitSize
//               ? null
//               : uploadLogoButton
//             : uploadLogoButton
//           : uploadLogoButton}
//       </Upload>
//     </Form.Item>
//     <Modal
//       destroyOnClose={true}
//       visible={previewVisible}
//       title={previewTitle}
//       footer={null}
//       onCancel={handleCancel}
//     >
//       <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
//     </Modal>
//   </>
// );
//         };
const VisaCheckout = () => {
  const history = useHistory();
  const {
    state: { visaBookingData },
    updateSelectedVisaData,
  } = useContext(GlobalStatesContext);

  const [checkoutForm] = Form.useForm();
  const mobile = useRef();
  // const areaCode = useRef();
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);

  const [visaCount, setVisaCount] = useState(1);
  const [pan, setPan] = useState([]);
  const [pasImage, setPasImage] = useState([]);
  const [passport, setPassport] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const getNumbersRange = (start, end) => {
    let array = [];
    for (let index = start; index < end; index++) {
      array.push(index);
    }
    return array;
  };
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const Passport = `"Scanned colour copy of first and last page of your valid Passport"`;
  const Pan = "Scanned colour copy of your valid Pan-Card";
  const PassPhoto =
    "Scanned colour copy of your passport size photograph with white background";
  const items = [
    {
      key: "1",
      label: "For Passport",
      children: <p>{Passport}</p>,
    },
    {
      key: "2",
      label: "For Pan-card",
      children: <p>{Pan}</p>,
    },
    {
      key: "3",
      label: "For Passport-size PhotoCopy",
      children: <p>{PassPhoto}</p>,
    },
  ];
  const handleAreaCodeChange = (value) => {
    setSelectedCountryCode(value);
    // When the area code changes, set the focus on the Mobile Number input field
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };

  // const updatepassengerData = () => {
  const onFinish = (values) => {
    // console.log(values.PassportUpload.fileList[0].response.data.filepaths[0], "form1");
    let Passport = [];

    if (values.PassportUpload) {
      if (values.PassportUpload.fileList.length > 0) {
        for (let i = 0; i < values.PassportUpload.fileList.length; i++) {
          // console.log("texvenu", i, values.PassportUpload.fileList);
          if (values.PassportUpload.fileList[i].response.status === 200) {
            // passportUpload =
            //   values.PassportUpload.fileList[0].response.data.filepaths[0];
            Passport.push(
              values.PassportUpload.fileList[i].response.data.filepaths[0]
            );
          }
        }
      }
    }
    let Pan = [];
    if (values.PANUpload) {
      if (values.PANUpload.fileList.length > 0) {
        if (values.PANUpload.fileList[0].response.status === 200) {
          Pan.push(values.PANUpload.fileList[0].response.data.filepaths[0]);
        }
      }
    }
    let ImgPass = [];

    if (values.PasImgUpload) {
      if (values.PasImgUpload.fileList.length > 0) {
        if (values.PasImgUpload.fileList[0].response.status === 200) {
          ImgPass.push(
            values.PasImgUpload.fileList[0].response.data.filepaths[0]
          );
        }
      }
    }

    checkoutForm
      .validateFields()
      .then((values) => {
        // console.log(values, "visacheck");
        let paxArray = [...Array(visaCount)].map((_, i) => ({
          firstName: values[`firstName_${i}`],
          lastName: values[`lastName_${i}`],
          dob: values[`dob_${i}`],
          nationality: values[`nationality_${i}`],
          passportNumber: values[`passportNumber_${i}`],
          title: values[`title_${i}`],
          email: values.emailId,
          mobile: values.mobileNo,
          areaCode: values.areaCode,
          uploadDocument: {
            // "pan": "string",
            // "passport": "string",
            // "photo": "string"
            pan: Pan ? Pan : "",
            photo: ImgPass ? ImgPass : "",
            passport: Passport ? Passport : "",
          },
          // PANUpload: PANUpload ? PANUpload : "",
          // imgUpload: imgUpload ? imgUpload : "",
          // passportUpload: passportUpload ? passportUpload : "",
          // passImg: values[`passpimage_${i}`],
          // panimg: values[`panpimage_${i}`],
          // photo: values[`image_${i}`],
          // if (values?.countryName) {
          //   let countryCode = CountryList.filter(
          //     (item) => item.name === values.countryName
          //   )[0].code;
          //   values: countryCode;
          // } else{
          //   values: "";

          // }
        }));

        if (paxArray.length === 0) {
          return;
        }

        updateSelectedVisaData({
          ...visaBookingData,
          passengers: paxArray,
          visaCount: visaCount,
        });
        // console.log(paxArray, "pax");
        history.push("/visa/visaReview");
      })
      .catch((e) => {
        if (e && e.errorFields && e.errorFields.length > 0)
          checkoutForm.scrollToField(e.errorFields[0].name);
      });
  };
  // useEffect(() => {
  //   // Close the panel when the key changes
  //   setActiveKey([]);
  // }, []);
  // const [activeKey, setActiveKey] = useState(['1']);
  // const onChange = (key) => {
  //   setActiveKey(key);
  // };
  return (
    <div className="buscheckout_bg">
      <div className="checkout-heading">
        <div
          // style={{ marginTop: "65px" }}
          fluid
          className="checkout-heading-container"
        >
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Select Other Service</span>
            </Link>
          </div>
          <h3>Fill the Form Below for Visa, and Book right now!</h3>
        </div>
      </div>
      <div className="visaCheckout">
        <Row gutter={18}>
          <Col md={24}>
            <Form
              form={checkoutForm}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError={true}
            >
              <Row gutter={18}>
                <Col className="car-checkout-detail-part">
                  <div className="left_wrapper">
                    <h3 className="title" style={{ color: "#bd0c21" }}>
                      {" "}
                      Visa Details
                    </h3>
                    <VisaDetailsCard visaBookingData={visaBookingData} />{" "}
                  </div>

                  <div className="pax-info-wrapper">
                    <h3 className="title" style={{ color: "#bd0c21" }}>
                      {" "}
                      Passenger Detail's
                    </h3>
                    <Card>
                      {visaBookingData.Entry == "Multiple" && (
                        <Form.Item
                          name="visa-count"
                          label="Applying Visa For (Pax)"
                          // style={{color:"#35459c", fontSize:"16px"}}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select No. of Pax's"
                            size="large"
                            onChange={(e) => {
                              setVisaCount(e);
                            }}
                            style={{ maxWidth: 250 }}
                          >
                            {getNumbersRange(1, 6).map((i) => (
                              <Option key={"count" + i} value={i}>
                                {i}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      <div className="paxcontact_wrapper">
                        <h3 className="title">Contact Details</h3>

                        <Row gutter={16}>
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              label="Phone number"
                              name="mobileNo"
                              className="phno"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                // {
                                //   minLength: 10,
                                //   maxLength: 10,
                                //   pattern: "^[0-9]{10}$",
                                //   message: "Must be 10 digits",
                                // },
                              ]}
                            >
                              <Input
                                ref={mobileRef}
                                addonBefore={
                                  <Form.Item
                                    style={{ width: "50px" }}
                                    name="areaCode"
                                    className="phno"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    placeholder="Select"
                                    noStyle
                                  >
                                    <Select
                                      showSearch
                                      showArrow={false}
                                      placeholder="Select ISD"
                                      style={{ width: "auto" }}
                                      onChange={handleAreaCodeChange}
                                      ref={areaCodeRef}
                                      focusRef={mobile}
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {CountryList.map((item) => (
                                        <Option
                                          className="visa-opt"
                                          key={item.dial_code}
                                          value={item.dial_code}
                                        >
                                          {item.dial_code}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                }
                                className="inputbg"
                                placeholder="Enter Mobile Number"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                size={"large"}
                                // style={{width:"70%"}}
                              />
                              {/* <Input placeholder="Please Enter Mobile Number" size="large" addonBefore="+91" className="inputbg" /> */}
                            </Form.Item>
                          </Col>
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              label="Email"
                              name="emailId"
                              rules={[
                                { required: true, message: "Required" },
                                { type: "email", message: "Invalid Email" },
                              ]}
                            >
                              <Input
                                placeholder="Please Enter Email-id"
                                size="large"
                                className="inputbg"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        {visaCount
                          ? [...Array(visaCount)].map((_, index) => (
                              <Row
                                className="pax-info-visa"
                                key={"paxINdex" + index}
                                gutter={8}
                              >
                                <Col md={24}>
                                  <p className="hdr-pax font-weight-bold mb-1">
                                    Passenger({index + 1})
                                  </p>
                                </Col>

                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={`title_${index}`}
                                    label="Title"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
                                      className="inputbg"
                                      size="large"
                                      placeholder="Title"
                                    >
                                      <Option value="Mr">Mr</Option>
                                      <Option value="Ms">Ms</Option>
                                      <Option value="Mrs">Mrs</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={10} sm={12} xs={24}>
                                  <Form.Item
                                    name={`firstName_${index}`}
                                    label="First Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter First Name"
                                      className="inputbg"
                                      autoComplete="off"
                                      onBlur={(e) => {}}
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={10} sm={12} xs={24}>
                                  <Form.Item
                                    name={`lastName_${index}`}
                                    label="Last Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Last name"
                                      className="inputbg"
                                      autoComplete="off"
                                      onBlur={(e) => {}}
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={`dob_${index}`}
                                    label="DOB"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      placeholder="DD-MM-YYYY"
                                      format={"DD-MM-YYYY"}
                                      style={{ width: "100%" }}
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={`passportNumber_${index}`}
                                    label="Passport Number"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please Enter passport number!",
                                      },
                                      {
                                        pattern: "^([a-zA-z]){1}([0-9]){7}?$",
                                        message:
                                          "Please Enter A Valid Passport Number",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Passport Number"
                                      className="inputbg"
                                      autoComplete="off"
                                      onBlur={(e) => {}}
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={`nationality_${index}`}
                                    label="Nationality"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    {/* <Input
                                    placeholder="Enter Your Nationality"
                                    className="inputbg"
                                    autoComplete="off"
                                    onBlur={(e) => { }}
                                    size="large"
                                /> */}
                                    <Select
                                      size="large"
                                      style={{ width: "100%" }}
                                      placeholder="Select Country"
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch
                                    >
                                      {CountryList.map((item) => (
                                        <Option
                                          key={item.name}
                                          value={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                {/* name={`passpimage_${index}`} */}
                                {/* <Col md={8} sm={12} xs={24}>

                                <Form.Item
                                  name={`passpimage_${index}`}
                                  label="Passport"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please upload Passport',
                                    },
                                  ]}
                                  valuePropName="fileList"
                                  getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                      return e;
                                    }
                                    return e && e.fileList;
                                  }}
                                >
                                  <Upload
                                    name="imagep"
                                    listType="picture"
                                    beforeUpload={(file) => {

                                      return false;
                                    }}
                                  >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col> */}
                                {/* name={`panpimage_${index}`} */}
                                {/* <Col md={8} sm={12} xs={24}>

                                <Form.Item
                                  name={`panpimage_${index}`}
                                  label="Pan"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please upload Passport',
                                    },
                                  ]}
                                  valuePropName="fileList"
                                  getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                      return e;
                                    }
                                    return e && e.fileList;
                                  }}
                                >
                                  <Upload
                                    name="imagepan"
                                    listType="picture"
                                    beforeUpload={(file) => {
                                     
                                      return false; 
                                    }}
                                  >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col> */}
                                {/* name={`image_${index}`} */}
                                {/* <Col md={8} sm={12} xs={24}>

                                <Form.Item
                                  name={`image_${index}`}
                                  label="Photo"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please upload Photo',
                                    },
                                  ]}
                                  valuePropName="fileList"
                                  getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                      return e;
                                    }
                                    return e && e.fileList;
                                  }}
                                >
                                  <Upload
                                    name="imagepass"
                                    listType="picture"
                                    beforeUpload={(file) => {
                                      
                                      return false; 
                                    }}
                                  >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col> */}

                                <Col md={24} sm={12} xs={24}>
                                  <div className="upload-img-11">
                                  <Form.Item
                                      // label="Passport"
                                      name="proof"
                                      rules={[
                                        {
                                          validator: (rule, value) => {
                                            const errorMessage =
                                              selectedCountryCode === "+91"
                                                ? "Please upload your PAN card details."
                                                : "Please upload your government ID.";
                                    
                                            return value ? Promise.resolve() : Promise.reject(errorMessage);
                                          },
                                        },
                                      ]} 
                                    >
                                    {selectedCountryCode === "+91" ? (
                                      <div>
                                        <Popover content="Original scanned color copy of PAN card details.">
                                          <h6 className="other-docrr agent-card-21 mb-3">
                                            Pan Card{" "}
                                            <span style={{ color: "#bd0c21" }}>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </h6>
                                        </Popover>

                                        <ImageUpload
                                          category="pan"
                                          fileList={pan}
                                          setFileList={setPan}
                                          formName="PANUpload"
                                          limit={true}
                                          limitSize={1}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <Popover content="Original scanned color copy of Govt.ID .">
                                          <h6 className="other-docrr agent-card-21 mb-3">
                                            Id Proof{" "}
                                            <span style={{ color: "#bd0c21" }}>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </h6>
                                        </Popover>

                                        <ImageUpload
                                          category="pan"
                                          fileList={pan}
                                          setFileList={setPan}
                                          formName="PANUpload"
                                          limit={true}
                                          limitSize={1}
                                        />
                                      </div>
                                    )}
                                    </Form.Item>
                                    <Form.Item
                                      // label="Passport"
                                      name="passport"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please upload your Passport.",
                                        },
                                      ]}
                                    >
                                      <div>
                                        <Popover content="Original Passport with at least 6 months validity and minimum 3 blank pages">
                                          <h6 className="other-docrr agent-card-21 mb-3">
                                            Passport{" "}
                                            <span style={{ color: "#bd0c21" }}>
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </h6>
                                        </Popover>
                                        
                                          <ImageUpload
                                            category="passport"
                                            fileList={passport}
                                            setFileList={setPassport}
                                            formName="PassportUpload"
                                            limit={true}
                                            limitSize={2}
                                          />
                                        
                                      </div>
                                    </Form.Item>
                                    <Form.Item
                                      // label="Passport"
                                      name="photo copy"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please upload your Photo.",
                                        },
                                      ]}
                                    >
                                    <div>
                                      <Popover content="3.5cm*5cm, 60 to 70% face appearance with white background">
                                        <h6 className="other-docrr agent-card-21 agency-22-trip mb-3">
                                          Photo Copy{" "}
                                          <span style={{ color: "#bd0c21" }}>
                                            <i
                                              class="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </h6>
                                      </Popover>
                                      <ImageUpload
                                        category="pasImage"
                                        fileList={pasImage}
                                        setFileList={setPasImage}
                                        formName="PasImgUpload"
                                        limit={true}
                                        limitSize={1}
                                        required
                                      />
                                    </div>
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                            ))
                          : ""}
                      </div>
                      <div className="note-upload">
                        <span className="hdr-note">Note</span>
                        {" : "}
                        <span className="dat">
                          Please Upload Original Scanned Color Copies of Each
                          Document Mentioned Above
                        </span>
                      </div>
                      {/* <div className="doc-info">
                        <h3 className="info-tit">Remember</h3>
                        <div className="info">
                      
                          
                          <Collapse accordion ghost onChange={onChange} activeKey={activeKey}>
                            {items.map((item) => (
                              <Collapse.Panel key={item.key} header={item.label}>
                                <p>{item.children}</p>
                              </Collapse.Panel>
                            ))}
                          </Collapse>

                        </div>
                      </div> */}

                      {/* <Row gutter={16} className="main-row-chec-page">
                        <Col md={12} sm={12} xs={24} className="col-main-check-page">
                          <Form.Item
                            label="Phone number"
                            name="mobileNo"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Required",
                              },
                              // {
                              //   minLength: 10,
                              //   maxLength: 10,
                              //   pattern: "^[0-9]{10}$",
                              //   message: "Must be 10 digits",
                              // },
                            ]}
                          >
                            <Input
                              ref={mobileRef}
                              addonBefore={
                                <Form.Item
                                  style={{ width: "50px" }}
                                  name="areaCode"
                                  className="phno"
                                  rules={[
                                    {
                                      required: true,

                                    },
                                  ]}
                                  placeholder="Select"
                                  noStyle
                                >
                                  <Select
                                    showSearch
                                    showArrow={false}
                                    placeholder="Select ISD"
                                    style={{ width: "auto" }}
                                    onChange={handleAreaCodeChange}
                                    ref={areaCodeRef}
                                    focusRef={mobile}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {CountryList.map((item) => (
                                      <Option
                                        className="visa-opt"
                                        key={item.dial_code}
                                        value={item.dial_code}
                                      >
                                        {item.dial_code}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              }
                              className="inputbg"
                              placeholder="Enter Mobile Number"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              size={"large"}
                            // style={{width:"70%"}}
                            />
                            {/* <Input placeholder="Please Enter Mobile Number" size="large" addonBefore="+91" className="inputbg" /> *
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                          <Form.Item
                            label="Email"
                            name="emailId"
                            rules={[
                              { required: true, message: "Required" },
                              { type: "email", message: "Invalid Email" },
                            ]}
                          >
                            <Input placeholder="Please Enter Email-id" size="large" className="inputbg" />
                          </Form.Item>
                        </Col>
                      </Row> */}
                    </Card>
                  </div>

                  {/* <div className="paxcontact_wrapper">
                    <h3 className="title">Contact Details</h3>
                    <Card>
                      <Row gutter={16}>
                        <Col md={10} sm={12} xs={24}>
                          <Form.Item
                            label="Phone number"
                            name="mobileNo"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Required",
                              },
                              {
                                minLength: 10,
                                maxLength: 10,
                                pattern: "^[0-9]{10}$",
                                message: "Must be 10 digits",
                              },
                            ]}
                          >
                            <Input addonBefore="+91" className="inputbg" />
                          </Form.Item>
                        </Col>
                        <Col md={10} sm={12} xs={24}>
                          <Form.Item
                            label="Email"
                            name="emailId"
                            rules={[
                              { required: true, message: "Required" },
                              { type: "email", message: "Invalid Email" },
                            ]}
                          >
                            <Input className="inputbg" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </div> */}
                </Col>

                <Col className="car-checkout-price-part">
                  <Card style={{ marginTop: "30px" }}>
                    <div className="right_wrapper">
                      <h3 className="title1"> Fare Details</h3>
                      <VisaFareDetails
                        visaBookingData={visaBookingData}
                        isPromoVisible={true}
                        visaCount={visaCount}
                      />
                    </div>
                  </Card>
                </Col>

                <Col className="car-checkout-detail-part">
                  <div className="agreement-card">
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Should accept agreement"),
                        },
                      ]}
                    >
                      <Checkbox>
                        I Agree To All The{" "}
                        <a
                          href="/termsofconditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {"  Terms & Conditions"}
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacypolicy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy.
                        </a>
                      </Checkbox>
                    </Form.Item>

                    <div className="btn_wrapper">
                      <Button
                        type="primary"
                        onClick={() => checkoutForm.submit()}
                        // onClick={() => {
                        //   updatepassengerData();
                        // }}
                        style={{
                          backgroundColor: "#bd0c21",
                          borderRadius: "8px",
                          border: "#bd0c21",
                        }}
                      >
                        Continue to Book
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VisaCheckout;
