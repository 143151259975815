// import * as ANTD from "antd";
import { Col, Collapse, Layout, Row } from "antd";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
//import TopCars from "../../../components/TopCars/TopCars";
import ApiClient from "../../../helpers/ApiClient";
import Cars from "../../../components/car-search/Car";
import { AppConstants } from "../../../helpers/constants";
import "./CarsSearch.scss";
import { Link } from "react-router-dom";
import imgimg from '../../../assets/images/banner-img-wor.webp'
import { useAuthContext } from "../../../common/providers/AuthProvider";
const { Content } = Layout;
const { Panel } = Collapse;
function CarsSearch() {
  const { carBanners } = useSytContext();
  const { hotelBanners } = useSytContext();
  const [dealsList, setDealsList] = useState([]);
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();


  const getDeals = () => {
    ApiClient.get("carextranet/getCarDeals")
      .then((res) => {
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };  let docTitle = "Book Luxury Cars Online";
  useEffect(() => {
    window.addEventListener("blur", () => {
      document.title = "Come Back, We Got Best Cars";
    })
    window.addEventListener("focus", () => {
      document.title = docTitle+ " - " + AppConstants.DOMAIN_NAME;
    })
  }, []);


  useEffect(() => {
    getDeals();
  }, []);
  return (
    <div>
      <Helmet>
        <title>{docTitle}- {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="Book car ticket with demooutc and get huge discounts. Save more on your online  booking. Volvo car booking, sleeper, AC, NON AC, Business Class, Semi Sleeper."
        />
      </Helmet>
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={hotelBanners} />
          {/* <Banner banner={3} /> */}

        </div>
        {/* <div className="bus-search-header">
          <h2>Book Cars with cancellation option</h2>
        </div> */}
        <div className="cars-search">
          <Cars />
          {/* <Link
            className="text-right text-white w-100 d-block links  m-0 h6"
            exact={true}
            to={"/car-hire"}
            target="_blank"
          >
            HIRE A CAR
          </Link> */}
        </div>
      </div>
      <div className="promo providerapi">
        <Layout className="holidays-search-apps">
          <div className="car-hmpage">
          {/* <section  className="main-box-dis-work-section1">

</section> */}


      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Sig up & save upto 70%</strog></h3>
        <div className="main-box-dis-work3" >
          <div className="main-box-dis-work" >
            <div className="main-box-dis-work1" >
              <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
            <div className="main-box-dis-work1" >
              <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
            <div className="main-box-dis-work1" >
              <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
            <div className="main-box-dis-work1" >
              <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
              <p>Unlock exclusive offers you won't find elseWhere</p>
            </div>
          </div>
          <div className="main-box-dis-work2" >
            <img src={imgimg} style={{width:'100%'}} />
          </div>
        </div>


        <div className="liks-about-sigup">
          <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
          {flag === true || agent === true ? (null) : (
            <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>
            <Content className="admin-container cms-pages-width">
              <Row gutter={8} className="cars-worls-rent">
                <Col md={8} xs={24}>
                  <img
                    className="car-data-img"
                    src={
                      require("../../../assets/images/carrental/car-1.png")
                        .default
                    }
                    alt=""
                  />
                  <p className="car-img-process">
                    <b>Worldwide Coverage</b>
                  </p>
                  <p className="car-img-process-1">
                    Get to your destination in 140+ countries and regions, 8200+
                    cities with over 400 suppliers
                  </p>
                </Col>

                <Col md={8} xs={24}>
                  <img
                    className="car-data-img"
                    src={
                      require("../../../assets/images/carrental/car-2.png")
                        .default
                    }
                    alt=""
                  />
                  <p className="car-img-process">
                    <b>Top Brands at Competitive Prices</b>
                  </p>
                  <p className="car-img-process-1">
                    Choose from a wide selection of affordable cars from your
                    preferred brands
                  </p>
                </Col>

                <Col md={8} xs={24}>
                  <img
                    className="car-data-img"
                    src={
                      require("../../../assets/images/carrental/car-3.png")
                        .default
                    }
                    alt=""
                  />
                  <p className="car-img-process">
                    <b>Multi-Language Customer Service</b>
                  </p>
                  <p className="car-img-process-1">
                    Reach out to our reliable help line at any time of the day
                  </p>
                </Col>
              </Row>
              <Row className="cars-worls-rent">
                <Col md={24} xs={24}>
                  <section className="top-route-bb">
                    {/* {console.log(dealsList, "deal")} */}
                    {/* {dealsList.length > 0 ? (
                      // <TopCars dealsList={dealsList} />
                    ) : null} */}
                  </section>
                </Col>
              </Row>
              {/* rating--clients */}

              {/* <Row className="cars-worls-rent">
                <Col md={24} xs={24}>
                  <h2>SERVICED BY TOP-RATED SUPPLIERS</h2>
                </Col>
              </Row>

              <Row className="cars-worls-rent-1">
                <Col md={8} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r1.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                <Col md={8} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r2.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                <Col md={8} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r3.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                {/* <Col md={3} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r1.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                <Col md={3} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r2.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                <Col md={3} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r3.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                <Col md={3} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r1.png")
                        .default
                    }
                    alt=""
                  />
                </Col>
                <Col md={3} xs={24}>
                  <img
                    className="rating-img"
                    src={
                      require("../../../assets/images/carrental/car-r2.png")
                        .default
                    }
                    alt=""
                  />
                </Col> */}
              {/* </Row>  */}
              {/* rating--clients--close*/}

              {/* --faq--start-- */}
              <Row className="cars-worls-rent">
                <Col md={24} xs={24}>
                  <h2>FAQs</h2>
                </Col>
              </Row>

              <Row className="cars-worls-rent-1">
                <Col md={24} xs={24}>
                  <Collapse accordion>
                    <Panel header="What payment options are accepted?" key="1">
                      <p>
                        You can book your car using most credit cards and a few
                        debit cards. You will be informed about the payment
                        options that are accepted while you're booking. It is
                        strongly suggested to fulfill the payment with a credit
                        card in the name of the lead driver. If your reservation
                        is paid by someone other than the lead driver, you may
                        need to present supplementary documents when the booking
                        is reviewed for some security reasons. However, you
                        don't have to use the same card to book your rental car
                        on Klook and pay the deposit at the counter desk.
                        IMPORTANT NOTE: When you pick up your car at the
                        counter, a valid credit card in the name of the lead
                        driver must be presented at the rental desk to
                        pre-authorize or charge the deposit. The credit card(s)
                        must be embossed and a PIN number may be required. In
                        case you fail to present a valid credit card, there is a
                        lack of sufficient funds available on the credit card,
                        or the credit card is not in the lead driver's name, the
                        car rental agent may refuse to release the vehicle. In
                        these instances, no refunds can be made.
                      </p>
                    </Panel>
                    <Panel
                      header="Where can I find my rental car's insurance coverage?"
                      key="2"
                    >
                      <p>
                        The details of your rental car's insurance coverage can
                        be viewed upon booking and on the day of pick up.
                      </p>
                    </Panel>
                    <Panel
                      header="What do I need to bring when picking up the car?"
                      key="3"
                    >
                      <p>
                        When picking up your car, you'll need: (1) Driver's
                        License: The main driver's license needs to have been
                        held for at least 1 or 2 years (depending on the rental
                        company's policy). Additional drivers must also present
                        their driver's license. If your driver's license is not
                        written using English characters (i.e. if it is in
                        Arabic, Greek, Russian or Chinese), you must bring an
                        International Driving Permit along with your original
                        license. For more information, please refer to the Terms
                        and Conditions at the time of booking. (2) Credit Card:
                        A valid credit card in the name of the main driver and
                        with sufficient funds must be presented at the rental
                        desk to pre-authorize or charge the deposit. (3) Valid
                        ID: A valid photo ID (passport or national ID) or any
                        other forms of identification must be presented to the
                        car rental company. (4) Printed Voucher: A printed
                        version of your voucher must be presented when you pick
                        up your car. Failure to present it may incur an
                        additional charge. IMPORTANT NOTE: Payments for any
                        additional drivers and extra equipment need to be
                        settled when you arrive to pick up the car. Please bear
                        those costs in mind when using your credit card. If you
                        fail to present a valid credit card, or there is a lack
                        of sufficient funds available on the credit card, or the
                        credit card is not in the main driver's name, the staff
                        may refuse to release the car. In these instances, no
                        refunds can be made.
                      </p>
                    </Panel>
                    <Panel header="What is the cancellation policy?" key="4">
                      <p>
                        In most cases, you will receive a full refund as long as
                        you cancel the booking before pick up. However, some
                        suppliers may offer free cancellation up to a certain
                        hour before pick up. If you cancel past the free
                        cancellation period, you'll receive a refund less than
                        the full prepaid amount or no refund at all. If you
                        cancel after your rental is due to start, no refunds
                        will be given. If you do not pick up the car (usually
                        counted as a 'no-show'), no refunds will be given. Car
                        rental companies call it a 'no-show' when you do not
                        pick the car up (e.g. you do not arrive at the arranged
                        date and time, you do not have the right documents,
                        you're not eligible for the rental, or the main driver
                        does not have a credit card with enough credit for the
                        deposit.) Some car rental companies may have their own
                        cancellation policy, which you'll find in the Terms and
                        Conditions of your booking.
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>

              {/* --faq--start--close*/}
            </Content>
          </div>
        </Layout>
      </div>
      {/* <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts.png").default
                  }
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts1.png").default
                  }
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      {/* <section className="top-route-bb new-bus-top">
        <TopBuses />
      </section>
      <section className="top-route-bb new-bus-top">
        <Reviews serviceType={3} />
      </section> */}
    </div>
  );
}

export default CarsSearch;
