import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Row,
  Form,
  Select,
  Table,
  Layout,
  message,
  Modal,
  Popconfirm,
  Popover,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { getRoleType, getTypeSign } from "./commissionhelper";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Content } = Layout;

const HotelCommissions = (props) => {
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [roleType, setRoleType] = useState(1);
  const [hotelCommissions, setHotelCommissions] = useState([]);
  const [NewHotelCommisionForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [toBeEditable, setToBeEditable] = useState(-1);
  const [membershipList, setMembershipList] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);

  const [filterOperatorList, setFilterOperatorList] = useState([]);

  const handleRoleType = (val) => setRoleType(val);

  const GetHotelCommissions = () => {
    ApiClient.get("admin/hotelCommissions")
      .then((res) => {
        if (res.status == 200) {
          let mapData = res.data.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
              RoleTypeName: getRoleType(item.RoleType),
              // FareTypeName:geTNetFare(item.FareType),
              MembershipID: item?.MembershipID?.MembershipID ?? "",
              MembershipName: item?.MembershipID?.Name ?? "",
              OperatorID: item?.OperatorID?.OperatorServiceID ?? "",
              OperatorName: item?.OperatorID?.OperatorName ?? "",
            };
          });

          setHotelCommissions(mapData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetHotelCommissions();
    getMembership();
    getOperators();
  }, []);

  const getMembership = () => {
    ApiClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          setMembershipList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOperators = () => {
    ApiClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let filterOperators = res.data.filter(
            (item) => item.ServiceType === 2
          );
          setFilterOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EditRecord = (rec) => {
    handleRoleType(rec.RoleType);
    NewHotelCommisionForm.setFieldsValue({
      ...rec,
    });
    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "Sno",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Role",
      dataIndex: "RoleTypeName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.RoleTypeName),
    },

    {
      title: "Membership",
      dataIndex: "MembershipName",
      sorter: (a, b) => a.MembershipName.localeCompare(b.MembershipName),
    },
    {
      title: "Operator",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData, _) => (
        <>{value + " " + getTypeSign(currentData.MarkupType)}</>
      ),
    },
    {
      title: "Star Rating",
      dataIndex: "Rating",
    },
    {
      title: "Commission",
      dataIndex: "Commission",
      render: (value, currentData, _) => (
        <>{value + " " + getTypeSign(currentData.CommissionType)}</>
      ),
    },
  ];

  const tableActions = (currentRec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setToBeEditable(currentRec.HotelCommissionID);
              setIsEdit(true);
              EditRecord(currentRec);
            }}
            style={{ color: "#2e0000", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        &nbsp;
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              DeleteHotelCommission(currentRec.HotelCommissionID)
            }
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const onFormSubmit = (val) => {
    let data = {
      CreatedBy: 1,
      ModifiedBy: 1,
      ...val,
      MembershipID: val.RoleType === 5 ? val.MembershipID : 1,
      Commission: val.RoleType === 5 ? val.Commission : 0,
      CommissionType: val.RoleType === 5 ? val.CommissionType : 0,
    };

    isEdit ? UpdateHotelCommission(data) : AddHotelCommission(data);
  };

  const AddHotelCommission = (data) => {
    ApiClient.post("admin/hotelCommissions", data)
      .then((response) => {
        if (response.status == 200) {
          GetHotelCommissions();
          message.success("Hotel Commission Added successfully", 3);
          setAddMarkupModal(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const UpdateHotelCommission = (data) => {
    ApiClient.put("admin/hotelCommissions/" + toBeEditable, {}, data)
      .then((response) => {
        if (response.status == 200) {
          GetHotelCommissions();
          message.success("Hotel Commission Updated successfully");
          setAddMarkupModal(false);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteHotelCommission = (id) => {
    ApiClient.delete("admin/hotelCommissions/" + id)
      .then((res) => {
        if (res.status == 200) {
          GetHotelCommissions();
          message.success("Hotel Commission Deleted Successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = hotelCommissions.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setHotelCommissions(searchResults);
    } else {
      GetHotelCommissions();
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = hotelCommissions.map((item) => {
      return {
        S_No: item.Sno,
        Role: item.RoleTypeName,
        Membership_Name: item.MembershipName,
        Operator_Name: item.OperatorName,
        Markup: item.Markup,
        Star_Rating: item.Rating,
        Commission: item.Commission,
      };
    });

    props.exportExcel(excelData, "HotelCommissionDetails");
  };

  return (
    <>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Hotel Commission/Markup{" "}
                      <HelpInfoHelper screenName={"/admin/hotelcommissions/Markup"} />
                    </h5>
                    <p>{hotelCommissions.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => GetHotelCommissions()}
                  />
                  <div
                    onClick={(e) => {
                      if (isEdit) {
                        setIsEdit(false);
                      }
                      handleRoleType(1);
                      setAddMarkupModal(true);
                      NewHotelCommisionForm.resetFields();
                    }}
                    className="add-icon cursor-pointer"
                  >
                    <PlusOutlined />
                  </div>
                </div>
              </div>
              <div>
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={hotelCommissions}
                  columns={tableColumns}
                />
              </div>
            </Card>
          </div>
        </Content>
      </div>

      <Modal
        width={"800px"}
        className="markup-modal modal-header-bg12"
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Hotel Commission </strong>
            </h6>
          </div>,
        ]}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={NewHotelCommisionForm.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              key="submit"
              type="danger"
              onClick={() => NewHotelCommisionForm.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          form={NewHotelCommisionForm}
          onFinish={onFormSubmit}
        >
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Role Type"
                  name="RoleType"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    onSelect={(val) => handleRoleType(val)}
                  >
                    <Option value={2}>Users</Option>
                    <Option value={4}>Guest</Option>
                    <Option value={5}>Partner</Option>
                  </Select>
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item
                    label="Membership"
                    name="MembershipID"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select Membership"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {membershipList.map((data, index) => (
                        <Option key={index} value={data.MembershipID}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
{/* 
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Operator"
                  name="OperatorID"
                  //rules={[{ required: true }]}
                >
                  <Select placeholder="Please Select">
                    {filterOperatorList.map((oper, index) => (
                      <Option key={index} value={oper.OperatorServiceID}>
                        {oper.OperatorName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Star Ratings"
                  name="Rating"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select placeholder="Please Select">
                    <Option value={1}>1 Star</Option>
                    <Option value={2}>2 Star</Option>
                    <Option value={3}>3 Star</Option>
                    <Option value={4}>4 Star</Option>
                    <Option value={5}>5 Star</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="seperation-line">
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup Type"
                  name="MarkupType"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select placeholder="Pleaase select">
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup"
                  name="Markup"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    type="number"
                  />
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="Commission Type"
                      name="CommissionType"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Please select">
                        <Option value={0}>Fixed</Option>
                        <Option value={1}>Percentage</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="Commission"
                      name="Commission"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatedComponent(HotelCommissions);
