import React, { useState, useEffect } from "react";
import "./CarsFilter.scss";
import { Card, Checkbox, Collapse, Slider } from "antd";
import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";

const { Panel } = Collapse;

const CarFilter = ({ carSearchResultObj, setCarResultsList, type}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  // console.log(carSearchResultObj, "filter-obj");
  let {
    data: { cars, filtersObj },
  } = carSearchResultObj;
  let count = cars?.length;

  const [resultCount, setResultCount] = useState(count);
  const [filters, setFilters] = useState({});
  const [priceRange, setPriceRange] = useState([]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    // document.getElementsByClassName("card-results-block")[0].scrollIntoView({
    //   behavior: "smooth",
    // });
  };

  const setDynamicFilters = () => {
    // console.log(filtersObj, "venu2");
    if (Object.keys(filtersObj).length > 0) {
      setFilters(filtersObj);
      setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
    }
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const applyFilters = () => {
    let visibleCount = 0;

    const carTypeChecked = checkedFilters("carType");

    const boardingPointsChecked = checkedFilters("pickUpLocation");
    const droppingPointsChecked = checkedFilters("droppingLocation");

    let filteredList = cars?.map((car) => {
      // console.log(cars, car, "ccc");
      let isVisible = true;

      const startingFare = Number(car?.BaseFare);
      if (
        !(
          startingFare >= filters.price.minPrice &&
          startingFare <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }

      if (
        carTypeChecked.length &&
        !carTypeChecked.map((cType) => cType.id).includes(car.carType.CarType)
      ) {
        isVisible = false;
      }

      if (
        boardingPointsChecked.length &&
        !boardingPointsChecked
          .map((bPoint) => bPoint.id)
          .includes(car.PickUpLocation)
      ) {
        isVisible = false;
      }
      if (
        droppingPointsChecked.length &&
        !droppingPointsChecked
          .map((dPoint) => dPoint.id)
          .includes(car.DroppingLocation)
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      return { ...car, isVisible: isVisible };
    });

    filteredList = filteredList.filter((item) => item.isVisible);
    setCarResultsList({ ...carSearchResultObj.data, cars: filteredList });

    // updateBusSearchResultObj({carSearchResultObj}data);
    setResultCount(visibleCount);
  };

  const appendZero = (hrMn) => {
    return ("0" + hrMn.hours).slice(-2) + ":" + ("0" + hrMn.minutes).slice(-2);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    {console.log(priceVal,"price")}
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
      
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  const applyTransferFilters = () => {
    let visibleCount = 0;
    const carTypeChecked = checkedFilters("carType");
    const boardingPointsChecked = checkedFilters("pickUpLocation");
    const droppingPointsChecked = checkedFilters("droppingLocation");
    let filteredList = cars.map((car) => {
      let isVisible = true;
      const startingFare = parseFloat(car.Vehicles[0].TransferPrice.PublishedFares);
      if (
        !(
          startingFare >= filters.price.minPrice &&
          startingFare <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }
      if (
        carTypeChecked.length &&
        !carTypeChecked
          .map((cType) => cType.id)
          .includes(car.Vehicles[0].Vehicle)
      ) {
        isVisible = false;
      }
      if (
        boardingPointsChecked.length &&
        !boardingPointsChecked
          .map((bPoint) => bPoint.id)
          .includes(car.PickUp.PickUpDetailName)
      ) {
        isVisible = false;
      }
      if (
        droppingPointsChecked.length &&
        !droppingPointsChecked
          .map((dPoint) => dPoint.id)
          .includes(car.DropOff.DropOffDetailName)
      ) {
        isVisible = false;
      }
      isVisible && visibleCount++;
      return { ...car, isVisible: isVisible };
    });
    filteredList = filteredList.filter((item) => item.isVisible);
    // console.log(filteredList, "traaa");
    setCarResultsList({ ...carSearchResultObj.data, cars: filteredList });
    // updateBusSearchResultObj({carSearchResultObj}data);
    setResultCount(visibleCount);
  };

  useEffect(() => {
    setDynamicFilters();
  }, []);

  useEffect(() => {
    if (type === "transfer") {
      Object.keys(filters).length && applyTransferFilters();
    } else {
      Object.keys(filters).length && applyFilters();
    }
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: (filters.price.minPriceRange),
        maxPrice: (filters.price.maxPriceRange),
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );

  return (
    <div className="side-bar">
      <Card>
        <div className="transfer-filters slider-icon-1">
          <div className="transfer-result-indicator">
            <p>
              {/* Showing {resultCount} Of {count} Buses */}
              We Found {resultCount} Cars For You
            </p>
          </div>
          <div className="overall-filter-container">
            <div className="overall-filter-header">
              <p className="filter-text">Filters</p>
              <p className="clear-text" onClick={setDynamicFilters}>
                Clear all
              </p>
            </div>
            <div className="overall-filter-body">
              <div className="stops-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel header="Price" key="1" extra={extraPanel("price")}>
                    <div className="slider-label" style={{ fontSize: "11px" }}>
                      <span>
                        {activeCurrency} {currencyValue(priceRange[0])}
                      </span>
                      <span>
                        {activeCurrency} {currencyValue(priceRange[1])}
                      </span>
                    </div>
                    {filters.price && (
                      <Slider
                        range
                        step={0.01}
                        defaultValue={[
                          filters.price.minPriceRange,
                          filters.price.maxPriceRange,
                        ]}
                        value={priceRange}
                        min={filters.price.minPriceRange}
                        max={filters.price.maxPriceRange}
                        onChange={priceChangeHandler}
                        onAfterChange={priceChangeCompleteHandler}
                      />
                    )}
                  </Panel>
                </Collapse>
              </div>

              <div className="stops-filter">
                <Collapse
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                  className="customscroll"
                >
                  <Panel
                    header={"Car Type"}
                    key="1"
                    extra={extraPanel("carType")}
                  >
                    {filters.carType &&
                      filters.carType.map((cType) => (
                        <p>
                          <Checkbox
                            checked={cType.isChecked}
                            onChange={(e) => onChange(e, "carType", cType.id)}
                          >
                            {cType.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>

              <div className="stops-filter">
                <Collapse
                  className="customscroll"
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                >
                  <Panel
                    header={"PickUp Address"}
                    key="1"
                    extra={extraPanel("pickUpLocation")}
                  >
                    {filters.pickUpLocation &&
                      filters.pickUpLocation.map((bPoint) => (
                        <p>
                          <Checkbox
                            checked={bPoint.isChecked}
                            onChange={(e) =>
                              onChange(e, "pickUpLocation", bPoint.id)
                            }
                          >
                            {bPoint.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
              <div className="stops-filter">
                <Collapse
                  className="customscroll"
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                >
                  <Panel
                    header={"Dropping Address"}
                    key="1"
                    extra={extraPanel("droppingLocation")}
                  >
                    {filters.droppingLocation &&
                      filters.droppingLocation.map((dPoint) => (
                        <p>
                          <Checkbox
                            checked={dPoint.isChecked}
                            onChange={(e) =>
                              onChange(e, "droppingLocation", dPoint.id)
                            }
                          >
                            {dPoint.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CarFilter;
