// import React, { useContext, useState, useEffect } from "react";
// import { Button, Row, Card, Col, Form, message, Spin, Input } from "antd";
// import { Link, useHistory } from "react-router-dom";
// import {
//   ArrowLeftOutlined,
//   SafetyCertificateOutlined,
// } from "@ant-design/icons";

// // import { useHistory } from "react-router-dom";
// import { GlobalStatesContext } from "../../../common/providers";
// import { useAuthContext } from "../../../common/providers/AuthProvider";
// import FareDetails, { getBusGrandTotal } from "../CarsCheckout/FareDetails";
// import { CarDetails, TransferDetails } from "../CarsCheckout/CarDetails";
// import ApiClient from "../../../helpers/ApiClient";
// import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
// import PaymentGateway from "../../../helpers/PaymentGateway";
// import { LoadingOutlined } from "@ant-design/icons";
// import "../CarsCheckout/CarsCheckout.scss";
// import moment from "moment";

// const CarReview = () => {
//   let history = useHistory();
//   const {
//     state: {
//       carBookingData,
//       carPassengerData,
//       carSearchObj,
//       otherData: { promoData },
//     },
//   } = useContext(GlobalStatesContext);
//   const [form] = Form.useForm();
//   const [pgDisplay, setPgDisplay] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [pgData, setPgData] = useState({});
//   const {
//     isLogin: { agent },
//     user,
//   } = useAuthContext();

//   const antIcon = (
//     <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
//   );

//   const carBlocking = (carPassengerData, type) => {
//     return;
//     setIsLoading(true);

//     // if (!user) {
//     //   userReg(
//     //     passengersData[0].Name,
//     //     busPassengerData[0].emailId,
//     //     busPassengerData[0].mobileNo
//     //   );
//     // }

//     // const allAmount = getBusGrandTotal(
//     //   selectedBusData,
//     //   ConvFee,
//     //   promoData,
//     //   selectedInsuranceData
//     // );

//     // let data = {
//     //   ...busPassengerDataObj,
//     //   userId: user?.UserID ?? 1,
//     //   roleType: user?.Role?.RoleId ?? 4,
//     //   membership: user?.Membership ?? 1,
//     //   promoData: {
//     //     ...promoData,
//     //     tripType: type,
//     //   },
//     //   convienenceData: ConvFee,
//     //   insuranceData: selectedInsuranceData,
//     //   totalPrice: Number(allAmount.totalFareCalculations),
//     // };

//     // ApiClient.post("buses/blockticket", data)
//     //   .then((result) => {
//     //     return result;
//     //   })
//     //   .then((resp) => {
//     //     setIsLoading(false);
//     //     if (resp.statusCode === 200) {
//     //       if (resp.data.BookingStatus === 1) {
//     //         processPayment(resp.data.BookingReferenceNo, allAmount);
//     //       } else {
//     //         message.error("Unable To Book Ticket: " + resp.data.Message);
//     //       }
//     //     } else {
//     //       message.error("Server Error: " + resp.message);
//     //     }
//     //   })
//     //   .catch((err) => {
//     //     setIsLoading(false);
//     //     console.log(err);
//     //   });
//   };
//   // const goTo = () => {
//   //   form
//   //     .validateFields()
//   //     .then(() => {
//   //       // const { totalFareCalculations } = getCarGrandTotal(
//   //       //   carBookingData.selectedCarData
//   //       // );
//   //       if (agent) {
//   //         carBlocking(carPassengerData, "oneWay");
//   //       } else {
//   //         let Data = {
//   //           AvailabilityID: carBookingData.AvailabilityID ?? 0,
//   //           SourceId: carBookingData.SourceID ?? "",
//   //           DestinationId: carBookingData.DestinationID ?? "",
//   //           EmailId: carPassengerData[0].emailId ?? "",
//   //           MobileNo: carPassengerData[0].mobileNo ?? "",
//   //           Source: carBookingData?.pickUpLocation
//   //             ? carBookingData.pickUpLocation
//   //             : carBookingData.Source ?? "",
//   //           Destination: carBookingData?.dropLocation
//   //             ? carBookingData.dropLocation
//   //             : carBookingData.Destination ?? "",

//   //           JourneyDate: carSearchObj.fromDate ?? "",
//   //           DropAddress: carPassengerData[0].droppingAddress ?? "",
//   //           PickUpAddress: carPassengerData[0].pickUpAddress ?? "",
//   //           BaseFare: carBookingData.BaseFare ?? "",
//   //           GST: carBookingData.GST ?? "",
//   //           StateTollTax: carBookingData.StateTollTax ?? "",
//   //           driverID: carBookingData.driver?.DriverID ?? "",
//   //           passengerDetails: [
//   //             {
//   //               age: 0,
//   //               name: carPassengerData[0].name ?? "",
//   //               title: carPassengerData[0].tittle ?? "",
//   //               gender: "",
//   //             },
//   //           ],
//   //           userId: user ? user.UserID : 1,
//   //           RoleType: user ? user.Role.RoleId : 4,
//   //           promoData: {
//   //             ...promoData,
//   //           },
//   //           totalPrice:
//   //             Number(carBookingData.BaseFare ? carBookingData.BaseFare : 0) +
//   //             Number(
//   //               carBookingData.StateTollTax ? carBookingData.StateTollTax : 0
//   //             ) +
//   //             Number(carBookingData.GST ? carBookingData.GST : 0) +
//   //             Number(
//   //               carBookingData.DriverAllowance
//   //                 ? carBookingData.DriverAllowance
//   //                 : 0
//   //             ),
//   //           VendorName: "",
//   //           carTypeName: carBookingData.car.CarName ?? "",
//   //           VendorId: carBookingData.VendorID ?? "",
//   //           carTypeId: carBookingData.CarTypeID,
//   //           GuestTitle: carPassengerData[0].tittle ?? "",
//   //           GuestName: carPassengerData[0].name ?? "",
//   //           GuestGender: "",
//   //           GuestAge: 0,
//   //           PostalCode: "",
//   //           State: "",
//   //           Address: "",
//   //           City: "",
//   //           Days: 0,
//   //           PickUpTime: "",
//   //           ReturnDate: carSearchObj.toDate ?? "",
//   //           BasicRate: 0,
//   //           ExtraHourRate: 0,
//   //           WaitingCharges: 0,
//   //           NoOfCars: 1,
//   //           NightHalt: 0,
//   //           DriverCharges: Number(carBookingData.DriverAllowance) ?? 0,
//   //           PerKmRateOneWayCharge: 0,
//   //           PerKmRateCharge: Number(carBookingData.ChargePerKM) ?? 0,
//   //           MinimumChargedDistance: 0,
//   //           ApproxRoundTripDistance: 0,
//   //           PromoCode: 0,
//   //           CarId: carBookingData.car.CarID,
//   //           gstDetails: carPassengerData[0].gstDetails ?? {},
//   //         };
//   //         ApiClient.post("carextranet/carBlock", Data)
//   //           .then((resp) => {
//   //             {console.log(resp,"carresp")}
//   //             if (resp.statusCode == 200) {
//   //               // message.success("Car block SuccessFully");
//   //               if (resp.data.BookingStatus === 2) {
//   //                 // return;
//   //                 window.location.href = resp.data.payment_link;
//   //               }
//   //             } else if (resp.statusCode == 400) {
//   //               message.error("Failed to Save Category");
//   //             } else {
//   //               message.error("Something Went Wrong");
//   //             }
//   //           })
//   //           .catch((error) => {
//   //             console.error(error);
//   //           });
//   //         // ApiClient.post("admin/validatebookinglimit", {
//   //         //   serviceType: 3,
//   //         //   roleType: user?.Role?.RoleId ?? 4,
//   //         //   bookingAmount: totalFareCalculations ? totalFareCalculations : 0,
//   //         // })
//   //         //   .then((res) => {
//   //         //     if (res?.status === 200 && res?.isValid) {
//   //         //       // handleSendOTP();
//   //         //     } else {
//   //         //       if (res?.message) message.error(res.message, 3);
//   //         //       else message.error("Booking Failed", 3);
//   //         //     }
//   //         //   })
//   //         //   .catch();
//   //       }
//   //     })
//   //     .catch((e) => { });
//   // };
//   return (
//     <div className="buscheckout_bg">
//       <div className="checkout-heading">
//         <div style={{ marginTop: "80px" }} fluid className="checkout-heading-container">
//           <div className="goback">
//             <Link
//               onClick={() => {
//                 history.go(-1);
//               }}
//             >
//               <ArrowLeftOutlined />
//               <span>Go back and Select Other Car</span>
//             </Link>
//           </div>
//           <h3>Fill Out the Form Below, and Book right now!</h3>
//         </div>
//       </div>
//       <div className="buscheckout_wrapper">
//         <Row gutter={[16, 16]}>
//           <Col md={24} sm={24} xs={24}>
//             <Form form={form} layout="vertical">
//               <h3 className="title" style={{color:"#bd0c21"}}>Car Details</h3>
//               <Row gutter={[16, 16]}>
//                 <Col md={17} sm={16} xs={24}>
//                   <div className="left_wrapper">
//                     {console.log(carPassengerData,"dtaa")}
//                     {carBookingData.type === "transfer" ? (
//                       <TransferDetails carBookingData={carBookingData} />
//                     ) : (
//                       <CarDetails carBookingData={carBookingData} />
//                     )}

//                     {/* <CarDetails carBookingData={carBookingData} /> */}
//                     <div className="user-details">
//                       <p className="title" style={{color:"#bd0c21"}}>Passengers</p>
//                       <Card className="flight-cards-details">
//                         {carPassengerData.length > 0 ? (
//                           <Form
//                             layout="vertical"
//                             className="passenger-form user-details "
//                           >
//                             <Row gutter={[16, 8]}>
//                               <Col md={6} sm={8} xs={24}>
//                                 <Form.Item label="Name">
//                                   <Input
//                                     className="inputbg"
//                                     value={
//                                       carPassengerData[0].tittle +
//                                       "." +
//                                       " " +
//                                       carPassengerData[0].name
//                                     }
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col>
//                               {carPassengerData[0].emailId ===
//                                 "" ? null : (
//                                 <Col md={6} sm={8} xs={24}>
//                                   <Form.Item label="Email Id">
//                                     <Input
//                                       value={
//                                         carPassengerData[0].emailId
//                                       }
//                                       readOnly
//                                       className="inputbg"
//                                     />
//                                   </Form.Item>
//                                 </Col>
//                               )}
//                                {carPassengerData[0].mobileNo ===
//                                 "" ? null : (
//                                 <Col md={6} sm={8} xs={24}>
//                                   <Form.Item label="Mobile Number">
//                                     <Input
//                                       value={
//                                         carPassengerData[0].mobileNo
//                                       }
//                                       readOnly
//                                       className="inputbg"
//                                     />
//                                   </Form.Item>
//                                 </Col>
//                               )}
//                               {/* <Col md={6} sm={8} xs={24}>
//                                 <Form.Item label="PickUp Address">
//                                   <Input
//                                     value={carPassengerData[0].pickUpAddress}
//                                     className="inputbg"
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col> */}
//                               {/* <Col md={6} sm={8} xs={24}>
//                                 <Form.Item label="Dropping Address">
//                                   <Input
//                                     value={carPassengerData[0].droppingAddress}
//                                     readOnly
//                                     className="inputbg"
//                                   />
//                                 </Form.Item>
//                               </Col> */}
//                               {carPassengerData[0].additionalRequests ===
//                                 "" ? null : (
//                                 <Col md={6} sm={8} xs={24}>
//                                   <Form.Item label="AdditionalRequests">
//                                     <Input
//                                       value={
//                                         carPassengerData[0].additionalRequests
//                                       }
//                                       readOnly
//                                       className="inputbg"
//                                     />
//                                   </Form.Item>
//                                 </Col>
//                               )}
//                             </Row>
//                           </Form>
//                         ) : null}
//                       </Card>
//                     </div>
//                   </div>
//                   <Card style={{marginTop:"30px"}}>
//                     {pgDisplay == false ? (
//                       <div className="btn_wrapper">
//                         <Button
//                           type="primary"
//                           onClick={() => {
//                             goTo();
//                           }}
//                         >
//                           {isLoading ? (
//                             <Spin indicator={antIcon} />
//                           ) : (
//                             "Book and Pay "
//                           )}
//                         </Button>
//                       </div>
//                     ) : (
//                       <PaymentGateway data={pgData} />
//                     )}
//                   </Card>
//                 </Col>

//                 <Col md={7} sm={8} xs={24}>
//                   <Card>
//                     <div className="right_wrapper">
//                       <h3 className="title1"> Fare Details</h3>
//                       <FareDetails
//                         selectedCarData={carBookingData}
//                         isPromoVisible={false}
//                       />
//                     </div>
//                   </Card>

//                   {/* <Card>
//                     {pgDisplay == false ? (
//                       <div className="btn_wrapper">
//                         <Button
//                           type="primary"
//                           onClick={() => {
//                             goTo();
//                           }}
//                         >
//                           {isLoading ? (
//                             <Spin indicator={antIcon} />
//                           ) : (
//                             "Book and Pay "
//                           )}
//                         </Button>
//                       </div>
//                     ) : (
//                       <PaymentGateway data={pgData} />
//                     )}
//                   </Card> */}
//                 </Col>
//               </Row>
//             </Form>
//           </Col>
//         </Row>
//       </div>
//       {/* {verifyModalVisible && (
//       <VerifyOtpModal
//         visible={verifyModalVisible}
//         setVerifyModalVisible={setVerifyModalVisible}
//         handleVerifyOtp={handleVerifyOtp}
//         handleResendOtp={handleSendOTP}
//         mobile={busPassengerData[0].mobileNo}
//       />
//     )} */}
//     </div>
//   );
// };
// export default CarReview;

import React, { createContext, useContext, useState, useEffect, useReducer } from "react";
import { Button, Row, Card, Col, Form, message, Spin, Input } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";

// import { useHistory } from "react-router-dom";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import FareDetails, { getBusGrandTotal } from "../CarsCheckout/FareDetails";
import { CarDetails, TransferDetails } from "../CarsCheckout/CarDetails";
import ApiClient from "../../../helpers/ApiClient";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import PaymentGateway from "../../../helpers/PaymentGateway";
import { LoadingOutlined } from "@ant-design/icons";
import "../CarsCheckout/CarsCheckout.scss";
import moment from "moment";
import CarrentalBookPayCard from "../CarsCheckout/CarRentalBookPayCard";
import PaymentComponent from "../../../helpers/CCavanuePayCheckout";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { getConvFee,getPromoDiscount } from "../../../common/AllTickets/promoConvhelper";
import CashFreeNewVersionCheckout from "../../../helpers/CashFreeNewVersionCheckout";

const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";
const dateFormat = "DD-MM-YYYY";
const oriDateFormat = "YYYY-MM-DD";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}

const CarReview = () => {
  let history = useHistory();
  const {
    state: {
      carBookingData,
      carPassengerData,
      carSearchObj,
      otherData: { promoData ,ConvFee},
    },
  } = useContext(GlobalStatesContext);
  const [form] = Form.useForm();
  const [pgDisplay, setPgDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pgData, setPgData] = useState({});
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false)
  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)
  const { activeCurrency,currencyRatio } = useCurrencyContext()
  // const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }
  const CashFreeNewVersionCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCashfreeNewVersionCheckout(true)
  }
   
  let totalBaseFare = 0
  let totalGst =0
  let discount = 0
  let  convamount =0
  let fareInfoData = carBookingData
  ?  Number(carBookingData.BaseFare ? carBookingData.BaseFare : 0) +
  Number(
    carBookingData.StateTollTax ? carBookingData.StateTollTax : 0
  ) +
  Number(carBookingData.GST ? carBookingData.GST : 0) +
  Number(
    carBookingData.DriverAllowance
      ? carBookingData.DriverAllowance
      : 0
  )
  : null;
  if (carBookingData?.type === "transfer") {
     totalBaseFare = carBookingData?.Vehicles?.[0]?.TransferPrice?.totalBasePrice;
     totalGst = carBookingData?.Vehicles[0]?.TransferPrice?.Tax;
  }else {
    totalBaseFare = fareInfoData;
  }
  let total = Number(totalBaseFare) + Number(totalGst);

  convamount = getConvFee(ConvFee, total);

  discount = getPromoDiscount(promoData, total)
  console.log(discount,ConvFee,"convfee")
  const carBlocking = (carPassengerData, type) => {
    // console.log(parseFloat(Number(carBookingData?.Vehicles?.[0]?.TransferPrice?.totalPrice)?.toFixed(2)), "pg-3");
    if (carBookingData?.type == "transfer") {
      let vehicleIndex = [];
      carBookingData?.Vehicles?.forEach((item) => {
        vehicleIndex.push(item?.VehicleIndex)
      })

      let transferRequest = {
        UserId: user ? user.UserID : 1,
        Role: user ? user.Role.RoleId : 4,
        MembershipId: 0,
        VehicleIndex: vehicleIndex,
        searchId: carBookingData?.searchId,
        Apikey: carBookingData?.ApiKey,
        // pgType: 4,
        pgType: type ? type : pgDetails.pgType,
        PickUp: {
          PickUpCode: carSearchObj?.PickUpPointCode,
          Time: carBookingData?.PickUp?.PickUpTime,
          City: carSearchObj?.cityName,
          Country: carSearchObj?.CountryCode,
          ZipCode: "",
          Remarks: "",
          AddressLine1: carBookingData?.pickUpLocation,
          AddressLine2: ""
        },
        DropOff: {
          DropOffCode: carSearchObj?.DropOffPointCode,
          Time: "0000",
          City: "",
          Country: "",
          ZipCode: "",
          Remarks: "",
          AddressLine1: "",
          AddressLine2: carBookingData?.dropLocation
        },
        ResultIndex: carBookingData?.ResultIndex,
        OccupiedPax: [
          {
            ChildAge: carSearchObj?.ChildAge,
            AdultCount: carSearchObj?.AdultCount,
            ChildCount: carSearchObj?.ChildAge?.Count
          }
        ],
        PaxInfo: [],
        TraceId: carBookingData?.traceId,
        PromoCode: promoData?.Code,
        ConvienceId: ConvFee.id,
        isCouponReedem: true,
        currency: activeCurrency || "INR",
        currencyRatio:Number(currencyRatio),
        totalPrice: parseFloat((Number(carBookingData?.Vehicles?.[0]?.TransferPrice?.totalPrice)+ Number(convamount) - Number(discount))?.toFixed(2)),
        // totalPrice: Number(carBookingData?.Vehicles?.[0]?.TransferPrice?.BasePrice ? carBookingData?.Vehicles?.[0]?.TransferPrice?.BasePrice : 0)
        //   + Number(carBookingData?.Vehicles?.[0]?.TransferPrice?.Tax ? carBookingData?.Vehicles?.[0]?.TransferPrice?.Tax : 0)
      }
      let guestsDetails = [];
      carPassengerData?.forEach((item) => {
        let guest = {
          Title: item?.tittle,
          PaxType: 1,
          FirstName: item?.name,
          LastName: item?.lastName,
          Age: 0,
          ContactNumber: item?.mobileNo,
          EmailId: item?.emailId,
          panCard: item?.panCard,
        }
        guestsDetails.push(guest);
      })
      transferRequest.PaxInfo = guestsDetails
      setLoadingSpin(true);
      //console.log(transferRequest, "reqbody-car");
      ApiClient.post("transfers/block", transferRequest)
        .then((resp) => {
          console.log("ve", resp)
          setLoadingSpin(false)
          if (resp.statusCode == 200) {
            if (resp.data?.BookingStatus === "Blocked") {


              CashFreeNewVersionCheckoutData(resp.data);
            } 
          //  if (resp?.pgType == 1) {

          //    ccavanueCheckoutData(resp)
          //   }
            //  else if (resp?.pgType == 2) {

            //   window.location.href = resp?.payment_link;
            // }
            else {

              window.location.href = resp?.url;
            }
            //  else {
            // ccavanueCheckoutData(resp)
            // }
          } else if(resp.statusCode == 400) {
            setLoadingSpin(false)
            console.log(resp?.Error?.ErrorMessage,resp.statusCode,"code");
            message.error(resp?.Error?.ErrorMessage);
          } else {
            setLoadingSpin(false)
            console.log(resp?.Error?.ErrorMessage,resp.statusCode,"code");
            message.error("Something Went Wrong");
          }
        })
        .catch((error) => {
          setLoadingSpin(false)
          console.error(error);
        });
    }
    else {
      let Data = {
        AvailabilityID: carBookingData.AvailabilityID ?? 0,
        SourceId: carBookingData.SourceID ?? 0,
        DestinationId: carBookingData.DestinationID ?? 0,
        EmailId: carPassengerData[0].emailId ?? "",
        MobileNo: carPassengerData[0].mobileNo ?? "",
        Source: carBookingData?.pickUpLocation
          ? carBookingData.pickUpLocation
          : carBookingData.Source ?? "",
        Destination: carBookingData?.dropLocation
          ? carBookingData.dropLocation
          : carBookingData.Destination ?? "",

        JourneyDate: carSearchObj.fromDate ?? "",
        DropAddress: carPassengerData[0].droppingAddress ?? "",
        PickUpAddress: carPassengerData[0].pickUpAddress ?? "",
        BaseFare: Number(carBookingData.BaseFare) ?? 0,
        GST: Number(carBookingData.GST) ?? 0,
        StateTollTax: Number(carBookingData.StateTollTax) ?? 0,
        driverID: carBookingData.driver?.DriverID ?? "",
        passengerDetails: [
          {
            age: 0,
            name: carPassengerData[0].name + " " + carPassengerData[0].lastName ?? "",
            title: carPassengerData[0].tittle ?? "",
            gender: "",
          },
        ],
        userId: user ? user.UserID : 1,
        RoleType: user ? user.Role.RoleId : 4,
        pgType: type ? type : pgDetails.pgType,
        // pgType: 1,
        promoData: {
          ...promoData,
        },
        // convienenceData: {
        //   ...ConvFee,
        // },
        convienenceId:ConvFee.id, 
        totalPrice:
          Number(carBookingData.BaseFare ? carBookingData.BaseFare : 0) +
          Number(
            carBookingData.StateTollTax ? carBookingData.StateTollTax : 0
          ) +
          Number(carBookingData.GST ? carBookingData.GST : 0) +
          Number(
            carBookingData.DriverAllowance
              ? carBookingData.DriverAllowance
              : 0
          )+Number(convamount)-Number(discount),
        VendorName: "",
        carTypeName: carBookingData?.car?.CarName ?? "",
        VendorId: carBookingData.VendorID ?? "",
        carTypeId: carBookingData.CarTypeID,
        GuestTitle: carPassengerData[0].tittle ?? "",
        GuestName: carPassengerData[0].name ?? "",
        GuestGender: "",
        GuestAge: 0,
        PostalCode: "",
        State: "",
        Address: "",
        City: "",
        Days: 0,
        PickUpTime: "",
        ReturnDate: carSearchObj.toDate ?? "",
        BasicRate: 0,
        ExtraHourRate: 0,
        WaitingCharges: 0,
        NoOfCars: 1,
        NightHalt: 0,
        DriverCharges: Number(carBookingData.DriverAllowance) ?? 0,
        PerKmRateOneWayCharge: 0,
        PerKmRateCharge: Number(carBookingData.ChargePerKM) ?? 0,
        MinimumChargedDistance: 0,
        ApproxRoundTripDistance: 0,
        PromoCode: promoData.Code,
        CarId: carBookingData?.car?.CarID,
        gstDetails: carPassengerData[0].gstDetails ?? {},
        currency: activeCurrency || "INR",
        currencyRatio:Number(currencyRatio),
      };
      setLoadingSpin(true)
      ApiClient.post("carextranet/carBlock", Data)
        .then((resp) => {
          console.log("ve", resp)
          setLoadingSpin(false)
          if (resp.status == 200) {
            if (resp?.data?.payment_link?.pgType == 2) {
              window.location.href = resp?.data?.payment_link?.payment_link;
            } else if (resp?.data?.pgType == 3) {
              window.location.href = resp?.data?.payment_link;
            } else {
              ccavanueCheckoutData(resp?.data?.payment_link)

            }
          } else if (resp.status == 400) {
            setLoadingSpin(false)
            message.error("Failed to Save Category");
          } else {
            setLoadingSpin(false)
            message.error("Something Went Wrong");
          }
        })
        .catch((error) => {
          setLoadingSpin(false)
          console.error(error);
        });
    }
  };
  // const goTo = (pgType) => {
  //   console.log("pg",pgType)
  //   form
  //     .validateFields()
  //     .then(() => {
  //       // const { totalFareCalculations } = getCarGrandTotal(
  //       //   carBookingData.selectedCarData
  //       // );
  //       if (agent) {
  //         carBlocking(carPassengerData, "oneWay");
  //       } else {
  //         let Data = {
  //           AvailabilityID: carBookingData.AvailabilityID ?? 0,
  //           SourceId: carBookingData.SourceID ?? "",
  //           DestinationId: carBookingData.DestinationID ?? "",
  //           EmailId: carPassengerData[0].emailId ?? "",
  //           MobileNo: carPassengerData[0].mobileNo ?? "",
  //           Source: carBookingData?.pickUpLocation
  //             ? carBookingData.pickUpLocation
  //             : carBookingData.Source ?? "",
  //           Destination: carBookingData?.dropLocation
  //             ? carBookingData.dropLocation
  //             : carBookingData.Destination ?? "",

  //           JourneyDate: carSearchObj.fromDate ?? "",
  //           DropAddress: carPassengerData[0].droppingAddress ?? "",
  //           PickUpAddress: carPassengerData[0].pickUpAddress ?? "",
  //           BaseFare: carBookingData.BaseFare ?? "",
  //           GST: carBookingData.GST ?? "",
  //           StateTollTax: carBookingData.StateTollTax ?? "",
  //           driverID: carBookingData.driver?.DriverID ?? "",
  //           passengerDetails: [
  //             {
  //               age: 0,
  //               name: carPassengerData[0].name ?? "",
  //               title: carPassengerData[0].tittle ?? "",
  //               gender: "",
  //             },
  //           ],
  //           userId: user ? user.UserID : 1,
  //           RoleType: user ? user.Role.RoleId : 4,
  //           promoData: {
  //             ...promoData,
  //           },
  //           totalPrice:
  //             Number(carBookingData.BaseFare ? carBookingData.BaseFare : 0) +
  //             Number(
  //               carBookingData.StateTollTax ? carBookingData.StateTollTax : 0
  //             ) +
  //             Number(carBookingData.GST ? carBookingData.GST : 0) +
  //             Number(
  //               carBookingData.DriverAllowance
  //                 ? carBookingData.DriverAllowance
  //                 : 0
  //             ),
  //           VendorName: "",
  //           carTypeName: carBookingData.car.CarName ?? "",
  //           VendorId: carBookingData.VendorID ?? "",
  //           carTypeId: carBookingData.CarTypeID,
  //           GuestTitle: carPassengerData[0].tittle ?? "",
  //           GuestName: carPassengerData[0].name ?? "",
  //           GuestGender: "",
  //           GuestAge: 0,
  //           PostalCode: "",
  //           State: "",
  //           Address: "",
  //           City: "",
  //           Days: 0,
  //           PickUpTime: "",
  //           ReturnDate: carSearchObj.toDate ?? "",
  //           BasicRate: 0,
  //           ExtraHourRate: 0,
  //           WaitingCharges: 0,
  //           NoOfCars: 1,
  //           NightHalt: 0,
  //           DriverCharges: Number(carBookingData.DriverAllowance) ?? 0,
  //           PerKmRateOneWayCharge: 0,
  //           PerKmRateCharge: Number(carBookingData.ChargePerKM) ?? 0,
  //           MinimumChargedDistance: 0,
  //           ApproxRoundTripDistance: 0,
  //           PromoCode: 0,
  //           CarId: carBookingData.car.CarID,
  //           gstDetails: carPassengerData[0].gstDetails ?? {},
  //         };
  //         console.log(Data,"vv")
  //         ApiClient.post("carextranet/carBlock", Data)
  //           .then((resp) => {
  //             console.log(resp, "resp")
  //             if (resp.statusCode == 200) {
  //               // message.success("Car block SuccessFully");
  //               if (resp.data.BookingStatus === 2) {
  //                 // return;
  //                 window.location.href = resp.data.payment_link;
  //               }
  //             } else if (resp.statusCode == 400) {
  //               message.error("Failed to Save Category");
  //             } else {
  //               message.error("Something Went Wrong");
  //             }
  //           })
  //           .catch((error) => {
  //             console.error(error);
  //           });
  //         // ApiClient.post("admin/validatebookinglimit", {
  //         //   serviceType: 3,
  //         //   roleType: user?.Role?.RoleId ?? 4,
  //         //   bookingAmount: totalFareCalculations ? totalFareCalculations : 0,
  //         // })
  //         //   .then((res) => {
  //         //     if (res?.status === 200 && res?.isValid) {
  //         //       // handleSendOTP();
  //         //     } else {
  //         //       if (res?.message) message.error(res.message, 3);
  //         //       else message.error("Booking Failed", 3);
  //         //     }
  //         //   })
  //         //   .catch();
  //       }
  //     })
  //     .catch((e) => { });
  // };

  const processPayGateway = () => {
    // console.log("venuu")
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const blockApiReq = (pgType) => {
    console.log(pgType, 'pg-2');
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    //console.log("pg", pgType)
    if (pgType) {
      // console.log("pg1", carPassengerData, pgType)
      // if (agent) {
      carBlocking(carPassengerData, pgType);
      // } else {
      //   ValidateBookingLimit();
      // }
    }
  };

  return (
    <>
      <div className="buscheckout_bg">
        <div className="checkout-heading">
          <div  fluid className="checkout-heading-container">
            <div className="goback">
              <Link
                onClick={() => {
                  history.go(-1);
                }}
              >
                <ArrowLeftOutlined />
                <span>Go back and Select Other Car</span>
              </Link>
            </div>
            <h3>Fill Out the Form Below, and Book right now!</h3>
          </div>
        </div>
        <div className="buscheckout_wrapper">
          <Row gutter={[16, 16]} className="cartoppp">
            <Col md={24} sm={24} xs={24}>
              <Form form={form} layout="vertical">
                <h3 className="title" style={{ color: "#bd0c21" }}>Car Details</h3>
                <Row gutter={[16, 16]}>
                  <Col className="car-checkout-detail-part">
                    <div className="left_wrapper">
                      {/* {console.log(carPassengerData, "dtaa")} */}
                      {carBookingData.type === "transfer" ? (
                        <TransferDetails carBookingData={carBookingData} />
                      ) : (
                        <CarDetails carBookingData={carBookingData} />
                      )}

                      {/* {/ <CarDetails carBookingData={carBookingData} / > /} */}
                      < div className="user-details">
                        <p className="title" style={{ color: "#bd0c21" }}>Passengers</p>
                        <Card className="flight-cards-details">
                          {carPassengerData.length > 0 ? (
                            <Form
                              layout="vertical"
                              className="passenger-form user-details "
                            >
                              <Row gutter={[16, 8]}>
                                <Col md={6} sm={8} xs={24}>
                                  <Form.Item label="Name">
                                    <Input
                                      className="inputbg"
                                      value={
                                        carPassengerData[0].tittle +
                                        "." +
                                        " " +
                                        carPassengerData[0].name
                                      }
                                      readOnly
                                    />
                                  </Form.Item>
                                </Col>
                                {carPassengerData[0].emailId ===
                                  "" ? null : (
                                  <Col md={6} sm={8} xs={24}>
                                    <Form.Item label="Email Id">
                                      <Input
                                        value={
                                          carPassengerData[0].emailId
                                        }
                                        readOnly
                                        className="inputbg"
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                                {carPassengerData[0].mobileNo ===
                                  "" ? null : (
                                  <Col md={6} sm={8} xs={24}>
                                    <Form.Item label="Mobile Number">
                                      <Input
                                        value={
                                          carPassengerData[0].mobileNo
                                        }
                                        readOnly
                                        className="inputbg"
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                                {/* <Col md={6} sm={8} xs={24}>
                                <Form.Item label="PickUp Address">
                                  <Input
                                    value={carPassengerData[0].pickUpAddress}
                                    className="inputbg"
                                    readOnly
                                  />
                                </Form.Item>
                              </Col> */}
                                {/* <Col md={6} sm={8} xs={24}>
                                <Form.Item label="Dropping Address">
                                  <Input
                                    value={carPassengerData[0].droppingAddress}
                                    readOnly
                                    className="inputbg"
                                  />
                                </Form.Item>
                              </Col> */}
                                {carPassengerData[0].additionalRequests ===
                                  "" ? null : (
                                  <Col md={6} sm={8} xs={24}>
                                    <Form.Item label="AdditionalRequests">
                                      <Input
                                        value={
                                          carPassengerData[0].additionalRequests
                                        }
                                        readOnly
                                        className="inputbg"
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                            </Form>
                          ) : null}
                        </Card>
                      </div>
                    </div>
                    {/* <Card style={{marginTop:"30px"}}>
                    {pgDisplay == false ? (
                      <div className="btn_wrapper">
                        <Button
                          type="primary"
                          onClick={() => {
                            goTo();
                          }}
                        >
                          {isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Book and Pay "
                          )}
                        </Button>
                      </div>
                    ) : (
                      <PaymentGateway data={pgData} />
                    )}
                  </Card> */}
                  </Col>

                  <Col className="car-checkout-price-part">
                    <Card>
                      <div className="right_wrapper">
                        <h3 className="title1"> Fare Details</h3>
                        <FareDetails
                          selectedCarData={carBookingData}
                          isPromoVisible={false}
                        />
                      </div>
                      <div className="cnt-btn">
                        <CarrentalBookPayCard
                          blockApiReq={blockApiReq}
                          loadingSpin={loadingSpin}
                          bookpaycardinfo={"review"}
                          pgData={pgDetails?.pgData}
                          pgDisplay={pgDetails.pgDisplay}
                          processPayGateway={processPayGateway}
                        // processOffline={processOffline}
                        />
                      </div>
                    </Card>

                    {/* {
                      openCCavanueCheckout && (
                        <PaymentComponent
                          pgData={pgData}

                        />
                      )
                    } */}
                    {
        openCashfreeNewVersionCheckout && (
          <CashFreeNewVersionCheckout
            pgData={pgData}

          />
        )
      }

                    {/* <Card>
                    {pgDisplay == false ? (
                      <div className="btn_wrapper">
                        <Button
                          type="primary"
                          onClick={() => {
                            goTo();
                          }}
                        >
                          {isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Book and Pay "
                          )}
                        </Button>
                      </div>
                    ) : (
                      <PaymentGateway data={pgData} />
                    )}
                  </Card> */}
                  </Col>
                </Row>
              </Form>
              {/* <CarrentalBookPayCard
          blockApiReq={blockApiReq}
          loadingSpin={loadingSpin}
          bookpaycardinfo={"review"}
          pgDisplay={pgDetails.pgDisplay}
          processPayGateway={processPayGateway}
        // processOffline={processOffline}
        /> */}
            </Col>

          </Row>
        </div>

        {/* <CarrentalBookPayCard
          blockApiReq={blockApiReq}
          loadingSpin={loadingSpin}
          bookpaycardinfo={"review"}
          pgDisplay={pgDetails.pgDisplay}
          processPayGateway={processPayGateway}
        // processOffline={processOffline}
        /> */}
        {/* {verifyModalVisible && (
      <VerifyOtpModal
        visible={verifyModalVisible}
        setVerifyModalVisible={setVerifyModalVisible}
        handleVerifyOtp={handleVerifyOtp}
        handleResendOtp={handleSendOTP}
        mobile={busPassengerData[0].mobileNo}
      />
    )} */}
      </div >
    </>
  );
};
export default CarReview;
