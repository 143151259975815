import React from "react";
import FlightSearch from "../flight-search/FlightSearch";
import Hotels from "../Hotels/Hotels";
import "../search-box/SearchBox.scss";
import { Tabs } from "antd";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";

// import ActivitiesSearchBox from "../Activities/ActivitiesSearchbox/ActivitiesSearchbox";
// import BuilpackageSearchBox from "../BuildYourPackage/Buildpackagesearchbox/BuildPackageBox";
import Buses from "../buses-search/Buses";
import { Link } from "react-router-dom";

const SearchBox = () => {
  const { TabPane } = Tabs;
  return (
    <div className="global_search_panel">
      <div className="search-wrapper">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <i className="fa fa-plane"></i> Flights
              </span>
            }
            key="1"
          >
            <FlightSearch />
            <Link
              className="req-grp text-right text-black w-100 d-block links  m-0 h6"
              exact={true}
              to={"/flightGroupEnquiry"}
              target="_blank"
              // style={{ paddingRight: "70px" }}
            >
              Request for Group Enquiry
            </Link>
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-bus" /> Buses
              </span>
            }
            key="2"
          >
            <Buses />
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-building-o" /> Hotels
              </span>
            }
            key="3"
          >
            <Hotels />
          </TabPane>
          {/* <TabPane
            tab={
              <span>
                <i className="fa fa-binoculars" /> Activities
              </span>
            }
            key="4"
          >
            <ActivitiesSearchBox />
          </TabPane>
          <TabPane
            tab={
              <span>
                <i className="fa fa-th-large" /> Build Package
              </span>
            }
            key="5"
          >
            <BuilpackageSearchBox />
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

export default SearchBox;
