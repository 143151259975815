import {
  AccountBookOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,

  BuildOutlined,
  CalendarOutlined,
  CreditCardOutlined,
  CloseSquareOutlined,

  DisconnectOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FormOutlined,
  MoneyCollectOutlined,
  InteractionOutlined,

  LayoutOutlined,

  SecurityScanOutlined,

  SettingOutlined,
  ShopOutlined,
  SplitCellsOutlined,
  SwitcherOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

export const SubMenus = [
  {
    submenukey: "sub1",
    icon: <UserOutlined />,
    title: "Masters",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/role",
        icon: <UserOutlined />,
        title: "Roles",
      },
      {
        key: "/admin/user",
        icon: <TeamOutlined />,
        title: "Admin Users",
      },
      {
        key: "/admin/accesspermissions",
        icon: <UserSwitchOutlined />,
        title: "Access Permissions",
      },
      {
        key: "/admin/agentregistration",
        icon: <UserOutlined />,
        title: "Site Agents",
      },
      {
        key: "/admin/siteusers",
        icon: <UserOutlined />,
        title: "Site Users",
      },

    
      {
        key: "/admin/operator",
        icon: <UsergroupAddOutlined />,
        title: "Operators",
      },
      {
        key: "/admin/membershipinfo",
        icon: <UserSwitchOutlined />,
        title: "Membershipinfo",
      },
      {
        key: "/admin/provider",
        icon: <AuditOutlined />,
        title: "Providers",
      },
      {
        key: "/admin/apiprovider",
        icon: <ApiOutlined />,
        title: "API Providers",
      },

      {
        key: "/admin/set-commission",
        icon: <UsergroupAddOutlined />,
        title: "Set Commission",
      },
      {
        key: "/admin/margins",
        icon: <UsergroupAddOutlined />,
        title: "Margins",
      },
     
      {
        key: "/admin/ServiceType",
        icon: <SettingOutlined />,
        title: "Service Type",
      },
     
      {
        key: "/admin/screens",
        icon: <UserOutlined />,
        title: "Screens",
      },
      {
        key: "/admin/hotelImageUpload",
        icon: <BuildOutlined />,
        title: "HotelImageUpload",
      },
    ],
  },
  {
    submenukey: "sub29",
    icon: <UserOutlined />,
    title: "Marketing",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/banners",
        icon: <SwitcherOutlined />,
        title: "Banners",
      },
      {
        key: "/admin/promo",
        icon: <DisconnectOutlined />,
        title: "Promo Code",
      },
      {
        key: "/admin/cms",
        icon: <SecurityScanOutlined />,
        title: "CMS Screens",
      },
      {
        key: "/admin/deals",
        icon: <LayoutOutlined />,
        title: "Deals",
      },
      {
        key: "/admin/cityhotel",
        icon: <LayoutOutlined />,
        title: "City Hotel",
      },
     
      {
        key: "/admin/HelpInfo",
        icon: <AuditOutlined />,
        title: "Help Info",
      },
      {
        key: "/admin/topCities",
        icon: <AuditOutlined />,
        title: "Top Cities",
      },
     
    ],
  },
  
  {
    submenukey: "sub4",
    icon: <DollarOutlined />,
    title: "Accounts",

    children: [
      {
        key: "/admin/ledger-statement",
        icon: <FileDoneOutlined />,
        title: "Ledger Statement",
      },
      {
        key: "/admin/Userledger-statement",
        icon: <FileDoneOutlined />,
        title: "User Ledger Statement",
      },
      {
        key: "/admin/b2b",
        icon: <SplitCellsOutlined />,
        title: "B2B Pg",
      },
      {
        key: "/admin/coupons",
        icon: <DisconnectOutlined />,
        title: "Coupons",
      },
      {
        key: "/admin/managebalance",
        icon: <AccountBookOutlined />,
        title: "Manage Balance",
      },
      {
        key: "/admin/taxmaster",
        icon: <AccountBookOutlined />,
        title: "Tax Master",
      },
      {
        key: "/admin/bankdetails",
        icon: <BankOutlined />,
        title: "Bank Details",
      },
    ],
  },
  {
    submenukey: "sub3",
    icon: <AuditOutlined />,
    title: "Commissions/Markup",
    className: "left-menu-decre",

    children: [
      
      {
        key: "/admin/flightcommissions",
        // icon: <SendOutlined />,
        title: "Flight Commission/Markup",
      },
      {
        key: "/admin/hotelcommissions",
        // icon: <BuildOutlined />,
        title: "Hotel Commission/Markup",
      },
      {
        key: "/admin/TranfersCommissions",
        // icon: <SelectOutlined />,
        title: "Transfers Commissions",
      },
      {
        key: "/admin/buscomissions",
        // icon: <SelectOutlined />,
        title: "Bus Commissions",
      },
    ],
  },
  {
    submenukey: "sub5",
    icon: <FilePdfOutlined />,
    title: "Ticketing",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/travelcalendar",
        icon: <CalendarOutlined />,
        title: "Travel Calendar",
      },
      
      {
        key: "/admin/cancelrequest",
        icon: <CloseSquareOutlined />,
        title: "Cancel Requests",
      },
      {
        key: "/admin/reshedule-request",
        icon: <CloseSquareOutlined />,
        title: "Reshedule Requests",
      },
    ],
  },
  {
    submenukey: "sub6",
    icon: <FileSearchOutlined />,
    title: "Reports",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/reports",
        icon: <FileTextOutlined />,
        title: "All Reports",
      },
      {
        key: "/admin/reports/flightReport",
        icon: <FileTextOutlined />,
        title: "Flight Reports",
      },
      {
        key: "/admin/reports/hotelReport",
        icon: <FileTextOutlined />,
        title: "Hotel Reports",
      },
      {
        key: "/admin/reports/busReport",
        icon: <FileTextOutlined />,
        title: "Bus Reports",
      },
      {
        key: "/admin/reports/transferReports",
        icon: <FileTextOutlined />,
        title: "Transfer Reports",
      },
      {
        key: "/admin/reports/holidayOffline",
        icon: <FileTextOutlined />,
        title: "Holiday Enquiries"
      },
      
      {
        key: "/admin/contactforms",
        icon: <FormOutlined />,
        title: "Contact Forms",
      },
      {
        key: "/admin/flightenquiries",
        icon: <ThunderboltOutlined />,
        title: "Flight Enquiries",
      },
      
    ],
  },
  
  {
    submenukey: "sub7",
    icon: <UserOutlined />,
    title: "Logs",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/pglogs",
        icon: <ApiOutlined />,
        title: "PG Log",
      },
      {
        key: "/admin/apilogs",
        icon: <ApiOutlined />,
        title: "API Log",
      },
      
    ],
  },
  {
    submenukey: "sub8",
    icon: <SettingOutlined />,
    title: "Settings",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/settings",
        icon: <SettingOutlined />,
        title: "Settings",
      },
    ],
  },
  
  {
    submenukey: "sub15",
    icon: <InteractionOutlined />,
    title: "Holiday Extranet",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/toursextranet-category",
        icon: <ShopOutlined />,
        title: "Category",
      },
      {
        key: "/admin/toursextranet-subcategory",
        icon: <ShopOutlined />,
        title: "Sub Category",
      },
      {
        key: "/admin/holiday-hotelroomcategory",
        icon: <ShopOutlined />,
        title: "Hotel RoomCategory",
      },



      {
        key: "/admin/toursextranet-packages",
        icon: <ShopOutlined />,
        title: "Packages",
      },
      {
        key: "/admin/toursextranet-price",
        icon: <SettingOutlined />,
        title: "Price",
      },
      {
        key: "/admin/toursextranet-media",
        icon: <ShopOutlined />,
        title: "Media",
      },
      
      {
        key: "/admin/toursextranet-cancelpolicy",
        icon: <SettingOutlined />,
        title: "Cancelation Policy",
      },
      {
        key: "/admin/toursextranet-cancelrequest",
        icon: <SettingOutlined />,
        title: "Cancel Request",
      },
      {
        key: "/admin/toursextranet-reschedulerequest",
        icon: <SettingOutlined />,
        title: "Reschedule Request",
      },

      {
        key: "/admin/hoildayextranet-orders",
        icon: <ShopOutlined />,
        title: "Orders",
      },
    ],
  },
  {
    submenukey: "sub17",
    icon: <CreditCardOutlined />,
    title: "Visa Extranet",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/visaDocs",
        icon: <SettingOutlined />,
        title: "VisaEssential Documents",
      },
      {
        key: "/admin/managevisa",
        icon: <SettingOutlined />,
        title: "ManageVisa Master",
      },
      {
        key: "/admin/visaType",
        icon: <SettingOutlined />,
        title: "Manage Visa Type",
      },
      {
        key: "/admin/visaGuide",
        icon: <SettingOutlined />,
        title: "Visa Guide",
      },
      {
        key: "/admin/docsReqdVisa",
        icon: <SettingOutlined />,
        title: "Documents required for Visa",
      },
      {
        key: "/admin/visafaqs",
        icon: <SettingOutlined />,
        title: "Visa FAQs",
      },
      {
        key: "/admin/embassyDetails",
        icon: <SettingOutlined />,
        title: "Embassy Details",
      },
      {
        key: "/admin/visa-customer-reports",
        icon: <SettingOutlined />,
        title: "Visa Reports",
      },
      // {
      //   key: "/visa/getVisaBookignReportsByFilter",
      //   icon: <SettingOutlined />,
      //   title: "Visa Reports",
      // },
    ],
  },
  {
    submenukey: "sub33",
    icon: <MoneyCollectOutlined />,
    title: "Currency Conversion",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/currency-conversion/currency-conversion",
        icon: <SettingOutlined />,
        title: "Currency Conversion",
      },
    ],
  },
  
];
