import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import arrowImg from "../../assets/images/flight-arrow.png";
import AirportAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectByCategory";
import { useSytContext } from "../../common/providers/SytProvider";
import { AppConstants } from "../../helpers/constants";
import { OriDesValidate } from "../../helpers/CustomValidators";
import { useSessionStorage } from "../../helpers/useStorage";
import "../flight-search/FlightSearch.scss";
import queryString from "query-string";
import MultiCityList from "./MultiCityList";
import {
  createQueryParamsFromRequest,
  createSearchFromForm,
  getSearchRequestFromQuery,
  MapRequestToForm,
} from "./SearchRequestHelper";

const FlightSearch = (props) => {
  let history = useHistory();
  const origin = useRef();
  const destination = useRef();

  const dateBox = useRef();
  const returnDateBox = useRef();
  const paxBox = useRef();
  const searchBtn = useRef();
  const [showDate, setShowDate] = useState(false);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [cabinClass, setCabinClass] = useState("Economy");
  const { Option } = Select;
  const [form] = Form.useForm();
  //const [departureDate, setDepartureDate] = useState(null);

  const handlePrevDay = () => {
    const currentDepartureDate = form.getFieldValue("departure");
    const today = moment().startOf('day'); // Get the current day's start time
  if (currentDepartureDate && moment(currentDepartureDate).isAfter(today)) {
    const prevDay = moment(currentDepartureDate).subtract(1, 'days');
    form.setFieldsValue({ departure: prevDay });
  }
  };
  
  const handleNextDay = () => {
    const currentDepartureDate = form.getFieldValue("departure");
    if (currentDepartureDate) {
      const nextDay = moment(currentDepartureDate).add(1, 'days');
      form.setFieldsValue({ departure: nextDay });
    }
  };
  // const handleNextyDay = () => {
  //   const currentReturnDate = form.getFieldValue("arrival");
  //   const currentDepartureDate = form.getFieldValue("departure");
  //   if (currentReturnDate && currentDepartureDate) {
  //     const nextDay = moment(currentReturnDate).add(1, "days");
  //     if (nextDay.isAfter(currentDepartureDate, "day")) {
  //       form.setFieldsValue({ arrival: nextDay });
  //     }
  //   }
  // };
  const handleNextyDay = () => {
    const currentReturnDate = form.getFieldValue("arrival");
    const currentDepartureDate = form.getFieldValue("departure");
  
    if (currentDepartureDate) {
        let updatedReturnDate;
        if (currentReturnDate && currentDepartureDate.isAfter(currentReturnDate, "day")) {
            // If the departure date is after the return date,
            // set the return date to be the same as the departure date plus one day
            updatedReturnDate = moment(currentDepartureDate).add(1, "days");
        } else {
            // Otherwise, use the original return date plus one day
            updatedReturnDate = moment(currentReturnDate).add(1, "days");
        }
        form.setFieldsValue({ arrival: updatedReturnDate });
    }
};
  
  const handlePrevyDay = () => {
    const currentReturnDate = form.getFieldValue("arrival");
    const currentDepartureDate = form.getFieldValue("departure");
    if (currentReturnDate && currentDepartureDate) {
      const prevDay = moment(currentReturnDate).subtract(1, "days");
      if (prevDay.isAfter(currentDepartureDate, "day")) {
        form.setFieldsValue({ arrival: prevDay });
      }
    }
  };
  
  
  const [FlightRecentSearchesResults, setFlightRecentSearchesResults] =
    useSessionStorage("recentSearchesResults", []);
  const [searchClicked, setSearchClicked] = useState(false);
  const departureDate = moment();
  const [arrivalDate, setArrivalDate] = useState(moment());
  const [cabin_type, setCabinType] = useState("Economy")
  const [faresearchType, setfaresearchType] = useState("REGULAR");
  const [travel_type, setTravelType] = useState("oneWay");
  const [noofroutes, setnoofroutes] = useState(2);
  const [passengers, setPassengers] = useState({
    adults: 1,
    childs: 0,
    infants: 0,
  });

  const [toggle, setTogle] = useState(false);

  const setUpFormInitial = () => {
    let results = false;
    if (props.modify) {
      let query_request = getSearchRequestFromQuery();
      results = MapRequestToForm(query_request);
    } else {
      let stored_request = JSON.parse(
        sessionStorage.getItem("flight_search_request")
      );
      results = MapRequestToForm(stored_request);
    }

    if (results) {
      setTravelType(results.airTravelType);
      setCabinClass(results.cabinClass);
      form.setFieldsValue(results);
      setPassengers({
        adults: results.adults,
        childs: results.childs,
        infants: results.infants,
      });
    }
  };

  const getsearchType = (e) => {
    setfaresearchType(e)

  };

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);
      if (FlightRecentSearchesResults.length > 0) {
        let array = [];
        array = [...FlightRecentSearchesResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setFlightRecentSearchesResults([
            searchObj,
            ...array.filter(
              (item) =>
                item.origin !== searchObj.origin &&
                item.destination !== searchObj.destination
            ),
          ]);
        }

        return;
      }
    }

    setFlightRecentSearchesResults([searchObj]);
  };
  let intitial = {
    cabinClass: "Economy",
    airTravelType: "OneWay",
    departure: departureDate,
    originDestinationFields: [{}, {}],
  };



  const { topCities } = useSytContext();
  const [FlightTopCities, setFlightTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 1);
      if (data.length) {
        setFlightTopCities(data);
      } else {
        setFlightTopCities([]);
      }
    }
  }, [topCities]);

  const updateTravelType = (val) => {
    const toDate = form.getFieldValue("departure");
    if (val) {
      if (val === "oneWay") {
        form.setFieldsValue({
          arrival: "",
        });
      } else {
        form.setFieldsValue({
          arrival: moment(toDate).add(1, "days"),
        });
      }

      setTravelType(val);
    }
  };

  const onFormSubmit = (formData) => {
    if(searchClicked){

    if (formData.origin === formData.destination && travel_type != "multidestination") {
      message.error("Origin and Destination cannot be same", 3);
      return false;
    }

    let includeCarrier = "";
    let stopOver = "none";
    const searchRequest = createSearchFromForm(
      formData,
      travel_type,
      cabinClass,
      passengers,
      includeCarrier,
      stopOver,
      faresearchType

    );

    const params = createQueryParamsFromRequest(searchRequest);

    recentSearches(params);

    if (props.modify) {
      history.replace("/flight/results?" + params);
      props.onFormSubmit();
    } else {
      history.push("/flight/results?" + params);
    }
  }
  };

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };
  const handleFocus = () => {
    searchBtn.current.focus();
  };
  const updatePaxCount = (opr) => {
    if (passengers.adults + passengers.childs + passengers.infants === 9) {
      if (opr === "adultInc" || opr === "infantInc" || opr === "childInc") {
        return;
      }
    }
    switch (opr) {
      case "adultInc":
        setPassengers({ ...passengers, adults: passengers.adults + 1 });
        break;
      case "adultDec":
        if (passengers.adults > 1) {
          setPassengers({ ...passengers, adults: passengers.adults - 1 });
        }
        break;
      case "childInc":
        setPassengers({ ...passengers, childs: passengers.childs + 1 });
        break;
      case "childDec":
        if (passengers.childs >= 1) {
          setPassengers({ ...passengers, childs: passengers.childs - 1 });
        }
        break;
      case "infantInc":
        setPassengers({ ...passengers, infants: passengers.infants + 1 });
        break;
      case "infantDec":
        if (passengers.infants >= 1) {
          setPassengers({ ...passengers, infants: passengers.infants - 1 });
        }
        break;
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(arrivalDate).startOf("day");
  };

  const PaxInput = () => (
    <>
      <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif" }} >Travellers</span>

      <Form.Item className="flight-passenger" style={{ border: '1px solid #dfdfe1' }} >
        <Input
          ref={paxBox}
          size="large"
          readOnly
          value={passengers.adults + passengers.childs + passengers.infants + " Guests"}
          style={{ cursor: "pointer" }}
          onClick={() => setTogle(toggle ? false : true)}
        />
        <div
          style={{
            display: toggle ? "block" : "none",
          }}
          className="pax-modal"
        >
          <div className="pax-modal-wrapper">
            <div className="pax-modal-arrow"></div>
            <li>
              <div className="pax-label">
                <p>Adults</p>
                <span>over 12 years old</span>
              </div>
              <div className="pax-count">
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={(e) => updatePaxCount("adultDec")}
                ></i>
                <span>{passengers.adults}</span>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={(e) => updatePaxCount("adultInc")}
                ></i>
              </div>
            </li>

            <li>
              <div className="pax-label">
                <p>Children</p>
                <span>2 - 12 years old</span>
              </div>
              <div className="pax-count">
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={(e) => updatePaxCount("childDec")}
                ></i>
                <span>{passengers.childs}</span>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={(e) => updatePaxCount("childInc")}
                ></i>
              </div>
            </li>
            <li>
              <div className="pax-label">
                <p>Infants</p>
                <span>upto 2 years old</span>
              </div>
              <div className="pax-count">
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={(e) => updatePaxCount("infantDec")}
                ></i>
                <span>{passengers.infants}</span>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={(e) => updatePaxCount("infantInc")}
                ></i>
              </div>
            </li>
            {passengers.infants > passengers.adults ? (
              <li>
                <span
                  style={{
                    fontSize: "11px",
                    color: "red",
                  }}
                >
                  The number of infants cannot be grater than the number of
                  adults.
                </span>
              </li>
            ) : (
              ""
            )}
            {passengers.adults + passengers.child > 9 ? (
              <li>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{ fontSize: "30px" }}
                ></i>
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "11px",
                    color: "blue",
                  }}
                >
                  Booking for more than 9 persons will be procced as group
                  booking.
                </span>
              </li>
            ) : (
              ""
            )}
            <div>
              <Button
                block
                className="pax-ready-btn"
                onClick={() => {
                  setTogle((prev) => !prev);
                  if (toggle) {
                    handleFocus();
                  }
                }}
              >
                Ready
              </Button>
            </div>
          </div>
        </div>
      </Form.Item>
    </>
  );

  const [dataFlip, setDataFlip] = useState(false);

  const handleSwap = () => {
    const origin = form.getFieldValue("origin");
    const destination = form.getFieldValue("destination");

    if (origin && destination) {
      form.setFieldsValue({
        origin: destination,
        destination: origin,
      });
      setDataFlip((prev) => !prev);
    }
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setArrivalDate(momentdate);
    const toDate = form.getFieldValue("arrival");
    if (travel_type === "roundTrip" && toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays <= 0) {
        let newTodate = moment(momentdate).add(2, "days");
        form.setFieldsValue({
          arrival: newTodate,
        });
      }
    }
  };
  const updateinputfield = (text) => {

    const fiel = form.getFieldValue("originDestinationFields");
    if (fiel?.[text]?.destination && noofroutes - 1 > text) {
      fiel[text + 1] = { ...fiel?.[text + 1], ["origin"]: fiel?.[text]?.destination };
      form.setFieldsValue("originDestinationFields", fiel)
    }

  }
  const ChangeDates = (date, key) => {
    const fiel = form.getFieldValue("originDestinationFields");

    if (noofroutes - 1 > key) {
      fiel[key + 1] = { ...fiel?.[key + 1], ["departureDateTime"]: date };
      form.setFieldsValue("originDestinationFields", fiel)
    }

  }
  const getnoofroutes = (count) => {
    setnoofroutes(count)
    updateinputfield(count - 2);
  }
  return (
    <div className="hotels_search_box1">
      <Helmet>
        <title>
          Book Flights, Hotels, Holiday Tickets   -{" "}
          {AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          content=" Get cheap flight tickets, hotels, HolidayCMS tickets with a best travel company of India- hopmker.com  Avail complete travel solutions for a lifetime journey. "
        />
      </Helmet>
      <div className="hotels-wrapper">
        <div className="flight-bg-target">
          <div className="class-container" style={{ marginBottom: '1%' }} >
            <div className="trip-container">

              <Form.Item
                name="airTravelType"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  value={travel_type}
                  onChange={updateTravelType}
                  className="modify-selectors" defaultValue="oneWay" >
                  <Option value={"oneWay"}>OneWay</Option>
                  <Option value={"roundTrip"}>Return</Option>
                  <Option value={"multidestination"}>Multi</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="fare_div">
              <div className="faretype_container">
                <Form.Item
                  className="form-st-reg-dropdown"
                  name="cabinClass"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}

                >
                  <Select value={cabinClass} onSelect={(val) => setCabinClass(val)}
                    className="classTypeSelect" defaultValue="Economy" ons >
                    <Option value={"Economy"} >Economy</Option>
                    <Option value={"premiumEconomy"}>Premium Economy</Option>
                    <Option value={"Business"}>Business</Option>
                    <Option value={"first"}>First Class</Option>
                    <Option value={"premiumFirst"}>Premium First Class</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div class="cabin-container" style={{ marginLeft: '3%' }}>

              <Form.Item

                name="airTravelType"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}

              >
                <Select
                  value={faresearchType}
                  onChange={getsearchType}
                  className="modify-selectors" defaultValue="Regular" >
                  <Option value={"REGULAR"}>Regular</Option>
                  <Option value={"STUDENT"}>Student</Option>
                </Select>
              </Form.Item>

            </div>

          </div>

          <div id="oneWayForm">


            <Form
              form={form}
              onFinish={onFormSubmit}
              className="flight-search flight-bg-side"
              layout="vertical"
              initialValues={intitial}
            >
              <Row className="flight-alert-sec"
                style={{ marginTop: "-3px" }}>
                {travel_type !== "multidestination" ? (
                  <>
                    <Col md={5} xs={24} className="originwrapper">
                      <span className="for-to-labflisearch">From</span>
                      <AirportAutoCompleteSelectByCategory
                        formItemProps={{
                          name: "origin",

                          rules: [
                            {
                              required: true,
                              message: "Please Specify The City",
                            },
                            {
                              validator: (_, value) => {
                                let destination =
                                  form.getFieldValue("destination");
                                return OriDesValidate(value, destination);
                              },
                            },
                          ],
                        }}
                        selectProps={{
                          size: "large",
                          placeholder: "Search By City",
                        }}
                        refName={origin}
                        focusRef={destination}
                        handleOnSubmit={handleOnSubmit}
                        dataFlip={dataFlip}
                        topCities={FlightTopCities}
                        recentKey={"originRecent"}
                        isSource={true}
                      />

                    </Col>
                    <Col md={1} xs={24} className="swip-btn-flight-work"  >
                      <img
                        className="arrow-bus-inr"
                        src={arrowImg}
                        alt="Arrow img"
                        onClick={handleSwap}
                      />
                    </Col>

                    <Col md={5} xs={24} className="destination"  >
                      <span className="for-to-labflisearch1">To</span>
                      <AirportAutoCompleteSelectByCategory
                        formItemProps={{
                          name: "destination",
                          rules: [
                            {
                              required: true,
                              message: "Please Specify The City",
                            },
                            {
                              validator: (_, value) => {
                                let origin = form.getFieldValue("origin");
                                return OriDesValidate(origin, value);
                              },
                            },
                          ],
                        }}
                        selectProps={{
                          size: "large",
                          placeholder: "Search By City",
                        }}
                        refName={destination}
                        focusRef={dateBox}
                        handleOnSubmit={handleOnSubmit}
                        dataFlip={dataFlip}
                        topCities={FlightTopCities}
                        recentKey={"destinationRecent"}
                        isSource={false}
                      />
                    </Col>
                    <Col md={3} xs={24} style={{ border: '1px solid #dfdfe1', maxHeight: "50px" }}>
                      <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif" }} >Departing</span>
                       <button onClick={handlePrevDay} style={{ position: 'absolute', top: '24%', left: '102px',zIndex:12,background:"transparent",color:"black",border:"none", transform: 'translateY(-50%)' }}>{'<'}</button>
          <button onClick={handleNextDay} style={{ position: 'absolute', top: '26%', right: '26px',zIndex:12,background:"transparent",color:"black",border:"none", transform: 'translateY(-50%)' }}>{'>'}</button>
                      <Form.Item
                        name="departure"
                        rules={[
                          {
                            required: true,
                            message: "Please Select a Date",
                          },
                        ]}
                      >
                        <DatePicker
                          allowClear={false}
                          style={{ width: "100%" }}
                          className="train-search-btn "
                          size="large"
                          format={"DD-MM-YYYY"}
                          //value={departureDate}
                          disabledDate={disabledOriginDate}
                          onChange={(date, dateString) =>
                            onChangeOriginDate(date, dateString)
                          }
                          ref={dateBox}
                          open={showDate}
                          onOpenChange={() => {
                            setShowDate((prev) => !prev);
                          }}
                          placeholder="Departure Date"
                          dropdownClassName="custom-mob-calendar"
                          inputReadOnly={true}
                          panelRender={(originalPanel) => {
                            return (
                              <div className="original-panel">
                                <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                  Departure Date
                                </p>
                                {originalPanel}
                              </div>
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={3} xs={24} style={{ border: '1px solid #dfdfe1', maxHeight: "50px" }} className="date-ret-mar">
                      <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif" }} >Returning</span>
                        <button onClick={handlePrevyDay} style={{ position: 'absolute', top: '24%', left: '102px',zIndex:12,background:"transparent",color:"black",border:"none", transform: 'translateY(-50%)' }}>{'<'}</button>
          <button onClick={handleNextyDay} style={{ position: 'absolute', top: '26%', right: '26px',zIndex:12,background:"transparent",color:"black",border:"none", transform: 'translateY(-50%)' }}>{'>'}</button>
                      <Form.Item
                        className="returnDate"

                        name="arrival"
                        rules={[
                          {
                            required:
                              travel_type === "roundTrip" ? true : false,
                            message: "Please Select a Date",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="Arrival Date"
                          dropdownClassName="custom-mob-calendar"
                          inputReadOnly={true}
                          style={{ width: "100%" }}
                          className="train-search-btn"
                          size="large"
                          format={"DD-MM-YYYY"}
                          defaultPickerValue={arrivalDate}
                          disabledDate={disabledDestDate}
                          onChange={(date) => {
                            if (!date) {
                              form.setFieldsValue({
                                arrival: "",
                              });
                              setTravelType("oneWay");
                            }
                          }}
                          ref={returnDateBox}
                          open={showReturnDate}
                          onOpenChange={() => {
                            setShowReturnDate((prev) => !prev);
                          }}

                          disabled={travel_type !== "roundTrip" ? true : false}
                          onClick={() => {
                            if (travel_type !== "roundTrip") {
                              setTravelType("roundTrip");
                              form.setFieldsValue({
                                // arrival: moment().add(1, "days"),
                              });
                            }
                          }}
                          panelRender={(originalPanel) => {
                            return (
                              <div className="original-panel">
                                <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6">
                                  Return Date
                                </p>
                                {originalPanel}
                              </div>
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <MultiCityList updateinputfield={updateinputfield} getnoofroutes={getnoofroutes} ChangeDates={ChangeDates} />
                )}

                <Col md={3} xs={24} className="class-target-e" style={{ marginRight: '1%' }} >
                  <PaxInput />
                </Col>
                <Col md={3} xs={24} className="class-target-e">
                  <Button
                    ref={searchBtn}
                    className="primary-btn"
                    htmlType="submit"
                     onClick={() => setSearchClicked(true)}
                  >
                    Search
                  </Button >
                </Col >
              </Row >
            </Form >
          </div >
        </div >
      </div >
    </div >
  );
};

export default FlightSearch;
