// import {
//   CaretDownOutlined,
//   RightOutlined,
//   ShoppingCartOutlined,
//   SyncOutlined,
//   UserOutlined,
// } from "@ant-design/icons";
// import googlelogo from '../../assets/images/google-logo.png'
// import { Dropdown, Menu, Modal } from "antd";
// import Drawersearch from "./Drawersearch";
// import moment from "moment";
// import React, { useEffect, useState, useCallback } from "react";
// import { Link, NavLink, useHistory, withRouter } from "react-router-dom";
// import { useAuthContext } from "../../common/providers/AuthProvider";
// import { useSytContext } from "../../common/providers/SytProvider";
// import Login from "../../components/Login/Login";
// import APIClient from "../../helpers/ApiClient";
// import "../navbar/Navbar.scss";
// import FlagsList from "./FlagsList";
// import { Input, Space } from 'antd';
// import SlidingPane from "react-sliding-pane";
// import "react-sliding-pane/dist/react-sliding-pane.css";

// const { SubMenu } = Menu;
// const Nav = ({ location, props }) => {
//   const [navclass, setNavclass] = useState("nav-transparent-class");
//   const [isHomePage, setIsHomePage] = useState(true)
//   const [y, setY] = useState(window.scrollY);
//   const [index, setIndex] = useState(0);
//   const { Search } = Input;
//   const onSearch = (value) => console.log(value);
//   const [state, setState] = useState({
//     isPaneOpen: false,
//     isPaneOpenLeft: false,
//   });
//   const {
//     isLogin: { flag, agent, isGuest },
//     user,
//     setUser,
//     resetIsLogin,
//   } = useAuthContext();
//   // console.log(agent, user, "affe")
//   let services = user?.Services ? user?.Services : [];

//   const handleNavigation = useCallback(
//     e => {
//       const window = e.currentTarget;
//       if (isHomePage && y > 30) {
//         setNavclass("header-container")
//       }
//       else if (isHomePage && y < 50) {
//         setNavclass("nav-transparent-class")
//       }
//       else if (!isHomePage) {
//         setNavclass("header-container-relative")
//       }
//       setY(window.scrollY);
//     }, [y]
//   );

//   useEffect(() => {
//     setY(window.scrollY);
//     window.addEventListener("scroll", handleNavigation);

//     return () => {
//       window.removeEventListener("scroll", handleNavigation);
//     };
//   }, [handleNavigation]);

//   useEffect(() => {
//     const activepath = location?.pathname ?? ""
//     if ((activepath !== '/hotels' && activepath !== '/Holiday' && activepath !== '/') || agent) {
//       setNavclass("header-container-relative")
//       setIsHomePage(false)
//     } else {
//       setIsHomePage(true)
//       setNavclass("nav-transparent-class")
//     }

//   }, [location?.pathname])


//   const {
//     logo,
//   } = useSytContext();
//   const BASE = process.env.REACT_APP_BASE_URL;


//   let history = useHistory();
//   const [fetchingWallet, setFetchingWallet] = useState(false);
//   const [navToggle, setNavToggle] = useState(false);

//   const logout = () => {
//     resetIsLogin();
//     history.push("/");
//   };

//   const toggleCount = () => {
//     setNavToggle((prev) => !prev);
//   };


//   const mainicon = (
//     <Menu>
//       <Menu.Item key="/flight">
//         <Link to="/">Flight</Link>
//       </Menu.Item>
//     </Menu>
//   );

//   const getwalletBalance = () => {
//     if ((agent /*|| flag*/)) {
//       setFetchingWallet(true);
//       APIClient.get(`admin/GetAgentWalletDetails/${user.UserID}`)
//         .then((resp) => {
//           if (resp.status == 200) {
//             setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
//           }
//           setFetchingWallet(false);
//         })
//         .catch((error) => {
//           setFetchingWallet(false);
//         });
//     }
//     if (user && flag) {
//       setFetchingWallet(true);
//       APIClient.get(`admin/GetUserWalletDetails/${user.UserID}`)
//         .then((resp) => {
//           if (resp.status == 200) {
//             setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
//           }
//           setFetchingWallet(false);
//         })
//         .catch((error) => {
//           setFetchingWallet(false);
//         });
//     }
//   };
//   /*============= check user logged or not =========== */
//   const [modalVisible, setModalVisible] = useState({
//     visible: false,
//     type: "USER",
//   });



//   const handelIndex = (index, link) => {
//     setIndex(index)
//   }
//   const showModal1 = (type) => {
//     /*============= check user logged or not =========== */
//     setModalVisible({ visible: true, type: type });

//   };
//   useEffect(() => {
//     if (location?.state) {
//       if (location?.state?.login === true) {
//         showModal1();
//       }
//     }
//   }, [location]);

//   const Balance = (
//     <Menu>
//       <div className="nav-wallet-wrapper">
//         {agent ? (
//           <span className="d-flex align-items-center justify-content-between">
//             Cash : INR{" "}
//             {user
//               ? user?.Walletdetails
//                 ? user?.Walletdetails.Amount
//                 : "0"
//               : ""}
//             {"  "}
//             <SyncOutlined
//               spin={fetchingWallet}
//               style={{ marginLeft: "10px" }}
//               onClick={() => {
//                 if (!fetchingWallet) getwalletBalance();
//               }}
//             />
//           </span>
//         ) : (
//           <span className="d-flex align-items-center justify-content-between">
//             Balance : INR
//             {user
//               ? user?.Walletdetails
//                 ? user?.Walletdetails.Amount
//                 : "0"
//               : ""}
//             {"  "}
//             <SyncOutlined
//               spin={fetchingWallet}
//               style={{ marginLeft: "10px" }}
//               onClick={() => {
//                 if (!fetchingWallet) getwalletBalance();
//               }}
//             />
//           </span>
//         )}
//       </div>

//       {user && user?.Role?.RoleLevel == 3 ? (
//         <>
//           {user?.Walletdetails?.CreditAmount >= 0 ? (
//             <div className="nav-wallet-wrapper">
//               <b>Credit Amount : {user?.Walletdetails?.CreditAmount}</b>
//             </div>
//           ) : null}
//           {user ? (
//             user?.Walletdetails?.ExpiryDate ? (
//               <div className="nav-wallet-wrapper">
//                 <p className="mb-0">
//                   Credit Expires :{" "}
//                   {user?.Walletdetails?.ExpiryDate
//                     ? moment(user?.Walletdetails?.ExpiryDate).format(
//                       "DD-MM-YYYY"
//                     )
//                     : ""}
//                 </p>
//               </div>
//             ) : null
//           ) : null}

//           <Menu.Item key="15">
//             <NavLink
//               exact
//               activeClassName="selectedNav"
//               className="cre-amount-14"
//               to="/deposits"
//             >
//               TOPUP
//             </NavLink>
//           </Menu.Item>
//         </>
//       ) : null}
//     </Menu>
//   );
//   const loginMenu = (
//     <Menu className="menu-bdr-nav">
//       <Menu.Item key="1">
//         <NavLink exact activeClassName="selectedNav" to="/profile">
//           My Profile
//         </NavLink>
//       </Menu.Item>

//       <SubMenu key="2" title="Reports" className="border-bottom-1">
//         <Menu.Item key="2-1">
//           {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ?
//             <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
//               Flight Reports
//             </NavLink> : null}
//         </Menu.Item>
//         <Menu.Item key="2-3">
//           {(user?.Role?.Name === "Agent" && services.includes("3")) || isGuest ?
//             <NavLink exact activeClassName="" to="/booking-reports?type=Holiday">
//               Holiday Reports
//             </NavLink> : null}
//         </Menu.Item>
//         <Menu.Item key="2-2">
//           {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ?
//             <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
//               Hotel Reports
//             </NavLink> : null}
//         </Menu.Item>

//       </SubMenu>
//       <Menu.Item key="5">
//         <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
//           Coupon Wallet
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="6">
//         <NavLink exact activeClassName="selectedNav" to="/traveller-details">
//           Passenger Details
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="8" className="border-bottom-0">
//         <NavLink exact activeClassName="selectedNav" to="/change-password">
//           Change Password
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="12">
//         <NavLink exact activeClassName="selectedNav" to="/UserStatements">
//           Statements
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="13">
//         <NavLink exact activeClassName="selectedNav" to="/wallet">
//           Wallet
//         </NavLink>
//       </Menu.Item>
//     </Menu>
//   );

//   const AgentMenu = (
//     <Menu className="menu-bdr-nav">
//       <Menu.Item key="1">
//         <NavLink exact activeClassName="selectedNav" to="/myprofile">
//           My Profile
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="2">
//         <NavLink exact activeClassName="selectedNav" to="/commission-details">
//           My Commissions
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="3">
//         <NavLink exact activeClassName="selectedNav" to="/bank-details">
//           Bank Details
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="4">
//         <NavLink exact activeClassName="selectedNav" to="/agent-markup">
//           Markups
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="5">
//         <NavLink exact activeClassName="selectedNav" to="/deposits">
//           Deposits
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="5">
//         <NavLink exact activeClassName="selectedNav" to="/agent-logo">
//           Logo
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="6">
//         <NavLink exact activeClassName="selectedNav" to="/store-vistors">
//           Store Visitors
//         </NavLink>
//       </Menu.Item>
//       <SubMenu key="7" title="Reports" className="border-bottom-1">
//         <Menu.Item key="7-1">
//           {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ?
//             <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
//               Flight Reports
//             </NavLink> : null}
//         </Menu.Item>
//         <Menu.Item key="7-3">
//           {(user?.Role?.Name === "Agent" && services.includes("3")) || isGuest ?
//             <NavLink exact activeClassName="" to="/booking-reports?type=Holiday">
//               Holiday Reports
//             </NavLink> : null}
//         </Menu.Item>
//         <Menu.Item key="7-2">
//           {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ?
//             <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
//               Hotel Reports
//             </NavLink> : null}
//         </Menu.Item>

//       </SubMenu>

//       <Menu.Item key="13">
//         <NavLink exact activeClassName="selectedNav" to="/Discounts">
//           Margins
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="12">
//         <NavLink exact activeClassName="selectedNav" to="/statements">
//           Statements
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="10" className="border-bottom-0">
//         <NavLink exact activeClassName="selectedNav" to="/change-password">
//           Change Password
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item key="11" className="border-bottom-0" style={{ borderTop: '1px solid #000' }}>
//         {flag === true || agent === true ? (
//           <li >
//             <NavLink
//               to="/"
//               exact
//               activeClassName="selectedNav"
//               onClick={logout}
//               style={{ color: 'black' }}
//             >
//               Logout
//             </NavLink>
//           </li>
//         ) : null}
//       </Menu.Item>
//     </Menu>
//   );
//   const MainMenu = (
//     <Menu className="menu-bdr-nav  loginnav-sub-menu-links"    >
//       <Menu.Item key="21" className="hovereffectdrpdwn">
//         <div onClick={() => showModal1("USER")}>User Login</div>
//       </Menu.Item>
//       <Menu.Item key="22" className="hovereffectdrpdwn">
//         <div onClick={() => showModal1("AGENT")}>Partner Login</div>
//       </Menu.Item>

//     </Menu>
//   );

//   const handleDirection = (direction) => {
//     localStorage.setItem("direction", direction);
//     document.getElementsByTagName("HTML")[0].setAttribute("dir", direction);
//   };

//   const NavbarLinks = ({ user, isGuest }) => {
//     let services = user?.Services ? user?.Services : [];

//     // console.log(services, "aff")


//     return (
//       <>
//         {(isGuest || user?.Role?.Name === "Agent") && (
//           <li onClick={toggleCount}>
//             <NavLink exact activeClassName="selectedNav" to="/">
//               <div>
//                 <i
//                   aria-hidden="true"
//                 ></i></div>
//               <div>{(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ? <span><span className="fa fa-plane" > </span><span style={{ fontStyle: 'Noto Sans', }}> Flights</span></span> : <span><span className="fa fa-home" > </span><span style={{ fontStyle: 'Noto Sans', fontWeight: "bold", }}> Home</span></span>}</div>
//             </NavLink>
//           </li>
//         )}
//         {(isGuest || services.includes("2")) && (
//           <li onClick={toggleCount}>
//             <NavLink exact activeClassName="selectedNav" to="/hotels" >
//               <div style={{ width: "100%" }}><span><span className="fa fa-hotel" > </span><span style={{ fontStyle: 'Noto Sans', }}> Hotels</span></span>{" "}</div>
//             </NavLink>
//           </li>
//         )}

//         {(isGuest || services.includes("6")) && (

//           <li onClick={toggleCount} >
//             <NavLink exact activeClassName="selectedNav" className='hover-for-all' to="/holidays" style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", width: "100%" }} >
//               {/* <div style={{ width: "20%" }}> <i className="fa fa-bed nav-itmes-target" aria-hidden="true"> </i>  </div> */}
//               <div style={{ width: "100%" }}><span><span className="fa fa-shopping-bag" > </span><span style={{ fontStyle: 'Noto Sans', }}> Holidays</span></span>{" "}</div>
//             </NavLink>
//           </li>
//         )}
//         {(isGuest || services.includes("3")) && (

//           <li onClick={toggleCount} >
//             <NavLink exact activeClassName="selectedNav" className='hover-for-all' to="/bus" style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", width: "100%" }} >
//               {/* <div style={{ width: "20%" }}> <i className="fa fa-bed nav-itmes-target" aria-hidden="true"> </i>  </div> */}
//               <div style={{ width: "100%" }}><span><span className="fa fa-bus" > </span><span style={{ fontStyle: 'Noto Sans', }}> Bus</span></span>{" "}</div>
//             </NavLink>
//           </li>
//         )}
//         {(isGuest || services.includes("19")) && (

//           <li onClick={toggleCount} >
//             <NavLink exact activeClassName="selectedNav" className='hover-for-all' to="/cars" style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", width: "100%" }} >
//               {/* <div style={{ width: "20%" }}> <i className="fa fa-bed nav-itmes-target" aria-hidden="true"> </i>  </div> */}
//               <div style={{ width: "100%" }}><span><span className="fa fa-car"> </span><span style={{ fontStyle: 'Noto Sans', }}> Transfers</span></span>{" "}</div>
//             </NavLink>
//           </li>
//         )}
//         {(isGuest || services.includes("8")) && (

//           <li onClick={toggleCount} >
//             <NavLink exact activeClassName="selectedNav" className='hover-for-all' to="/visasearch" style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", width: "100%" }} >
//               {/* <div style={{ width: "20%" }}> <i className="fa fa-bed nav-itmes-target" aria-hidden="true"> </i>  </div> */}
//               <div style={{ width: "100%" }}><span><span className="fa fa-cc-visa" > </span><span style={{ fontStyle: 'Noto Sans', }}> Visa</span></span>{" "}</div>
//             </NavLink>
//           </li>
//         )}
//       </>
//     );
//   };

//   return (
//     <>
//       <div className="main-header-upper" >
//         <div className="header-cont-detal" >
//           <div className="header-cont-detal1">
//             <div className="top-nav-sec1">
//               <span style={{ fontSize: '15px', fontWeight: '600', marginRight: '2%' }} >Call us 7 days    <span class="fa fa-whatsapp" style={{ fontSize: '15px', fontWeight: '600', marginLeft: '1%', color: "green" }} ></span>   9704201143 </span>
//             </div>
//             <div className="top-nav-sec">
//               <span>
//                 <a href="#" class="fa fa-facebook"></a>
//                 <a href="#" class="fa fa-twitter"></a>
//                 <a href="#" class="fa fa-instagram"></a>
//                 <a href="#" class="fa fa-youtube"></a>
//                 <a href="#" class="fa fa-skype"></a>
//               </span>
//               <span className="mob-none ">
//                 <img src={googlelogo} style={{ width: "5%" }} />
//                 {/* <span style={{marginLeft:'1%', marginRight:'1%'}}>4.6/5 <span style={{color:"#83868d"}}>(rating)</span></span> */}
//                 <span style={{ marginLeft: '1%', marginRight: '1%' }}>
//                   <span>   4.1/5   </span>(
//                   <span class="fa fa-star checked11 allstarsame"></span>
//                   <span class="fa fa-star checked11 allstarsame"></span>
//                   <span class="fa fa-star checked11 allstarsame"></span>
//                   <span class="fa fa-star checked11 allstarsame"></span>
//                   <span class="fa fa-star allstarsame"></span>)
//                 </span>
//               </span>

//               <ul className="all-top-menu">
//                 <li className="mob-none cart-nav-top">
//                   {user &&
//                     Object.keys(user).length > 0 &&
//                     (flag == true || agent == true) ? (
//                     <span>
//                       Welcome {user.FirstName}{" "}
//                       {agent ? `(${user.AgentID})` : null}
//                     </span>
//                   ) : null}
//                 </li>


//                 {agent == true ? (
//                   <li >
//                     <Dropdown overlay={Balance} trigger={["click"]}>
//                       <span >
//                         Balance <CaretDownOutlined className="mr-0" />
//                       </span>
//                     </Dropdown>
//                   </li>
//                 ) : null}

//                 {user && !agent ? (
//                   <li>
//                     <Dropdown overlay={Balance} trigger={["click"]}>
//                       <span >
//                         Wallet <CaretDownOutlined className="mr-0" />
//                       </span>
//                     </Dropdown>
//                   </li>
//                 ) : null}
//                 {/* {flag === true || agent === true ? (
//                   <li className="mob-none cart-nav-top">
//                     <NavLink
//                       to="/"
//                       exact
//                       activeClassName="selectedNav"
//                       onClick={logout}
//                     >
//                       Logout
//                     </NavLink>
//                   </li>
//                 ) : null} */}
//               </ul>

//             </div>
//           </div>
//         </div>

//         <div className="header-wid-logo-one-main" >
//           <div className="header-wid-logo-one" >

//             <div className="header-wid-logo-one1" onClick={toggleCount}>
//               <NavLink exact activeClassName="" to="/">
//                 {logo ? (
//                   <img style={{ width: '60%' }} src={BASE + logo.substring(1)} alt="OutcDemo_Logo" />
//                 ) : (
//                   <img
//                     style={{ width: '60%' }}
//                     src={
//                       require("../../assets/images/OutcLogo-new.png").default
//                     }
//                     alt="OutcDemo"
//                   />
//                 )}
//               </NavLink>
//             </div>

//             {/* <div className="header-wid-logo-one3" >
//             {agent ? (
//                     <NavbarLinks user={user} isGuest={false} />
//                   ) : (
//                     <NavbarLinks user={null} isGuest={true} />
//                   )} */}

//             {/* {user ? (
//                 <ul >
//                   <li >
//                     <span className="flag-main-span" style={{ marginRight: '2%' }}>
//                       <span className="currency-text last-mob-bdr mob-none">
//                         <FlagsList />
//                       </span>
//                     </span>


//                     {flag ? (
//                       <Dropdown overlay={loginMenu} trigger={["click"]}  >
//                         <span ><a>
//                           <UserOutlined /> My_Account{" "}
//                           <CaretDownOutlined className="mr-0" /></a>
//                         </span>
//                       </Dropdown>

//                     ) : agent ? (
//                       <Dropdown overlay={AgentMenu} trigger={["click"]} >
//                         <span ><a>
//                           <UserOutlined /> My_Account
//                           <CaretDownOutlined className="mr-0" /></a>
//                         </span>
//                       </Dropdown>
//                     ) : null}
//                   </li>
//                 </ul> */}
//             <div className="header-wid-logo-one3" style={{ display: 'flex' }}>

//               <div className="menu-work-css-allser">

//                 {agent ? (
//                   <NavbarLinks user={user} isGuest={false} />
//                 ) : (
//                   <NavbarLinks user={null} isGuest={true} />
//                 )}
//                 {/* {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
//                     <li>
//                       {/* <NavLink exact activeClassName="active" to="/flightGroupEnquiry"  >
//                         <span>Flight Enquiry</span>
//                       </NavLink> *
//                     </li> : null}
//                   {agent ? (
//                     null
//                   ) : (<li>
//                     {/* <NavLink exact activeClassName="active" to="/offers" >
//                       <span>Offers</span>
//                     </NavLink> *
//                   </li>)}
//                   {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
//                     <li>
//                       {/* <NavLink exact activeClassName="active" to="/web-check-in" >
//                         <span>Web Check In</span>
//                       </NavLink> *
//                     </li> : null}
//                   <li>
//                     <NavLink exact activeClassName="active" to="/mytrips" >
//                       <span>Retrieve Booking</span>
//                     </NavLink>
//                   </li> */}

//               </div>


//               <div style={{ width: '30%' }}>
//                 {/* <span className="flag-main-span" style={{ marginRight: '10%' }}>
//                   <span className="currency-text last-mob-bdr mob-none">
//                     <FlagsList />
//                   </span>
//                 </span> */}
//                 {/* <span>
//                       {agent !== true ? (
//                         <span className=" cart-nav-top trip-mar-ri " onClick={toggleCount}  >
//                           <NavLink exact to="/mytrips" onClick={toggleCount}>
//                             <span  > My Trips</span>
//                           </NavLink>
//                         </span>
//                       ) : null}
//                     </span> */}
//                 {user ? (
//                   <ul className="menu1">
//                     <li className="border-right-0 mob-none">
//                       {flag ? (
//                         <Dropdown overlay={loginMenu} trigger={["click"]}>
//                           <span className="user-btn-wrapper my-acc-dropdown">
//                             <UserOutlined /> My_Account{" "}
//                             <CaretDownOutlined className="mr-0" />
//                           </span>
//                         </Dropdown>
//                       ) : agent ? (
//                         <Dropdown overlay={AgentMenu} trigger={["click"]}>
//                           <span className="my-acc-dropdown">
//                             <UserOutlined /> My_Account
//                             <CaretDownOutlined className="mr-0" />
//                           </span>
//                         </Dropdown>

//                       ) : null}
//                     </li>
//                     {/* {!agent ? */}
//                     <li className="currency-text last-mob-bdr mob-none">
//                       <FlagsList />
//                     </li>
//                     {/* : null} */}
//                     {/* <li className="border-right-0 mob-none">
//                       <div id="google_translate_element"></div>
//                     </li> */}
//                   </ul>
//                 ) : (
//                   <ul className="menu1">
//                     {/* <li className="mob-none cart-nav-top" onClick={toggleCount}>
//                     <NavLink exact to="/activities/cart">
//                       <span className="user-btn-wrapper">
//                         <ShoppingCartOutlined />
//                         Cart
//                       </span>
//                     </NavLink>
//                   </li> */}
//                     <li className="border-right-0 mob-none sign-register-css-work">
//                       <Dropdown overlay={MainMenu} trigger={["click"]}>
//                         <span className="user-btn-wrapper">
//                           Sign-In
//                           {/* <CaretDownOutlined className="mr-0" /> */}
//                         </span>
//                       </Dropdown>
//                     </li>
//                     {/* {!agent ? */}
//                     <li className="currency-text last-mob-bdr mob-none sign-register-css-work1">
//                       <FlagsList />
//                     </li>
//                     {/* : null} */}
//                     {/* <li className="border-right-0 mob-none sign-register-css-work1">
//                     <div id="google_translate_element"></div>
//                   </li> */}

//                   </ul>
//                   // <span
//                   //   className="user-btn-wrapper login-register-mob"
//                   //   onClick={showModal1}
//                   // >
//                   //   <UserOutlined /> Login | Register
//                   // </span>
//                 )}

//                 {/* <span className="border-right-0  ">
//                     <Dropdown overlay={MainMenu} trigger={["click"]} className='dropdwn'  >
//                       <span className="signin-button-navbar" ><span className="mob-none" >
//                         <a>
//                           Sign in
//                         </a>
//                       </span>
//                       </span>
//                     </Dropdown>
//                   </span> */}

//               </div>

//               {/* </div> */}
//             </div>

//           </div>
//         </div>

//         <div className="header-wid-logo-one-main1-pho" >
//           <div className="header-wid-logo-one-pho" >
//             <div className="header-wid-logo-one1-pho" onClick={toggleCount}>
//               <NavLink exact activeClassName="" to="/">
//                 {logo ? (
//                   <img src={BASE + logo.substring(1)} alt="OutcDemo_Logo" />
//                 ) : (
//                   <img
//                     src={
//                       require("../../assets/images/OutcLogo-new.png").default
//                     }
//                     alt="OutcDemo"
//                   />
//                 )}
//               </NavLink>
//             </div>

//             <div className="header-wid-logo-one3-pho"   >

//               <span>
//                 {flag != true || agent === true ? (
//                   <span className="  cart-nav-top">
//                     <NavLink
//                       to="/"
//                       exact
//                       activeClassName="selectedNav"
//                       onClick={logout}
//                     >
//                       Logout
//                     </NavLink>
//                   </span >
//                 ) : null}
//               </span>

//               {console.log(agent, flag, "check")}
//               <span className="mob-none">
//                 {agent !== true ? (
//                   <span className=" cart-nav-top trip-mar-ri " onClick={toggleCount}  >
//                     <NavLink exact to="/mytrips" onClick={toggleCount}>
//                       <span > Trips</span>
//                     </NavLink>
//                   </span>
//                 ) : null}
//               </span>

//               {flag === true || agent === true ? (
//                 null
//               ) : <Dropdown overlay={MainMenu} trigger={["click"]} className='dropdwn'   >
//                 <span className="signin-button-navbar " style={{ backgroundColor: 'white' }} ><a>
//                   <UserOutlined style={{ color: 'black' }} /></a>
//                 </span>
//               </Dropdown>}
//             </div>
//             <li >
//               {flag ? (
//                 <Dropdown overlay={loginMenu} trigger={["click"]}  >
//                   <span ><a>
//                     <UserOutlined /> {" "}
//                     <CaretDownOutlined className="mr-0" /></a>
//                   </span>
//                 </Dropdown>

//               ) : agent ? (
//                 <Dropdown overlay={AgentMenu} trigger={["click"]} >
//                   <span ><a>
//                     <UserOutlined />
//                     <CaretDownOutlined className="mr-0" /></a>
//                   </span>
//                 </Dropdown>
//               ) : null}
//             </li>
//             <li className=" cart-nav-top" style={{ textAlign: 'left', width: '92%' }}>
//               {user &&
//                 Object.keys(user).length > 0 &&
//                 (flag == true || agent == true) ? (
//                 <span>
//                   Welcome {user.FirstName}{" "}
//                   {agent ? `(${user.AgentID})` : null}
//                 </span>
//               ) : null}
//             </li>
//             <div className="header-wid-logo-one2-pho" >
//               {/* <Drawersearch
//                 index={index} /> */}
//             </div>
//           </div >
//         </div >
//       </div >

//       <div class="scrollable-tabs-container">

//         <div class="scrollable-tabs-container1">
//           <div class="left-arrow">
//             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"> <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
//           </div>
//           <ul>
//             {agent ? (
//               <NavbarLinks user={user} isGuest={false} />
//             ) : (
//               <NavbarLinks user={null} isGuest={true} />
//             )}
//             {/* {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
//               <li>
//                 <NavLink exact activeClassName="active" to="/flightGroupEnquiry"  >
//                   <span>Flight Enquiry</span>
//                 </NavLink>
//               </li> : null}
//             {agent ? (
//               null
//             ) : (<li>
//               <NavLink exact activeClassName="active" to="/offers" >
//                 <span>Offers</span>
//               </NavLink>
//             </li>)}
//             {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
//               <li>
//                 <NavLink exact activeClassName="active" to="/web-check-in" >
//                   <span>Web Check In</span>
//                 </NavLink>
//               </li> : null}
//             <li>
//               <NavLink exact activeClassName="active" to="/mytrips" >
//                 <span>Retrieve Booking</span>
//               </NavLink>
//             </li> */}

//           </ul >
//           <div class="right-arrow">
//             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"> <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
//           </div>
//         </div >


//       </div >







//       <div className="header-bg">
//         <div className="header-container">
//           <div className="wrapper">
//             <div className="top-nav-sec">
//               <ul className="all-top-menu">
//                 <li className="mob-none cart-nav-top">
//                   {user &&
//                     Object.keys(user).length > 0 &&
//                     (flag === true || agent === true) ? (
//                     <span>
//                       Welcome {user.FirstName}{" "}
//                       {agent ? `(${user.AgentID})` : null}
//                     </span>
//                   ) : null}
//                 </li>


//                 {agent === true ? (
//                   <li className="mob-none cart-nav-top">
//                     <Dropdown overlay={Balance} trigger={["click"]}>
//                       <span className="user-btn-wrapper"
//                         style={{ backgroundColor: 'transparent', border: '1px solid #320063', paddingLeft: '10%', paddingRight: "10%", borderRadius: '5px', marginTop: '-1%' }}
//                       >
//                         Balance <CaretDownOutlined />
//                       </span>
//                     </Dropdown>
//                   </li>
//                 ) : null}

//                 {user && !agent ? (
//                   <li className="mob-none cart-nav-top">
//                     <Dropdown overlay={Balance} trigger={["click"]}>
//                       <span className="user-btn-wrapper">
//                         Wallet <CaretDownOutlined className="mr-0" />
//                       </span>
//                     </Dropdown>
//                   </li>
//                 ) : null}
//                 {flag === true || agent === true ? (
//                   <li className="mob-none cart-nav-top">
//                     <NavLink
//                       to="/"
//                       style={{ backgroundColor: 'transparent', border: '1px solid #320063', paddingLeft: '10%', paddingRight: "10%", borderRadius: '5px' }}
//                       exact
//                       activeClassName="selectedNav"
//                       onClick={logout}
//                     >
//                       Logout
//                     </NavLink>
//                   </li>
//                 ) : null}
//               </ul>
//             </div>

//             <header className="header">
//               <div className="app-logo">

//                 <div className="user-icon">
//                   <input className="menu-btn" type="checkbox" id="menu-btn" />
//                   <span className="d-block d-md-none mobile-user">
//                     <div className="mobile-user-icon">
//                       {flag === true ? (
//                         <Dropdown overlay={loginMenu} trigger={["click"]}>
//                           <span className="user-btn-wrapper">
//                             <UserOutlined />
//                           </span>
//                         </Dropdown>
//                       ) : agent === true ? (
//                         <Dropdown overlay={AgentMenu} trigger={["click"]}>
//                           <span className="user-btn-wrapper">
//                             <UserOutlined />
//                           </span>
//                         </Dropdown>
//                       ) : (
//                         <span className="user-btn-wrapper" onClick={showModal1}>
//                           <UserOutlined />
//                         </span>
//                       )}
//                     </div>
//                   </span>

//                 </div>
//               </div>
//               <div className="app-links">
//                 <ul
//                   className="menu"

//                   style={{ maxHeight: navToggle ? "610px" : "0px", }}
//                 >

//                   {agent ? (
//                     <NavbarLinks user={user} isGuest={false} />
//                   ) : (
//                     <NavbarLinks user={null} isGuest={true} />
//                   )}

//                   <li onClick={toggleCount}>
//                     <NavLink exact activeClassName="selectedNav" to="/flightGroupEnquiry"  >
//                       <div>Flight Enquiry</div>
//                     </NavLink>
//                   </li>
//                   {agent ? (
//                     null

//                   ) : (<li onClick={toggleCount}>
//                     <NavLink exact activeClassName="selectedNav" to="/offers" >
//                       <div>Offers</div>
//                     </NavLink>
//                   </li>)}

//                   <li onClick={toggleCount}>
//                     <NavLink exact activeClassName="selectedNav" to="/web-check-in" >
//                       <div>Web Check In</div>
//                     </NavLink>
//                   </li>
//                   <li onClick={toggleCount}>
//                     <NavLink exact activeClassName="selectedNav" to="/mytrips" >
//                       <div>Retrieve Booking</div>
//                     </NavLink>
//                   </li>




//                   <li onClick={toggleCount} className="delas-taget-flight">
//                     <NavLink exact activeClassName="selectedNav" to="/deals">
//                       {" "}
//                       <i
//                         className="fa fa-plane nav-itmes-target"
//                         aria-hidden="true"
//                       ></i>
//                       Flight Deals
//                     </NavLink>
//                   </li>
//                   {agent ? (
//                     null

//                   ) : (<li
//                     id="delas-taget-flight"
//                     onClick={toggleCount}
//                     className={`${agent ? null : "border-right-0"}`}
//                   >
//                     <NavLink exact activeClassName="selectedNav" to="/offers">
//                       <i
//                         className="fa fa-tags nav-itmes-target"
//                         aria-hidden="true"
//                       ></i>
//                       Offers
//                     </NavLink>
//                   </li>)}




//                   <li
//                     onClick={() => showModal1("USER")}
//                     className="border-right-0"
//                     id="only-mob-user"

//                   >
//                     <NavLink exact activeClassName="selectedNav" onClick={toggleCount} to="">
//                       User Login
//                     </NavLink>
//                   </li>


//                 </ul >

//                 {
//                   user ? (
//                     <ul className="menu" style={{ border: '2px solid #340167', borderRadius: '10px' }} >
//                       <li className="border-right-0 mob-none" style={{ padding: "6%", marginLeft: "2%", marginRight: "3%", paddingRight: "2%", width: "100%", border: "none" }}>
//                         {flag ? (
//                           <Dropdown overlay={loginMenu} trigger={["click"]}  >
//                             <span className="user-btn-wrapper" ><a>
//                               <UserOutlined />  My_Account{" "}
//                               <CaretDownOutlined className="mr-0" /></a>
//                             </span>
//                           </Dropdown>
//                         ) : agent ? (
//                           <Dropdown overlay={AgentMenu} trigger={["click"]} >
//                             <span className="user-btn-wrapper"><a>
//                               <UserOutlined /> My_Account
//                               <CaretDownOutlined className="mr-0" /></a>
//                             </span>
//                           </Dropdown>
//                         ) : null}
//                       </li>

//                     </ul >
//                   ) : null
//                 }


//               </div >
//             </header >
//           </div >
//         </div >


//         <Modal
//           centered
//           visible={modalVisible.visible}
//           onOk={(e) => setModalVisible(false)}
//           onCancel={(e) => setModalVisible(false)}
//           className="login-modal"
//           footer={false}
//         >
//           <Login
//             location={location}
//             setModalVisible={(value) => setModalVisible(value)}
//             type={modalVisible.type}
//           />
//         </Modal>
//       </div >
//     </>
//   );
// };

// export default withRouter(Nav);
import {
  CaretDownOutlined,
  RightOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, withRouter } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import Login from "../../components/Login/Login";
import APIClient from "../../helpers/ApiClient";
import "../navbar/Navbar.scss";
import FlagsList from "./FlagsList";
import { useCurrencyContext } from "../providers/CurrencyProvider";

const { SubMenu } = Menu;
const Nav = ({ location }) => {
  const {
    logo,
    popularCFFleets,
    cruiseTypes,
    popularVisaList,
    countryCruiseList,
    countryTourList,
    categoryList,
    popularActivityList,
  } = useSytContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  const {
    isLogin: { flag, agent, isGuest},
    user,
    setUser,
    resetIsLogin,
  } = useAuthContext();
  let services = user?.Services ? user?.Services : [];
  const { activeCurrency, currencyValue } = useCurrencyContext()

  let history = useHistory();
  const [fetchingWallet, setFetchingWallet] = useState(false);
  const [navToggle, setNavToggle] = useState(false);

  const logout = () => {
    resetIsLogin();
    history.push("/");
  };

  const [colorChange, setColorchange] = useState(false);

  const toggleCount = () => {
    setNavToggle((prev) => !prev);
  };

   const menuicon = (
   <Menu>
     <div>
        <p style={{ padding: "5px" }}>Comming Soon</p>
      </div>
  {/* //     <Menu.Item key="r&b">
  //       <Link to="/r&b">Recharge/Bills</Link>
  //     </Menu.Item>
  //     <Menu.Item key="forex">
  //       <Link to="/forex">Forex</Link>
  //     </Menu.Item>
  //     <Menu.Item key="0">
  //       <Link to="/Yacht">Yacht</Link>
  //     </Menu.Item>
  //     <Menu.Item key="1">
  //       <Link to="/Helicopter">Helicopter</Link>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <Link to="/bus-hire">Bus Hire</Link>
  //     </Menu.Item>
  //     <Menu.Item key="3">
  //       <Link to="/car-hire">Car Hire</Link>
  //     </Menu.Item>
  //     <Menu.Item key="10">
  //       <Link to="/hotelOfflineRequest">Hotel FIT Request</Link>
  //     </Menu.Item>
  //     <Menu.Item key="11">
  //       <Link to="/hotelGroupEnquiry ">Hotel Group Request</Link>
  //     </Menu.Item>
  //     <Menu.Item key="4">
  //       <Link to="/flightGroupEnquiry">Flight Enquiry</Link>
  //     </Menu.Item>
  //     <Menu.Item key="5">
  //       <Link to="/offers"> Offers</Link>
  //     </Menu.Item>
  //     <Menu.Item key="6">
  //       <Link to="/insurance">Insurance</Link>
  //     </Menu.Item>
  //     <Menu.Item key="7">
  //       <Link to="/Travelrequests">Travel Requests</Link>
  //     </Menu.Item>
  //     <Menu.Item key="8">
  //       <Link to="/blog">Blogs</Link>
  //     </Menu.Item>
  //     <Menu.Item key="9">
  //       <Link to="/web-check-in">Web Check In</Link>
  //     </Menu.Item> */}
   </Menu>
   );
  const mainicon = (
    <Menu>
      <Menu.Item key="/flight">
        <Link to="/">Flight</Link>
      </Menu.Item>
    </Menu>
  );

  const getwalletBalance = () => {
    if (user && (agent || flag)) {
      setFetchingWallet(true);
      APIClient.get(`admin/GetAgentWalletDetails/${user.UserID}`)
        .then((resp) => {
          if (resp.status == 200) {
            setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
          }
          setFetchingWallet(false);
        })
        .catch((error) => {
          setFetchingWallet(false);
        });
    }
    if (user && flag) {
      setFetchingWallet(true);
      APIClient.get(`admin/GetUserWalletDetails/${user.UserID}`)
        .then((resp) => {
          if (resp.status == 200) {
            setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
          }
          setFetchingWallet(false);
        })
        .catch((error) => {
          setFetchingWallet(false);
        });
    }
  };
  /*============= check user logged or not =========== */
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });

  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });
  };
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.login === true) {
        showModal1();
      }
    }
  }, [location]);

  // const Balance = (
  //   <Menu>
  //     <div className="nav-wallet-wrapper">
  //       {agent ? (
  //         <span className="d-flex align-items-center justify-content-between">
  //           Cash : INR{" "}
  //           {user
  //             ? user?.Walletdetails
  //               ? user?.Walletdetails.Amount
  //               : "0"
  //             : ""}
  //           {"  "}
  //           <SyncOutlined
  //             spin={fetchingWallet}
  //             style={{ marginLeft: "10px" }}
  //             onClick={() => {
  //               if (!fetchingWallet) getwalletBalance();
  //             }}
  //           />
  //         </span>
  //       ) : (
  //         <span className="d-flex align-items-center justify-content-between">
  //           Balance : INR
  //           {user
  //             ? user?.Walletdetails
  //               ? user?.Walletdetails.Amount
  //               : "0"
  //             : ""}
  //           {"  "}
  //           <SyncOutlined
  //             spin={fetchingWallet}
  //             style={{ marginLeft: "10px" }}
  //             onClick={() => {
  //               if (!fetchingWallet) getwalletBalance();
  //             }}
  //           />
  //         </span>
  //       )}
  //     </div>

  //     {user && user?.Role?.RoleLevel == 3 ? (
  //       <>
  //         {user?.Walletdetails?.CreditAmount >= 0 ? (
  //           <div className="nav-wallet-wrapper">
  //             <b>Credit Amount : {user?.Walletdetails?.CreditAmount}</b>
  //           </div>
  //         ) : null}
  //         {user ? (
  //           user?.Walletdetails?.ExpiryDate ? (
  //             <div className="nav-wallet-wrapper">
  //               <p className="mb-0">
  //                 Credit Expires :{" "}
  //                 {user?.Walletdetails?.ExpiryDate
  //                   ? moment(user?.Walletdetails?.ExpiryDate).format(
  //                     "DD-MM-YYYY"
  //                   )
  //                   : ""}
  //               </p>
  //             </div>
  //           ) : null
  //         ) : null}

  //         <Menu.Item key="15">
  //           <NavLink
  //             exact
  //             activeClassName="selectedNav"
  //             className="cre-amount-14"
  //             to="/deposits"
  //           >
  //             TOPUP
  //           </NavLink>
  //         </Menu.Item>
  //       </>
  //     ) : null}
  //   </Menu>
  // );
  const Balance = (
    <Menu>
      <div className="nav-wallet-wrapper">
        {agent ? (
          <span className="d-flex align-items-center justify-content-between">
            {/* {console.log(user, "usss")} */}
            Cash : {user?.Currency}{" "}
            {currencyValue(user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : "", user?.Currency)}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        ) : (
          <span className="d-flex align-items-center justify-content-between">
            Balance : {activeCurrency}
            {currencyValue(user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : "", user?.Currency)}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        )}
      </div>

      {user && user?.Role?.RoleLevel == 3 ? (
        <>
          {user?.Walletdetails?.CreditAmount >= 0 ? (
            <div className="nav-wallet-wrapper">
              <b>Credit Amount :{user.Currency} {currencyValue(user?.Walletdetails?.CreditAmount, user.Currency)}</b>
            </div>
          ) : null}
          {user ? (
            user?.Walletdetails?.ExpiryDate ? (
              <div className="nav-wallet-wrapper">
                <p className="mb-0">
                  Credit Expires :{" "}
                  {user?.Walletdetails?.ExpiryDate
                    ? moment(user?.Walletdetails?.ExpiryDate).format(
                      "DD-MM-YYYY"
                    )
                    : ""}
                </p>
              </div>
            ) : null
          ) : null}

          <Menu.Item key="15">
            <NavLink
              exact
              activeClassName="selectedNav"
              className="cre-amount-14"
              to="/deposits"
            >
              TOPUP
            </NavLink>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );

  const loginMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/profile">
          My Profile
        </NavLink>
      </Menu.Item>

      <SubMenu key="2" title="Reports" className="border-bottom-1">
        <Menu.Item key="2-1">
        {/* {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ? */}
          <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
            Flight Reports
            </NavLink>
          {/* </NavLink>:null} */}
        </Menu.Item>
        <Menu.Item key="2-3">
        {/* {(user?.Role?.Name === "Agent" && services.includes("3")) || isGuest ? */}
          <NavLink exact activeClassName="" to="/booking-reports?type=Bus">
            Bus Reports
            </NavLink>
          {/* </NavLink>:null} */}
        </Menu.Item>
        <Menu.Item key="2-2">
        {/* {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ? */}
          <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
            Hotel Reports
            </NavLink>
          {/* </NavLink>:null} */}
        </Menu.Item>
         {/* <Menu.Item key="2-4">
          <NavLink exact activeClassName="" to="/booking-reports?type=Activity">
            Activities Reports
          </NavLink>
        </Menu.Item>  */}
        <Menu.Item key="2-6">
          <NavLink exact activeClassName="" to="/booking-reports?type=Transfer">
            Transfer Reports
          </NavLink>
          </Menu.Item>
          <Menu.Item key="2-4">
          {/* {(user?.Role?.Name === "Agent" && services.includes("8")) || isGuest ? */}
          <NavLink exact activeClassName="" to="/booking-reports?type=Visa">
            Visa Reports
          </NavLink>
          {/* // :null} */}
        </Menu.Item>
        <Menu.Item key="2-5">
          <NavLink exact activeClassName="" to="/booking-reports?type=Holiday">
            Holiday Reports
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="2-4">
        {(user?.Role?.Name === "Agent" && services.includes("4")) || isGuest ?
          <NavLink exact activeClassName="" to="/booking-reports?type=Activity">
            Activities Reports
          </NavLink>:null}
        </Menu.Item> */}
        {/* <Menu.Item key="2-18" className="">
          <NavLink exact activeClassName="" to="/r&b-orders">
            R&b Reports
          </NavLink>
        </Menu.Item> */}
      </SubMenu>
      {/* <Menu.Item key="3">
        <NavLink exact activeClassName="selectedNav" to="/mypackage">
          My Package
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="4">
        <NavLink exact activeClassName="selectedNav" to="/wallet">
          Wallet
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
          Coupon Wallet
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/traveller-details">
          Passenger Details
        </NavLink>
      </Menu.Item>
      <Menu.Item key="7">
        <NavLink exact activeClassName="selectedNav" to="/transaction-reports">
          Transaction Reports
        </NavLink>
      </Menu.Item>
      <Menu.Item key="8" className="border-bottom-0">
        <NavLink exact activeClassName="selectedNav" to="/change-password">
          Change Password
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const AgentMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/myprofile">
          My Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink exact activeClassName="selectedNav" to="/commission-details">
          My Commissions
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink exact activeClassName="selectedNav" to="/bank-details">
          Bank Details
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink exact activeClassName="selectedNav" to="/agent-markup">
          Markups
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/deposits">
          Deposits
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/agent-logo">
          Logo
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/store-vistors">
          Store Visitors
        </NavLink>
      </Menu.Item>
      <SubMenu key="7" title="Reports" className="border-bottom-1">
        <Menu.Item key="7-1">
        {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ?
          <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
            Flight Reports
          </NavLink>:null}
        </Menu.Item>
        <Menu.Item key="7-3">
        {(user?.Role?.Name === "Agent" && services.includes("3")) || isGuest ?
          <NavLink exact activeClassName="" to="/booking-reports?type=Bus">
            Bus Reports
          </NavLink>:null}
        </Menu.Item>
        <Menu.Item key="7-2">
        {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ?
          <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
            Hotel Reports
          </NavLink>:null}
        </Menu.Item>
        {/* <Menu.Item key="7-4">
        {(user?.Role?.Name === "Agent" && services.includes("4")) || isGuest ?
          <NavLink exact activeClassName="" to="/booking-reports?type=Activity">
            Activities Reports
          </NavLink>:null}
        </Menu.Item> */}
        <Menu.Item key="7-4">
          {(user?.Role?.Name === "Agent" && services.includes("6")) || isGuest ?
            <NavLink exact activeClassName="" to="/booking-reports?type=Holiday">
              Holiday Reports
            </NavLink> : null}
        </Menu.Item>
        <Menu.Item key="7-5">
        {(user?.Role?.Name === "Agent" && services.includes("7")) || isGuest ?
          <NavLink exact activeClassName="" to="/booking-reports?type=Transfer">
            Transfer Reports
          </NavLink> : null}
        </Menu.Item>
        <Menu.Item key="7-6">
          {(user?.Role?.Name === "Agent" && services.includes("8")) || isGuest ?
            <NavLink exact activeClassName="" to="/booking-reports?type=Visa">
              Visa Reports
            </NavLink> : null}
        </Menu.Item>
        {/* <Menu.Item key="7-18" className="">
           <NavLink exact activeClassName="" to="/r&b-orders">
            R&b Reports
          </NavLink>
        </Menu.Item> */}
      </SubMenu>

      {/* <Menu.Item key="8">
        <NavLink exact activeClassName="selectedNav" to="/mypackage">
          My Package
        </NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="9">
        <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
          Coupon Wallet
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="13">
        <NavLink exact activeClassName="selectedNav" to="/discounts">
          Margins
        </NavLink>
      </Menu.Item>
      <Menu.Item key="12">
        <NavLink exact activeClassName="selectedNav" to="/statements">
          Statements
        </NavLink>
      </Menu.Item>
      <Menu.Item key="10" className="border-bottom-0">
        <NavLink exact activeClassName="selectedNav" to="/change-password">
          Change Password
        </NavLink>
      </Menu.Item>

      {/* <Menu.Item key="11" className="border-bottom-0">
        <NavLink to="/" exact activeClassName="selectedNav" onClick={logout}>
          Logout
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );
  const MainMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="21">
        <div onClick={() => showModal1("USER")}>User Login</div>
      </Menu.Item>
      <Menu.Item key="22">
        <div onClick={() => showModal1("AGENT")}>Partner Login</div>
      </Menu.Item>
      {/* <Menu.Item key="23">
        <div onClick={() => showModal1("CORPORATE")}>Corporate Login</div>
      </Menu.Item> */}
    </Menu>
  );

  //Mega Menus
  const TourPackagesMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Category wise Tour Packages" className="px-3">
          {categoryList?.map((item, index) => {
            if (index <= 10) {
              return (
                <Menu.Item
                  icon={<RightOutlined />}
                  key={item.CategoryId + "TourCatMenus"}
                >
                  {" "}
                  <NavLink
                    exact
                    to={`/holidays/results?catId=${item.CategoryId}`}
                  >
                    {item.CategoryName}
                  </NavLink>
                </Menu.Item>
              );
            }
          })}
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Country Tour Packages" className="px-3">
          {Object.keys(countryTourList).length
            ? Object.keys(countryTourList).map((index, key) => {
              return (
                <Menu.Item key={key + "TourMenus"} icon={<RightOutlined />}>
                  <NavLink
                    exact
                    // activeClassName="selectedNav"
                    to={`/holidays/results?countryId=${countryTourList[index][0].CountryID
                      }&countryName=${countryTourList[index][0].CountryName
                      }&travelDate=${moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD")}`}
                  >
                    {index} Tour Packages{" "}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  const VisaMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Popular Visa Countries" className="px-3">
          {Object.keys(popularVisaList).length
            ? Object.keys(popularVisaList)?.map((index, key) => {
              return (
                <Menu.Item icon={<RightOutlined />} key={index + "visaMenus"}>
                  {" "}
                  <NavLink
                    exact
                    to={`/visa/result?country=${popularVisaList[index][0].Country}`}
                  >
                    {popularVisaList[index][0].Country}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );
  const CFMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Popular Aircraft's" className="px-3">
          {popularCFFleets?.map((item, index) => {
            if (index <= 10) {
              return (
                <Menu.Item
                  icon={<RightOutlined />}
                  key={item.FleetId + "CFMenus"}
                >
                  {" "}
                  <NavLink
                    exact
                    to={`/charterFlights/results?FleetId=${item.FleetId}`}
                  >
                    {item.AircraftModel}
                  </NavLink>
                </Menu.Item>
              );
            }
          })}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  const CruiseMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Popular Cruise Types" className="px-3">
          {Object.keys(cruiseTypes).length
            ? Object.keys(cruiseTypes).map((index, key) => {
              return (
                <Menu.Item icon={<RightOutlined />} key={key + 1}>
                  {" "}
                  <NavLink exact to={`/cruise/results?cruiseName=${index}`}>
                    {index}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Country Cruise Packages" className="px-3">
          {Object.keys(countryCruiseList)?.length
            ? Object.keys(countryCruiseList)?.map((index, key) => {
              return (
                <Menu.Item key={key + "cruiseMenus"} icon={<RightOutlined />}>
                  <NavLink
                    exact
                    // activeClassName="selectedNav"
                    to={`/cruise/results?countryId=${countryCruiseList[index][0]?.CountryID
                      }&countryName=${countryCruiseList[index][0]?.CountryName
                      }&travelDate=${moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD")}`}
                  >
                    {index} Cruise Packages{" "}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  const ActivitiesMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        <Menu.ItemGroup title="Popular Countries & Cities" className="px-3">
          {Object.keys(popularActivityList).length
            ? Object.keys(popularActivityList).map((index, key) => {
              return (
                <Menu.Item
                  key={key + "activitiesMenu"}
                  icon={<RightOutlined />}
                >
                  <NavLink
                    exact
                    // activeClassName="selectedNav"
                    to={`/activities/results?city=${popularActivityList[index][0]?.cityName
                      }-${popularActivityList[index][0]?.cityId}&country=${popularActivityList[index][0]?.countryName
                      }-${popularActivityList[index][0]?.countryId
                      }&travelDate=${moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD")}`}
                  >
                    {popularActivityList[index][0]?.cityName}, {index}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  //End Mega Menus
  const handleDirection = (direction) => {
    localStorage.setItem("direction", direction);
    document.getElementsByTagName("HTML")[0].setAttribute("dir", direction);
  };

  const NavbarLinks = ({ user, isGuest }) => {
    let services = user?.Services ? user?.Services : [];
    return (
      <>
        {(isGuest || user?.Role?.Name === "Agent") && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/">
              {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ? (
                <i
                  className="fa fa-plane nav-itmes-target"
                  aria-hidden="true"
                ></i>) : (<i
                  className="fa fa-home nav-itmes-target"
                  aria-hidden="true"
                ></i>)}
              {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ? "Flights" : "Home"}
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("2")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/hotels">
              <i className="fa fa-bed nav-itmes-target" aria-hidden="true"></i>
              Hotels{" "}
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("3")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/bus">
              <i className="fa fa-bus nav-itmes-target" aria-hidden="true"></i>
              Bus{" "}
            </NavLink>
          </li>
        )}

        {(isGuest || services.includes("7")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/cars">
              <i className="fa fa-car nav-itmes-target" aria-hidden="true"></i>
              Transfers{" "}
            </NavLink>
          </li>
        )}

        {/* {(isGuest || services.includes("3")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/bus">
              <i className="fa fa-bus nav-itmes-target" aria-hidden="true"></i>
              Bus{" "}
            </NavLink>
          </li>
        )}

       
        {/* {(isGuest || services.includes("4")) && (
          <li onClick={toggleCount}>
            <Dropdown
              overlay={ActivitiesMenus}
              placement="bottomCenter"
              arrow
              trigger={["hover"]}
            >
              <NavLink exact activeClassName="selectedNav" to="/activities">
                <i
                  className="fa fa-anchor nav-itmes-target"
                  aria-hidden="true"
                ></i>
                Activities{" "}
              </NavLink>
            </Dropdown>
          </li>
        )} */}

        {/* {(isGuest || services.includes("5")) && (
          <li onClick={toggleCount}> */}



        {/* <Dropdown
              overlay={TourPackagesMenus}
              placement="bottomCenter"
              arrow
              trigger={["hover"]}
            >
              <NavLink exact activeClassName="selectedNav" to="/holidays">
                <i
                  className="fa fa-sitemap nav-itmes-target"
                  style={{paddingRight:"5px"}}
                  aria-hidden="true"
                ></i>
                Holidays{" "}
              </NavLink>
            </Dropdown>    */}


        {/* this one need to uncomment */}
        {(isGuest || services.includes("6")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/holidays">
              <i className="fa fa-sitemap nav-itmes-target" aria-hidden="true"></i>
              Holidays{" "}
            </NavLink>
          </li>
        )}
                    
            {/* <NavLink exact activeClassName="selectedNav" to="/holidays">
              <i
                className="fa fa-sitemap nav-itmes-target"
                style={{ paddingRight: "5px" }}
                aria-hidden="true"
              ></i>
              Holidays{" "}
            </NavLink> */}





        {/*           <NavLink exact activeClassName="selectedNav" to="/holidays">
              <i
                className="fa fa-sitemap nav-itmes-target"
                style={{ paddingRight: "5px" }}
                aria-hidden="true"
              ></i>
              Holidays{" "}
            </NavLink> */}




        {/* </li>
        )} */}
        {(isGuest || services.includes("8")) && (
          <li onClick={toggleCount}>
            {/* <Dropdown
              overlay={VisaMenus}
              placement="bottomCenter"
              arrow
              trigger={["hover"]}
            >
              <NavLink exact to="/visasearch" activeClassName="selectedNav">
                <i
                  className="fa fa-cc-visa nav-itmes-target"
                  style={{ paddingRight: "5px" }}
                  aria-hidden="true"
                ></i>
                Visa
              </NavLink>
            </Dropdown> */}
            <NavLink exact to="/visasearch" activeClassName="selectedNav">
              <i
                className="fa fa-cc-visa nav-itmes-target"
                style={{ paddingRight: "5px" }}
                aria-hidden="true"
              ></i>
              Visa
            </NavLink>
          </li>
        )}


        {/* {(isGuest || services.includes("5")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/buildyourpackage">
              <i
                className="fa fa-object-group nav-itmes-target"
                aria-hidden="true"
              ></i>
              Build Package{" "}
            </NavLink>
          </li>
        )}

       
        {/* {agent !== true ? (
          <li className="mob-none cart-nav-top" onClick={toggleCount}>
            <NavLink exact to="/mytrips" onClick={toggleCount}>
              <span className="user-btn-wrapper"> Trips</span>
            </NavLink>
          </li>
        ) : null} */}
        {/* {(isGuest || services.includes("10")) && (
          <li onClick={toggleCount}>
            <Dropdown
              overlay={CFMenus}
              placement="bottomCenter"
              arrow
              trigger={["hover"]}
            >
              <NavLink exact to="/charterFlights" activeClassName="selectedNav">
                <i
                  className="fa fa-paper-plane nav-itmes-target"
                  aria-hidden="true"
                ></i>
                Charter Flights
              </NavLink>
            </Dropdown>
          </li>
        )} */}

        {/* {(isGuest || services.includes("9")) && (
          <li onClick={toggleCount}>
            <Dropdown
              overlay={CruiseMenus}
              placement="bottomCenter"
              arrow={true}
              trigger={["hover"]}
            >
              <NavLink exact to="/cruise" activeClassName="selectedNav">
                <i
                  className="fa fa-ils nav-itmes-target"
                  aria-hidden="true"
                ></i>
                Cruise
              </NavLink>
            </Dropdown>
          </li>
        )} */}

        {/* {(isGuest || services.includes("6")) && agent && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/sastaeurope">
              {" "}
              <i
                className="fa fa-space-shuttle nav-itmes-target"
                aria-hidden="true"
              ></i>
              Sasta Europe{" "}
            </NavLink>
          </li>
        )} */}
        {/* {(isGuest || services.includes("6")) && (
        <li>
          <Dropdown
            overlay={TourPackagesMenus}
            placement="bottomCenter"
            arrow={true}
            trigger={["hover"]}
          >
            <NavLink exact activeClassName="selectedNav" to="/holidays">
              {" "}
              <span className="user-btn-wrapper">Holidays</span>
            </NavLink>
          </Dropdown>
        </li>
      )} */}
      </>
    );
  };

  return (
    <div className="header-bg">
      <div className={colorChange ? 'header-container' : 'header-container'} >
        <ul className="mov-viw-agent-head">
          <li className="pcvi-wor-hid cart-nav-top">
            {user &&
              Object.keys(user).length > 0 &&
              (flag == true || agent == true) ? (
              <span>
                Hi {user.FirstName}{" "}
                {/* {agent ? `(${user.AgentID})` : null} */}
              </span>
            ) : null}
          </li>
          {agent == true ? (
            <li className="pcvi-wor-hid cart-nav-top">
              <Dropdown overlay={Balance} trigger={["click"]}>
                <span className="user-btn-wrapper">
                  Balance <CaretDownOutlined className="mr-0" />
                </span>
              </Dropdown>
            </li>
          ) : null}

          {user && !agent ? (
            <li className="pcvi-wor-hid cart-nav-top">
              <Dropdown overlay={Balance} trigger={["click"]}>
                <span className="user-btn-wrapper">
                  Wallet <CaretDownOutlined className="mr-0" />
                </span>
              </Dropdown>
            </li>
          ) : null}
          {flag === true || agent === true ? (
            <li className="pcvi-wor-hid cart-nav-top">
              <NavLink
                to="/"
                exact
                activeClassName="selectedNav"
                onClick={logout}
              >
                Logout
              </NavLink>
            </li>
          ) : null}
        </ul>
        <div className="wrapper">
          <div className="top-nav-sec"   >
            <ul className="all-top-menu">
              <li className="mob-none cart-nav-top">
                {user &&
                  Object.keys(user).length > 0 &&
                  (flag == true || agent == true) ? (
                  <span>
                    Welcome {user.FirstName}{" "}
                    {agent ? `(${user.AgentID})` : null}
                  </span>
                ) : null}
              </li>
              {agent == true ? (
                <li className="mob-none cart-nav-top">
                  <Dropdown overlay={Balance} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      Balance <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </li>
              ) : null}

              {user && !agent ? (
                <li className="mob-none cart-nav-top">
                  <Dropdown overlay={Balance} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      Wallet <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </li>
              ) : null}
              {flag === true || agent === true ? (
                <li className="mob-none cart-nav-top">
                  <NavLink
                    to="/"
                    exact
                    activeClassName="selectedNav"
                    onClick={logout}
                  >
                    Logout
                  </NavLink>
                </li>
              ) : null}



            </ul>
          </div>

          <header className="header">
            <div className="app-logo">
              <div className="company-logo" onClick={toggleCount}>
                <NavLink exact activeClassName="" to="/">

                  {/* {logo ? (
                    <img src={LOGO} alt="OUTC_Logo" />
                  ) : (
                    <img
                      src={
                        require("../../assets/images/Logo.png").default
                      }
                      alt="demooutc"
                    />
                  )} */}

                  {logo ? (
                    <img src={BASE + logo.substring(1)} alt="OUTC_Logo" />
                  ) : (
                    <img
                      src={
                        require("../../assets/images/OutcLogo-new.png").default
                      }
                      alt="Outc"
                    />
                  )}
                </NavLink>
              </div>
              <div className="user-icon">
                <input className="menu-btn" type="checkbox" id="menu-btn" />

                <span className="d-block d-md-none mobile-user mob-none">
                  <div className="mobile-user-icon">
                    {flag === true ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper mob-none">
                          <UserOutlined />
                        </span>
                      </Dropdown>
                    ) : agent === true ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper mob-none">
                          <UserOutlined />
                        </span>
                      </Dropdown>
                    ) : (
                      <span className="user-btn-wrapper mob-none" onClick={showModal1}>
                        <UserOutlined />
                      </span>
                    )}
                  </div>
                </span>
                <li className="currency-text last-mob-bdr pcvi-wor-hid">
                  <FlagsList />
                </li>
                <li className="pcvi-wor-hid">
                  {flag ? (
                    <Dropdown overlay={loginMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper my-acc-dropdown">
                        <UserOutlined />
                        <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  ) : agent ? (
                    <Dropdown overlay={AgentMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper my-acc-dropdown">
                        <UserOutlined />

                        <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  ) : null}
                </li>
                {agent || user ? (<></>
                ) : (
                  <Dropdown overlay={MainMenu} trigger={["click"]} className='dropdwn d-md-none'>
                    <span className="signin-button-navbar d-md-none" style={{ backgroundColor: 'transparent' }} ><a>
                      <UserOutlined style={{ color: 'black', fontSize: '25px' }} /> <span style={{ color: '#00415c' }} > </span></a>
                    </span>
                  </Dropdown>
                )}
                <label className="menu-icon" onClick={toggleCount}>
                  <span className="navicon"></span>
                  <span className="menu-text d-block d-md-none">Menu</span>
                </label>
              </div>
            </div>

            <div className={agent || user ? "app-links" : "app-links1"}   >
              <ul
                className={navToggle ? "mob-viw menu" : "nor-viw menu"}
                style={{ maxHeight: navToggle ? "610px" : "0px" }}
              >
                {/* <li onClick={toggleCount}>
                  <Dropdown overlay={mainicon}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i
                        className="fa fa-plane nav-itmes-target"
                        aria-hidden="true"
                      ></i>
                      Travel
                    </a>
                  </Dropdown>
                </li> */}
                {agent ? (
                  <NavbarLinks user={user} isGuest={false} />
                ) : (
                  <NavbarLinks user={null} isGuest={true} />
                )}

                {/* <li onClick={toggleCount}> */}
                {/* <li>
                  <Dropdown overlay={menuicon} trigger={["click"]}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {/* <i
                        className="fa fa-ellipsis-h nav-itmes-target"
                        aria-hidden="true"
                ></i> * */}
                      {/* More
                    </a> */}
                  {/* </Dropdown> */}
                {/* </li> */} 

                {/* <li onClick={toggleCount}>
                  <NavLink exact to="/">
                    {" "}
                    <i
                      className="fa fa-plane nav-itmes-target"
                      aria-hidden="true"
                    ></i>
                    Charter Flights
                  </NavLink>
                </li> */}

                <li onClick={toggleCount} className="delas-taget-flight">
                  <NavLink exact activeClassName="selectedNav" to="/deals">
                    {" "}
                    <i
                      className="fa fa-plane nav-itmes-target"
                      aria-hidden="true"
                    ></i>
                    Flight Deals
                  </NavLink>
                </li>

                <li
                  id="delas-taget-flight"
                  onClick={toggleCount}
                  className={`${agent ? null : "border-right-0"}`}
                >
                  <NavLink exact activeClassName="selectedNav" to="/offers">
                    <i
                      className="fa fa-tags nav-itmes-target"
                      aria-hidden="true"
                    ></i>
                    Offers
                  </NavLink>
                </li>
                 {/* {agent && (
                  <li onClick={toggleCount} className="border-right-0">
                    <NavLink
                      exact
                      activeClassName="selectedNav"
                      to="/statements"
                    >
                      {" "}
                      <i
                        className="fa fa-superpowers nav-itmes-target"
                        aria-hidden="true"
                      ></i>
                      Statements
                    </NavLink>
                  </li>
                )}  */}

                <li
                  onClick={() => showModal1("USER")}
                  className="border-right-0"
                  id="only-mob-user"
                >
                  <NavLink exact activeClassName="selectedNav" to="">
                    User Login
                  </NavLink>
                </li>

                {/* <ul className="d-block d-md-none responive-more">
                  <li className="currency-text currency-panel-text">
                    <FlagsList />
                  </li>
                </ul> */}

              </ul>
              {user ? (
                <ul className="menu1">
                  <li className="border-right-0 mob-none">
                    {flag ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper my-acc-dropdown">
                          <UserOutlined /> My_Account{" "}
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : agent ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper my-acc-dropdown">
                          <UserOutlined /> My_Account
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : null}
                  </li>
                  <li className="currency-text last-mob-bdr mob-none">
                    <FlagsList />
                  </li>
                  <li className="border-right-0 mob-none">
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
              ) : (
                <ul className="menu1">
                  {/* <li className="mob-none cart-nav-top" onClick={toggleCount}>
                    <NavLink exact to="/activities/cart">
                      <span className="user-btn-wrapper">
                        <ShoppingCartOutlined />
                        Cart
                      </span>
                    </NavLink>
                  </li> */}



                  <li className="currency-text last-mob-bdr mob-none sign-register-css-work1">
                    <FlagsList />
                  </li>
                  {/* <li className="border-right-0 mob-none sign-register-css-work1">
                    <div id="google_translate_element"></div>
                  </li> */}
                  <li className="border-right-0 mob-none sign-register-css-work">
                    <Dropdown overlay={MainMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper">
                        Sign-In
                        {/* <CaretDownOutlined className="mr-0" /> */}
                      </span>
                    </Dropdown>
                  </li>
                </ul>
                // <span
                //   className="user-btn-wrapper login-register-mob"
                //   onClick={showModal1}
                // >
                //   <UserOutlined /> Login | Register
                // </span>
              )}

              {/* {user ? (
                <ul className="menu">
                  <li className="border-right-0 mob-none">
                    {flag ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined /> My Account{" "}
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : agent ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined /> My Account
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : null}
                  </li>
                </ul>
              ) : (
                <ul className="menu">
                  <li className="border-right-0 mob-none">
                    <Dropdown overlay={MainMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper">
                        My Account
                        <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  </li>

                  <li className="border-right-0 mob-none">
                    <NavLink exact activeClassName="selectedNav" to="/offers">
                      <span className="user-btn-wrapper">Offers</span>
                    </NavLink>
                  </li>
                </ul>
                // <span
                //   className="user-btn-wrapper login-register-mob"
                //   onClick={showModal1}
                // >
                //   <UserOutlined /> Login | Register
                // </span>
              )} */}
            </div>
          </header>
        </div>
      </div>

      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={location}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </div>
  );
};

export default withRouter(Nav);

