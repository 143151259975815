import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  message,
  Modal,
  Upload,
} from "antd";
import "./Profile.scss";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import profileImg from "../../admin/assets/profileUser.png";
import ApiClient from "../../../src/helpers/ApiClient";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import countryList from "../../common/CountryList";

const Profile = () => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const initialState = {
    Email: "",
    Mobile: "",
    Name: "",
    Gender: 0,
    DailingCode: "",
    DOB: "",
    ProfilePic: null,
  };
  const { user } = useAuthContext();
  const userID = user?.UserID;
  const [upLoadImage, setUpLoadImage] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const [imageUrl, setImageUrl] = useState();

  const [ProfileForm] = Form.useForm();

  const [userData, setUserData] = useState(initialState);

  const { Option } = Select;

  const uploadLogoButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <img src={profileImg} alt="" />}
      <div className="ant-upload-text">Upload Image</div>
    </div>
  );
  const disabledOriginDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };
  function onChangeDate(date, dateString) {
   }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChange(info) {

    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      setImageUrl(null);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
        setUserData({
          ...userData,
          ProfilePic: info.file.response.data.filepath,
        });
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await ApiClient.get(`admin/b2c/user/${userID}`);
      if (response.status === 200) {
        setUserData({
          ...response.data.userDetails,
        });
        if (response.data.userDetails.ProfilePic) {
          setImageUrl(BASE + response.data.userDetails.ProfilePic.substring(1));
        }
        ProfileForm.setFieldsValue({
          ...response.data.userDetails,
          DOB: response.data.userDetails.DOB
            ? moment(response.data.userDetails.DOB)
            : "",
          DailingCode: response.data?.userDetails?.DailingCode
            ? `+${response.data?.userDetails?.DailingCode}`
            : "",
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const EditUserDetail = async (values) => {
    delete values.UploadImage;

    let userInfo = {
      ...values,
      ProfilePic: userData.ProfilePic,
    };
    try {
      const response = await ApiClient.put(
        `admin/b2c/user/${userID}`,
        {},
        userInfo
      );
      if (response.status === 200) {
        message.success("Profile Updated");
        getUserProfile();
      } else {
        message.error("Something went wrong. Try Again");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="profile-bg ourteam-bg ">
      <div id="profile" className="retrieve-linebar">
        <Form form={ProfileForm} onFinish={EditUserDetail}>
          <Row gutter={16} className="mb-2">
            <Col md={6} xs={24} className="gutter-class max-width-ns">
              <Card bordered={false} className="retrieve-booking ">
                <div className="profile_pic">
                  <Form.Item
                    label="Upload Image"
                    name="UploadImage"
                  >
                    <Upload
                      name="image"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={uploadActionUrl}
                      data={{ category: "profilepic" }}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadLogoButton
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              </Card>
            </Col>
            <Col md={18} xs={24} className="gutter-class">
              <Card bordered={false} className="retrieve-booking">
                <Row gutter={24}>
                  <Col className="gutter-class" md={20} xs={18}>
                    <h3>Profile</h3>
                    <p>Basic Info, for a faster Booking Experience</p>
                  </Col>

                  <Col className="gutter-class" md={4} xs={6}>
                    <Button
                      className="profile-btn"
                      type="primary"
                      htmlType="submit"
                    >
                      <EditOutlined />
                      Update
                    </Button>
                  </Col>
                </Row>

                <Row gutter={[8, 8]} className="select-profile">
                  <Col className="gutter-class" md={8} xs={24}>
                    <Form.Item
                      rules={[
                        { required: true, message: "Please Enter your Name" },
                      ]}
                      name="Name"
                    >
                      <Input
                        className="profile-value-de-retrive"
                        placeholder="USER NAME"
                        size={"large"}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-class" md={8} xs={24}>
                    <Form.Item name="DOB">
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        disabledDate={disabledOriginDate}
                        onChange={onChangeDate}
                        className="profile-value-de-retrive"
                        style={{ width: "100%" }}
                        placeholder="BIRTHDAY"
                        size={"large"}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-class" md={8} xs={24}>
                    <Form.Item name="Gender">
                      <Select size={"large"} placeholder="GENDER">
                        <Select.Option value={0}>Male</Select.Option>
                        <Select.Option value={1}>Female</Select.Option>
                        <Select.Option value={2}>Other</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={6} xs={24} className="gutter-class min-width-pf">
              <div className="profile_pic">
                <Form.Item
                  name="UploadImage"
                >
                  <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    action={uploadActionUrl}
                    showUploadList={false}
                    onChange={handleChange}
                    data={{ category: "profilepic" }}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadLogoButton
                    )}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      src={previewImage}
                      alt="imagePreview"
                      style={{ width: "100%" }}
                    />
                  </Modal>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={16} className="select-profile">
            <Col md={18} xs={24} className="gutter-class">
              <Card bordered={false} className="retrieve-booking">
                <Row gutter={24}>
                  <Col className="gutter-class" md={24} xs={24}>
                    <h3>Login Details</h3>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col className="gutter-class" md={4} sm={8} xs={12}>
                    <Form.Item
                      name="DailingCode"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number Code Required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        readOnly
                        disabled
                        size={"large"}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countryList.map((item) => (
                          <Option key={item.dial_code} value={item.dial_code}>
                            {item.dial_code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-class" md={10} sm={8} xs={12}>
                    <Form.Item
                      name="Mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Mobile Number",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="profile-value-de-retrive"
                        placeholder="MOBILE NUMBER"
                        readOnly
                        size={"large"}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-class" md={10} sm={8} xs={12}>
                    <Form.Item
                      name="Email"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Email",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="profile-value-de-retrive"
                        placeholder="EMAIL ID"
                        readOnly
                        size={"large"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
