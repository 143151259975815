import React, { useState, useEffect } from "react";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  message,
  Radio,
  Select,
  Table,
  Row,
  Popconfirm,
  Collapse,
  Layout,
  DatePicker,
  InputNumber,
  Checkbox,
  Tooltip,
  Spin
} from "antd";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import modules from "../../helpers/reactQuillModules";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import ApiClient from "../../../src/helpers/ApiClient";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";
import "./Styles.scss";
const { Content } = Layout;

const Packages = (props) => {
  let history = useHistory();
  const [packageForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [nameList, setNameList] = useState([]);

  const [currentId, setCurrentId] = useState();
  const [excelList, setExcelList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isCloneMode, setIsCloneMode] = useState(false);

  const [packageModal, setPackageModal] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const { Panel } = Collapse;
  const [content, setContent] = useState("");
  const [Addupdate, setAddupdate] = useState(false);
  const [inclusionExclusion, setInclusionExclusion] = useState([])
  const [toDisableDate, setToDisableDate] = useState(moment());
  const [weekendDate, setWeekendDate] = useState(null);

  useEffect(() => {
    getPackagesList();
    getCountries();
    getCategoryList();
    //getSubCategoryList();
    //  getInclusionExclusion() //commented because inclusion not taking from helper screen i.e inclusion and exlusion table
  }, []);

  // const getInclusionExclusion = () => {
  //   ApiClient.get("HolidayExtranet/getAllHolidayInclusionExclusion")
  //     .then((res) => {
  //       if (res.status == 200) {
  //         let data = res.data
  //         setInclusionExclusion(data);

  //       } else {
  //         setInclusionExclusion([]);
  //       }
  //     })
  //     .catch((e) => {
  //       setInclusionExclusion([]);
  //     });
  // };

  const getCategoryList = () => {
    ApiClient.get("HolidayExtranet/GetCategory")
      .then((res) => {
        if (res.status == 200) {
          setCategoryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const getSubCategoryList = () => {
  //   ApiClient.get("HolidayExtranet/GetSubCategory")
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setSubCategoryList(res.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const handleDescription = (content) => {
    if (content === "<p><br></p>") {
      content = "";
    }
    setContent(content);
  };
  const getPackagesList = () => {
    ApiClient.get("HolidayExtranet/getHolidayPackages")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });

          setNameList(data);
          defineExcelData(data);
        } else {
          setNameList([]);
          defineExcelData([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (val) => {



    const countryInfo = val.CountryInfo.split('-')
    const cityInfo = val.CityInfo.split('-')

    let Obj = {
      ...val,
      // PerAdultCharge: val.PerAdultCharge ?? 0,
      // PerChildCharge: val.PerChildCharge ?? 0,
      // PerInfantCharge: val.PerInfantCharge ?? 0,
      // ExtraCharges: val.ExtraCharges ?? 0,
      CountryCode: countryInfo[0],
      CountryName: countryInfo[1],
      CityId: cityInfo[0],
      CityName: cityInfo[1],
      Nights: val.Nights ?? 0,
      Days: val.Days ?? 0,
      ValidFromDatetime: val.ValidFromDatetime
        ? moment(val.ValidFromDatetime).format("YYYY-MM-DD")
        : "",
      ValidtillDatetime: val.ValidtillDatetime
        ? moment(val.ValidtillDatetime).format("YYYY-MM-DD")
        : "",
    };

    delete Obj.CountryInfo
    delete Obj.CityInfo

    if (isEdit) {
      updatePackageRequest(Obj, currentId);
    } else if (isCloneMode) {
      addPackageRequest(Obj);
    } else {
      addPackageRequest(Obj);
    }
  };

  const updatePackageRequest = (obj, recId) => {
    setAddupdate(true);
    ApiClient.put("HolidayExtranet/updateHolidayPackage/" + recId, {}, obj).then(
      (res) => {
        if (res.statusCode == 200) {
          Modal.confirm({
            title: 'Package Updated successfully!!',
            content: 'Please click OK to manage pricing for this Updated package',
            onOk() {
              history.push('/admin/toursextranet-price')
            },
          })
          getPackagesList();
          //   message.success("Packages Updated successfully", 3);
          setPackageModal(false);
        } else {
          message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  const addPackageRequest = (obj) => {
    setAddupdate(true);
    ApiClient.post("HolidayExtranet/createHolidayPackages", obj)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.statusCode == 200) {

          Modal.confirm({
            title: 'Package added successfully!!',
            content: 'Please click OK to manage pricing for this added package',
            onOk() {
              history.push('/admin/toursextranet-price')
            },
          })
          setPackageModal(false);
          getPackagesList();
        } else if (response.statusCode == 400) {
          message.error("Packages Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {

    const filteredData = data.map((item) => {
      const obj = {
        Sno: item?.SNo,
        PackageTitle: item?.PackageTitle,
        Country: item?.CountryName,
        City: item?.CityName,
        Category: item?.CategoryName,
        SubCategory: item?.SubCategory,
        Rating: item?.StarRating,
        AvailableOrders: item?.OrdersAvailable,
        RemainingOrders: item?.OrdersRemaining,
        Duration: item?.Nights + "/" + item?.Days,
        ValidFrom: item?.ValidFromDatetime,
        ValidTill: item?.ValidtillDatetime,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = nameList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setNameList(searchResults);
    } else {
      getPackagesList();
      setNameList(nameList);
    }
  };

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  /*****country data*****/
  const getCountries = () => {
    ApiClient.get("HolidayExtranet/counties/list")
      .then((resp) => {
        if (resp.statusCode == 200) {
          let data = resp.data
          data.forEach((item) => {
            if (item.CountryCode != null) {
              item["displayName"] = `${item.CountryCode}-${item.Country}`
            }
          });

          setCountriesList(data);
        } else if (resp.statusCode == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const countryCityList = (val) => {
    const countryInfo = val.split('-')

    if (val) {
      ApiClient.get("HolidayExtranet/cities/list/" + countryInfo[0])
        .then((res) => {
          if (res.statusCode == 200) {
            let data = res.data
            data.forEach((item) => {
              if (item.CountryCode != null) {
                item["displayName"] = `${item.cityId}-${item.name}`
              }
            });
            setCitiesList(data);
          } else {
            message.error(res.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getSubCategoryList = (val) => {

    ApiClient.get("HolidayExtranet/get/subCategorybycategoryId/" + val)
      .then((res) => {
        if (res.status == 200) {
          setSubCategoryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }



  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };
  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setIsEdit(true);
              setIsCloneMode(false);
              editCurrentItem(currentRecord);
            }}
            style={{ color: "#2e0000", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div>
          <Tooltip placement="top" title="Clone Package">
            {" "}
            <CopyOutlined
              onClick={() => {
                setIsCloneMode(true);
                setIsEdit(false);
                editCurrentItem(currentRecord);
              }}
              style={{
                color: "#2e0000",
                fontSize: "20px",
                textAlign: "center",
              }}
            />
          </Tooltip>
        </div>

        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deletePackage(currentRecord?.HolidayPackageId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setAddupdate(false)
    setCurrentId(rec.HolidayPackageId);
    packageForm.setFieldsValue({
      ...rec,
      ValidFromDatetime: moment(rec.ValidFromDatetime),
      ValidtillDatetime: moment(rec.ValidtillDatetime),
      CountryInfo: `${rec.CountryCode}-${rec.CountryName}`,
      CityInfo: `${rec.CityId}-${rec.CityName}`,
    });

    const countryDisplayName = `${rec.CountryCode}-${rec.CountryName}`
    countryCityList(countryDisplayName);
    getSubCategoryList(rec.CategoryId)

    setPackageModal(true);
  };

  const deletePackage = (id) => {
    ApiClient.delete("HolidayExtranet/deleteHolidayPackage/" + id)
      .then((res) => {
        if (res.statusCode == 200) {
          Modal.confirm({
            title: 'Package Deleted successfully!!',
            content: 'Please click OK to manage pricing for this Deleted package',
            onOk() {
              history.push('/admin/toursextranet-price')
            },
          })
          getPackagesList();
        } else {
          message.error(res.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },

    {
      title: "Package",
      dataIndex: "PackageTitle",
      sorter: (a, b) => a.PackageTitle.localeCompare(b.PackageTitle),
      ellipsis: true
    },

    {
      title: "Country",
      dataIndex: "CountryName",
      sorter: (a, b) => a.CountryName.localeCompare(b.CountryName),
      ellipsis: true
    },
    {
      title: "City",
      dataIndex: "CityName",
      sorter: (a, b) => a.CityName.localeCompare(b.CityName),
      ellipsis: true
    },
    {
      title: "Category",
      dataIndex: "CategoryName",
      sorter: (a, b) => a.CategoryName.localeCompare(b.CategoryName),
      ellipsis: true
    },

    {
      title: "SubCategory",
      dataIndex: "SubCategory",
      sorter: (a, b) => a.SubCategory.localeCompare(b.SubCategory),
      ellipsis: true
    },


    {
      title: "Star Rating",
      dataIndex: "StarRating",
    },
    {
      title: "OrdersAvailable",
      dataIndex: "OrdersAvailable",
    },
    {
      title: "OrdersRemaining",
      dataIndex: "OrdersRemaining",
    },
    {
      title: "Duration",
      render: (text) => `${text.Nights} Nights/${text.Days} Days`,

      ellipsis: true
    },


    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  /***** Radio-btn*****/

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const validation = (e) => {
    var i = "0123456789";
    for (var j = 0; j < e.length; j++) {

    }
  }
  /*******************Disable paste and future dates******************/
  const onChangeFromDate = (momentdate, _) => {
    if (momentdate > toDisableDate) {
      packageForm.setFieldsValue({
        ValidtillDatetime: momentdate,
      });
    }
    setToDisableDate(momentdate);
  };
  const disabledFutureDate = (currentDate) => {
    //return currentDate < moment().add(-1, "day");
    if (weekendDate != null) {
      return currentDate.valueOf() < (new Date(weekendDate));
    }
    // return current && current > moment().endOf('day');

    return currentDate.valueOf() < moment().add(-1, "day");
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
    //return currentDate.valueOf() < Date.now() || currentDate.valueOf()>  (new Date(toDisableDate));
  };
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  /*************************End********************************* */
  /*added for prevent the negative value */
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  const preventMinus = (e) => {
    if (e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };
  /*diable dates */

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div className="navigationmain-box" >
                  <a className="navigation-color" style={{ marginLeft: "1.5%", color: "orange" }} href="/admin/toursextranet-packages">Packages </a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-price">Price</a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-media">media</a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-cancelpolicy">Cancel Policy</a>
                </div>
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Packages{" "}
                          <HelpInfoHelper screenName={"/admin/managehotel"} />
                        </h5>
                        <p>{nameList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          props.exportExcel(excelList, "Packages")
                        }
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={(e) => {
                          setAddupdate(false)
                          if (isEdit) {
                            setIsEdit(false);
                          }

                          setPackageModal(true);
                          packageForm.resetFields();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  {nameList.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none table-data"
                        bordered
                        dataSource={nameList}
                        rowKey={uniqueKey}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEdit ? "Update" : isCloneMode ? "Clone" : "Add"} Packages
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header hotel-extranet-hotel modal-header-bg12"
        visible={packageModal}
        onCancel={(e) => setPackageModal(false)}
        width={"1100px"}
        footer={[
          <div>{Addupdate ? (<Spin style={{ marginRight: "5px" }} />) : null}
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={packageForm.submit}
              disabled={Addupdate}
            >
              {isEdit ? "Update" : isCloneMode ? "Clone" : "Add"}
            </Button>
            <Button
              key="submit"
              type="danger"
              onClick={() => packageForm.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          form={packageForm}

        >
          <Row gutter={16}>
            <Col md={6} xs={24}>
              <Form.Item
                label="Country"
                rules={[{ required: true, message: "Please Select Country" }]}
                name="CountryInfo"
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(val, _) => {
                    countryCityList(val);
                  }}
                >
                  {countriesList.map((item, index) => (
                    <Option key={index} value={`${item.CountryCode}-${item.Country}`}>
                      {item.Country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="City"
                name="CityInfo"
                rules={[
                  {
                    required: true,
                    message: "Please Select City",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select City"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {citiesList.length > 0
                    ? citiesList.map((item, index) => (
                      <Option key={"city" + index} value={`${item.CityId}-${item.CityName}`}>
                        {item.CityName}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Category"
                rules={[{ required: true, message: "Please Select Category" }]}
                name="CategoryId"
              >
                <Select
                  showSearch
                  placeholder="Select "
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(val, _) => {
                    getSubCategoryList(val);
                  }}

                >
                  {categoryList.map((item, index) => (
                    <Option key={index + "CategoryId"} value={item.CategoryId}>
                      {item.CategoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Sub Category"
                rules={[
                  { required: true, message: "Please Select Sub Category" },
                ]}
                name="SubCategoryId"
              >
                <Select
                  showSearch
                  placeholder="Select "
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {subCategoryList.map((item, index) => (
                    <Option
                      key={index + "SubCategoryId"}
                      value={item.SubCategoryId}

                    >
                      {item.SubCategoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Package Title"
                name="PackageTitle"
                rules={[
                  { required: true, message: "Please Enter Pacakage Title" },
                ]}
              >
                <Input placeholder="Please Enter Pacakage Title" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Package Short Description"
                name="Packagesdescription"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package Short Description",
                  },
                ]}
              >
                <Input placeholder="Enter upto max 75 Charecters" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Star Rating"
                name="StarRating"
                rules={[
                  { required: true, message: "Please Enter Star Rating" },
                ]}
              >
                <Select placeholder="Select">
                  <Select.Option value={1}>1 </Select.Option>
                  <Select.Option value={2}>2 </Select.Option>
                  <Select.Option value={3}>3 </Select.Option>
                  <Select.Option value={4}>4 </Select.Option>
                  <Select.Option value={5}>5 </Select.Option>
                </Select>
              </Form.Item>
            </Col>


            <Col md={6} xs={24}>
              <Form.Item
                name="OrdersAvailable"
                label="Orders Available"
                rules={[
                  { required: true, message: "Please add Orders Available" },
                ]}
              >
                <InputNumber typ="number"
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  min={0}
                  style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Valid From Date"
                name="ValidFromDatetime"
                rules={[
                  { required: true, message: "Please add Valid From Date" },
                ]}
              >
                <DatePicker style={{ width: "100%" }}
                  disabledDate={disabledFutureDate}
                  onChange={(date, dateString) =>
                    onChangeFromDate(date, dateString)
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="ValidtillDatetime"
                label="Valid Till Date"
                rules={[
                  { required: true, message: "Please add Valid Till Date" },
                ]}
              >
                <DatePicker style={{ width: "100%" }}
                  disabledDate={disabledSelectedDate}
                  onChange={(date, dateString) =>
                    onChangeToDate(date, dateString)

                  }
                />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="No. of Nights"
                name="Nights"
                rules={[{ required: true, message: "Please add Nights" }]}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="No. of Days"
                name="Days"
                rules={[{ required: true, message: "Please add Days" }]}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>


          </Row>
          <Row gutter={16}>
            <Col md={4} xs={24}>
              <Form.Item
                label="Show on Homepage"
                name="ShowHomepage"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Instant Booking"
                name="InstantBooking"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Flight Available"
                name="IsFlightAvailable"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={4} xs={24}>
              <Form.Item
                label="Hotels Available"
                name="IsHotelsAvailable"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={4} xs={24}>
              <Form.Item
                label="Transport Available"
                name="IsTransportAvailable"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Sightseeing Available"
                name="IsSightseeingAvailable"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Meals Available"
                name="IsMealsAvailable"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Recommended"
                name="IsRecommended"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Online Cancellation"
                name="IsOnlineCancellation"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="GST Required"
                name="IsGST"
                rules={[{ required: true, message: "Please choose!" }]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>{" "}
          </Row>

          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Itinerary" key="9">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="Itinerary">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Packages Overview" key="10">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="Overview">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>


          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Inclusion" key="11">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="Whatsincluded">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Exclusion" key="12">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="Whatsnotincluded">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="TermsandConditions" key="13">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="TermsandConditions">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Cancellation Policy" key="14">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="CancelPolicy">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Instructions" key="15">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="Instructions">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row gutter={16} className="mb-bottom">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Additionalinfo" key="16">
                  <Row gutter={16}>
                    <Col md={24} xs={24}>
                      <Form.Item name="Additionalinfo">
                        <ReactQuill
                          modules={modules}
                          value={content}
                          placeholder="Type Here ...."
                          onChange={handleDescription}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(Packages);
