// /* import * as ReactBoostrap from "react-bootstrap"; */
// import { SearchOutlined } from "@ant-design/icons";
// import { Button, Col, Form, Layout, Row, Select, Tabs } from "antd";
// import queryString from "query-string";
// import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
// import { useHistory } from "react-router";
// import defaultimage from "../../../assets/images/Icons/defaultflagicon.png";

// import { AppConstants } from "../../../helpers/constants";
// import { Link } from "react-router-dom";
// import { useSytContext } from "../../../common/providers/SytProvider";
// import ApiClient from "../../../helpers/ApiClient";
// import {
//   useLocalStorage,
//   useSessionStorage,
// } from "../../../helpers/useStorage";
// import "../Visasearch/Visasearch.scss";

// const { Option, OptGroup } = Select;

// export const VisaSearchPanel = ({
//   modifySearch,
//   getSearchresults,
//   getAllVisaTypes,
// }) => {
//   const history = useHistory();
//   const [countryLists, setCountryList] = useState([]);
//   const [form] = Form.useForm();

//   const submitForm = (values) => {
//     let formData = {
//       country: values.Country,
//     };

//     const query = queryString.stringify(formData);
//     recentSearches(query);

//     history.push("/visa/result?" + query);
//     if (modifySearch) {
//       getAllVisaTypes();
//       getSearchresults();
//     }
//   };

//   useEffect(() => {
//     let param = queryString.parse(document.location.search);
//     if (modifySearch) {
//       form.setFieldsValue({
//         Country: param.country,
//       });
//     }
//   }, [modifySearch]);

//   const getCountriesLists = () => {
//     ApiClient.get("admin/country")
//       .then((res) => {
//         if (res.status == 200) {
//           setCountryList(res.data);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };

//   useEffect(() => {
//     getCountriesLists();
//   }, []);

//   const [VisaRecentSearcheResults, setVisaRecentSearcheResults] =
//     useSessionStorage("VisaRecentSearcheResults", []);

//   const recentSearches = (searchObj) => {
//     if (searchObj) {
//       searchObj = queryString.parse(searchObj);

//       if (VisaRecentSearcheResults.length > 0) {
//         let array = [];
//         array = [...VisaRecentSearcheResults];
//         if (array.length > 4) {
//           array.pop();
//         }

//         if (searchObj) {
//           setVisaRecentSearcheResults([
//             searchObj,
//             ...array.filter((item) => item.country !== searchObj.country),
//           ]);
//         }

//         return;
//       }

//       setVisaRecentSearcheResults([searchObj]);
//     }
//   };

//   const { topCities } = useSytContext();
//   const [VisaTopCities, setVisaTopCities] = useState([]);
//   useEffect(() => {
//     if (topCities.length) {
//       let data = topCities.filter((item) => item.ServiceType === 8);
//       if (data.length) {
//         setVisaTopCities(data);
//       } else {
//         setVisaTopCities([]);
//       }
//     }
//   }, [topCities]);
//   const getflag = (code) => {
//     if (code === "yu" || code === "tp") {
//       let codeflag = defaultimage;
//       return codeflag;
//     } else {
//       let flag = require(`../../../assets/country-flag-svg${code}.svg`);

//       return flag;
//     }
//   }
//   return (
//     <>
//       <Helmet>
//         <title>
//           Book Visa-{" "}
//           {AppConstants.DOMAIN_NAME}
//         </title>
//         <meta
//           name="description"
//           content=" Get cheap flight tickets, hotels, holiday packages with a best tour & travel company of India- demooutc.in  Avail complete travel solutions for a lifetime journey. "
//         />
//       </Helmet>
//       <h4 className="text-white text-center mb-3 ">
//         We make visas easy for you
//       </h4>
//       <Form form={form} name="visaSearch" onFinish={submitForm}>
//         <Row gutter={16} className="outer-div-flight-searchfieldscar padding-outerdiv-work-searchbar">
//           <Col md={20} xs={24}>

//             <Form.Item name="Country" className="m-0"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please Specify the Country",
//                 },
//               ]}>
//               <Select
//                 showSearch
//                 placeholder="Search For A Country"
//                 onSelect={(e) => {
//                   // recentSearches(e);
//                 }}
//               >
//                 {/* {VisaRecentSearches.length && (
//                   <OptGroup label="Recent Searches">
//                     {VisaRecentSearches.map((item, index) => {
//                       return (
//                         <Option
//                           key={"recent_country" + index}
//                           value={item.Country}
//                         >
//                           <div className="d-flex align-items-center justify-content-between  ">
//                             <div>
//                               <p className="m-0">{item.Country}</p>
//                             </div>
//                             <div className="city-column">
//                               <p className="m-0" style={{ color: "#9B9B9B" }}>
//                                 COUNTRY
//                               </p>
//                             </div>
//                           </div>
//                         </Option>
//                       );
//                     })}
//                   </OptGroup>
//                 )} */}

//                 {/* {VisaTopCities.length && (
//                   <OptGroup label="Popular Countries">
//                     {VisaTopCities.map((item, index) => {
//                       return (
//                         <Option key={"countrytop" + index} value={item.Country}>
//                           <div className="d-flex align-items-center justify-content-between  ">
//                             <div>
//                               <p className="m-0">{item.Country}</p>
//                             </div>
//                             <div className="city-column">
//                               <p className="m-0" style={{ color: "#9B9B9B" }}>
//                                 COUNTRY
//                               </p>
//                             </div>
//                           </div>
//                         </Option>
//                       );
//                     })}
//                   </OptGroup>
//                 )} */}

//                 {countryLists.length && (
//                   <OptGroup label="Search Results">
//                     {countryLists.map((item, index) => {
//                       return (
//                         <Option
//                           key={"Search_country" + index}
//                           value={item.Country}
//                         >
//                           <div className="d-flex align-items-center justify-content-between  ">
//                             <i class="fa fa-cc-visa forplace-wor-dropdownflight"></i>
//                             <div className="for-elepsis-work-dropdowncartrans">
//                               <p className="m-0 for-elepsis-work-dropdowncartrans">{item.Country}</p>
//                             </div>
//                             <div className="city-column search-opti-cod-flg" >
//                               <p className="m-0" style={{ color: "#9B9B9B" }}>
//                                 <span>{item.CountryCode}</span>
//                                 &nbsp;
//                                 {/* <img
//                                   style={{ borderRadius: '4px' }}
//                                   // src={`${process.env.REACT_APP_COUNTRY_FLAG_URL}${d.Country}`}
//                                   src={item?.CountryCode ? getflag(item?.CountryCode?.toLowerCase()) : ""}
//                                   width="30"
//                                   alt={item?.Country?.toLowerCase()}></img> */}
//                               </p>
//                             </div>
//                           </div>
//                         </Option>
//                       );
//                     })}
//                   </OptGroup>
//                 )}
//               </Select>
//             </Form.Item>

//           </Col>
//           <Col md={4} xs={24}>
//             <Button className="primary-btn" size="large" htmlType="submit">
//               <SearchOutlined />
//               Search
//             </Button>
//           </Col>
//         </Row>
//         <Link
//           className="text-right text-white w-100 d-block visa-guide"
//           exact={true}
//           to={"/visa/visaGuidelines"}
//           target="_blank"
//         >
//           Visa Guidelines
//         </Link>
//       </Form>
//     </>
//   );
// };

// export default VisaSearchPanel;
/* import * as ReactBoostrap from "react-bootstrap"; */
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, AutoComplete, Layout, Row, Select, Tabs } from "antd";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { AppConstants } from "../../../helpers/constants";
import { Link } from "react-router-dom";
import { useSytContext } from "../../../common/providers/SytProvider";
import ApiClient from "../../../helpers/ApiClient";
import {
  useLocalStorage,
  useSessionStorage,
} from "../../../helpers/useStorage";
import "../Visasearch/Visasearch.scss";
import VisaAutoCompleteSelect from "../../../common/AutoCompleteSelect/visaAutoCompleteSelect";

const { Option, OptGroup } = Select;

export const VisaSearchPanel = ({
  modifySearch,
  getSearchresults,
  getAllVisaTypes,
}) => {
  const history = useHistory();
  const [countryLists, setCountryList] = useState([]);
  const [form] = Form.useForm();
  const [suggestions, setSuggestions] = useState([])

  const submitForm = (values) => {
    let formData = {
      country: values.Country,
    };

    const query = queryString.stringify(formData);
    recentSearches(query);

    history.push("/visa/result?" + query);
    if (modifySearch) {
      getAllVisaTypes();
      getSearchresults();
    }
  };

  useEffect(() => {
    let param = queryString.parse(document.location.search);
    if (modifySearch) {
      form.setFieldsValue({
        Country: param.country,
      });
    }
  }, [modifySearch]);

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCountriesLists();
  }, []);

  const [VisaRecentSearcheResults, setVisaRecentSearcheResults] =
    useSessionStorage("VisaRecentSearcheResults", []);

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);

      if (VisaRecentSearcheResults.length > 0) {
        let array = [];
        array = [...VisaRecentSearcheResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setVisaRecentSearcheResults([
            searchObj,
            ...array.filter((item) => item.country !== searchObj.country),
          ]);
        }

        return;
      }

      setVisaRecentSearcheResults([searchObj]);
    }
  };

  const { topCities } = useSytContext();
  const [VisaTopCities, setVisaTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 8);
      if (data.length) {
        setVisaTopCities(data);
      } else {
        setVisaTopCities([]);
      }
    }
  }, [topCities]);
  const handleSearch = (value) => {
    if (value.length >= 3) {
      let filteredSuggestions = countryLists
        .filter((item) =>
          item.Country.toLowerCase().includes(value.toLowerCase())
        )
        .map((item) => ({
          value: item.Country,
        }));

      if (value.toLowerCase() === "dub" || value.toUpperCase() === "duba" || value.toUpperCase() === "dubai" || value.toUpperCase() === "DUBAI") {
        filteredSuggestions.unshift({
          value: "United Arab Emirates",
        });
      }

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };



  return (
    <>
      <Helmet>
        <title>
          Book Visa-{" "}
          {AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          content=" Get cheap flight tickets, hotels, holiday packages with a best tour & travel company of India- beta.outc.in  Avail complete travel  for a lifetime journey. "
        />
      </Helmet>
      {/* <h4 className="text-white text-center mb-3 ">
        We make visas easy for you
      </h4> */}
      <Form form={form} name="visaSearch" onFinish={submitForm}  style={{marginTop:'2%'}}>
        <Row gutter={16} className="outer-div-flight-searchfieldscar padding-outerdiv-work-searchbar">
          <Col md={20} xs={24} >

            <Form.Item name="Country" className="m-0"
              rules={[
                {
                  required: true,
                  message: "Please Specify the Country",
                },
              ]}>
              <AutoComplete
                showSearch
                placeholder="Search For A Country"
                onSelect={(e) => {
                  // Handle selection
                }}
                onSearch={handleSearch}
                dataSource={suggestions}
                //style={{border:'1px solid  #dfdfe1'}}
              />

            </Form.Item>

          </Col>
          <Col md={4} xs={24}>
            <Button className="primary-btn" size="large" htmlType="submit">
              <SearchOutlined />
              Search
            </Button>
          </Col>
        </Row>
        <Link
          className="text-right text-white w-100 d-block visa-guide"
          exact={true}
          to={"/visa/visaGuidelines"}
          target="_blank"
        >
          Visa Guidelines
        </Link>
      </Form>
    </>
  );
};

export default VisaSearchPanel;
