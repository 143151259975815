import React, { useState, useRef, useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Button, Card, Col, Skeleton, Rate, Row, message, Modal } from "antd";
import { useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import Hotelssearch from '../../../components/Hotels/Hotels1';
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import ImagesDetbox from "../../../components/ImagesLightbox/ImagedetBox";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import HotelCardImage from "./HotelCardImage";
import { EnvironmentOutlined } from "@ant-design/icons";
import hotelNoImg from "../../../assets/images/hotels/no_img.png";
import giftimg from "../../../assets/images/cp-gift-icon.png";
import "./HotelDet.scss";
import Kingbed from "../../../assets/images/inclusionimages/Kindbed.png"
import twinbeds from "../../../assets/images/inclusionimages/twin-beds.png"
import sea from "../../../assets/images/inclusionimages/sea.png"
import breakfast from "../../../assets/images/inclusionimages/breakfast.png"
import wifi from "../../../assets/images/inclusionimages/wifi.png"



const HotelDet = () => {
  let history = useHistory();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const { user } = useAuthContext();
  const [hotelDetailsRespObj, setHotelDetailsRespObj] = useState({});

  const [isRoomModal, setIsRoomModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomsDetails, setRoomsDetails] = useState({ roomList: [], type: "" });
  const [isShowModal, setIsShowModal] = useState(false);
  const [roomImagesModal, setRoomImagesModal] = useState({});
  const [selectedRooms, setSelectedRooms] = useState({});
  const [isinclusionvisible, setisinclusionvisible] = useState(false);
  const [isboardingvisible, setisboardingvisible] = useState(false);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      address: "",
      lat: 17.42159,
      lng: 78.33752,
    },
    zoom: 12,
    mapVisible: false,
  });
  const [showcancellationModal, setShowCancellationModal] = useState(false)
  const [roomsData, setRoomsData] = useState(null)
  const [inclusiondata, setinclusiondata] = useState([]);
  const [boardingdata, setboardingdata] = useState([]);
  const [cancellationInfo, setCancellationInfo] = useState([])

  useEffect(() => {
    fetchHotelDetails();
  }, []);

  const fetchHotelDetails = () => {
    const hotelDetSearchParams = queryString.parse(window.location.search);
    fetchHotelRooms({
      traceId: hotelDetSearchParams.traceId,
      hotelCode: hotelDetSearchParams.hotelId,
      supplier: hotelDetSearchParams.supplier,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
    });
  };

  const fetchHotelRooms = (params) => {
    setLoading(true);
    setHotelDetailsRespObj({});
    ApiClient.post("hotels-v2/hotelrooms", params)
      .then((res) => res)
      .then((res) => {

        if (res.status === 200) {

          if (res.data.errors.length < 1) {

            setHotelDetailsRespObj(res.data);
            if (res.data?.latitude && res.data?.longitude) {
              setDefaultProps((prev) => ({
                ...prev,

                center: {

                  lat: parseFloat(res.data.latitude),
                  lng: parseFloat(res.data.longitude),
                },
                mapVisible: true,
              }));
            }

            setRoomsDetails({
              roomList: res.data.combineRoom,
              type: res.data.fixedFormat,
            });
          } else {
            setRoomsDetails({
              roomList: [],
              type: "",
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSelectedRooms = (hotelRoom, key) => {

    let copyData = { ...selectedRooms };


    copyData[key] = hotelRoom;
    setSelectedRooms(copyData);
  };

  const handleCheckout = () => {
    let array = Object.keys(selectedRooms).map((key) => selectedRooms[key]);


    if (roomsDetails?.roomList[0]?.combineRooms?.length === array[0].combineRooms.length) {


      navigateToCheckout(array);
    } else {
      message.error("Please select Rooms", 3);
    }
  };



  const navigateToCheckout = (roomsArray, isFixedCombination = true) => {

    if (roomsArray.length > 0) {
      const hotelDetSearchParams = queryString.parse(window.location.search);


      let roomPlan = roomsArray.map((data) => ({
        roomID: data.roomId,
        rateID: data.ratePlanId,
      }));

      let query = {
        traceId: hotelDetSearchParams.traceId,

        roomsId: roomsArray[0]?.roomsId,
        roomPlan: JSON.stringify(roomPlan),
        supplier: hotelDetSearchParams.supplier,
      };


      query = queryString.stringify(query);
      history.push(`/hotels/hotel-checkout?${query}`);
    } else if (roomsArray) {
      const hotelDetSearchParams = queryString.parse(window.location.search);


      let roomPlan = roomsArray.combineRooms.map((data) => ({
        roomID: data.roomId,
        rateID: data.ratePlanId,
      }));

      let query = {
        traceId: hotelDetSearchParams.traceId,

        roomsId: roomsArray.roomsId,
        roomPlan: JSON.stringify(roomPlan),

        supplier: hotelDetSearchParams.supplier,
      };


      query = queryString.stringify(query);
      history.push(`/hotels/hotel-checkout?${query}`);
    } else {
      message.error("Please select Rooms", 3);
    }
  };

  const backToList = () => {
    history.goBack("/hotels/listing");
  };

  let myRef1 = useRef(null);
  let myRef2 = useRef(null);
  let myRef3 = useRef(null);
  let myRef4 = useRef(null);
  let myRef5 = useRef(null);

  const scrollToRef = (ref) => {
    try {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) { }
  };

  const onHandleModal = (roomObj) => {
    setRoomImagesModal(roomObj);
    setIsShowModal(true);
  };

  const getRoomDec = (roomDesc, ratePlans) => {
    return (
      <div className="tooltipWrapper">
        <p>
          <b> {roomDesc} </b>
        </p>
        <p>Policies:</p>
        {ratePlans.cancellationPolicy[0]?.policies.map((pol, i) => (
          <div key={pol + i}> {ReactHtmlParser(pol)}</div>
        ))}
      </div>
    );
  };
  const Marker = ({ text }) => (
    <div className="markerWrapper">
      <EnvironmentOutlined />
    </div>
  );
  const guestCount = (roomGuests) => {
    return roomGuests.reduce(
      (acc, cur) => acc + (cur.noOfChilds + cur.noOfAdults),
      0
    );
  };

  const handelCancellationPolicy = (roomInfo) => {
    setRoomsData(roomInfo)


    let cancellationdata = roomInfo?.cancellationPolicy ?? null


    setCancellationInfo(cancellationdata);
    setShowCancellationModal(true);
  }
  const handleinclusiondata = (val) => {
    setinclusiondata(val);
    setisinclusionvisible(true);
  }
  const handleboarding = (val) => {
    setboardingdata(val);
    setisboardingvisible(true);
  }
  const RoomsInclusion = (i, idx) => {
    return (
      <>
        {idx === 1 ? (
          <p className="mb-0 mr-1">
            <strong>Inclusions :</strong>
          </p>
        ) : null}

        <p className="mb-0 mr-1">

          {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
            <i class="fa fa-wifi" aria-hidden="true"></i> : null}
          {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
            <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
          {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
            <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
          {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
            <i class="fa fa-glass" aria-hidden="true"></i> : null}
          {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
            <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
          &nbsp;
          {i}
          {(i.toLowerCase().includes('king bed') || i.toLowerCase().includes('king-bed') || i.toLowerCase().includes('double')) ?
            <>
              <img src={Kingbed} alt="no-photo" />
              <span>  {i}  </span>
            </> : ""}

          {(i.toLowerCase().includes('twin bed') || i.toLowerCase().includes('twin-bed') || i.toLowerCase().includes('queen')) ?
            <>

              <img src={twinbeds} alt="no-photo" />
              <span>  {i}  </span>
            </> : ""}

          {(i.toLowerCase().includes('break')) ?
            <>

              <img src={breakfast} alt="no-photo" />
              <span>  {i}  </span>
            </> : ""}
          {i.toLowerCase().includes('view') ?
            <>

              <img src={sea} alt="no-photo" />
              <span>  {i}  </span>
            </> : ""}

        </p>
      </>
    )
  }



  return (
    <div className="hotel-details details-main-2">
      <section className="hotel-details-header">
        <div className="details-header-container">
          {loading ? (
            <div className="hotel-full-address">
              <div className="hotel-header-wrapper">
                <Row className="hotel-detail-header-row" align="middle">
                  <Col md={18} xs={24} className="hotel-details-header-right">
                    <Skeleton active={true} paragraph={{ rows: 1 }} />
                  </Col>
                  <Col md={6} xs={0} className="show-moreskeleton-btn">
                    <Skeleton.Button active={true} size={"large"} />
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            Object.keys(hotelDetailsRespObj).length > 0 && (
              <div className="hotel-full-address">
                <div className="hotel-header-wrapper">
                  <div className="hotel-name">
                    <div className="hotel-name-wrapper">
                      <h4>
                        {hotelDetailsRespObj?.hotelName}
                        {hotelDetailsRespObj?.starRating && (
                          <sup>
                            <Rate
                              className="starRating"
                              disabled
                              defaultValue={Number(
                                hotelDetailsRespObj.starRating
                              )}
                              allowHalf={true}
                            />
                          </sup>
                        )}
                      </h4>

                      <p>{hotelDetailsRespObj?.addresses[0]?.address}</p>

                      {/* <span>GREAT LOCATION!</span> */}
                    </div>
                    <div className="show-rooms-btn">
                      <Button onClick={() => scrollToRef(myRef2)}>
                        SHOW ROOMS
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}

          <div className="sticky-links">
            <div className="sticky-links1" >
              <a onClick={() => scrollToRef(myRef1)}>Gallery</a>
            </div>
            <div className="sticky-links1" >

              <a onClick={() => scrollToRef(myRef2)}>Rooms</a>
            </div>


            <div className="sticky-links1" >

              <a onClick={() => scrollToRef(myRef3)}>
                About hotel and facilities{" "}
              </a>
            </div>

            <div className="sticky-links1" >

              <a onClick={() => scrollToRef(myRef4)}>Check-in instructions{" "}</a>
            </div>
            <div className="sticky-links1" >

              <a onClick={() => scrollToRef(myRef5)}>Special instructions </a>
            </div>
          </div>
        </div>
      </section>
      {loading ? (
        <div className="cp-hotel-inf0-top">
          <Row gutter={[16, 16]}>
            <Col md={10} xs={24} className="hotel-details-carsl-img">
              <Skeleton.Image />
            </Col>
            <Col md={7} xs={24}>
              <Skeleton active />
              <Skeleton active />
            </Col>
            <Col md={7} xs={24}>
              <Skeleton active />
              <Skeleton active />
            </Col>
          </Row>
        </div>
      ) : (
        Object.keys(hotelDetailsRespObj).length > 0 && (
          <div className="hotel-det-card-main-bbox">
            <div className="hotel-det-card-main-bbox1" >

              <div>
                <Hotelssearch modifySearch={true} />
              </div>
              <div className="mapWrapper" >
                <GoogleMapReact
                  bootstrapURLKeys={{

                    key: "AIzaSyCWMz7PQCXuxL28R5tUY7nzjHY2L1NtgUc",

                    language: "en",
                    region: "in",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  distanceToMouse={() => { }}
                >
                  <Marker
                    lat={defaultProps.center.lat}
                    lng={defaultProps.center.lng}
                  />
                </GoogleMapReact>
              </div>

            </div>
            <div className="hotel-det-card-main-bbox2">
              <div style={{ width: '100%', marginTop: '2%', paddingLeft: '2%' }} >


                <h2 class='fa fa-hotel' style={{ color: '#026de9', fontSize: '20px' }} >  <span style={{ color: 'black' ,fontSize:28}} >{hotelDetailsRespObj?.hotelName}</span>
                </h2>    {hotelDetailsRespObj?.starRating && (
                  <sup>
                    <Rate

                      className="starRating"
                      disabled
                      defaultValue={Number(
                        hotelDetailsRespObj.starRating
                      )}
                      allowHalf={true}
                    />
                  </sup>
                )}<br />
                <p class="fa fa-map-marker" style={{ color: '#026de9' }} >  <span style={{ color: 'black',fontSize:"14px" }} >{hotelDetailsRespObj?.addresses[0]?.address}</span></p>

                <div className="Bookthis-now-main-div" >

                  <Button
                    className="cp-book-this"
                    onClick={() => scrollToRef(myRef2)}
                  >
                    <span>BOOK THIS NOW</span>
                  </Button>
                </div>


              </div>

              <section
                className="hotel-detail-images cp-slider-hotel"
                ref={myRef1}
              >
                <div className="light-box-wrapper">

                  <ReactBootstrap.Container>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col md={12}>
                        {hotelDetailsRespObj.images.length > 0 ? (
                          <ImagesDetbox
                            hotelImages={hotelDetailsRespObj.images}
                          />
                        ) : (
                          <img src={hotelNoImg} alt="no-photo" />
                        )}
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Container>
                </div>
              </section>
            </div>
          </div>
        )
      )}






      <section className="hotel-rooms-list" ref={myRef2} >
        <div className="rooms-wrapper">
          <div>
            <h5 className="rooms-available-sta">Available Rooms</h5>
          </div>

          <div className="rooms-list">
            {loading ? (
              <div className="hotel-details-block">
                <div className="hotel-details-room-card-container">
                  {/* ----Room Skeleton Card---- */}
                  {[...Array(2)].map((_, i) => (
                    <div key={"skeleton" + i} className="room-card">
                      <Row gutter={16}>
                        <Col md={4}>
                          <div className="room-image-skel">
                            <Skeleton.Image />
                          </div>
                        </Col>
                        <Col md={16}>
                          <Skeleton active />
                        </Col>
                        <Col md={4}>
                          <div className="choose-btn-s">
                            <Skeleton paragraph={{ rows: 0 }} />
                            <Skeleton.Button active={true} size={"large"} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  {/* ----End Of Room Skeleton Card---- */}
                </div>
              </div>
            ) :
              //(["fixed", "Fixed"].includes(roomsDetails.type)) ?

              (
                <div className="card-arrangemov-pc1"  >

                  <table>
                    <tr className="header-hot">
                      <th  >Hotel image</th>
                      <th>Room type</th>

                      {/* <th>Refund</th> */}
                      <th>Your Choices</th>
                      <th>Today's price</th>
                      <th>Reserve</th>
                    </tr>

                    {roomsDetails?.roomList?.length > 0 ? (
                      roomsDetails?.roomList?.map((roomsArray, index) => (
                        <>
                          {roomsArray?.combineRooms?.map((hotelRoom, key) => (
                            <>
                              <tr className="card-hot" key={roomsArray.roomsId}>
                                <td>
                                  {console.log(hotelRoom, "room")}
                                  {hotelDetailsRespObj?.images ? (
                                    hotelDetailsRespObj?.images?.length === 0 ? (
                                      <div className="hotel_image_">
                                        <img src={hotelNoImg} alt="no-photo" />
                                      </div>
                                    ) : (
                                      hotelDetailsRespObj?.images?.length > 0 && (
                                        <HotelCardImage
                                          key={roomsArray.roomsId + index}
                                          hotelDetailsRespObj={hotelDetailsRespObj}
                                          hotelRoom={hotelRoom}
                                          onHandleModal={onHandleModal}
                                        />
                                      )
                                    )
                                  ) : (
                                    <div className="hotel_image_">
                                      <img src={hotelNoImg} alt="no-photo" />
                                    </div>
                                  )}
                                </td>
                                <td><span className="rmname">  {hotelRoom?.roomName?.split(',')[0]}</span>
                                  <br />
                                  <span className="rmname">  {hotelRoom?.roomName?.split(',')[1]?.split('-')[0]}</span>
                                  <div className="incl-rms-brek">

                                    {roomsArray?.boardingDetails ? (roomsArray?.boardingDetails?.map((i, idx) =>
                                      idx <= 3 ? (
                                        <><ul><li>{i}</li></ul>
                                        </>
                                      ) : (null)
                                    )) : (
                                      <>
                                        {roomsArray?.boardingDetails?.length > 1 &&
                                          (<span style={{ color: "blue" }} onClick={() => handleboarding([...roomsArray?.boardingDetails])}><button style={{ color: "blue", border: "1px solid white", backgroundColor: "white" }} >Boarding</button></span>
                                          )
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>


                                <td>
                                  <div className="incl-rms-brek">

                                    {roomsArray?.inclusions.length < 1 ? (roomsArray?.inclusions.map((i, idx) =>
                                      idx <= 3 ? (
                                        <>


                                          <ul>

                                            <li>
                                              {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
                                                <i class="fa fa-wifi" aria-hidden="true"></i> : null}
                                              {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
                                                <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
                                              {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
                                                <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
                                              {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
                                                <i class="fa fa-glass" aria-hidden="true"></i> : null}
                                              {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
                                                <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
                                              &nbsp;
                                              {i}
                                            </li>
                                          </ul>
                                        </>
                                      ) : (null)
                                    )) : (
                                      <>
                                        {(roomsArray?.inclusions.length > 2 || roomsArray?.inclusions?.[0]) &&
                                          (<span style={{ color: "blue" }} onClick={() => handleinclusiondata([...roomsArray?.inclusions?.[0]?.split(',')])}><button style={{ color: "blue", border: "1px solid white", backgroundColor: "white" }} >Inclusions</button></span>
                                          )
                                        }
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    {hotelRoom.lastCancellationDate ? (
                                      <span className="roomlastcancel1">
                                        Free Cancellation Till  {moment(hotelRoom.lastCancellationDate).format("DD MMM YYYY")}
                                      </span>

                                    ) : null}
                                    <br />

                                    {hotelRoom?.underCancellation ?
                                      <span><a onClick={() => handelCancellationPolicy(hotelRoom)} href="#" data-toggle="modal"
                                        data-target="#myBuildActivitiesModal">
                                        Cancellation Policy
                                      </a></span> : null}

                                  </div>
                                  <div>
                                    {hotelRoom?.refundable ? (<p style={{ color: "green" }} className="roomlastcancel">Refundable</p>) : (<p style={{ color: "red" }} className="roomlastcancel">Non-Refundable</p>)
                                    }
                                  </div>
                                </td>
                                {key === 0 && <td rowSpan={roomsArray?.combineRooms?.length}>{activeCurrency}{" "}
                                  <span>
                                    {currencyValue(roomsArray.price)}
                                  </span></td>}
                                {key === 0 && <td rowSpan={roomsArray?.combineRooms?.length} className="res-last-cell">
                                  <Button
                                    className="res-btn"
                                    onClick={() => navigateToCheckout(roomsArray)}

                                  >
                                    I'll Reserve
                                  </Button>
                                </td>}
                              </tr>
                            </>
                          ))}
                          <Card className="room-card-wrapper mb-2 card-arrangemov-pc " key={index}>
                            {roomsArray?.combineRooms.map((hotelRoom, key) => (<>
                              <div className="room-card" key={roomsArray.roomsId}>
                                <span className="room-number-span">Room {key + 1}</span>
                                <div className="room-card-wrapper">
                                  <div className="hotel-image-box">
                                    <div className="hotel-image">
                                      {hotelDetailsRespObj?.images ? (
                                        hotelDetailsRespObj?.images?.length === 0 ? (
                                          <div className="hotel_image_">
                                            <img src={hotelNoImg} alt="no-photo" />
                                          </div>
                                        ) : (
                                          hotelDetailsRespObj?.images.length > 0 && (
                                            <HotelCardImage
                                              key={roomsArray.roomsId + index}
                                              hotelDetailsRespObj={hotelDetailsRespObj}
                                              hotelRoom={hotelRoom}
                                              onHandleModal={onHandleModal}
                                            />
                                          )
                                        )
                                      ) : (
                                        <div className="hotel_image_">
                                          <img src={hotelNoImg} alt="no-photo" />
                                        </div>
                                      )}

                                    </div>
                                  </div>

                                  <div className="hotel-info-wrapper">
                                    {
                                      <>
                                        <div className="hotel-room-details">
                                          <div className="hotel-room-type">

                                            <div className="room-title">
                                              <p className="roomName">
                                                {hotelRoom.roomName.split(',')[0]}
                                              </p>

                                            </div>

                                          </div>
                                          <div className="amenities-box">
                                            <ul>
                                              <p>
                                                {roomsArray?.underCancellation ?
                                                  <span><a onClick={() => handelCancellationPolicy(hotelRoom)} href="#" data-toggle="modal"
                                                    data-target="#myBuildActivitiesModal">
                                                    Cancellation Policy
                                                  </a></span> : null}
                                                {hotelRoom.lastCancellationDate ? (
                                                  <span className="roomlastcancel">
                                                    Free Cancellation Till  {moment(hotelRoom.lastCancellationDate).format("DD MMM YYYY")}
                                                  </span>
                                                ) : null}
                                              </p>
                                            </ul>
                                          </div>

                                          <div className="amenities-box d-flex">
                                            {console.log(roomsArray, hotelRoom, "array")}
                                            {[...hotelRoom?.roomName?.split(","), ...roomsArray.inclusions]
                                              .map((i, idx) =>
                                                idx > 0 ? (
                                                  RoomsInclusion(i, idx)
                                                ) : (
                                                  ""
                                                )
                                              )}
                                            {
                                              [...hotelRoom?.roomName?.split(","), ...roomsArray.inclusions].length > 3 &&
                                              (<span style={{ color: "blue" }} onClick={() => handleinclusiondata([...roomsArray.inclusions])}><button style={{ color: "blue", border: "1px solid white", backgroundColor: "white" }} >Inclusions</button></span>
                                              )
                                            }
                                          </div>
                                        </div>


                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="select-room-btn">
                                {hotelRoom?.refundable ? (<p style={{ color: "green" }} className="roomlastcancel">Refundable</p>) : (<p style={{ color: "red" }} className="roomlastcancel">Non-Refundable</p>)
                                }
                                <p className="hotel-room-price">
                                  {activeCurrency}
                                  <span>
                                    {currencyValue(roomsArray.price)}
                                  </span>
                                </p>

                              </div>

                            </>))}

                            {/* <div className="select-room-btn">
                              {roomsArray.combineRooms.refundable ? (<p style={{ color: "green" }} className="roomlastcancel">Refundable</p>) : (<p style={{ color: "red" }} className="roomlastcancel">Non-Refundable</p>)
                              }
                              <p className="hotel-room-price">
                                {activeCurrency}
                                <span>
                                  {currencyValue(roomsArray.price)}
                                </span>
                              </p>

                            </div> */}
                            <div className="button-wrapper d-flex align-items-end flex-column">
                              <Button

                                onClick={() => navigateToCheckout(roomsArray)}>Reserve</Button>
                            </div>
                          </Card>
                        </>

                      ))) : (
                      <p>Rooms Are Not Available</p>
                    )}
                  </table>
                </div>
              )}

          </div>
        </div>
      </section>






      <section className="hotel-description" ref={myRef3} style={{ marginTop: '5%' }} >
        <div className="hotel-description-wrapper">
          <div className="description-block">
            {/* <div className="description-title">About Hotel</div> */}

            {loading ? (
              <div className="description-content">
                {" "}
                <Skeleton active />
              </div>
            ) : (
              <div
                className="description-content"

              >
                <p className="rooms-available-sta">About Hotel</p>
                {" "}
                {ReactHtmlParser(hotelDetailsRespObj?.description)}
              </div>
            )}

          </div>

          <div className="description-block facilities-list">
            {/* <div className="description-title">Hotel Facilities</div> */}
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <p className="rooms-available-sta">Miscellaneous</p>
                {loading ? (
                  <Skeleton active />
                ) : (
                  <ul>
                    <Row>
                      {hotelDetailsRespObj?.hotelFacility?.length > 0 ? (
                        hotelDetailsRespObj.hotelFacility.map(
                          (facility, index) => (
                            <Col key={"facili" + index} md={8} sm={8} xs={12}>
                              <li>
                                <i className="fa fa-check color-blue"></i> <span style={{ fontSize: '14px' }}>{" "}
                                  {facility}</span>
                              </li>{" "}
                            </Col>
                          )
                        )
                      ) : (
                        <p>No Data Available</p>
                      )}
                    </Row>
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="description-block facilities-list" ref={myRef4}>
            {/* <div className="description-title">Check-in Instructions</div> */}
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <p className="rooms-available-sta">Check-in Instructions</p>

                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Extra-Person
                      charges may apply and vary depending on property policy.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i>{" "}
                      Government-issued photo identification and a credit card,
                      debit card, or cash deposit may be required at check-in
                      for incidental charges.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Special
                      requests are subject to availability upon check-in and may
                      incur additional charges; special requests cannot be
                      guaranteed .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> The primary
                      guest must be at least 18 years of age to check into this
                      hotel(s) .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> In some
                      countries including India, as per Government regulations,
                      it is mandatory for all guests above 18 years of age to
                      carry a valid photo identity card & address proof at the
                      time of check-in. In case, check-in is denied by the hotel
                      due to lack of required documents, you cannot claim for
                      the refund & the booking will be considered as NO SHOW.
                      Please check with the hotel(s) directly .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Unless
                      mentioned, the tariff does not include charges for
                      optional room services (such as telephone calls, room
                      service, mini bar, snacks, laundry extra bed etc.). In
                      case, such additional charges are levied by the hotel(s),
                      we shall not be held responsible for it .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Extra bed can
                      be accommodated with a folding cot or a mattress, subject
                      to room size & availability .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> The hotel(s)
                      reserves the right to decline accommodation to
                      localities/same city residents.hopmker.com will not
                      be responsible for any check-in declined by the hotel(s)
                      or any refunds due to the above-mentioned reason .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i>{" "}
                      beta.outc.in will not be responsible for any service
                      issues at the hotel(s) .
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="description-block facilities-list" ref={myRef5}>
            {/* <div className="description-title">Special Instructions</div> */}
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <p className="rooms-available-sta">Special Instructions</p>

                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Early check
                      -in/ Late checkout (Subject to availability, Amount
                      varies) to be Charges by the Property at time of Service.
                      .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> To make
                      arrangements for check-in please contact the property at
                      least 24 hours before arrival using the information on the
                      booking confirmation.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Guests must
                      contact the property in advance for check-in instructions.
                      Front desk staff will greet guests on arrival.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="description-block facilities-list">
            {/* <div className="description-title">Disclaimer Notification</div> */}
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <p className="rooms-available-sta">Disclaimer Notification</p>

                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Amenities are
                      subject to availability and may be chargeable as per the
                      hotel policy.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> We attempts to
                      ensure that the information on this page is complete and
                      accurate; however this information along with its links
                      may contain typographical errors, and other errors or
                      inaccuracies. We assume no responsibility for such errors
                      or omissions, and reserve the right to correct any errors,
                      inaccuracies or omissions.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> All information
                      provided on this page is meant to serve as a general
                      information source only and does not constitute
                      professional advice. This page may not cover all
                      information available on a particular issue. Before
                      relying on this page, we urge you to independently
                      validate or obtain professional advice relevant to your
                      particular circumstances .
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

      </section >

      <section className="hotel-details-footer e-hide">
        <div className="details-footer-wrapper">
          <div>
            <p>Ready for booking ?</p>
          </div>
          <div>
            <ReactBootstrap.Button>Show Rooms</ReactBootstrap.Button>
          </div>
        </div>
        <div className="details-footer-second">
          <div className="second-footer-wrapper">
            <div>
              <p>Looking for something else in Hyderabad ?</p>
            </div>
            <div>
              <ReactBootstrap.Button>See similar hotels</ReactBootstrap.Button>
            </div>
            <div>
              <ReactBootstrap.Button>
                Return to search results
              </ReactBootstrap.Button>
            </div>
          </div>
        </div>
      </section>

      {/* Hotel Facilities Modal */}
      <div className="facilities-modal">
        <ReactBootstrap.Modal
          show={isRoomModal}
          onHide={(e) => setIsRoomModal(false)}
          size="lg"
          className="room-modal"
        >
          <ReactBootstrap.Modal.Header closeButton>
            <ReactBootstrap.Modal.Title>
              <div className="custom-modal-header">
                <div className="header-img">
                  <img
                    src={require("../../../assets/images/hotels/no_photo.png")}
                    alt="blank"
                  />
                </div>
                <div className="header-text">

                  <p>
                    <i className="fa fa-wifi" aria-hidden="true"></i>

                  </p>
                </div>
              </div>
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>

        </ReactBootstrap.Modal>
      </div>

      {/* Room images modal */}
      <div className="images-carousel-modal">
        <ReactBootstrap.Modal
          className="hotel-images-modal"
          show={isShowModal}
          onHide={(e) => setIsShowModal(false)}
          size="lg"
        >
          <ReactBootstrap.Modal.Header closeButton className="modal-wrapper">
            <ReactBootstrap.Modal.Title>
              <p>{roomImagesModal.roomName}</p>
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
          <ReactBootstrap.Modal.Body>
            <ImagesDetbox hotelImages={roomImagesModal.images} />
          </ReactBootstrap.Modal.Body>
        </ReactBootstrap.Modal>
      </div>
      <div
        className="modal left fade tabs-left-bar"
        id="myBuildActivitiesModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myBuildActivitiesModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span style={{ fontSize: "20px", fontWeight: "bold" }} >Cancellation Policy</span>
              <button
                type="button"
                className="close close-hide-show"
                data-dismiss="modal"
                aria-label="Close"
              >

                <span aria-hidden="true">X</span>
              </button>
            </div>

            <div className="modal-body">
              {roomsData != null && (
                <>
                  <p className="heading-part-modal-cancellation" >{roomsData.roomName?.split(',')?.[0]}</p>

                  {cancellationInfo?.length > 0 ? (
                    cancellationInfo?.map((can, i) => {

                      return (
                        <div className="modal-popup-cancellation">
                          <div className="modal-popup-cancellation2">
                            <i class="fa fa-calendar" style={{ color: "red", fontSize: "20px" }}></i>
                          </div>
                          <div className="modal-popup-cancellation1">
                            <p style={{ margin: "0px 0px 0px" }} ><strong>From-</strong>  {moment(can.fromDate).format("DD MMM YYYY HH:mm:ss")}{" IST"}</p>
                            {can.toDate ?
                              <p style={{ margin: "0px 0px 0px" }} ><strong>To-</strong>  {moment(can.toDate).format("DD MMM YYYY HH:mm:ss")}{" IST"}</p> : ""}

                          </div>
                          {(can.chargeType != "nights" && can.chargeType != "Nights") ?
                            <div className="modal-popup-cancellation1">
                              <p style={{ margin: "0px 0px 0px" }}><strong>Cancellation Charges</strong></p>
                              <p style={{ margin: "0px 0px 0px" }}><strong class="fa fa-rupee">  {can.penaltyAmount} {can.chargeType == "Percentage" ? "%" : ""}</strong></p>


                            </div> :
                            <div className="modal-popup-cancellation1">
                              <p style={{ margin: "0px 0px 0px" }}><strong>Cancellation</strong></p>
                              <p style={{ margin: "0px 0px 0px" }}><strong class="fa fa-moon-o">  {can?.nights > 0 ? can.nights : can.penaltyAmount} {((can.nights > 0 || can.penaltyAmount > 0) && (can.chargeType === "nights" || can.chargeType === "Nights")) ? " Night" : null}</strong></p>


                            </div>}

                        </div>
                      )
                    })
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal className="modal-css-direction-popup" visible={isboardingvisible} onCancel={() => setisboardingvisible(false)} onOk={() => setisboardingvisible(false)}> {boardingdata?.length ? boardingdata

        .map((i, idx) =>
          idx >= 0 ? (
            <>
              {idx === 0 ? (
                <p className="mb-0 mr-1">
                  <strong>Boarding Details :</strong>
                </p>
              ) : null}
              <p className="mb-0 mr-1">

                {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
                  <i class="fa fa-wifi" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
                  <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
                  <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
                  <i class="fa fa-glass" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
                  <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
                &nbsp;
                {i}
                {(i.toLowerCase().includes('king bed') || i.toLowerCase().includes('king-bed') || i.toLowerCase().includes('double')) ?
                  <>
                    <img src={Kingbed} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}

                {(i.toLowerCase().includes('twin bed') || i.toLowerCase().includes('twin-bed') || i.toLowerCase().includes('queen')) ?
                  <>

                    <img src={twinbeds} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}

                {(i.toLowerCase().includes('breakfast')) ?
                  <>

                    <img src={breakfast} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}
                {i.toLowerCase().includes('view') ?
                  <>

                    <img src={sea} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}

              </p>


            </>
          ) : (
            ""
          )
        ) : ""}</Modal>
      <Modal className="modal-css-direction-popup" visible={isinclusionvisible} onCancel={() => setisinclusionvisible(false)} onOk={() => setisinclusionvisible(false)}> {inclusiondata?.length ? inclusiondata

        .map((i, idx) =>
          idx >= 0 ? (
            <>
              {idx === 0 ? (
                <p className="mb-0 mr-1">
                  <strong>Inclusions :</strong>
                </p>
              ) : null}
              <p className="mb-0 mr-1">

                {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
                  <i class="fa fa-wifi" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
                  <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
                  <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
                  <i class="fa fa-glass" aria-hidden="true"></i> : null}
                {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
                  <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
                &nbsp;
                {i}
                {(i.toLowerCase().includes('king bed') || i.toLowerCase().includes('king-bed') || i.toLowerCase().includes('double')) ?
                  <>
                    <img src={Kingbed} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}

                {(i.toLowerCase().includes('twin bed') || i.toLowerCase().includes('twin-bed') || i.toLowerCase().includes('queen')) ?
                  <>

                    <img src={twinbeds} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}

                {(i.toLowerCase().includes('break')) ?
                  <>

                    <img src={breakfast} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}
                {i.toLowerCase().includes('view') ?
                  <>

                    <img src={sea} width={'5%'} alt="no-photo" />
                    <span>  {i}  </span>
                  </> : ""}

              </p>


            </>
          ) : (
            ""
          )
        ) : ""}</Modal>
    </div >
  );
};

export default HotelDet;
