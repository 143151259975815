import React, { useState, useEffect, useContext } from "react";
import "../Promo/Promo.scss";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Modal,
  Button,
  Col,
  Radio,
  Popover,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";

const Promo = (props) => {
  const { Option } = Select;
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const initialFormData = {
    ApplicationType: "1",
    Code: "",
    Description: "",
    Discount: "",
    DiscountType: 1,
    FromAmount: "",
    Image: "",
    Name: "",
    PGType: 3,
    ServiceType: undefined,
    ToAmount: "",
    UsageLimit: 1,
    UserLimit: 1,
    ValidFrom: "12-12-2020",
    ValidTill: "12-02-2021",

  };
  const { Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isServiceFieldVisible, setIsServiceFieldVisible] = useState(1);
  const [isEditMode, setIsEditMode] = useState(true);
  const [form] = ANTD.Form.useForm();
  const [formData, setFormData] = useState(initialFormData);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [formType, setFormType] = useState(-1);
  const [validtilldate, setvalidtilldate] = useState(moment());
  const [currentRecID, setCurrentRecID] = useState(-1);

  const { user } = useAuthContext();
  const {
    state: {
      serviceTypeList
    },
    getAllServiceType
  } = useContext(GlobalStatesContext);
  let dateFormat = "YYYY-MM-DD";
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinishFailed = (errorInfo) => {
   };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  /********** Uploading Image **********/

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  function handleChange(info) {
    if (info.file.status === "error") {
      ANTD.message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
        setFormData({
          ...formData,
          Image: info.file.response.data.filepath,
        });
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const updatePromoModel = (rec) => {
    setIsServiceFieldVisible(rec.ServiceType)
    setImageUrl(null);
    setFormData({ ...formData, PromoID: rec.PromoID, Image: rec.Image });
    form.setFieldsValue({
      ApplicationType: rec.ApplicationType.toString(),
      Code: rec.Code,
      Description: rec.Description,
      Discount: rec.Discount,
      DiscountType: rec.DiscountType,
      FromAmount: rec.FromAmount,
      Name: rec.Name,
      PGType: '3',
      ServiceType: rec.ServiceType,
      ToAmount: rec.ToAmount,
      UsageLimit: rec.UserLimit,
      UserLimit: rec.UserLimit,
      ValidFrom: moment(rec.ValidFrom, dateFormat),
      ValidTill: moment(rec.ValidTill, dateFormat),


      airlineCode: rec.airlineCode ? rec.airlineCode : rec.airlineCode == null ? "N/A" : "N/A",

      StarRating: rec.StarRating ? rec.StarRating : rec.StarRating == null ? "N/A" : "N/A",

    });
    if (rec.Image != "" && rec.Image != "string" && rec.Image != null) {
      const imgUrl = BASE + rec.Image.substring(1);
      setImageUrl(imgUrl);
    }
    setIsEditMode(true);
    showModal();
  };

  const addNewPromoModel = () => {
    setIsEditMode(false);
    form.resetFields();
    setImageUrl(null);
    setFormData(initialFormData);
    showModal();
  };

  const tableActions = (text, rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updatePromoModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <ANTD.Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => {
            setVerifyModalVisible(true);
            setCurrentRecID(text);
            setFormType(2);
          }}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </ANTD.Popconfirm>
      </div>
    );
  };

  const getOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {

        if (res.status == 200) {
          let filterOperators = res.data.filter((item) => item.ServiceType === 3);
          setOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: "Promo Name",
      dataIndex: "Name",
      refno: "",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      key: "Name",
    },
    {
      title: "Promo Description",
      dataIndex: "Description",
      refno: "",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      key: "Description",
    },
    {
      title: "Code",
      dataIndex: "Code",
      key: "Code",
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },
    {
      title: "Valid From",
      dataIndex: "ValidFrom",
      key: "ValidFrom",
    },
    {
      title: "Valid Till",
      dataIndex: "ValidTill",
      key: "ValidTill",
    },
    {
      title: "Discount Type",
      dataIndex: "DiscountType",
      key: "DiscountType",
      render: (text, rec, ind) => {
        return getDiscountType(text);
      },
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "Discount",
    },
    {
      title: "From Amont",
      dataIndex: "FromAmount",
      key: "FromAmount",
    },
    {
      title: "To Amount",
      dataIndex: "ToAmount",
      key: "ToAmount",
    },
    {
      title: "Airline Code",
      dataIndex: "airlineCode",
      render: (a, b) => {

        if (a == null) {
          return "N/A"
        } else return a
      },
    },

    {
      title: "Star Rating",
      dataIndex: "StarRating",
      render: (a, b) => {

        if (a == null) {
          return "N/A"
        } else return a
      },
    },
    {
      title: "Actions",
      dataIndex: "PromoID",
      render: (text, rec, index) => {
        return tableActions(text, rec);
      },
    },
  ];

  const getServiceType = (num) => {
    let type = "";
    serviceTypeList.map((item) => {
      if (item.ServiceType == num) {
        type = item.ServiceName;
      }
    });
    return type;
  };

  const getDiscountType = (num) => {
    if (num == 0) {
      return "Fixed";
    } else if (num == 1) {
      return "Percentage";
    } else {
      return "Invalid";
    }
  };

  /* =============== API calls ================*/

  useEffect(() => {
    getPromoCodes();
    getAllServiceType()
    getOperators();;
  }, []);

  // api call to get all avaialble promo Codes
  const getPromoCodes = () => {
    ApiClient.get("admin/promo").then((res) => {
      if (res.status == 200) {
         setDataSource(res.data);
      }
    });
  };

  // Delete specific promo Codes
  const deletePromoCode = (id) => {
    ApiClient.delete("admin/promo/" + id).then((res) => {
      if (res.status == 200) {
        ANTD.message.success("PromoCode Deleted  successfully", 3);
        getPromoCodes();
      }
    });
  };

  const submitNewPromo = (data) => {
    const newformData = {
      ...formData,
      ...data,
    };
    ApiClient.post("admin/promo", newformData).then((res) => {
      if (res.status == 200) {
        ANTD.message.success("Promo added successfully", 3);
        setModalVisible(false);
        setFormData(initialFormData);
        getPromoCodes();
      } else if (res.status == 400) {
        ANTD.message.error(" Duplicate Promo Code", 3);
      } else {
        ANTD.message.error(" Error Uploading Data", 3);
      }
    });
  };

  const submitUpdatePromo = (data) => {
    delete data.UploadImage;
    const updatedFormData = {
      ...formData,
      ...data,
    };
    ApiClient.put("admin/promo/" + formData.PromoID, {}, updatedFormData).then(
      (res) => {
        if (res.status == 200) {
          ANTD.message.success("Promo Updated  successfully", 3);
          setModalVisible(false);
          setFormData(initialFormData);
          getPromoCodes();
        } else if (res.status == 400) {
          ANTD.message.error(" Duplicate Promo Code", 3);
        } else {
          ANTD.message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  // add new Promo Code api call
  const submitFormData = (formvalues) => {
    formvalues.ValidFrom = formvalues.ValidFrom.format(dateFormat);
    formvalues.ValidTill = formvalues.ValidTill.format(dateFormat);
    const imageData = imageUrl ? imageUrl : " ";
    const edit_data = {
      ...formvalues,
      Image: imageData,
    };

    if (isEditMode) {
      submitUpdatePromo(formvalues);
    } else {
      submitNewPromo(formvalues);
    }
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getPromoCodes();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    const excelCustomData = dataSource.map((item) => {
      return {
        Name: item.Name,
        Description: item.Description,
        Code: item.Code,
        Service_Type: getServiceType(item.ServiceType),
        Valid_From: item.ValidFrom,
        Valid_Till: item.ValidTill,
        Discount_Type: getDiscountType(item.DiscountType),
        Discount: item.Discount,
        From_Amount: item.FromAmount,
        To_Amount: item.ToAmount,
      };
    });

    props.exportExcel(excelCustomData, "PromoCodesExcel");
  };

  const handleVerifyPassword = (values) => {
    ApiClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            deletePromoCode(currentRecID);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const disabledOriginDate = (currentDate) => {
    //setvalidtilldate(moment(currentDate));
    return currentDate < moment().startOf("day");
  };
  const disabledDestDate = (currentDate) => {
    return currentDate < moment(validtilldate).startOf("day");
  }
  const onChangeOriginDate = (momentdate, _) => {
     let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setvalidtilldate(moment(momentdate));
  };
  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Promocode{" "}
                          <HelpInfoHelper screenName={"/admin/promo"} />
                        </h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(dataSource)}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getPromoCodes()}
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={() => {
                          addNewPromoModel();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <Modal
        title="Promocode"
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[null]}
        width={"1100px"}
      >
        <Form
          {...layout}
          form={form}
          onFinish={submitFormData}
          onFinishFailed={onFinishFailed}
        >
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Service"
                name="ServiceType"
                rules={[{ required: true, message: "Service is required" }]}
              >
                <Select placeholder="Select Service Type" onSelect={(e) => setIsServiceFieldVisible(e)}>
                  {serviceTypeList.length
                    ? serviceTypeList.map((item, index) => {
                      return (
                        <Select.Option
                          key={"serviceType" + item}
                          value={item.ServiceType}
                        >
                          {item.ServiceName}
                        </Select.Option>
                      );
                    })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Application Type"
                name="ApplicationType"
                rules={[
                  { required: true, message: "Application Type is required" },
                ]}
              >
                <Select placeholder="Select Service Type">
                  <Select.Option value="1">Website</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Promo Name"
                name="Name"
                rules={[{ required: true, message: "Promo Name is required" }]}
              >
                <Input name="Name" placeholder="Promo Name" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Promo Code"
                name="Code"
                rules={[{ required: true, message: "Promo Code is required" }]}
              >
                <Input name="Code" placeholder="Promo Code" />
              </Form.Item>
            </Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Valid From"
                name="ValidFrom"
                rules={[{ required: true, message: "Valid From is required" }]}
              >
                <DatePicker disabledDate={disabledOriginDate} onChange={(date, dateString) =>
                  onChangeOriginDate(date, dateString)
                } format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Valid Till"
                name="ValidTill"
                rules={[{ required: true, message: "Valid Till is required" }]}
              >
                <DatePicker disabledDate={disabledDestDate} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="From Amount "
                name="FromAmount"
                rules={[
                  { required: true, message: "From Amount is required" },
                  {
                    validator: (_, value) => {
                      if (value > -1) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please Enter valid Amount"
                        );
                      }
                    },
                  },
                ]}
              // rules={[{ required: true, message: "From Amount is required" }]}
              >
                <Input
                  name="FromAmount"
                  placeholder="From Amount"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="To Amount"
                name="ToAmount"
                rules={[
                  { required: true, message: "To Amount is required" },
                  {
                    validator: (_, value) => {
                      if (value > -1) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please Enter valid Amount"
                        );
                      }
                    },
                  },
                ]}
              // rules={[{ required: true, message: "To Amount is required" }]}
              >
                <Input name="ToAmount" placeholder="To Amount" />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Discount Type"
                name="DiscountType"
                rules={[
                  { required: true, message: "Discount Type is required" },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Percentage</Radio>
                  <Radio value={0}>Fixed</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Discount Amount"
                name="Discount"
                rules={[
                  { required: true, message: "Discount Amount is required" },
                  {
                    validator: (_, value) => {
                      if (value > -1) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please Enter valid Amount"
                        );
                      }
                    },
                  },
                ]}
              // rules={[
              //   { required: true, message: "Discount Amount is required" },
              // ]}
              >
                <Input placeholder="Discount Amount" />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Usage Limit"
                name="UsageLimit"
                rules={[
                  { required: true, message: "Usage Limit is required" },
                  {
                    validator: (_, value) => {
                      if (value > -1) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please Enter valid Amount"
                        );
                      }
                    },
                  },
                ]}
              // rules={[{ required: true, message: "Usage Limit is required" }]}
              >
                <Input
                  name="UsageLimit"
                  placeholder="Usage Limit"
                // type="number"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="User Limit"
                name="UserLimit"
                rules={[{ required: true, message: "User Limit is required" }]}
              >
                <Input
                  name="UserLimit"
                  placeholder="User Limit"
                // type="number"
                />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>

            <Col md={12} xs={24}>
              <Form.Item
                label="Promo Description"
                name="Description"
                rules={[
                  { required: true, message: "Promo Description is required" },
                ]}
              >
                <TextArea name="Description" placeholder="Description" />
              </Form.Item>
            </Col>

            {isServiceFieldVisible == 1 ? <Col md={12} xs={24}>
              <AirlineAutoCompleteSelect
                formItemProps={{
                  label: "Airline Name",
                  name: "airlineCode",
                  rules: [],
                }}
                selectProps={{ placeholder: "" }}
                api={"flights/airlineNames/search/"}
                keytext="nameAirline"
                keyvalue="codeIataAirline"
              />
            </Col> : null}
            {isServiceFieldVisible == 2 ? (<Col md={12} xs={24}>
              <Form.Item label="Select Star Rating" name="StarRating" rules={[{ required: true, message: "Star Rating is required" }]}>
                <Select >
                  <Select.Option value={0}>ALL</Select.Option>
                  <Select.Option value={1}>1 star</Select.Option>
                  <Select.Option value={2}>2 star</Select.Option>
                  <Select.Option value={3}>3 star</Select.Option>
                  <Select.Option value={4}>4 star</Select.Option>
                  <Select.Option value={5}>5 star</Select.Option>
                </Select>
              </Form.Item>
            </Col>) :
              null}
            {
              isServiceFieldVisible == 3 ? (<Col md={12} xs={24}>

              </Col>) :
                null}
          </ANTD.Row>
          <Col md={12} xs={24}>
            <Form.Item label="Upload Image" name="UploadImage">
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={uploadActionUrl}
                data={{ category: "promo" }}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              onClick={() => {
                setVerifyModalVisible(true);
                setFormType(1);
              }}
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {verifyModalVisible && (
        <VerifyPasswordModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyPassword={handleVerifyPassword}
        />
      )}
    </div>
  );
};
export default UpdatedComponent(Promo);
