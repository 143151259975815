import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";
import modules from "../../helpers/reactQuillModules";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import "./Managervisamaster.scss";
import moment from "moment";

export const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    // const checkFileSize = file.size < 256000;
    // if (!checkFileSize) {
    //   message.error(" Image must be smaller than 250kb!");
    // }

    //return checkJpgOrPng && checkFileSize;

    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/multipleImages";

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const ManageVisaMaster = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isCloneMode, setIsCloneMode] = useState(true);
  const [VisaMaster, setVisaMaster] = useState([]);
  const [RecId, setId] = useState(-1);
  const [form] = Form.useForm();

  const [visaimgList, setVisaImagesList] = useState([]);

  const [visaImage, setVisaImage] = useState();

  const [upLoadImage, setUpLoadImage] = useState(false);

  const [countryLists, setCountryList] = useState([]);
  const [visaTypeList, setVisaTypeList] = useState([]);

  const [CurrecyList, setCurrencyList] = useState([]);
  const [imageUrl, setImageUrl] = useState();

  const { TextArea } = Input;

  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";

  const initialState = {
    OverView: "",
    whychooseUs: "",
    howWeCanHelp: "",
  };
  const [content, setContent] = useState(initialState);
  const getContent = (text, type) => {
    setContent((prev) => ({
      ...prev,
      [type]: text,
    }));
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const getCurrencies = () => {
    ApiClient.get("currency/getallcurrency")
      .then((res) => {
        if (res.status == 200) {
          setCurrencyList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
    setIsCloneMode(false);
  };
  const getVisaMaster = () => {
    ApiClient.get("visa/getAllManageMasterVisa").then((res) => {
      if (res.status == 200) {
        setVisaMaster(res.data);
      }
    });
  };
  const handleEditClick = (rec) => {
    getAllVisaTypeByCountryName(rec.Country);
    setVisaImagesList([]);
    let array = [];
    if (rec.Images.length > 0) {
      for (let i = 0; i < rec.Images.length; i++) {
        array.push({
          uid: rec.Images[i].Id,
          name: "image.jpg",
          status: "done",
          url: BASE + rec.Images[i].Image.substring(1),
          response: {
            status: 200,
            data: {
              filepaths: [rec.Images[i].Image],
            },
          },
        });
      }
      setVisaImagesList(array);
    }

    form.setFieldsValue({
      Country: rec.Country,
      Continent: rec.Continent,
      TypeofVisa: rec.TypeofVisa,
      VisaName: rec.VisaName,
      ProcessingTime: rec.ProcessingTime,
      Stayperiod: rec.Stayperiod,
      Validity: rec.Validity,
      Entry: rec.Entry,
      Fees: rec.Fees,
      Currency: rec.Currency,
      VisaPriceIncludes: rec.VisaPriceIncludes,
      Status: rec.Status,
      VisaDescription: rec.VisaDescription,
      Image: { fileList: array },
    });
    let obj = {
      OverView: rec.OverView,
      whychooseUs: rec.whychooseUs,
      howWeCanHelp: rec.howWeCanHelp,
    };

    setContent(obj);

    setId(rec.Id);

    showModal();
  };

  const [dataSource, setDataSource] = useState([]);
  const getManageVisaMaster = () => {
    setDataSource([]);
    ApiClient.get("visa/getAllManageMasterVisa").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getManageVisaMaster();
    getCountriesLists();
    getVisaMaster();
    getCurrencies();
  }, []);

  const submitForm = (formvalues) => {
    ApiClient.post("visa/addManageMasterVisa", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Added New Visa Master");

        getManageVisaMaster();
        form.resetFields();
      } else {
        message.error(response.message);
      }
    });
  };

  const handleSubmit = (formvalues) => {
    if (formvalues.Image.fileList.length > 0) {
      let array = [];

      for (let i = 0; i < formvalues.Image.fileList.length; i++) {
        if (formvalues.Image.fileList[i].response.status === 200) {
          array.push(formvalues.Image.fileList[i].response.data.filepaths[0]);
        }
      }
      if (array.length > 0) {
        formvalues.Image = array;
      }
    }

    formvalues.OverView = content.OverView;
    formvalues.whychooseUs = content.whychooseUs;
    formvalues.howWeCanHelp = content.howWeCanHelp;

    delete formvalues.UploadImage;
    if (isEditMode) {
      UpdateSubmitForm(formvalues);
    } else if (isCloneMode) {
      submitForm(formvalues);
    } else {
      submitForm(formvalues);
    }
  };

  const UpdateSubmitForm = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put(
      "visa/updateManageMasterVisa/" + RecId,
      {},
      updatedFormData
    ).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);

        getManageVisaMaster();
        form.resetFields();
      } else {
        message.error(response.message);
      }
    });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteVisaMaster = (id) => {
    ApiClient.delete("visa/deleteManageMasterVisa/" + id).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        getManageVisaMaster();
      }
    });
  };

  const getAllVisaTypeByCountryName = (countryName) => {
    setVisaTypeList([]);
    ApiClient.get("visa/getVisaTypeByCountryName/" + countryName).then(
      (res) => {
        if (res.status == 200) {
          setVisaTypeList(res.data);
        }
      }
    );
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setIsEditMode(true);
              setIsCloneMode(false);
              handleEditClick(rec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Tooltip placement="top" title="Clone Visa Master">
            {" "}
            <CopyOutlined
              onClick={() => {
                setIsCloneMode(true);
                setIsEditMode(false);
                handleEditClick(rec);
              }}
              style={{
                color: "#1890ff",
                fontSize: "20px",
                textAlign: "center",
              }}
            />
          </Tooltip>
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteVisaMaster(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
    {
      title: "VisaName",
      dataIndex: "VisaName",
    },
    {
      title: "VisaDescription",
      dataIndex: "VisaDescription",
    },

    {
      title: "Continent",
      dataIndex: "Continent",
    },

    {
      title: "Country",
      dataIndex: "Country",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "Entry",
      dataIndex: "Entry",
    },
    {
      title: "Fees",
      dataIndex: "Fees",
    },
    {
      title: "ProcessingTime",
      dataIndex: "ProcessingTime",
    },
    {
      title: "Stayperiod",
      dataIndex: "Stayperiod",
    },
    {
      title: "TypeofVisa",
      dataIndex: "TypeofVisa",
    },
    {
      title: "Validity",
      dataIndex: "Validity",
    },

    {
      title: "VisaPriceIncludes",
      dataIndex: "VisaPriceIncludes",
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      );
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" />;
    }
  };

  const getNumbersRange = (start, end) => {
    let array = [];
    for (let index = start; index < end; index++) {
      array.push(index);
    }
    return array;
  };

  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Manage Visa Master
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  {dataSource.length ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={`${
          isEditMode ? "Update" : isCloneMode ? "Clone" : "Add"
        } Visa Master`}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          form={form}
          layout={"vertical"}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          // onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col md={6} xs={24}>
              <Form.Item
                label="Continent"
                rules={[{ required: true }]}
                name="Continent"
              >
                <Select placeholder="Please Select Your Continent">
                  <Select.Option value="Asia">Asia</Select.Option>
                  <Select.Option value="Africa">Africa</Select.Option>
                  <Select.Option value="NorthAmerica">
                    North America
                  </Select.Option>
                  <Select.Option value="SouthAmerica">
                    South America
                  </Select.Option>
                  <Select.Option value="Antarctica">Antarctica</Select.Option>
                  <Select.Option value="Europe">Europe</Select.Option>
                  <Select.Option value="Australia">Australia</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Country"
                rules={[{ required: true }]}
                name="Country"
              >
                <Select
                  showSearch
                  placeholder="Please Select Your Country"
                  onSelect={(e) => {
                    getAllVisaTypeByCountryName(e);
                  }}
                >
                  {countryLists.map((i, index) => (
                    <Option key={"country" + index} value={i.Country}>
                      {i.Country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Type of Visa"
                rules={[{ required: true }]}
                name="TypeofVisa"
              >
                <Select placeholder="Please Select Your Entry">
                  {visaTypeList ? (
                    visaTypeList.map((item, index) => {
                      return (
                        <Option value={item.visaType}>{item.visaType}</Option>
                      );
                    })
                  ) : (
                    <Option>No Visa Type Found!</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="VisaName"
                label="Visa Name"
                rules={[{ required: true, message: "Please input your Age!" }]}
              >
                <Input placeholder="Please enter your Visa Name" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Processing Time (Days)"
                rules={[{ required: true }]}
                name="ProcessingTime"
              >
                <Select placeholder="Processing Time" showSearch>
                  {getNumbersRange(1, 46).map((i) => (
                    <Select.Option value={i}>{i}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="Stayperiod"
                label="Stay period (Month's)"
                rules={[
                  { required: true, message: "Please input your Stay Period!" },
                ]}
              >
                <Select placeholder="Stay periods" showSearch>
                  {getNumbersRange(1, 46).map((i) => (
                    <Select.Option value={i}>{i}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="Validity"
                label="Validity (Month's)"
                rules={[
                  { required: true, message: "Please input your Validity" },
                ]}
              >
                <Select placeholder="Validity" showSearch>
                  {getNumbersRange(1, 13).map((i) => (
                    <Select.Option value={i}>{i}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Entry"
                rules={[{ required: true }]}
                name="Entry"
              >
                <Select placeholder="Please Select Your Entry">
                  <Select.Option value="Single">Single </Select.Option>
                  <Select.Option value="Multiple">Multiple</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="Fees"
                label="Fees"
                rules={[{ required: true, message: "Please input your Fees" }]}
              >
                <Input placeholder="Please enter your Fees" min={0} step={1} type="number" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Currency"
                rules={[{ required: true }]}
                name="Currency"
              >
                <Select placeholder="Please Select Your Currency" showSearch>
                  {CurrecyList.length
                    ? CurrecyList.map((item, index) => {
                        return (
                          <Option key={"Currency" + index} value={item.Code}>
                            {item.Code + "-" + item.CurrencyName}
                          </Option>
                        );
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="VisaPriceIncludes"
                rules={[{ required: true }]}
                label="Visa Price includes"
              >
                <Input
                  placeholder="Please enter your Visa Price includes"
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Visa Long Description"
                rules={[{ required: true }]}
                name="VisaDescription"
              >
                <TextArea
                  placeholder="Please Enter Visa Long Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col md={16} xs={24}>
              <Logos
                category="visimage"
                fileList={visaimgList}
                setFileList={setVisaImagesList}
                formName="Image"
                limit={true}
                limitSize={5}
              />
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="OverView">
                <ReactQuill
                  value={content.OverView}
                  modules={modules}
                  onChange={(e) => getContent(e, "OverView")}
                  placeholder="please input ..."
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Why Choose Us?">
                <ReactQuill
                  value={content.whychooseUs}
                  modules={modules}
                  onChange={(e) => getContent(e, "whychooseUs")}
                  placeholder="please input ..."
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label="How We Can Help?">
                <ReactQuill
                  value={content.howWeCanHelp}
                  modules={modules}
                  onChange={(e) => getContent(e, "howWeCanHelp")}
                  placeholder="please input ..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : isCloneMode ? "Clone" : "Add"}
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageVisaMaster;
