//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Radio,
  message,
  Popover,
  Divider,
  List,
} from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CarPriceHelper } from "../../../helpers/CarsHelpers/carPriceHelper";
import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";
import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getCarGrandTotal = (
  selectedCarData,
  ConvFee,
  promoData,
  selectedInsuranceData
) => {
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let discount = 0;
  let convamount = 0;
  let totalBaseFare = 0;
  let totalGst = 0;
  let fareInfoData = selectedCarData
    ? Number(selectedCarData.BaseFare ? selectedCarData.BaseFare : 0) +
    Number(selectedCarData.StateTollTax ? selectedCarData.StateTollTax : 0) +
    Number(selectedCarData.GST ? selectedCarData.GST : 0) +
    Number(
      selectedCarData.DriverAllowance ? selectedCarData.DriverAllowance : 0
    )
    : null;

  // fareInfoData = fareInfoData.filter((i) => i != undefined);
  if (selectedCarData?.type === "transfer") {
    totalBaseFare = selectedCarData?.Vehicles[0]?.TransferPrice?.totalBasePrice;
    totalGst = selectedCarData?.Vehicles[0]?.TransferPrice?.Tax;
  } else {
    totalBaseFare = fareInfoData;
  }
  // console.log(selectedCarData, "cardata");
  // let totalGst = selectedCarData?.Vehicles[0]?.TransferPrice?.Tax;
  // let totalCommission = 0;
  // let totalCommission = fareInfoData.reduce(
  //   (acc, value) => Number(value.commission) + Number(acc),
  //   0
  // );
  let total = Number(totalBaseFare) + Number(totalGst);
  discount = getPromoDiscount(promoData, total);
  convamount = getConvFee(ConvFee, total);

  total = total + Number(convamount);

  total -= discount;


  let totalFareCalculations = Number(Number(total)).toFixed(2);

  // agentMarkup = fareInfoData.reduce(
  //   (acc, value) => Number(value.agentMarkup) + Number(acc),
  //   0
  // );

  // adminCommission = fareInfoData.reduce(
  //   (acc, value) => Number(value.adminCommission) + Number(acc),
  //   0
  // );
  // markup = fareInfoData.reduce(
  //   (acc, value) => Number(value.markup) + Number(acc),
  //   0
  // );

  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount: Number(discount).toFixed(2),
    totalFareCalculations,
    totalServiceAmount: Number(total).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    // totalCommission: totalCommission ? Number(totalCommission) : 0,
    // markup,
    // agentMarkup,
    // adminCommission,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
  };
};

const FareDetails = ({ selectedCarData, isPromoVisible }) => {
  const [form] = Form.useForm();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
  } = useContext(GlobalStatesContext);
  const dataChargeSource = [
    `Base Fare : ${selectedCarData.BaseFare ?? "00"} rs.`,
    `StateToll Tax : ${selectedCarData.StateTollTax ?? "00"} rs.`,
    `GST Amount : ${selectedCarData.GST ?? "00"} rs.`,
    `Driver Allowance : ${selectedCarData.DriverAllowance ?? "00"} rs.`,

  ];
  const dataTransferChargeSource = [
    `Base Fare :  ${selectedCarData.type === "transfer" ?
      selectedCarData?.Vehicles[0]?.TransferPrice?.BasePrice ?? "00" : "00"
    } rs.`,
    `GST Amount : ${selectedCarData.type === "transfer" ?
      selectedCarData?.Vehicles[0]?.TransferPrice?.GST ?? "00" : "00"
    } rs.`,
  ];
  const dataExtraChargeSource = [
    `Charge Per Extra KM : ${selectedCarData.ChargePerExtraKM ?? "00"} rs./km`,
    `Late Night Charge : ${selectedCarData.NightCharges ?? "00"} rs./KM`,
    `Late Pickup Charge : ${selectedCarData.LatePickupCharges ?? "00"} rs./km`,
    `Early Pickup Charge : ${selectedCarData.EarlyPickupCharges ?? "00"
    } rs./km`,
  ];
  const content = (Data) => {
    return (
      <div>
        <Divider orientation="left">Fare Charges</Divider>
        <List
          size="small"
          bordered
          dataSource={
            selectedCarData.type === "transfer"
              ? dataTransferChargeSource
              : dataChargeSource
          }
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <h6 style={{ color: "Red", marginTop: "10px", textAlign: "right" }}>
          {" "}
          TOTAL PRICE: INR{" "}
          {selectedCarData.type === "transfer"
            ? selectedCarData?.Vehicles[0]?.TransferPrice?.PublishedFares
            : `${Number(Data.BaseFare ? Data.BaseFare : 0) +
            Number(Data.StateTollTax ? Data.StateTollTax : 0) +
            Number(Data.GST ? Data.GST : 0) +
            Number(Data.DriverAllowance ? Data.DriverAllowance : 0)
            }`}
        </h6>
        {!selectedCarData.type === "transfer" ? (
          <>
            <Divider></Divider>
            <p style={{ color: "Green" }}>
              Extra Charges for Extra KM's and LateNight Charges:
            </p>
            <List
              size="small"
              bordered
              dataSource={dataExtraChargeSource}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </>
        ) : null}
      </div>
    );
  };
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  let {
    totalBaseFare,
    totalGst,
    discount,
    convamount,
    totalFareCalculations,
    totalServiceAmount,
    insuranceTotal,
  } = getCarGrandTotal(
    selectedCarData,
    ConvFee,
    promoData,
    selectedInsuranceData
  );
 console.log(totalServiceAmount,"visa")
  useEffect(() => {
    getInsuranceByServiceType(3);
  }, []);

  useEffect(() => {
    if (!agent) {
      if(selectedCarData.type !== "transfer"){
        AddConvFee(7);
        getpromo();
      }else if(selectedCarData.type === "transfer"){
      AddConvFee(5);
      getpromo();
      }
    }
  }, [agent]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let carPromo = data.filter(
              (promo) =>
                promo.ServiceType === 7 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );
            let TrasferPromo = data.filter(
              (promo) =>
                promo.ServiceType === 5 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(carPromo);
            setPromoCodes(TrasferPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });

    form.submit();
  };

  return (
    <>
      {selectedCarData.type !== "transfer" ? (
        <>
          <div className="busFareDetail_wrapper mb-0">
            <div class="charge-details">
              <p><span class="label">Base Fare:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.BaseFare) ?? "00"}</span></p>
              <p><span class="label">StateToll Tax:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.StateTollTax) ?? "00"}</span></p>
              <p><span class="label">GST Amount:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.GST) ?? "00"}</span></p>
              <p><span class="label">Driver Allowance:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.DriverAllowance) ?? "00"}</span></p>
            </div>
            {/* <div className="price_wrapper">
          {/* <Popover placement="rightBottom" content={content(selectedCarData)}>
              <p className="text" >
                Charges
              </p>
            </Popover>
            <p className="price">
              {activeCurrency}{" "}
              {totalBaseFare ? currencyValue(totalBaseFare) : ""}
            </p> */}

            {/* <List
            size="large"
            // bordered
            dataSource={
              selectedCarData.type === "transfer"
                ? dataTransferChargeSource
                : dataChargeSource
            }
            renderItem={(item) => <List.Item>{item}</List.Item>}
          /> *
        </div> */}
            {/* {console.log(object);} */}
            {/* <div className="price_wrapper">
            <p className="text">GST Amount</p>
            <p className="price">
              {activeCurrency} {totalGst ? currencyValue(totalGst) : ""}
            </p>
          </div> */}
            {!agent && user?.Role?.RoleLevel !== 3 && (
              <>
                {convamount > 0 ?
                  <div className="price_wrapper">
                    <p className="text">Convenience Fee</p>
                    <p className="price">
                      {activeCurrency} {currencyValue(convamount)}
                    </p>
                  </div> : null}
                {promoData.status && (
                  <div className="price_wrapper">
                    <p className="text">Discount</p>
                    <p className="price">
                      - {activeCurrency}{" "}
                      {promoData.status ? currencyValue(discount) : " 0.00"}
                    </p>
                  </div>
                )}
              </>
            )}

            {/* <div className="price_wrapper total_price">
            <p className="text">Total:</p>
            <p style={{ color: "#35459c" }} className="price">
              {activeCurrency} {currencyValue(totalServiceAmount)}
            </p>
          </div> */}

          </div>

          <div className="busFareDetail_wrapper">
            {/* {selectedInsuranceData.status === 1 &&
            selectedInsuranceData?.serviceType === 3 ? (
            <div className="price_wrapper">
              <p className="text">Insurance Amount</p>
              <p className="price">
                {activeCurrency} {currencyValue(insuranceTotal)}
              </p>
            </div>
          ) : null} */}


            <div className="price_wrapper">

              <p className="text" style={{ fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px' }}>Grand Total:</p>

              <p style={{ color: "#bd0c21", fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px' }} className="price">
                {activeCurrency}{" "}
                {totalFareCalculations
                  ? currencyValue(totalFareCalculations)
                  : ""}
              </p>
            </div>
          </div>
        </>
      ) : null}
      {selectedCarData.type === "transfer" ? (<>
        <div className="busFareDetail_wrapper mb-0">
          <div class="charge-details">
            <p>Base Fare:</p> <p class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData?.Vehicles[0]?.TransferPrice?.totalBasePrice) ?? "00"}</p>
            {selectedCarData.Vehicles[0]?.TransferPrice?.Tax > 0 ?
              <p><span class="label">StateToll Tax:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.Vehicles[0]?.TransferPrice?.Tax)}</span></p> : null}
            {/* <p><span class="label">GST Amount:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData?.Vehicles[0]?.TransferPrice?.GST)}</span></p> */}
            {/* <p><span class="label">Driver Allowance:</span> <span class="value"> {activeCurrency}{" "}{selectedCarData.DriverAllowance ?? "00"}</span></p> */}
          </div>
          {/* <div className="price_wrapper">
          {/* <Popover placement="rightBottom" content={content(selectedCarData)}>
              <p className="text" >
                Charges
              </p>
            </Popover>
            <p className="price">
              {activeCurrency}{" "}
              {totalBaseFare ? currencyValue(totalBaseFare) : ""}
            </p> */}

          {/* <List
            size="large"
            // bordered
            dataSource={
              selectedCarData.type === "transfer"
                ? dataTransferChargeSource
                : dataChargeSource
            }
            renderItem={(item) => <List.Item>{item}</List.Item>}
          /> *
        </div> */}
          {/* {console.log(object);} */}
          {/* <div className="price_wrapper">
            <p className="text">GST Amount</p>
            <p className="price">
              {activeCurrency} {totalGst ? currencyValue(totalGst) : ""}
            </p>
          </div> */}
          {!agent && user?.Role?.RoleLevel !== 3 && (
            <>
              {convamount > 0 ?
                <div className="price_wrapper">
                  <p className="text">Convenience Fee</p>
                  <p className="price">
                    {activeCurrency} {currencyValue(convamount)}
                  </p>
                </div> : null}
              {promoData.status && (
                <div className="price_wrapper">
                  <p className="text">Discount</p>
                  <p className="price">
                    - {activeCurrency}{" "}
                    {promoData.status ? currencyValue(discount) : " 0.00"}
                  </p>
                </div>
              )}
            </>
          )}

          {/* <div className="price_wrapper total_price">
            <p className="text">Total:</p>
            <p style={{ color: "#35459c" }} className="price">
              {activeCurrency} {currencyValue(totalServiceAmount)}
            </p>
          </div> */}

        </div>
        <div className="busFareDetail_wrapper">
          <div className="price_wrapper">

            <p className="text" style={{ fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px', whiteSpace: "nowrap" }}>Grand Total:</p>

            <p style={{ color: "#bd0c21", fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px', whiteSpace: "nowrap" }} className="price">
              {activeCurrency}{" "}
              {currencyValue(Number(selectedCarData?.Vehicles[0]?.TransferPrice?.PublishedFares) + Number(convamount) - Number(discount) )}
            </p>
          </div>
        </div>
      </>) : null}
      {selectedCarData.type !== "transfer" ? (
        <>
      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <div>
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  validatePromoCode({
                    ServiceType: 7,
                    Amount: Number(totalServiceAmount),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item name="promo">
                      <Input
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" onClick={() => form.submit()}>
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
      </>
      ):null}
      {selectedCarData.type === "transfer" ? (
        <>
      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <div>
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  validatePromoCode({
                    ServiceType: 5,
                    Amount: Number(totalServiceAmount),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item name="promo">
                      <Input
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" onClick={() => form.submit()}>
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCodes.length ? (
                promoCodes.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
      </>
      ) : null}
    </>
  );
};
export default FareDetails;
