import React, { useEffect, useRef, useState } from "react";
import { Button, Select, Col, DatePicker, Form, Row } from "antd";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../helpers/ApiClient";
import moment from "moment";
import queryString from "query-string";
import { SearchOutlined } from "@ant-design/icons";
import { useSytContext } from "../../../common/providers/SytProvider";
import ReactHtmlParser from "react-html-parser";
import ActiveHolidays from "./ActiveHolidays";
import HolidayAutoCompleteSelectByCategory from "../../../common/AutoCompleteSelect/HolidayAutoCompleteSelectByCategory";
import {
  useLocalStorage,
  useSessionStorage,
} from "../../../helpers/useStorage";
import parseHtml from "react-pdf-html/dist/parse";
const { Option } = Select;
const oriDateString = "YYYY-MM-DD";
const dateFormat = "DD-MM-YYYY";
const HolidaySearchForm = (props) => {
  const [form] = Form.useForm();
  let history = useHistory();
  const [HolidaysHide, setHolidaysHide] = useState(true);
  const city = useRef(null);
  const dateBox = useRef(null);


  const handleOnSubmit = (ref) => {
    ref.current.focus();
  };
  const [category, setCategory] = useState([]);
  const getCategoryList = () => {
    ApiClient.get("HolidayExtranet/GetCategory")
      .then((resp) => {

        if (resp.status == 200) {
          setCategory(resp.data);

        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmitForm = (val) => {


    let countryCities = {};

    if (val.HolidayCityCode) {
      countryCities = queryString.parse(val.HolidayCityCode);
    }


    let query = {

      ...countryCities,
      catId: (val.Category),
      travelDate: moment(val.journeyDate).format(oriDateString),
    };
    let queryParams = queryString.stringify(query);
    recentSearches(queryParams);

    if (props.modify) {
      history.replace("/holidays/results?" + queryParams);
      props.onModify();
      setHolidaysHide(false);
    } else {
      history.push("/holidays/results?" + queryParams);
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };
  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (props.modifySearch) {
      setHolidaysHide(false);
      const holidaySearchParams = queryString.parse(document.location.search);
      let cityCode = "";
      if (holidaySearchParams.cityName) {
        cityCode = `${holidaySearchParams.cityName}`;
      }

      let categoryCode = "";
      if (holidaySearchParams.CatId) {
        categoryCode = `${holidaySearchParams.CatId}`;
      }
      form.setFieldsValue({
        HolidayCityCode: ReactHtmlParser(holidaySearchParams?.cityName),
        journeyDate: moment(holidaySearchParams.travelDate),
        Category: parseInt(holidaySearchParams?.catId),

      });
    }

  }, []);

  const [HolidaysRecentSearchesResults, setHolidaysRecentSearchesResults] =
    useSessionStorage("holidaysRecentSearchesResults", []);

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);
      if (HolidaysRecentSearchesResults.length > 0) {
        let array = [];
        array = [...HolidaysRecentSearchesResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setHolidaysRecentSearchesResults([
            searchObj,
            ...array.filter(
              (item) =>
                item?.cityName !== searchObj?.cityName ||
                item?.countryName !== searchObj?.countryName
            ),
          ]);
        }

        return;
      }
    }

    setHolidaysRecentSearchesResults([searchObj]);
  };

  const { topCities } = useSytContext();
  const [HolidayTopCities, setHolidayTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 6);
      if (data.length) {
        setHolidayTopCities(data);
      } else {
        setHolidayTopCities([]);
      }
    }
  }, [topCities]);

  return (
    <div className="hotel_search acitiv-pans holiday-upper-mar-work"   >
      <div>
      <Form form={form} onFinish={onSubmitForm} >
        <Row className="search-row activity-cols-num" gutter={16}>
          <Col md={5} xs={24} className="from-to-inputs new-activity-pro">
            <span className="for-to-labflisearch "  >Category</span>

            <Form.Item
              name="Category"
              label="CategoryName"
              className="form-holiday"
              rules={[
                { required: true },
              ]}
              api={"HolidayExtranet/GetCategory"}
            >
              <Select
                className="select-holidayform"
                style={{ fontSize: '16px', lineHeight: '37px' }}
                placeholder="Select Category's">

                <Option
                  value={0}>
                  ALL
                </Option>
                {category?.map((item) => {
                  return (
                    <Option
                      key={item.CategoryId}
                      value={item.CategoryId}
                      style={{ lineHeight: '37px' }}
                    >
                      {item.CategoryName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={10} xs={24}
            className="from-to-inputs new-activity-pro br-right mar-lef-holiday"
          >
            <span className="for-to-labflisearch3"  >Where</span>


            <HolidayAutoCompleteSelectByCategory
              formItemProps={{
                label: "City",
                name: "HolidayCityCode",
                rules: [{ required: true, message: "Please Specify The City" }],
              }}
              selectProps={{
                size: "large",
                placeholder: "Search By Destination",
              }}
              api={"HolidayExtranet/holiday/autosuggestion/cities/"}
              refName={city}
              focusRef={dateBox}
              handleOnSubmit={handleOnSubmit}
              topCities={HolidayTopCities}
              recentKey={"holidayRecentCities"}
            />
          </Col>
          <Col md={4} xs={24} className="from-to-inputs new-activity-pro  mar-lef-holiday">
            <span className="for-to-labflisearch" >Journey Date</span>

            <Form.Item
              name="journeyDate"
              rules={[{ required: true }]}
              initialValue={moment()}
            >
              <DatePicker
                style={{ width: "100%" }}
                allowClear={false}
                ref={dateBox}
                size="large"
                className="search-inputs journeyDate journeyDate-holiday"
                placeholder="Journey Date"
                format={dateFormat}
                disabledDate={disabledOriginDate}
                dropdownClassName="custom-mob-calendar"
                inputReadOnly={true}
                panelRender={(originalPanel) => {
                  return (
                    <div className="original-panel"  >

                      {originalPanel}
                    </div>
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col md={4} xs={24} className="new-activity-pro  mar-lef-holiday">
            <Form.Item className="mr-none">
              <Button
                size="large"
                className="primary-btn train-search-btn button-promo-activity mar-but-searchhol"
                htmlType="submit"

              >
                <SearchOutlined />
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </div>
      {HolidaysHide ?
        <ActiveHolidays /> : null}
    </div>
  );
};

export default HolidaySearchForm;
