import React, { useContext, useEffect, useState, Suspense, useCallback } from "react";
import { Col, Collapse, Grid, Row, Modal, Button } from "antd";

import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import queryString from "query-string";
import BusSort from "../BusSort/BusSort";
import ApiClient from "../../../helpers/ApiClient";
import Buses from "../../../components/buses-search/Buses";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
//import BusFilter from "./BusFilter/BusFilter";
import BusResultCards from "./BusResultCards/BusResultCards";
import BusSkeleton from "./Skeleton/BusSkeleton";
import ac from "../../../assets/images/ac.png";
import nonac from "../../../assets/images/nonac.png";
import seat from "../../../assets/images/seat.png";
import sleeper from "../../../assets/images/sleeper.jpg";
import morning from "../../../assets/images/morning-icon.png";
import night from "../../../assets/images/night-icon.png";


const BusFilter = React.lazy(() => import('./BusFilter/BusFilter'))
//import OnSitePopUp from "../../../components/SitePopups/onsitePopup";
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const BusResults = () => {
  const {
    state: { busSearchResultObj },
    updateBusSearchResultObj,
    updateSelectedBusData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  // const { md } = useBreakpoint();

  const { user } = useAuthContext();
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const [istopset, setistopset] = useState(-450);
  const [y, setY] = useState(window.scrollY);
  let busInitial = {
    trips: [],
    filtersObj: {
      price: {
        minPrice: 0,
        maxPrice: 0,
        maxPriceRange: 0,
        minPriceRange: 0,
      },
      timings: [
        {
          id: "day",
          start: "06:00",
          end: "17:59",
          label: "Day",
          icon: morning,
          isChecked: false,
        },
        {
          id: "night",
          start: "18:00",
          end: "05:59",
          label: "Night",
          icon: night,
          isChecked: false,
        },
      ],
      travels: [

      ],
      busType: [
        {
          type: "AC",
          label: "AC",
          id: "AC",
          icon: ac,
          isChecked: false
        },
        {
          type: "Non AC",
          label: "Non AC",
          id: "NONA/C",
          icon: nonac,
          isChecked: false,
        },
        {
          type: "Sleeper",
          label: "Sleeper",
          id: "SLEEPER",
          icon: sleeper,
          isChecked: false,
        },
        {
          type: "Seater",
          label: "Seater",
          id: "SEATER",
          icon: seat,
          isChecked: false,
        },
      ],
      boardingPoints: [],
      droppingPoints: [],
    },
    origin: "",
    destination: "",
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);

  const [popUpVisibility, setPopUpVisibility] = useState(false);

  function onInactive(ms, cb) {
    var wait = setTimeout(cb, ms);

    document.onmousemove =
      document.mousedown =
      document.mouseup =
      document.onkeydown =
      document.onkeyup =
      document.focus =
      document.scroll =
      function () {
        clearTimeout(wait);

        if (
          !localStorage.getItem("popupShown") &&
          JSON.parse(localStorage.getItem("popupShown")) !== true
        ) {
          wait = setTimeout(cb, ms);
        }
      };
  }
  const showNet = (val) => {
    setShowNetFare(val);
  };
  useEffect(() => {
    if (
      !localStorage.getItem("popupShown") &&
      JSON.parse(localStorage.getItem("popupShown")) !== true
    ) {
      onInactive(15000, function () {
        setPopUpVisibility(true);
      });
    }
  }, []);

  const showModalbusfilters = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalbussort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };
  const [busResultsList, setBusResultsList] = useState(busInitial);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    RemovePromoConvFee();
  }, []);
  useEffect(() => {
    getBusesAvailability();
  }, [window.location.search]);

  /* Buses Search Availability */
  const getBusesAvailability = () => {
    let param = queryString.parse(document.location.search);

    let formData = {
      tripType: 1,
      sourceId: param?.sourceId,
      destinationId: param?.destinationId,
      journeyDate: param?.journeyDate,
      returnDate: "",
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
    };
    let origin = param?.sourceName;
    let destination = param?.destinationName;

    fetchBusAvailability(formData, origin, destination, 1);
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const fetchBusAvailability = (formData, origin, destination, type) => {
    setIsLoading(true);
    updateBusSearchResultObj({});
    setBusResultsList(busInitial);
    ApiClient.post("buses/availability/price", formData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (resp.data.trips != {}) {
            let filtersObj = { ...busInitial.filtersObj };

            let trips = resp.data.trips.map((trip, i) => {
              let val = trip.startingFare.split("/").sort((a, b) => {
                return Number(b) - Number(a);
              });

              let startingFare = Number(val[0]);

              if (i === 0) {
                filtersObj.price.minPrice = Number(val[val.length - 1]);
              }
              let minFare = Number(val[val.length - 1]);
              if (startingFare > filtersObj.price.maxPrice) {
                filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
                  startingFare;
              }

              if (minFare < filtersObj.price.minPrice) {
                filtersObj.price.minPrice = minFare;
              }
              filtersObj.price.minPriceRange = filtersObj.price.minPrice;

              const { displayName } = trip;
              checkIfExist(filtersObj.travels, displayName) &&
                filtersObj.travels.push({
                  id: displayName,
                  label: displayName,
                  isChecked: false,
                });

              const { boardingPoints } = trip;
              boardingPoints.map((bPoint) => {
                checkIfExist(filtersObj.boardingPoints, bPoint.Location) &&
                  filtersObj.boardingPoints.push({
                    id: bPoint.Location,
                    label: bPoint.Location,
                    isChecked: false,
                  });
              });

              const { droppingPoints } = trip;
              droppingPoints.map((dPoint) => {
                checkIfExist(filtersObj.droppingPoints, dPoint.Location) &&
                  filtersObj.droppingPoints.push({
                    id: dPoint.Location,
                    label: dPoint.Location,
                    isChecked: false,
                  });
              });
              return {
                ...trip,
                isVisible: true,
              };
            });

            resp = {
              ...resp,
              data: {
                ...resp.data,
                trips: trips,
                origin: origin,
                destination: destination,
                journeyDate: formData.journeyDate,
                filtersObj: filtersObj,
              },
              tripType: type,
            };
            updateBusSearchResultObj(resp);
            setBusResultsList(resp.data);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > window.scrollY) {

        setistopset(0)//(istopset+Number(y)-Number(window.scrollY))

      } else if (y < window.scrollY) {
        setistopset(-450)
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    <>
      <div className="combined-results-container">
        <section className="hotels_modify  buses-div-bg">
          <div className="hotel-modify-container">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={
                  <span className="hotels-hide-search">Modify Search</span>
                }
                key="1"
              >
                <div className="search-modify-block bus-res-cc2">
                  <Buses
                    modifySearch={true}
                    busesSearchAPI={getBusesAvailability}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        </section>
        {/* <div className="modify-combined-search-container buses-div-bg">
          <div className="modify-combined-block block-of-info-bus">
            <div className="accordion" id="accordionExample">
              <div className="card hotels-search-card-value">
                <div
                  className="card-head hide-icon-heading-two"
                  id="headingOne"
                >
                  <span
                    className="hotels-hide-search bus-res-target"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Modify Search
                  </span>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="search-modify-block bus-res-cc2">
                      <Buses
                        modifySearch={true}
                        busesSearchAPI={getBusesAvailability}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container filters-number-tab">
            <Row
              gutter={16}
              className="margins-alignments"
            // style={{ padding: "14px 14px" }}
            >
              {isLoading ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <FilterSkeleton />
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    <BusSkeleton />
                  </Col>
                </>
              ) : Object.keys(busSearchResultObj).length > 0 &&
                busSearchResultObj?.data &&
                busSearchResultObj?.data?.trips?.length > 0 ? (
                <>
                  <Col md={6} className="filter-section-bus  result-marginn-work">
                    <div style={{ position: "sticky", zIndex: "0", top: istopset }}>
                      <Suspense fallback={<div>LOADING...</div>}>
                        <BusFilter
                          busSearchResultObj={busSearchResultObj}
                          setBusResultsList={setBusResultsList}
                        />
                      </Suspense>
                    </div>
                  </Col>
                  <Col className="result-marginn-work1">
                    <BusResultCards
                      updateSelectedBusData={updateSelectedBusData}
                      trips={busResultsList.trips}
                      busSearchResultObj={busSearchResultObj}
                      setBusResultsList={setBusResultsList}
                      showNetFare={showNetFare}
                      setShowNetFare={showNet}
                      busSearchObject={queryString.parse(
                        window.location.search
                      )}
                    />
                  </Col>
                </>
              ) : (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Buses Available"} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalbusfilters}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalbussort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;SORT BY
          </h5>
        </Col>
        {/* <Col md={8} xs={8} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1">
            <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Map
          </h5>
        </Col> */}
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Bus Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              <BusFilter
                busSearchResultObj={busSearchResultObj}
                setBusResultsList={setBusResultsList}
              />
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>SORT BY</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              <BusSort
                // busSearchResultObj={busSearchResultObj}
                // setBusResultsList={setBusResultsList}
                trips={busResultsList.trips}
                busSearchResultObj={busSearchResultObj}
                setBusResultsList={setBusResultsList}
                showNetFare={showNetFare}
                setShowNetFare={setShowNetFare}
                // trips={trips}
              />
            </div>
          </Col>
        </Row>
      </Modal>
      {/* <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
      {/* </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            Flight Filters Temp
            <BusFilter
              busSearchResultObj={busSearchResultObj}
              updateBusSearchResultObj={updateBusSearchResultObj}
              count={
                busSearchResultObj.tripType === 1
                  ? busResultsList.trips.length
                  : busTwoWayTrips.trips.length
              }
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Confirm</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
      {/* </div>,
        ]}
      >
        <div className="mobBusSortWrapper">
          <BusSort gscontext={gscontext} />
        </div>
      </Modal> */}

      {/*popUpVisibility ? (
        <OnSitePopUp
          visibility={popUpVisibility}
          setPopUpVisibility={setPopUpVisibility}
          serviceType={3}
          searchData={window.location.search}
        />
      ) : (
        ""
      )*/}
    </>
  );
};

export default BusResults;
