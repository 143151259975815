import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import moment from "moment";

import "./topDomesticRoutes.scss";

const { TabPane } = Tabs;
const MobileSlidersettings = {
  arrows: true,
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const TopDomesticRoutes = ({ deals, type }) => {
  const [dealsList, setDealsList] = useState([]);
  useEffect(() => {
    if (deals.length > 0) {
      if (type === "Flights")
        setDealsList(deals.filter((item) => item.ServiceType === 1));
      if (type === "Hotels")
        setDealsList(deals.filter((item) => item.ServiceType === 2));
    }
  }, [deals]);

console.log("dealsList",deals)

  const filterData = dealsList.filter((d) => d.Status == 1)

  return (
    <div className="domestic-airsearch">
      <Row>

        {type === "Flights" && <Col md={24} xs={24}>
          {filterData.length == 0 ? (null) : (
            <h3>Top Flight Routes</h3>)}
        </Col>}

        {type === "Flights" && <Col md={24} xs={24}>
            <div>
              <Slider {...MobileSlidersettings}>
                {filterData.length > 0 &&
                  filterData.map((item, i) => (
                    <div key={i}>
                      <Link
                        to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                          .add(10, "days")
                          .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${item.origin.split("-")[2]
                          }&destination=${item.destination.split("-")[2]}`}
                      >
                        <div className="ducarosuel">
                          <div className="origin-item-sel">
                            <div className="city-due">{item.origin.split("-")[0]}<br />({item.origin.split("-")[3]})</div>
                            <div className="time-due">
                              {moment()
                                .add(10, "days")
                                .format("DD, MMM YYYY")}
                            </div>
                            <div className="city-due">
                              {item.destination.split("-")[0]}<br />({item.destination.split("-")[3]})
                            </div>
                          </div>

                        </div>
                      </Link>
                    </div>
                  ))}
              </Slider>
            </div>



          </Col>}


      </Row >
    </div >
  );
};

export default TopDomesticRoutes;
