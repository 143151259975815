import { Button, Col, Divider, List, Popover,Card,Image, Row, Modal, Tabs } from "antd";
import { React, useState, useContext } from "react";
import { Tooltip } from 'antd';
import { format } from "crypto-js";
import ReactHtmlParser from "react-html-parser";
import { MinutesToHours } from "./../../../helpers/MinutesToHours";
import "./CarDetails.scss";
import NoImage from "../../../assets/images/ct-taxi.png"
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
const { TabPane } = Tabs;
export const CarDetails = (selectedCarData) => {
  const {
    state: {
      carBookingData,
      carPassengerData,
      otherData: { promoData },
    },
  } = useContext(GlobalStatesContext);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div className="busDetails_wrapper">
      {selectedCarData?.carBookingData ? (
        <div className="card_wrapper">
          {/* {console.log(carBookingData, "car")} */}
          <Card>
            <div className="check-car">
              <p className="carname">
                {selectedCarData?.carBookingData.car
                  ? selectedCarData?.carBookingData?.car.CarName?.toUpperCase()
                  : "Car"}
              </p>
              <Row gutter={16}>
                <Col md={4}>
                  <div className="carImage">
                    {selectedCarData?.carBookingData?.car?.CarImages != "" ?
                      <Image
                        width={"130px"}
                        height={"80"}
                        src={
                          BASE_URL +
                          selectedCarData?.carBookingData?.car?.CarImages?.substring(1)
                        }
                        alt="cars"
                      /> : <Image
                        width={"130px"}
                        src={NoImage}
                        alt="cars"
                      />}
                  </div>
                  {/* {console.log(selectedCarData?.carBookingData?.car?.CarImages.substring(1), "img")} */}

                </Col>
                <Col md={20}>
                  <Row className="info-car" gutter={[16, 16]}>
                    {/* <Col md={6} sm={6} xs={24}>
                    <p className="busname">
                      {selectedCarData.carBookingData.car
                        ? selectedCarData.carBookingData.car.CarName
                        : ""}
                    </p>
                  </Col> */}
                    <Col md={9} sm={9} xs={24}>
                      {/* {console.log(selectedCarData, "cartype")} */}
                      <p className="Cartype-t">
                        {selectedCarData.carBookingData.carType ? selectedCarData.carBookingData.carType.CarType : ""}
                      </p>
                      <p className="Cartype-i">
                        {selectedCarData?.carBookingData.carType ? selectedCarData?.carBookingData.carType.CarDescription : ""}
                      </p>
                    </Col>
                    <Col md={10} sm={12} xs={24}>
                      <div className="fromto_wrapper">
                        <p className="fromto">
                          {carBookingData?.pickUpLocation
                            ? carBookingData.pickUpLocation
                            : selectedCarData.carBookingData.Source
                              ? selectedCarData.carBookingData.Source
                              : ""}
                        </p>
                        {carBookingData?.Destination === null ? "" :
                          <i className="fa fa-arrow-right"></i>
                        }
                        <p className="fromto">
                          {carBookingData?.dropLocation
                            ? carBookingData.dropLocation
                            : selectedCarData.carBookingData.Destination
                              ? selectedCarData.carBookingData.Destination
                              : ""}
                        </p>
                      </div>
                      <Tooltip className="car-info-pop" title={selectedCarData.carBookingData?.DisplayInfoForUser}   >
                        <p className="Cartype-i"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                          {selectedCarData.carBookingData?.DisplayInfoForUser}</p>
                      </Tooltip>
                    </Col>
                    {/* <Col md={8} sm={6} xs={24}>
                    <div className="journeyDate text-right">
                      Journey Date:{" "}
                      <span style={{color:"#35459c"}}>
                      {moment(selectedCarData.carBookingData.FromDate).format(
                        "DD-MM-YYYY"
                      )}
                      </span>
                    </div>
                  </Col> */}
                  </Row>
                  <div className="hr"></div>
                  {/* <Row gutter={[16, 16]}>
                    <Col md={16} sm={10} xs={24}>
                      {carPassengerData.length > 0 ? (
                        <div className="points_wrapper">
                          <div className="boarding_wrapper">
                            <p className="point">PickUp Address</p>

                            <p className="loc">
                              {carBookingData?.PickUpLocation
                                ? carBookingData?.PickUpLocation
                                : ""}
                            </p>
                          </div>

                          <i className="fa fa-arrow-right"></i>

                          <div className="dropping_wrapper">
                            <p className="point">Droping Address</p>
                            <p className="loc">
                              {carBookingData?.DroppingLocation
                                ? carBookingData?.DroppingLocation
                                : ""}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </Col>

                    {/* <Col md={9} sm={9} xs={24}>
                <p className="busType">
                  {selectedCarData.carBookingData.carType ? selectedCarData.carBookingData.carType : ""}
                </p>
                <div className="opdetail">
                  <p className="optitle">Operator-Contact </p>
                  <p className="text">
                    {selectedCarData.carBookingData.contactNumber
                      ?selectedCarData.carBookingData.contactNumber
                      : "-"}
                  </p>
                </div>
              </Col> */}
                  {/* <Col md={8} sm={5} xs={24}>
                    <p className="pricetitle">
                      {/* {i === 0 ? "Onward" : "Return"} Trip Fare *
                      Trip Fare
                    </p>
                    <p className="price">
                      {"₹ " +
                        (Number(selectedCarData.carBookingData.BaseFare ?? 0) +
                          Number(
                            selectedCarData.carBookingData.StateTollTax ?? 0
                          ) +
                          Number(selectedCarData.carBookingData.GST ?? 0) +
                          Number(
                            selectedCarData.carBookingData.DriverAllowance ?? 0
                          ) -
                          Number(promoData.Discount ?? 0))}
                    </p>
                    <p className="infotext">Including GST</p>
                  </Col> *
                  </Row> */}
                  <Row gutter={[16, 16]}>
                    <Col md={8} sm={12} xs={24}>
                      <div className="fromto_wrapper">
                        <div>
                          <p><i class="fa fa-map-marker" aria-hidden="true"></i>
                            {" "}Pick-up :</p>
                          <p className="fromto">
                            {carBookingData?.PickUpLocation
                              ? carBookingData.PickUpLocation
                              : selectedCarData.carBookingData.Source
                                ? selectedCarData.carBookingData.Source
                                : ""}
                          </p>
                        </div>
                        {carBookingData?.Destination === null ? "" :
                          <div>
                            <p>
                              <i class="fa fa-map-marker" aria-hidden="true"></i> {" "}
                              Drop-of :
                            </p>
                            <p className="fromto">
                              {carBookingData?.DroppingLocation
                                ? carBookingData.DroppingLocation
                                : selectedCarData.carBookingData.Destination ?
                                  selectedCarData.carBookingData.Destination
                                  : ""}
                            </p>
                          </div>}
                      </div>
                    </Col>
                    <Col className="car-drv-info" md={8} sm={10} xs={24}>
                      <div className="car-driver">
                        <p className="dri-1"><i class="fa fa-taxi" aria-hidden="true"></i> {" "}
                          Driver :</p> {" "}
                        <p className="dri-2">{selectedCarData?.carBookingData?.driver ? selectedCarData?.carBookingData?.driver.Name : ""}</p>
                      </div>
                      <div className="car-driver">
                        <p className="dri-1"><i class="fa fa-phone" aria-hidden="true"></i> {" "}
                          Phone :</p>{" "}
                        <p className="dri-2">
                          {selectedCarData?.carBookingData?.driver ? selectedCarData?.carBookingData?.driver.MobileNo : ""}

                        </p>
                      </div>
                    </Col>
                    {selectedCarData?.carBookingData?.car ?
                      <Col md={8} sm={10} xs={24}>
                        <div className="amme">
                          <p className="am-1"><i class="fa fa-handshake-o" aria-hidden="true"></i>{" "}
                            Amenities :</p>{" "} {selectedCarData?.carBookingData?.car?.ACAvailable === true ?
                              <p className="am-2"><i class="fa fa-snowflake-o" aria-hidden="true"></i>{" "} A/c</p> : ""}
                        </div>
                        <div>
                          <p className="am-2">{selectedCarData?.carBookingData?.car?.extraAmenities}</p>
                        </div>

                      </Col> : null}
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      ) : null}
    </div>
  );
};

export const TransferDetails = (selectedCarData) => {
  const {
    state: {
      carBookingData,
      carPassengerData,
      otherData: { promoData },
    },
  } = useContext(GlobalStatesContext);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let [canclePolicy, setCanclePolicy] = useState(false);
  const cancePolicy = () => {
    setCanclePolicy(true);
    // setShowCancleModal(true);
  };
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    // <div className="busDetails_wrapper">
    //   {console.log(selectedCarData, "tra")}
    //   {selectedCarData.carBookingData ? (
    //     <div className="card_wrapper">
    //       <Card>
    //         <Row className="tran-car">
    //             <p className="car-type-tr">
    //               {selectedCarData.carBookingData.TransferName
    //                 ? selectedCarData.carBookingData.TransferName
    //                 : ""}
    //             </p>
    //           <Col md={6}>
    //             <div className="carImage">
    //               {selectedCarData?.carBookingData?.car?.CarImages ? (
    //                 <Image
    //                   width={100}
    //                   src={
    //                     BASE_URL +
    //                     selectedCarData.carBookingData.car.CarImages.substring(
    //                       1
    //                     )
    //                   }
    //                 />
    //               ) : (
    //                 <Image
    //                   width={100}
    //                   src={
    //                     require("../../../assets/images/carrental/car-rental-re-1.jpg")
    //                       .default
    //                   }
    //                 />
    //               )}
    //             </div>
    //           </Col>
    //           <Col md={18}>
    //           <div className="fromto_wrapper">
    //                   <p className="from">
    //                     {selectedCarData.carBookingData?.pickUpLocation
    //                       ? selectedCarData.carBookingData.pickUpLocation
    //                       : ""}
    //                   </p>

    //                   <i className="fa fa-arrow-right"></i>

    //                   <p className="to">
    //                     {selectedCarData.carBookingData?.dropLocation
    //                       ? selectedCarData.carBookingData.dropLocation
    //                       : ""}
    //                   </p>
    //                 </div>
    //           </Col>
    //           {/*  */}
    //         </Row>
    //       </Card>
    //     </div>
    //   ) : null}
    // </div>
    <div className="busDetails_wrapper">
      {selectedCarData?.carBookingData ? (
        <div className="card_wrapper">
          {/* {console.log(carBookingData, "car")} */}
          <Card>
            <div className="check-car">
              <p className="carname">
                {selectedCarData?.carBookingData.TransferName
                  ? selectedCarData?.carBookingData?.TransferName?.toUpperCase()
                  : "Car 4 U"}
              </p>
              <Row gutter={16}>
                <Col md={4}>
                  <div className="carImage">
                    {selectedCarData?.carBookingData?.Vehicles[0]?.CarImages ?
                      <Image
                        width={"130px"}
                        height={"80"}
                        src={
                          BASE_URL +
                          selectedCarData?.carBookingData?.Vehicles[0]?.CarImages?.substring(1)
                        }
                        alt="cars"
                      /> : <Image
                        width={"130px"}
                        src={NoImage}
                        alt="cars"
                      />}
                  </div>
                  {/* {console.log(selectedCarData?.carBookingData?.car?.CarImages.substring(1), "img")} */}

                </Col>
                <Col md={20}>
                  <Row className="info-car" gutter={[16, 16]}>
                    {/* <Col md={6} sm={6} xs={24}>
                  <p className="busname">
                    {selectedCarData.carBookingData.car
                      ? selectedCarData.carBookingData.car.CarName
                      : ""}
                  </p>
                </Col> */}
                    <Col md={8} sm={9} xs={24}>
                      {/* {console.log(selectedCarData, "cartype")} */}
                      <p className="Cartype-t">
                        {selectedCarData?.carBookingData.type ? selectedCarData?.carBookingData?.type?.toUpperCase() : ""}
                      </p>
                      <p className="Cartype-i">
                        <span>Journey Date: </span>
                        {selectedCarData?.carBookingData?.PickUp?.PickUpDate ? selectedCarData?.carBookingData?.PickUp?.PickUpDate : ""}
                      </p>
                    </Col>
                    <Col md={14} sm={12} xs={24}>
                      <div className="fromto_wrapper">
                        <p className="from">
                          {carBookingData?.PickUp?.PickUpDetailName
                            ? carBookingData?.PickUp?.PickUpDetailName
                            : selectedCarData?.carBookingData?.pickUpLocation
                              ? selectedCarData?.carBookingData?.PickUpName
                              : ""}
                        </p>
                        {carBookingData?.Destination === null ? "" :
                          <i className="fa fa-arrow-right"></i>
                        }
                        <p className="from">
                          {carBookingData?.DropOff.DropOffDetailName
                            ? carBookingData.DropOff.DropOffDetailName
                            : selectedCarData.carBookingData.dropLocation
                              ? selectedCarData.DropOff.DropOffName
                              : ""}
                        </p>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        {/* <Tooltip className="car-info-pop" title={selectedCarData.carBookingData?.Condition?.[0]}   > */}
                          <p className="Cartype-i"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>{" "} Max : 
                            {selectedCarData.carBookingData?.Vehicles?.[0].VehicleMaximumPassengers + "Pax's"}</p>
                        {/* </Tooltip> */}

                        {/* <Tooltip className="car-info-pop" title={"From " + moment(selectedCarData.carBookingData?.Vehicles?.[0]?.TransferCancellationPolicy[0].FromDate).format("DD-MM-YYYY") + " To " + moment(selectedCarData.carBookingData?.Vehicles?.[0]?.TransferCancellationPolicy[0].ToDate).format("DD-MM-YYYY") + " Will be Charged"}   > */}
                          <p onClick={() => cancePolicy()} className="Cartype-i">
                            {/* <i class="fa fa-exclamation-circle" aria-hidden="true"></i>{" "} */}
                            Cancellation Policy</p>
                        {/* </Tooltip> */}
                      </div>
                    </Col>

                    {/* <Col md={8} sm={6} xs={24}>
                  <div className="journeyDate text-right">
                    Journey Date:{" "}
                    <span style={{color:"#35459c"}}>
                    {moment(selectedCarData.carBookingData.FromDate).format(
                      "DD-MM-YYYY"
                    )}
                    </span>
                  </div>
                </Col> */}
                  </Row>
                  <div className="hr"></div>
                  <Row style={{display:"flex",justifyContent:"space-between"}}>
                    <Col md={6} sm={10} xs={24}>

                      <div className="car-lug">
                        No.of Luggage's : <span style={{ color: "#35459c" }}>{selectedCarData.carBookingData?.Vehicles?.[0]?.VehicleMaximumLuggage}{" "}<i class="fa fa-suitcase" aria-hidden="true"></i></span>
                      </div>
                    </Col>
                    <Col md={7} sm={10} xs={24}>
                      <div style={{ whiteSpace: "nowrap" }} className="lang">
                        Language : <span style={{ color: "#35459c" }}>{selectedCarData.carBookingData?.Vehicles?.[0]?.Language}</span>
                      </div>
                    </Col>
                    <Col md={7} sm={10} xs={24}>
                      <div className="can">
                        Cancel Before : <span style={{ color: "#35459c" }}>{moment(selectedCarData.carBookingData?.Vehicles?.[0]?.LastCancellationDate).format("DD-MM-YYYY")}</span>
                      </div>
                    </Col>
                    {/* <Col md={4} sm={10} xs={24}>
                <div className="cancel">
                <Tooltip className="car-info-pop" title={"From " + moment(selectedCarData.carBookingData?.Vehicles?.[0]?.TransferCancellationPolicy[0].FromDate).format("DD-MM-YYYY") + " To " + moment(selectedCarData.carBookingData?.Vehicles?.[0]?.TransferCancellationPolicy[0].ToDate).format("DD-MM-YYYY") + " Will be Charged"}   >
                        <p className="Cartype-i"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                          Cancellation</p>
                      </Tooltip>
                </div>
                    </Col> */}

                    {/* <Col md={9} sm={9} xs={24}>
              <p className="busType">
                {selectedCarData.carBookingData.carType ? selectedCarData.carBookingData.carType : ""}
              </p>
              <div className="opdetail">
                <p className="optitle">Operator-Contact </p>
                <p className="text">
                  {selectedCarData.carBookingData.contactNumber
                    ?selectedCarData.carBookingData.contactNumber
                    : "-"}
                </p>
              </div>
            </Col> */}
                    {/* <Col md={8} sm={5} xs={24}>
                  <p className="pricetitle">
                    {/* {i === 0 ? "Onward" : "Return"} Trip Fare *
                    Trip Fare
                  </p>
                  <p className="price">
                    {"₹ " +
                      (Number(selectedCarData.carBookingData.BaseFare ?? 0) +
                        Number(
                          selectedCarData.carBookingData.StateTollTax ?? 0
                        ) +
                        Number(selectedCarData.carBookingData.GST ?? 0) +
                        Number(
                          selectedCarData.carBookingData.DriverAllowance ?? 0
                        ) -
                        Number(promoData.Discount ?? 0))}
                  </p>
                  <p className="infotext">Including GST</p>
                        </Col> */}
                  </Row>
                  {/* <Row gutter={[16, 16]}>
                  <Col md={8} sm={12} xs={24}>
                    <div className="fromto_wrapper">
                      <div>
                        <p><i class="fa fa-map-marker" aria-hidden="true"></i>
                          {" "}Pick-up :</p>
                        <p className="fromto">
                          {carBookingData?.PickUpLocation
                            ? carBookingData.PickUpLocation
                            : selectedCarData.carBookingData.Source
                              ? selectedCarData.carBookingData.Source
                              : ""}
                        </p>
                      </div>
                      {carBookingData?.Destination === null ? "" :
                        <div>
                          <p>
                            <i class="fa fa-map-marker" aria-hidden="true"></i> {" "}
                            Drop-of :
                          </p>
                          <p className="fromto">
                            {carBookingData?.DroppingLocation
                              ? carBookingData.DroppingLocation
                              : selectedCarData.carBookingData.Destination ?
                                selectedCarData.carBookingData.Destination
                                : ""}
                          </p>
                        </div>}
                    </div>
                  </Col>
                  <Col className="car-drv-info" md={8} sm={10} xs={24}>
                    <div className="car-driver">
                      <p className="dri-1"><i class="fa fa-taxi" aria-hidden="true"></i> {" "}
                        Driver :</p> {" "}
                      <p className="dri-2">{selectedCarData?.carBookingData?.driver ? selectedCarData?.carBookingData?.driver.Name : ""}</p>
                    </div>
                    <div className="car-driver">
                      <p className="dri-1"><i class="fa fa-phone" aria-hidden="true"></i> {" "}
                        Phone :</p>{" "}
                      <p className="dri-2">
                        {selectedCarData?.carBookingData?.driver ? selectedCarData?.carBookingData?.driver.MobileNo : ""}

                      </p>
                    </div>
                  </Col>
                  {selectedCarData?.carBookingData?.car ?
                    <Col md={8} sm={10} xs={24}>
                      <div className="amme">
                        <p className="am-1"><i class="fa fa-handshake-o" aria-hidden="true"></i>{" "}
                          Amenities :</p>{" "} {selectedCarData?.carBookingData?.car?.ACAvailable === true ?
                            <p className="am-2"><i class="fa fa-snowflake-o" aria-hidden="true"></i>{" "} A/c</p> : ""}
                      </div>
                      <div>
                        <p className="am-2">{selectedCarData?.carBookingData?.car?.extraAmenities}</p>
                      </div>

                    </Col> : null}
                </Row> */}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      ) : null}
      <Modal
        title="Car Details"
        className="promo-modal-header modal-header-bg12"
        visible={canclePolicy}
        onOk={(e) => setCanclePolicy(false)}
        onCancel={(e) => setCanclePolicy(false)}
        footer={null}
        width={"1100px"}
      >
        <div className="car-data-mo">

          <Tabs>
            <TabPane tab="Car Pickup " key="1">

              {selectedCarData?.carBookingData?.Description ?
                <div className="info">
                  <p>{ReactHtmlParser(selectedCarData?.carBookingData?.Description)}</p>
                  <p><i class="fa fa-asterisk" aria-hidden="true"></i>{" "}{selectedCarData.carBookingData?.Condition?.[0]}</p>
                </div> :
                <div className="info">
                  <span style={{ color: "#bd0c21" }}>Remember : </span>{selectedCarData.carBookingData?.Condition?.[0]}
                </div>}
            </TabPane>
            <TabPane tab="Cancellation Policy" key="2">
              <div className="table-container">
                <table className="my-table">
                  <thead>
                    <tr>
                      {/* <th>Charge</th>
                          <th>Charge Type</th>
                          <th>Currency</th> */}
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Charge</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCarData.carBookingData?.Vehicles[0].TransferCancellationPolicy?.map((item, index) => (
                      <tr key={index}>
                        {/* <td>{item.Charge}</td> */}
                        {/* <td>{item.ChargeType}</td> */}
                        {/* <td>{item.Currency}</td> */}
                        <td>{moment(item.FromDate).format("DD-MM-YYYY")}</td>
                        <td>{moment(item.ToDate).format("DD-MM-YYYY")}</td>
                        {/* <td>{item.ToDate}</td> */}
                        <td>{activeCurrency} {currencyValue(item.Charge)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ marginTop: "5px" }}>
                  <span style={{ color: "#bd0c21" }}>Remember : </span>Cancellation will be as per Company Policy, Refer to <span style={{ color: "#35459c" }}>Policy</span> for more info
                </div>
              </div>
            </TabPane>
            <TabPane tab="Policy" key="3">
              <div>
                <h6 style={{ color: "#35459c" }}>Cancellation Policy :</h6>
                <p>Please be advised that our cancellation policy is currently in accordance with IST (Indian Standard Time) and follows the guidelines set forth by our company. However, it is important to note that the cancellation policy may be subject to change prior to any given transaction or reservation.
                  Our company reserves the right to modify, amend, or update the cancellation policy at its sole discretion. Any changes made to the cancellation policy will be effective immediately upon posting on our website or notification through other communication channels.
                  We understand the importance of transparency and will make reasonable efforts to communicate any modifications to the cancellation policy in a timely manner. We recommend reviewing our cancellation policy regularly to stay informed of any updates.
                  If you have any questions or concerns regarding our cancellation policy, please contact our customer support team for clarification.
                  Thank you for your understanding and cooperation.</p>
                <p style={{ color: "#bd0c21" }}>demooutc</p>
              </div>
            </TabPane>
            {/* <TabPane tab="Car Inspection" key="3">
                  {ReactHtmlParser(
                    carObj.HowToInspectTheCar ?? "No Information Found"
                  )}
                </TabPane>
                <TabPane tab="Terms & Conditions" key="4">
                  {ReactHtmlParser(
                    carObj.TermsAndConditions ?? "No  Information Found"
                  )}
                </TabPane>
                <TabPane tab="Required Documents" key="5">
                  {ReactHtmlParser(
                    carObj.RequiredDocumentsForPickup ?? "No  Information Found"
                  )}
                </TabPane> */}
          </Tabs>
        </div>
      </Modal>
    </div>
  );
};
