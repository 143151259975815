import React, { useState, useEffect } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import { useLocalStorage } from "../../helpers/useStorage";
import { useSessionStorage } from "../../helpers/useStorage";

import { message } from "antd";

let dateFormat = "YYYY-MM-DD";

const defCombinedSearch = {
  tripType: "oneWay",
  classType: "Economy",
  origin: [],
  destination: [],
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: "",
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
};

const GlobalStatesProvider = (props) => {
  const [busPersist, setBusPersist] = useLocalStorage("busPersist", {
    busSearchObj: {
      from: null,
      to: null,
      departureDate: new Date(),
    },
    selectedBusData: [],
  });

  const [busPassengerData, setBusPassengerData] = useState([]);
  const [busBookingResp, setBusBookingResp] = useState([]);
  const [busSearchResultObj, setBusSearchResultObj] = useState({});
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [otherData, setOtherData] = useState({
    promoData: { status: false, Discount: 0 },
    ConvFee: { status: false, amount: 0 },
    insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    redeemAmount: { CouponAmt: 0, status: false },
    mealData:[],
    baggageData:[],
    seatData:[]
  });

  const validatePromoCode = (req) => {
    ApiClient.post("admin/validate/promo", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            promoData: {
              Code: res.data.Code,
              Discount: res.data.Discount,
              DiscountType: res.data.DiscountType,
              FromAmount: res.data.FromAmount,
              ToAmount: res.data.ToAmount,
              PromoID: res.data.PromoID,
              status: true,
            },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => {});
  };

  const validateRedeemCoupon = (req) => {
    ApiClient.post("admin/validateCouponAmt", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            redeemAmount: { CouponAmt: res.appliedCouponAmt, status: true },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => {});
  };
  const getAllServiceType = () => {
    setServiceTypeList([]);
    ApiClient.get(`admin/services`)
      .then((res) => {
        if (res.status === 200) {
          setServiceTypeList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const RemovePromoConvFee = () => {
    setOtherData({
      promoData: { status: false, Discount: 0 },
      ConvFee: { status: false, amount: 0 },
      insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      redeemAmount: { CouponAmt: 0, status: false },
    });
  };

  const RemovePromo = () => {
    setOtherData((prev) => ({
      ...prev,
      promoData: { status: false, Discount: 0 },
    }));
  };

  const RemoveRedeemCoupon = () => {
    setOtherData((prev) => ({
      ...prev,
      redeemAmount: { CouponAmt: 0, status: false },
    }));
  };

  const AddConvFee = (type) => {
    ApiClient.get(`admin/conveniencefeebyservicetype/${type}`)
      .then((res) => {
        if (res.status === 200) {
          let convobj = {
            id: res.data.ID,
            status: true,
            amount: res.data.Value,
            type: res.data.ConvenienceFeeType,
          };
          setOtherData((prev) => ({
            ...prev,
            ConvFee: convobj,
          }));
        }
      })
      .catch((error) => {});
  };

  const getInsuranceByServiceType = (type) => {
    ApiClient.get(`admin/getInsuranceByserviceType?serviceType=${type}`)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            insuranceData: resp.data,
          }));
        } else {
          setOtherData((prev) => ({
            ...prev,
            insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
          }));
        }
      })
      .catch((err) => err);
  };

  // PART PAYMENT

  const [partPayment, setPartPayment] = useState({
    isPartPayment: false,
    ServiceType: 1,
    PartPaymentPercentage: 0,
  });

  const getPartPayment = (type, value) => {
    if (type == 2) {
      let obj = {
        BusinessType: value.BusinessType,
        ServiceType: value.ServiceType,
      };
      ApiClient.post("admin/partPayment", obj)
        .then((resp) => {
          if (resp.status == 200) {
            let catId = resp?.data?.CategoryID
              ? resp?.data?.CategoryID.split(",")
                  .map((item) => {
                    if (item) {
                      return parseInt(item);
                    }
                  })
                  .filter((item) => item)
              : [];
            let data = {
              ...resp.data,
              isPartPayment: true,
              ServiceType: resp.data.ServiceType,
              PartPaymentPercentage: parseFloat(
                resp.data.PartPaymentPercentage
              ),
              CategoryID: catId,
              categories: resp?.data?.categories ?? [],
            };
            setPartPayment(data);
          } else {
            setPartPayment({
              ...partPayment,
              isPartPayment: false,
              PartPaymentPercentage: 0,
            });
            message.error("Part Payment Not Availale For The Moment", 3);
          }
        })
        .catch((error) => {
         });
    } else {
      setPartPayment({
        ...partPayment,
        isPartPayment: false,
        PartPaymentPercentage: 0,
      });
    }
  };

  //Hydrating Bus Data from localstorage

  //Hydrating Combined Data from localstorage
  const combinedPersistJson =
    JSON.parse(localStorage.getItem("combinedPersist")) || {};
  let initCombinedSearch =
    combinedPersistJson.combineSearchData || defCombinedSearch;

  const [airlineMatrixReset, setAirlineMatrixReset] = useState(false);

  const [tourPassangerData, setTourPassangerData] = useState([]);

  const [combineSearchData, setCombineSearchData] =
    useState(initCombinedSearch);

  useEffect(() => {
    //Bus Data in localstorage
    localStorage.setItem(
      "combinedPersist",
      JSON.stringify({
        combineSearchData: combineSearchData,
      })
    );
  }, [combineSearchData]);
  //carContext
  const [carBookingData, setCarBookingData] = useLocalStorage(
    "carBookingData",
    {}
  );
  const [charterFlightSearchResults, setCharterFlightSearchResults] =
    useLocalStorage("charterFlightSearchResult", {});

  const [carPersist, setCarPersist] = useLocalStorage("carPersist", {
    cityName: "",
    cityId: "",
    result: {
      Airport: "[]",
      Hotel: "[]",
      RailwayStation: "[]",
    },
  });

  const [visaPersist, setVisaPersist] = useLocalStorage("visaPersist", {
    Id: "",
    VisaName: "",
    VisaType: "",
    embassyDetails: {},
    visaFaq: [],
    docsReqForVisa: [],
  });
  const [carPassengerData, setCarPassengerData] = useState([]);
  const [carBookingResp, setCarBookingResp] = useState([]);
  const [carSearchResultObj, setCarSearchResultObj] = useState({});

  const [visaSearchResultObj, setVisaSearchResultObj] = useState({});

  const [visaSearchObj, SetVisaSearchObj] = useState({});

  const [visaBookingData, setVisaBookingData] = useLocalStorage(
    "visaBookingData",
    {}
  );
  const [carSearchObj, setCarSearchObj] = useState({});
  const [holidaySearchResultObj, setHolidaySearchResultObj] = useState({});
  const [holidayBookingResp, setHolidayBookingResp] = useState([]);
  const [holidayBookingData, setHolidayBookingData] = useLocalStorage(
    "holidayBookingData",
    {}
  );
  const [charterFlightBookingData, setCharterFlightBookingData] =
    useLocalStorage("charterFlightBookingData", {});

  const [cruiseSearchResultObj, setCruiseSearchResultObj] = useState({});
  const [cruiseBookingResp, setCruiseBookingResp] = useState([]);
  const [cruiseBookingData, setCruiseBookingData] = useLocalStorage(
    "cruiseBookingData",
    {}
  );
  const [cruiseCheckoutData, setCruiseCheckoutData] = useLocalStorage(
    "cruiseCheckoutData",
    {}
  );

  const [flightFilters, setFlightFilters] = useState({});

  return (
    <GlobalStatesContext.Provider
      value={{
        state: {
          busPersist,
          busPassengerData,
          busBookingResp,
          combineSearchData,
          busSearchResultObj,
          otherData,
          tourPassangerData,
          airlineMatrixReset,
          partPayment,
          carBookingData,
          carPassengerData,
          carBookingResp,
          carSearchResultObj,
          carSearchObj,
          carPersist,
          visaSearchResultObj,
          visaSearchObj,
          visaBookingData,
          holidaySearchResultObj,
          holidayBookingResp,
          holidayBookingData,
          charterFlightSearchResults,
          charterFlightBookingData,
          cruiseSearchResultObj,
          cruiseBookingResp,
          cruiseBookingData,
          cruiseCheckoutData,
          flightFilters,
          serviceTypeList
        },
        ResetAirlineMatrix: (val) => {
          setAirlineMatrixReset(val);
        },

        validatePromoCode: (val) => {
          validatePromoCode(val);
        },

        getPartPayment: (e, val) => {
          getPartPayment(e, val);
        },
        AddConvFee: (v) => {
          AddConvFee(v);
        },
        getInsuranceByServiceType: (v) => {
          getInsuranceByServiceType(v);
        },
        updateOtherData: (val) => {
          setOtherData(val);
        },
        setSelectedInsuranceData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            selectedInsuranceData: val,
          }));
        },
        UpdateMealData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            mealData: val,
          }));
        },
        UpdateBaggageData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            baggageData: val,
          }));
        },
        UpdatedSeatData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            seatData: val,
          }));
        },
        RemovePromo: () => {
          RemovePromo();
        },
        RemoveRedeemCoupon: () => {
          RemoveRedeemCoupon();
        },
        RemovePromoConvFee: () => {
          RemovePromoConvFee();
        },
        setBusSearchObj: (searchObj) => {
          setBusPersist((prev) => ({
            ...prev,
            busSearchObj: searchObj,
          }));
        },
        updateBusSearchResultObj: (busSearchResp) => {
          setBusSearchResultObj(busSearchResp);
        },
        updateSelectedBusData: (selectedbusobj) => {
          setBusPersist((prev) => ({
            ...prev,
            selectedBusData: [selectedbusobj],
          }));
        },
        updateBusPassengerData: (obj) => {
          setBusPassengerData(obj);
        },

        updateBusBookingData: (respObj) => {
          setBusBookingResp(respObj);
        },

        updataCombineSearchObj: (attribute, value) => {
          setCombineSearchData({ ...combineSearchData, [attribute]: value });
        },

        updateTourPassangerData: (obj) => {
          setTourPassangerData(obj);
        },
        updateSelectedCarData: (selectedcarobj) => {
          setCarBookingData(selectedcarobj);
        },
        updateCarPersist: (obj) => {
          setCarPersist(obj);
        },
        updateCarPassengerData: (obj) => {
          setCarPassengerData(obj);
        },
        updateCarSearchResultObj: (carSearchResp) => {
          setCarSearchResultObj(carSearchResp);
        },
        updateCarSearchObj: (carSearchResp) => {
          setCarSearchObj(carSearchResp);
        },
        updateCarBookingData: (respObj) => {
          setCarBookingResp(respObj);
        },
        validateRedeemCoupon: (val) => {
          validateRedeemCoupon(val);
        },
        updateVisaSearchResultObj: (visaSearchResp) => {
          setVisaSearchResultObj(visaSearchResp);
        },
        updateVisaSearchObj: (visaSearchObj) => {
          SetVisaSearchObj(visaSearchObj);
        },
        updateSelectedVisaData: (visaSelectedObj) => {
          setVisaBookingData(visaSelectedObj);
        },
        updateVisaPersist: (obj) => {
          setVisaPersist(obj);
        },
        updateHolidaySearchResultObj: (holidaySearchResp) => {
          setHolidaySearchResultObj(holidaySearchResp);
        },
        updateSelectedholidayData: (selectedobj) => {
          setHolidayBookingData(selectedobj);
        },
        updateHolidayBookingData: (respObj) => {
          setHolidayBookingResp(respObj);
        },
        updateCharterFlightSearchRseultsObj: (searchObj) => {
          setCharterFlightSearchResults(searchObj);
        },
        updateCharterFlightBookingDataObj: (respObj) => {
          setCharterFlightBookingData(respObj);
        },
        updateCruiseSearchResultObj: (cruiseSearchResp) => {
          setCruiseSearchResultObj(cruiseSearchResp);
        },
        updateSelectedCruiseData: (selectedobj) => {
          setCruiseBookingData(selectedobj);
        },
        updateCruiseCheckoutData: (selectedobj) => {
          setCruiseCheckoutData(selectedobj);
        },
        updateCruiseBookingData: (respObj) => {
          setCruiseBookingResp(respObj);
        },
        updateFlightFilterForSlider: (flightFilteres) => {
          setFlightFilters(flightFilteres);
        },
        getAllServiceType:()=>{
          getAllServiceType();
        }
      }}
    >
      {props.children}
    </GlobalStatesContext.Provider>
  );
};
export default GlobalStatesProvider;
