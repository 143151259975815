import { React, useState, useEffect } from "react";
import { Card, message, Skeleton } from "antd";
import "./PaymentFailure.scss";
import ApiClient from "../../helpers/ApiClient";
import queryString from "query-string";

function PaymentFailure() {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentResp, setPaymentResp] = useState();

  const getPaymentByRefNo = (orderId) => {
    ApiClient.get("admin/pgOrdersByRefNo/" + orderId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
         
          setPaymentResp(resp.data);
        } else {
          message.error("Server Error: " + resp.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const params = queryString.parse(document.location.search);
    getPaymentByRefNo(params.ref);
  }, []);

  return (
    <div className="pgFailureWrapper">
      {isLoading ? (
        <Card>
          <h2 className="title">Your payment failed !!</h2>
          <Skeleton active />
        </Card>
      ) : (
        <Card>
          <h2 className="title">Your payment failed !!</h2>
          <p className="subtitle">
            Please call or write to our customer care team for more details{" "}
            <span className="mail"> support@beta.outc.in </span>. Please note your
            reference ID:{" "}
            {paymentResp && Object.keys(paymentResp).length > 0 ? (
              <>
                <span className="tid">{paymentResp.OrderId}</span> 
                
              </>
            ) : (
              ""
            )}
          </p>
        </Card>
      )}
    </div>
  );
}

export default PaymentFailure;
