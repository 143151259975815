import { Button, Col, Collapse, Modal, Row, Grid } from "antd";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import Cars from "../../../components/car-search/Car";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
import ApiClient from "../../../helpers/ApiClient";
import CarsFilter from "./CarsFilter/CarsFilter";
import TransferCard from "./CarsResultCards/TransferCard";
import CarSort from "./../Carsort/Carsort";
import CarSkeleton from "./Skeleton/CarSkeleton";
import { useSytContext } from "../../../common/providers/SytProvider";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const TransferResults = () => {
  const {
    state: { carSearchResultObj },
    updateSelectedCarData,
    updateCarBookingData,
    updateCarSearchResultObj,
  } = useContext(GlobalStatesContext);
  const { user } = useAuthContext();
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  let CarInitial = {
    cars: [],
    filtersObj: {
      price: {
        minPrice: 0,
        maxPrice: 0,
        maxPriceRange: 0,
        minPriceRange: 0,
      },
      timings: [
        {
          id: "day",
          start: "06:00",
          end: "17:59",
          label: "Day",
          isChecked: false,
        },
        {
          id: "night",
          start: "18:00",
          end: "05:59",
          label: "Night",
          isChecked: false,
        },
      ],

      carType: [],
      pickUpLocation: [],
      droppingLocation: [],
    },
    origin: "",
    destination: "",
  };
  const { carSearchBanners } = useSytContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);

  const showModalbusfilters = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalbussort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };
  const [carResultsList, setCarResultsList] = useState(CarInitial);

  const [isLoading, setIsLoading] = useState(true);

  /* Car Search Availability */
  const getCarsAvailability = () => {
    let param = queryString.parse(document.location.search);
    let origin = param?.sourceName;
    let destination = param?.destinationName;
    if (param.TravelType == 3) {
      fetchTransferAvaility(param, origin, destination, 3);
    }
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const fetchTransferAvaility = (param, origin, destination, type) => {
    let formData = {
      AdultCount: param.AdultCount,
      AlternateLanguage: param.AlternateLanguage,
      ChildCount: param.ChildCount,
      CityId: param.CityId,
      CountryCode: param.CountryCode?.trim(),
      DropOffCode: param.DropOffCode,
      DropOffPointCode: param.DropOffPointCode,
      PickUpCode: param.PickUpCode,
      PickUpPointCode: param.PickUpPointCode,
      PreferredCurrency: param.PreferredCurrency,
      PreferredLanguage: param.PreferredLanguage,
      TransferDate: param.TransferDate,
      TransferTime: param.TransferTime,
      ChildAge: [],
      TravelType: param.TravelType,
      roleType: user?.Role?.RoleId ?? 4,
    };
    ApiClient.post("transfers/Search", formData)
      .then((res) => {
        if (res.statusCode === 200) {
          let filterData = filterObjectResponse({
            formData: param,
            response: res.data.TransferSearchResults,
            origin: origin,
            destination: destination,
            type: type,
            searchId: res?.data?.searchId,
            traceId: res?.data?.TraceId
          });

          updateCarSearchResultObj(filterData);
          setCarResultsList(filterData.data);
        } else {
          setCarResultsList(CarInitial);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setCarResultsList(CarInitial);
      });
  };
  const filterObjectResponse = ({
    formData,
    response,
    origin,
    destination,
    type,
    searchId,
    traceId
  }) => {
    let filtersObj = { ...CarInitial.filtersObj };

    let cars = response.map((car, i) => {
      let startingFare =
        car.Vehicles.length > 0
          ? Number(car.Vehicles[0].TransferPrice.PublishedFares)
          : 0;

      if (i === 0) {
        filtersObj.price.minPrice = startingFare;
      }
      let minFare = startingFare;
      if (startingFare > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
          startingFare;
      }

      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;

      const { Vehicle } = car.Vehicles[0];
      checkIfExist(filtersObj.carType, Vehicle) &&
        filtersObj.carType.push({
          id: Vehicle,
          label: Vehicle,
          isChecked: false,
        });

      const { PickUpDetailName } = car.PickUp;
      checkIfExist(filtersObj.pickUpLocation, PickUpDetailName) &&
        filtersObj.pickUpLocation.push({
          id: PickUpDetailName,
          label: PickUpDetailName,
          isChecked: false,
        });

      const { DropOffDetailName } = car.DropOff;
      checkIfExist(filtersObj.droppingLocation, DropOffDetailName) &&
        filtersObj.droppingLocation.push({
          id: DropOffDetailName,
          label: DropOffDetailName,
          isChecked: false,
        });

      return {
        ...car,
        searchId,
        traceId,
        isVisible: true,
        pickUpLocation: formData?.pickUpLocation ? formData.pickUpLocation : "",
        dropLocation: formData?.dropLocation ? formData.dropLocation : "",
      };
    });
    // console.log("venu",response)
    return (response = {
      data: {
        ...response,
        cars: cars,
        origin: origin,
        destination: destination,
        journeyDate: formData.fromDate,
        filtersObj: filtersObj,
      },
      tripType: type,
    });
  };

  useEffect(() => {
    getCarsAvailability();
  }, []);

  let bannreIndex = -1;
  return (
    <>
      <div className="combined-results-container car-body-res">
        <section className="hotels_modify  buses-div-bg">
          <div className="hotel-modify-container">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={
                  <span className="hotels-hide-search">Modify Search</span>
                }
                key="1"
              >
                <div className="search-modify-block bus-res-cc2">
                  <Cars
                    modifySearch={true}
                    carSearchAPI={getCarsAvailability}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        </section>

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container filters-number-tab">
            <Row
              gutter={16}
              className="margins-alignments"
              style={{ padding: "14px 14px" }}
            >
              {isLoading ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <FilterSkeleton />
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    <CarSkeleton />
                  </Col>
                </>
              ) : 
              // Object.keys(carSearchResultObj).length > 0 &&
                carSearchResultObj?.data &&
                carSearchResultObj?.data?.cars?.length > 0 ? (
                <>
                  <Col className="Transfer-filters-fun">
                    <CarsFilter
                      carSearchResultObj={carSearchResultObj}
                      setCarResultsList={setCarResultsList}
                      type={"transfer"}
                    />
                  </Col>
                  <Col className="Transfer-resut-fun">
                    <Row className="sorting-car-v1">
                      <Col md={24} xs={24}>
                        <CarSort
                          carResultObj={carSearchResultObj}
                          setCarResultsList={setCarResultsList}
                          type={"transfer"}
                        />
                      </Col>
                    </Row>
                    {carResultsList.cars.length > 0 ? (
                      carResultsList.cars.map((carObj, key) => {
                        return (
                          <>
                            {/* {key != 0 &&
                              key % 3 == 0 &&
                              carSearchBanners[++bannreIndex] && (
                                <div className="combine-roundtrip-view">
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "90px",
                                      objectFit: "contain",
                                    }}
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      carSearchBanners[
                                        bannreIndex
                                      ].path.substring(1)
                                    }
                                    data={
                                      carSearchBanners[bannreIndex + 1]
                                        ? null
                                        : (bannreIndex = -1)
                                    }
                                  />{" "}
                                </div>
                              )} */}

                            <TransferCard
                              key={key}
                              carObj={carObj}
                              updateSelectedCarData={updateSelectedCarData}
                              updateCarBookingData={updateCarBookingData}
                            />
                          </>
                        );
                      })
                    ) : (
                      <Col md={24} sm={24} xs={24}>
                        <CustomNoResultFound title={"No Cars Available"} />
                      </Col>
                    )}
                  </Col>
                </>
              ) : (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Cars Available"} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalbusfilters}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
          <div></div>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalbussort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Car Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
          </div>,
        ]}
      >
        <Row>
        <Col md={24} xs={24}>
        <CarsFilter
        carSearchResultObj={carSearchResultObj}
        setCarResultsList={setCarResultsList}
        type={"transfer"}
        />
        </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
          </div>,
        ]}
      >
        <Row>
        <Col md={24} xs={24}>
        <CarSort
        carResultObj={carSearchResultObj}
        setCarResultsList={setCarResultsList}
        type={"transfer"}
        />
        </Col>
        </Row>
      </Modal>
    </>
  );
};

export default TransferResults;
