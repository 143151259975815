

import React, { useState, useEffect } from 'react';
import PaymentRedirect from './paymentRedict';
import ApiClient from './ApiClient';

const PaymentComponent = ({ pgData }) => {

    useEffect(() => {
        ccavanuePayment()
        document.getElementById("ccavanue").click()

    }, [pgData])
     const [encRequest, setEncRequest] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const [isRedirecting, setIsRedirecting] = useState(false);



    const ccavanuePayment = () => {
        //setIsRedirecting(true);
        setEncRequest(pgData.encRequest);
        setAccessCode(pgData.accessCode);
    }



    return (

        <div>

            <form
                //action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                action={`${process.env.REACT_APP_CCAVANUE_FORM}`}
                method="post"

            >

                <input type="hidden" name="encRequest" value={pgData.encRequest} />
                <input type="hidden" name="access_code" value={pgData.accessCode} />

                <button id="ccavanue" style={{ display: "none" }} type="submit">Proceed to Payment</button>
            </form>


        </div>

    );
};

export default PaymentComponent;



