import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  message,
  Modal,
  Row,
  Spin,
  Image,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import { useAuthContext } from "../../providers/AuthProvider";
import { useSytContext } from "../../providers/SytProvider";
import APIClient from "../../../helpers/ApiClient";
import TicketSidebar from "../TicketSidebar";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import Base from "antd/lib/typography/Base";
import TicketVisa from "./TicketVisa";

const { Panel } = Collapse;
const dateFormat = "DD MMM YYYY";
const VisaTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);
  const BASE = process.env.REACT_APP_BASE_URL;


  const [ticketData, setTicketData] = useState({});
  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  

  const getTicketDetails = () => {
    setLoadingTicket(true);
    APIClient.get("visa/getVisaBookingDetailsByRefNo/" + ticketSearchParams.ref ) 
    .then((res) => {
        if (res.status === 200) {
          if (mode === "USER") {
            let userId = user?.UserID ?? 1;
            if (res.data.UserId === userId) {
              setTicketData(res.data);
            } else {
              setTicketData({});
            }
          } else {
            setTicketData(res.data);
          }
        } else {
          setTicketData({});
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData({});
        setLoadingTicket(false);
      });
  };



  useEffect(() => {
    getTicketDetails();
  }, [ticketSearchParams.ref]);
  
  
  return (
    <div className="visa-ticket-collapse">
      <div className="print-agentlogo">
        {agent ? (
          agentLogo ? (
            <Image
              className="printImageStyle"
              src={BASE + agentLogo.substring(1)}
            ></Image>
          ) : logo ? (
            <Image
              className="printImageStyle"
              src={BASE + logo.substring(1)}
            ></Image>
          ) : (
            <Image className="printImageStyle" src={LogoImg}></Image>
          )
        ) : logo ? (
          <Image
            className="printImageStyle"
            src={BASE + logo.substring(1)}
          ></Image>
        ) : (
          <Image className="printImageStyle" src={LogoImg}></Image>
        )}
      </div>
      <Card>
        <div className="visa-ticket-container" style={{paddingTop:70}}>
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="visa-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : Object.keys(ticketData).length > 0 ? (
            <div className="visa-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum">
                  <TicketVisa
                    ticketData={ticketData}
                  />
                </Col>

                <Col xs={24} md={6} className="tic-info-visa">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Visa"
                      getTicketDetails={getTicketDetails}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Visa"
                          getTicketDetails={getTicketDetails}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p className="no-tickt">No Ticket Found</p>
          )}
        </div>
      </Card>

      {/* <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={submitCancelForm}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {Object.keys(ticketData).length > 0 ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> : {ticketData.RefNumber}
            </p>
            <p>
              <b>Visa Type :</b> : {ticketData.VisaType}
            </p>
            <p>
              <b>Applicant Name :</b> : {ticketData.ApplicantName}
            </p>

            <p>
              <b>Issue Date:</b> :{" "}
              {moment(ticketData.IssueDate).format(dateFormat)}
            </p>
            <p>
              <b> Expiry Date:</b> :{" "}
              {moment(ticketData.ExpiryDate).format(dateFormat)}
            </p>
          </div>
        ) : null}
      </Modal> */}
    </div>
  );
};

export default VisaTicketScreen;
 
