import { Card, Col, Form, Input, message, Row, Select, Button, Dropdown, Menu, Modal } from "antd";
import { MailOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import ApiClient from "../../../helpers/ApiClient";
import { getHolidayPrice } from "../holidayHelper";
import HolidayBookPayCard from "./HolidayBookPayCard";
import HolidayDetailCard from "./HolidayDetailCard";
import "./HolidaysCheckout.scss";
import { PDFDownloadLink } from "@react-pdf/renderer";
import HolidayTotalCard from "./HolidayTotalCard";
import ReactDOMServer from 'react-dom/server'
import HolidayPackageCheckoutDoc from "../../../common/PdfDocuments/BuildPackage/HolidayPackageCheckoutDoc";
import PaymentComponent from "../../../helpers/CCavanuePayCheckout";

const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";
const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}

const HolidaysReview = () => {
  let history = useHistory();
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount },
      holidayBookingData,

    },

  } = useContext(GlobalStatesContext);

  const {
    user,
    isLogin: { agent },
  } = useAuthContext();

  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isPartPayment, setIsPartPayment] = useState(0);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [contactData, setContactData] = useState({});
  const [emailForm] = Form.useForm();
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false);
  const [pgData, setPgData] = useState({});

  const fetchHolidayData = () => {
    if (!holidayBookingData) {
      history.goBack();
    }
  };

  useEffect(() => {
    fetchHolidayData();
  }, []);


  const handleBookHoliday = ({ bookingData, pgType = null, blockType = false }) => {

    const allAmount = getHolidayPrice(bookingData.holidayCheckOutData, ConvFee, promoData, redeemAmount);

    let blockObj = {
      pgType: pgType ?? 4,
      TraceId: bookingData.holidayCheckOutData?.Request?.traceId,
      UserId: user?.UserID ?? 1,
      Role: user?.Role?.RoleId ?? 4,

      Name: bookingData.addressInfo.firstName,
      Email: bookingData.addressInfo.email,
      PhoneNo: bookingData.addressInfo.mobile,
      Address: bookingData.addressInfo.Address,
      City: bookingData.addressInfo.nationality,
      Country: bookingData.addressInfo.nationality,
      HolidayPackageId: bookingData.holidayCheckOutData?.Request?.holidayId,
      AdditionalRequest: "",
      PromoCode: promoData.Code ?? "",
      ConvienceId: 0,

      Day: bookingData.holidayCheckOutData.Days,
      VendorID: 0,
      CreatedBy: 0,
      ModifiedBy: 0,
      TotalPrice: allAmount.grandTotal,
      isBookOffline: blockType,
      gstDetails: {
        gstNumber: bookingData.gstDetails.gstNumber,
        gstAddressLine1: bookingData.gstDetails.gstAddressLine1,
        gstEmailId: bookingData.gstDetails.gstEmailId,
        gstPhoneNo: bookingData.gstDetails.gstPhoneNo,
        gstCompanyName: bookingData.gstDetails.gstCompanyName,
      },
    };

    setIsLoading(true);
    setLoadingSpin(true);

    ApiClient.post("ToursExtranet/tours_block", blockObj)
      .then((result) => result)
      .then((resp) => {
        setIsLoading(false);
        setLoadingSpin(false);

        if (resp.status == 200) {

          ccavanueCheckoutData(resp.data.payment_link)
        } else {
          message.error(resp?.message, 5);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setLoadingSpin(true);
      });
  };

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: contactData?.mobile,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          handleBookHoliday(holidayBookingData, pgDetails.pgType);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = (contactDetails) => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: contactDetails?.mobile,
      DialingCode: "+91",
    })
      .then((res) => {
        if (res?.status === 200) {
          setContactData(contactDetails);
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = (contactDetails) => {
    setContactData((prev) => ({ ...prev, ...contactDetails }));
    const { grandTotal } = getHolidayPrice(
      holidayBookingData.holidayCheckOutData?.TourDetails,
      ConvFee,
      promoData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 4,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP(contactDetails);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });

    if (pgType === 1) {
      handleBookHoliday({ bookingData: holidayBookingData, pgType: pgType });

    } else if (pgType === 2) {
      handleBookHoliday({
        blockType: true,
        pgType: pgType,
        bookingData: holidayBookingData,
      });
    } else if (pgType === 3) {
      handleBookHoliday({
        pgType: pgType,
        bookingData: holidayBookingData,
      });
    }
  };
  const processPayGateway = () => {
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const pdfdocument = () => {
    let arr2 = [];
    holidayBookingData?.holidayCheckOutData?.PacakgeOverview.forEach((day, idx) => {
      Object.entries(day).map(([key, datas]) => {
        if (key.includes("Day")) {

          arr2.push(datas)
        }
      })
    })
    return arr2;
  }



  const PdfData = () => {
    const ComponentToHtmlConversion = ReactDOMServer.renderToStaticMarkup(<HolidayPackageCheckoutDoc buildPackage={pdfdocument()} price={holidayBookingData?.holidayCheckOutData?.price} pax={paxdetails()} />)

    let TagChanging = ComponentToHtmlConversion.toString();
    TagChanging = TagChanging.replaceAll("VIEW", "div")
    TagChanging = TagChanging.replaceAll("DOCUMENT", "div");
    TagChanging = TagChanging.replaceAll("PAGE", "div");
    TagChanging = TagChanging.replaceAll("TEXT", "p");
    TagChanging = TagChanging.replaceAll("IMAGE", "img");
    return TagChanging;
  }

  const toggleEmailModal = () => {
    emailForm.resetFields();
    setEmailModalVisible((prev) => !prev);
  };


  const submitEmailForm = (val) => {
    var formData = {
      travelType: 6,
      email: val?.Email,
      PDFContent: PdfData(),
      packageName: holidayBookingData?.holidayCheckOutData?.PackageTitle,
    };

    sendEmailSmsETicket(formData);
  };

  const sendEmailSmsETicket = (val) => {
    ApiClient.post("email/emailpdf", val).then((res) => {
      if (res.status === 200) {
        message.success(`Email Sent Successfully`);
        setEmailModalVisible(false);
      } else if (res.status === 400) {
        if (res?.message) message.success(res?.message, 3);
      } else {
        if (res?.message) message.success(res?.message, 3);
        else message.error("Failed");
      }
    });
  };
  const paxdetails = () => {
    let arr = [];
    arr.push(holidayBookingData?.addressInfo);
    arr.push(holidayBookingData?.holidayCheckOutData?.TravellingDate)
    arr.push(holidayBookingData?.holidayCheckOutData?.PackageTitle)
    return arr;
  }
  const MainMenu = (
    <Menu>
      <Menu.Item key="32">
        <PDFDownloadLink
          document={<HolidayPackageCheckoutDoc buildPackage={pdfdocument()} price={holidayBookingData?.holidayCheckOutData?.price} pax={paxdetails()} />}
          fileName="QuotationList.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? <>Loading document...</> : <>With Rate Breakup</>
          }
        </PDFDownloadLink>
      </Menu.Item>
      <Menu.Item key="33">
        <PDFDownloadLink
          document={
            <HolidayPackageCheckoutDoc buildPackage={pdfdocument()} showPrice={false} pax={paxdetails()} />
          }
          fileName="QuotationList.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? <>Loading document...</> : <>Without Rate Breakup</>
          }
        </PDFDownloadLink>
      </Menu.Item>
    </Menu>
  );


  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <div className="admin-container bar-admin-width">
            <Row className="mobile-row-res">
              <Col md={16} xs={24} className="mobile-offset activi-leftbat">
                <HolidayDetailCard
                  holidayData={holidayBookingData.holidayCheckOutData}
                />

                <Card className="checkout-activity-card">
                  <div>
                    <h5 className="booking-option-cart">
                      LEAD PASSENGER DETAILS
                    </h5>
                  </div>
                  <Row gutter={(8, 8)} className="form-rules">
                    <Col md={8} xs={24}>
                      <Form.Item label="Full Name">
                        <Input
                          disabled
                          value={`${holidayBookingData.addressInfo.prefix} ${holidayBookingData.addressInfo.firstName} ${holidayBookingData.addressInfo.lastName}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item label="Mobile No.">
                        <Input
                          disabled
                          value={holidayBookingData.addressInfo.mobile}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item label="Email">
                        <Input
                          disabled
                          value={holidayBookingData.addressInfo.email}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col md={8} xs={24} className="mobile-offset total-card-bar">
                {holidayBookingData.holidayCheckOutData && (
                  <HolidayTotalCard
                    holidayCheckOutData={holidayBookingData.holidayCheckOutData}
                    isPartPayment={isPartPayment}
                    isPromoVisible={false}
                  />
                )}
                <Card className="quo-border-sec">
                  <Row gutter={[16, 16]} className="top-block-quiet">
                    <Col md={12} sm={8} xs={24}>
                      <Dropdown overlay={MainMenu} trigger={["click"]}>
                        <Button style={{ backgroundColor: "#ec8333", color: "white" }} className="dark-btn">
                          <DownloadOutlined /> Download Package
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col md={4} sm={8} xs={24}>
                      <Button style={{ backgroundColor: "#ec8333", color: "white" }}
                        className="dark-btn"
                        onClick={() => {
                          toggleEmailModal();
                        }}
                      >
                        <MailOutlined /> Send Email
                      </Button>
                    </Col>
                  </Row>
                </Card>
                <HolidayBookPayCard
                  blockApiReq={blockApiReq}
                  loadingSpin={loadingSpin}
                  bookpaycardinfo={"review"}
                  pgDisplay={pgDetails.pgDisplay}
                  processPayGateway={processPayGateway}
                // processOffline={processOffline}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {
        openCCavanueCheckout && (
          <PaymentComponent
            pgData={pgData}

          />
        )
      }

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Email Quotation</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={emailModalVisible}
        onOk={toggleEmailModal}
        onCancel={toggleEmailModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleEmailModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={emailForm.submit}
            >
              Send
            </Button>
          </div>,
        ]}
      >
        <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Email is not a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default HolidaysReview;
