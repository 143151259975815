import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDaDXLGl4h7duP84EQR3iqY_Pss-200v3w",
    authDomain: "outc-3c24c.firebaseapp.com",
    projectId: "outc-3c24c",
    storageBucket: "outc-3c24c.appspot.com",
    messagingSenderId: "88148616776",
    appId: "1:88148616776:web:225655273773c5654b5a7d",
    measurementId: "G-QQ8FZ0SKSN"
  };


const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const fbAuthProvider = new FacebookAuthProvider();
export {auth,provider,fbAuthProvider};