import React, { useEffect, useState,useContext } from "react";
import { Calendar, Card, Col, Form, Row, Select, Spin, Popover } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import "./TravelCalendar.scss";
import ApiClient from "../../helpers/ApiClient";
import { Link } from "react-router-dom";
import moment from "moment";
import { createReportFromApiReponse } from "./CalenderHelpers";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import { GlobalStatesContext } from "../../common/providers";

const { Option } = Select;

const TravelCalendar = () => {
  const [serviceType, setServiceType] = useState(0);
  const [bookings, setBookings] = useState({
    flights: [],
    hotels: [],
    holidays: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const {
    state: {
      serviceTypeList
    },
    getAllServiceType
  } = useContext(GlobalStatesContext);

  const getBookingListData = (travelType) => {
    setIsLoading(true);
    setBookings({
      flights: [],
      hotels: [],
      holidays: [],
    });
    let url = `admin/myBookings/report?travelType=${travelType}`;
    ApiClient.get(url)
      .then((res) => {
        if (res.statusCode == 200) {
          setBookings(createReportFromApiReponse(res.data));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getBookingListData(0);
    getAllServiceType();
  }, []);

  

  const cellContent = ({ flights, hotels, holidays }, date, type) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
   

    return (
      <div className="travelDateContent">
        {flights > 0 && (serviceType == 0 || serviceType == 1) ? (
          <Link
            to={`/admin/reports?travelType=1&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-plane"></i> {flights}
              </span>
            </div>
          </Link>
        ) : null}

        {holidays > 0 && (serviceType == 0 || serviceType == 6) ? (
          <Link
            to={`/admin/reports?travelType=3&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-bus" /> {holidays}
              </span>
            </div>
          </Link>
        ) : null}

        {hotels > 0 && (serviceType == 0 || serviceType == 2) ? (
          <Link
            to={`/admin/reports?travelType=2&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-hotel" /> {hotels}
              </span>
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  const dateCellRender = (dated) => {
    let formatedDate = moment(dated).format("DD-MM-YYYY");
    
    let data = {
      flights: bookings.flights.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
      holidays: bookings.buses.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
      hotels: bookings.hotels.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
    };
    return cellContent(data, dated, 1);
  };

  const monthCellRender = (dated) => {
    let month = moment(dated).month();
    let year = moment(dated).year();
    let data = {
      flights: bookings?.flights?.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
      holidays: bookings?.holidays?.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
      hotels: bookings?.hotels?.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
    };
    return cellContent(data, dated, 2);
  };

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="travel-calendar">
          <Card bordered={false}>
            <h5>
              Travel Calendar{" "}
              <HelpInfoHelper screenName={"/admin/travelcalendar"} />
            </h5>
            <Form>
              <Row>
                <Col span={8}>
                  <Form.Item label="Service Type">
                    <Select
                      placeholder="Select Service Type"
                      onChange={(v) => {
                        getBookingListData(v);
                        setServiceType(v);
                      }}
                      defaultValue={0}
                    >
                      <Option value={0}>All</Option>
                      {serviceTypeList.length
                        ? serviceTypeList.map((item, index) => {
                            return (
                              <Select.Option
                                key={"serviceType" + index}
                                value={item.ServiceType}
                              >
                                {item.ServiceName}
                              </Select.Option>
                            );
                          })
                        : ""}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {isLoading ? (
              <Spin />
            ) : (
              <Calendar
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TravelCalendar;
