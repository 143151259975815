import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Layout,
  message,
  Select,
} from "antd";

import ApiClient from "../../helpers/ApiClient";
import "./Mytrips.scss";
import { useHistory } from "react-router-dom";
const { Content } = Layout;
const { Option } = Select;

const Mytrips = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const getType = (type) => {
    let url = "";
    switch (type) {
      case 1: {
        url = "flight/ticket";
        break;
      }
      case 2: {
        url = "hotel/ticket";
        break;
      }
      case 3: {
        url = "bus/ticket";
        break;
      }
      case 5: {
        url = "carTransfer/ticket";
        break;
     }
      case 6: {
        url = "holiday/ticket";
        break;
      }
      case 8: {
        url = "visa/ticket";
        break;
      }
    }

    return url;
  };

  const verifyTicket = (data) => {
    if (
      data.type === 1 ||
      data.type === 2 ||
      data.type === 3 ||
      data.type === 4 ||
      data.type === 5 ||
      data.type === 6
    ) {
      return new Promise((resolve, reject) => {
        if (data.type === 6) {
          ApiClient.get(`HolidayExtranet/holiday/ticket/${data.ref}`).then((resp) => {
            if (resp && resp.data) {
              resolve();
            } else {
              reject("No Such Ticket Found");
            }
          });
        } else {
          ApiClient.get(
            `admin/myBookings/details?travelType=${data.type}&id=${data.ref}`
          ).then((resp) => {
            let errorMessage = "No Such Ticket Found";
            if (!resp) {
              reject("Server Error");
              return;
            }

            if (!resp.data) {
              reject("No Such Ticket Found");
              return;
            }

            if (resp.data.length > 0) {
              if (data.email) {
                let guestEmail = resp.data[0].guestEmaiId
                  ? resp.data[0].guestEmaiId.toLowerCase()
                  : "";
                if (guestEmail === data.email.toLowerCase()) {
                  resolve();
                } else {
                  reject("Email Does Not Match Refference Id");
                }
              }

              resolve();
            } else {
              reject(errorMessage);
            }
          });
        }
      });
    }
    if (data.type == 8) {
      return new Promise((resolve, reject) => {
        ApiClient.get(`visa/getVisaBookingDetailsByRefNo/${data.ref}`).then(
          (resp) => {
            let errorMessage = "No Such Ticket Found";
            if (!resp) {
              reject("Server Error");
              return;
            }

            if (!resp.data) {
              reject("No Such Ticket Found");
              return;
            }
            if (Object.keys(resp.data).length > 0) {
              if (data.email) {
                let guestEmail = resp.data.customerEmail
                  ? resp.data.customerEmail.toLowerCase()
                  : "";
                if (guestEmail === data.email.toLowerCase()) {
                  resolve();
                } else {
                  reject("Email Does Not match Refference Id");
                }
              }

              resolve();
            } else {
              reject(errorMessage);
            }
          }
        );
      });
    }
    if (data.type == 5) {
      return new Promise((resolve, reject) => {
        ApiClient.get(`transfers/bookingDetails?refNo=${data.ref}`).then(
          (resp) => {
            {console.log(resp,"carresp")}
            let errorMessage = "No Such Ticket Found";
            if (!resp) {
              reject("Server Error");
              return;
            }

            if (!resp.data) {
              reject("No Such Ticket Found");
              return;
            }
            if (Object.keys(resp.data).length > 0) {
              if (data.email) {
                let guestEmail = resp.data.EmailId
                  ? resp.data.EmailId.toLowerCase()
                  : "";
                if (guestEmail === data.email.toLowerCase()) {
                  resolve();
                } else {
                  reject("Email Does Not match Refference Id");
                }
              }

              resolve();
            } else {
              reject(errorMessage);
            }
          }
        );
      });
    }


  };

  const submitForm = (data) => {
    verifyTicket(data)
      .then(() => {
        let type = getType(data.type);
        let url = type + "?ref=" + data.ref;
        history.push(url);
      })
      .catch((e) => {
        message.error(e, 3);
      });
  };
  const getAllServiceTypes = () => {
    setServiceTypeList([]);
    ApiClient.get("admin/services").then((res) => {
      if (res.status === 200) {
        setServiceTypeList(res.data);
      }
    });
  };

  useEffect(() => {
    getAllServiceTypes();
  }, []);

  return (
    <div>
      <div className="promo providerapi">
        <Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container cms-pages-width retrieve-linebar">
              <Row className="fst-book">
                <Col md={24} xs={24}></Col>
              </Row>

              <Form
                layout="vertical"
                form={form}
                onFinish={submitForm}
                initialValues={{ type: 1 }}
              >
                <Row>
                  <Col md={12} xs={24}>
                    <Card className="retrieve-booking">
                      <h4>Retrieve Your Booking</h4>
                      <p className="booking-email-p">
                        Enter your booking number and email address below and
                        we’ll find your ticket for you. We sent your booking
                        number to you in your confirmation email.
                      </p>
                      <Form.Item
                        label="Service Type"
                        name="type"
                        rules={[
                          { required: true, message: "Field is Required" },
                        ]}
                      >
                        <Select>

                          {serviceTypeList.length
                            ? serviceTypeList.map((item, index) => {
                              return (
                                <Option
                                  key={"serviceType" + index}
                                  value={item.ServiceType}
                                >
                                  {item.ServiceName}
                                </Option>
                              );
                            })
                            : ""}
                        </Select>
                      </Form.Item>

                      <Row className="booking-value-id">
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Reference Number"
                            name="ref"
                            rules={[
                              {
                                required: true,
                                message: "Reference No is Required",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              {
                                type: "email",
                                message: "Email is not a valid email",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col md={12} xs={24}>
                          <Button
                            className="google-btn-booking"
                            htmlType="submit"
                          >
                            Retrieve Booking
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col md={12} xs={24}>
                    <img
                      className="email-from-book"
                      src={
                        require("../../assets/images/email-tickets.png").default
                      }
                      alt="search-img"
                    />
                  </Col>
                </Row>
              </Form>
            </Content>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Mytrips;
