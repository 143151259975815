// import React from "react";
// import "../Flightsalescount/Flightsalescount.scss";
// import { Card, Col, Row, Table } from "antd";
// import { Link } from "react-router-dom";
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// // import { AntDesignOutlined, SettingFilled } from "@ant-design/icons";
// // import { Link } from "react-router-dom";
// // import Siteusers from "../../admin/Siteusers/Siteusers";
// // import { Chart, LineAdvance } from "bizcharts";

// const Flightsalescount = ({ flightsData, dashboardData }) => {
//   const columnsUpcommingBookings = [
//     {
//       title: "ReferenceID",
//       dataIndex: "orderId",
//       render: (text, record) => renderReffrence(text, record),
//     },
//     {
//       title: "Source",
//       dataIndex: "origin",
//     },
//     {
//       title: "Destination",
//       dataIndex: "destination",
//     },
//     {
//       title: "Amount ",
//       dataIndex: "amount",
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       render: (type) => getStatus(type),
//     },
//   ];
//   const columnsRecentBooking = [
//     {
//       title: "ReferenceID",
//       dataIndex: "orderId",
//       render: (text, record) => renderReffrence(text, record),
//     },
//     {
//       title: "Source",
//       dataIndex: "origin",
//     },
//     {
//       title: "Destination",
//       dataIndex: "destination",
//     },
//     {
//       title: "Amount ",
//       dataIndex: "amount",
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       render: (type) => getStatus(type),
//     },
//   ];

//   const renderReffrence = (ref, rec) => {
//     let url = "";

//     if (ref.indexOf("OUTC-F") != -1) {
//       url = "/admin/flight/ticket";
//     }
//     return <Link to={url + "?ref=" + ref}>{ref}</Link>;
//   };
//   const topCurrencies = dashboardData?.topCurrencyBookingAllCount
//   ?.sort((a, b) => parseInt(b.bookingCount) - parseInt(a.bookingCount))
//   .slice(0, 3);
// const combinedData = [
//   ...(Array.isArray(dashboardData.topflightsSellingDestinationCity) ? dashboardData.topflightsSellingDestinationCity : []),
//   ...(Array.isArray(dashboardData.hotelsTopSellingCity) ? dashboardData.hotelsTopSellingCity : [])
// ];
// combinedData.sort((a, b) => parseInt(b.bookingCount) - parseInt(a.bookingCount));
// const topEntries = combinedData.slice(0, 3);
// const totalBookingCount = topEntries.reduce((acc, entry) => acc + parseInt(entry.bookingCount), 0);

//   const getStatus = (type) => {
//     switch (type) {
//       case 1:
//         return "Created ";
//       case 2:
//         return "Blocked ";
//       case 3:
//         return "Confirmed ";
//       case 4:
//         return "Cancelled ";
//       case 5:
//         return "Partially Cancelled ";
//       case 6:
//         return "CancellationRequest ";
//       // case 7:
//       //   return "CancellationRequest ";
//       // case 8:
//       //   return "CancellationPending ";
//       // case 9:
//       //   return "CancellationInProgress ";
//       default:
//         return "";
//     }
//   };

//   return (
//     <div>
//       <div className="order-count-cards">
//         <Row>
//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-1">
//                 <span className="ad-span-col-1">
//                   <i className="fa fa-bar-chart"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Profit Amount</p>
//                   <h5>2,520</h5>
//                 </div>
//               </div>
//             </Col> */}

//           <Col xs={24} md={4}>
//             <div className="ad-hom-box-2">
//               <span className="ad-span-col-2">
//                 <i className="fa fa-bookmark" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Booking Count</p>
//                 <h5>{Number(flightsData?.booking_count).toFixed(2)}</h5>
//               </div>
//             </div>
//             <div className="ad-hom-box-12">
//               <span className="ad-span-col-12">
//                 <i className="fa fa-calendar" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Yesterday Sales </p>
//                 <h5>
//                   {flightsData?.yesterday_sales
//                     ? Number(flightsData?.yesterday_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>
//           </Col>

//           <Col xs={24} md={4}>
//             <div className="ad-hom-box-3">
//               <span className="ad-span-col-3">
//                 <i className="fa fa-ban" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Cancelled Count </p>
//                 <h5>{Number(flightsData?.cancelled_count).toFixed(2)}</h5>
//               </div>
//             </div>
//             <div className="ad-hom-box-13">
//               <span className="ad-span-col-13">
//                 <i className="fa fa-calendar" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Week Sales</p>
//                 <h5>
//                   {flightsData?.week_sales
//                     ? Number(flightsData?.week_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>
            
//           </Col>

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-4">
//                 <span className="ad-span-col-4">
//                   <i className="fa fa-times-circle" aria-hidden="true"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Cancel requests </p>
//                   <h5>{Number(flightsData?.cancel_request).toFixed(2)}</h5>
//                 </div>
//               </div>
//             </Col> */}

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-5">
//                 <span className="ad-span-col-5">
//                   <i className="fa fa-retweet" aria-hidden="true"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Reschedule Requests </p>
//                   <h5>{Number(flightsData?.reschedule_request).toFixed(2)}</h5>
//                 </div>
//               </div>
//             </Col> */}

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-6">
//                 <span className="ad-span-col-6">
//                   <i className="fa fa-bolt" aria-hidden="true"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Refunded Count </p>
//                   <h5>{Number(flightsData?.refund_count).toFixed(2)}</h5>
//                 </div>
//               </div>
//             </Col>*/}

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-7">
//                 <span className="ad-span-col-7">
//                   <i className="fa fa-bar-chart"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Day refunds</p>
//                   <h5>
//                     {flightsData?.day_refunds
//                       ? Number(flightsData?.day_refunds).toFixed(2)
//                       : 0}
//                   </h5>
//                 </div>
//               </div>
//             </Col> */}

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-8">
//                 <span className="ad-span-col-8">
//                   <i className="fa fa-bookmark" aria-hidden="true"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Yesterday ref</p>
//                   <h5>
//                     {flightsData?.yesterday_refunds
//                       ? Number(flightsData?.yesterday_refunds).toFixed(2)
//                       : 0}
//                   </h5>
//                 </div>
//               </div>
//             </Col> */}

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-9">
//                 <span className="ad-span-col-9">
//                   <i className="fa fa-calendar-o" aria-hidden="true"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>Week refunds </p>
//                   <h5>
//                     {flightsData?.week_refunds
//                       ? Number(flightsData?.week_refunds).toFixed(2)
//                       : 0}
//                   </h5>
//                 </div>
//               </div>
//             </Col> */}

//           {/* <Col xs={24} md={4}>
//               <div className="ad-hom-box-10">
//                 <span className="ad-span-col-10">
//                   <i className="fa fa-calendar-o" aria-hidden="true"></i>
//                 </span>

//                 <div className="ad-hom-view-com">
//                   <p>month refunds</p>
//                   <h5>
//                     {flightsData?.month_refunds
//                       ? Number(flightsData?.month_refunds).toFixed(2)
//                       : 0}
//                   </h5>
//                 </div>
//               </div>
//             </Col> */}

//           <Col xs={24} md={4}>
//             <div className="ad-hom-box-11">
//               <span className="ad-span-col-11">
//                 <i className="fa fa-random" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Day Sales </p>
//                 <h5>
//                   {flightsData?.day_sales
//                     ? Number(flightsData?.day_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>
//             <div className="ad-hom-box-14">
//               <span className="ad-span-col-14">
//                 <i className="fa fa-bookmark" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Month Wise</p>
//                 <h5>
//                   {flightsData?.month_sales
//                     ? Number(flightsData?.month_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>

//           </Col>

//           {/* <Col xs={24} md={4}>
//             <div className="ad-hom-box-12">
//               <span className="ad-span-col-12">
//                 <i className="fa fa-calendar" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Yesterday Sales </p>
//                 <h5>
//                   {flightsData?.yesterday_sales
//                     ? Number(flightsData?.yesterday_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>
//           </Col>

//           <Col xs={24} md={4}>
//             <div className="ad-hom-box-13">
//               <span className="ad-span-col-13">
//                 <i className="fa fa-calendar" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Week Sales</p>
//                 <h5>
//                   {flightsData?.week_sales
//                     ? Number(flightsData?.week_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>
//           </Col>

//           <Col xs={24} md={4}>
//             <div className="ad-hom-box-14">
//               <span className="ad-span-col-14">
//                 <i className="fa fa-bookmark" aria-hidden="true"></i>
//               </span>

//               <div className="ad-hom-view-com">
//                 <p>Month Wise</p>
//                 <h5>
//                   {flightsData?.month_sales
//                     ? Number(flightsData?.month_sales).toFixed(2)
//                     : "0"}
//                 </h5>
//               </div>
//             </div>
//           </Col> */}
//           <Col xs={24} md={7}>
//             <div className="currency-top">
//               <div className="ad-hom-box-8">
//                 {/* <span className="ad-span-col-curr">
//                                     <i className="fa fa-inr" aria-hidden="true"></i>
//                                 </span> */}

//                 {/* <div >

//                                     <h5>
//                                         {dashboardData?.flightsTopCurrencyBooking
//                                             ?.sort((a, b) => parseInt(b.bookingCount) - parseInt(a.bookingCount))
//                                             .slice(0, 3)
//                                             .map((flight, index) => (
//                                                 <div key={index}>
//                                                     <span>{flight.currency ?? "INR"}</span>{" - "}
//                                                     <span>
//                                                         {Number(flight.bookingCount).toFixed(2)}
//                                                     </span>
//                                                     {/* Currency: {flight.currency}, Booking Count: {flight.bookingCount}, Total Price: {flight.totalPrice} *
//                                                 </div>
//                                             ))}

//                                     </h5>
//                                 </div> */}
//                 <p className="top-co-curr">Top Destination's</p>


//                 {topEntries?.map((entry, index) => (
//                   <div style={{ width: '100px', display: 'inline-block', margin: '10px' }}>
//                     <CircularProgressbar
//                       key={index}
//                       value={(parseInt(entry.bookingCount) / totalBookingCount) * 100}
//                       // text={
//                       //     <div>
//                       //         {`${entry?.destinationName ? <i class="fa fa-plane"></i> : <i class="fas fa-hotel"></i>}`},
//                       //         {`${entry.destinationName  || entry.HotelCityName}: ${parseInt(entry.bookingCount)}`}
//                       //     </div>
//                       // }
//                       text={` ${entry?.destinationName ? "(Fly2) - " : ""}  ${entry.destinationName || entry.HotelCityName}`}
//                       styles={buildStyles({
//                         strokeLinecap: 'butt',
//                         textSize: '16px',
//                         pathTransitionDuration: 0.5,
//                         pathColor: `rgba(42, 152, 199, ${1})`,
//                         textColor: '#f88',
//                         trailColor: '#d6f6d6',
//                         backgroundColor: '#3e98c7',
//                       })}
//                     />
//                   </div>
//                 ))}


//               </div>

//             </div>
//           </Col>
//         </Row>
//       </div>

//       <div className="order-count-cards">
//         <Row
//           gutter={[
//             { xs: 0, md: 16 },
//             { xs: 8, md: 16 },
//           ]}
//           className="charts-bars card-count-balance "
//         >
//           <Col xs={24} md={12}>
//             <Card className="side-bar-graph-1">
//               <p className="month-wise-cart">Upcoming Bookings</p>
//               {/* <Card className="side-bar-graph-1">
//                 <p className="month-wise-cart">Month wise (Show in Graph)</p>
//                 <Chart
//                   padding={[10, 20, 50, 40]}
//                   autoFit
//                   height={300}
//                   data={data}
//                 >
//                   <LineAdvance
//                     shape="smooth"
//                     point
//                     area
//                     position="month*temperature"
//                     color="city"
//                   />
//                 </Chart>
//               </Card> */}
//               <Table
//                 className="table-scroll-none flight-new-pagenation"
//                 bordered
//                 dataSource={flightsData?.upcoming_bookings.filter(x => x.status === 3)}
//                 columns={columnsUpcommingBookings}
//               />{" "}
//             </Card>
//           </Col>

//           <Col xs={24} md={12}>
//             <div className="site-card-border-less-wrapper">
//               <Card className="side-bar-graph-new-flight">
//                 <p className="month-wise-cart">Recent Bookings</p>
//                 <Row className="boder-count">
//                   <Col xs={24} md={24} className="total-cal-fl">
//                     <Table
//                       className="table-scroll-none flight-new-pagenation"
//                       bordered
//                       dataSource={flightsData?.recent_bookings}
//                       columns={columnsRecentBooking}
//                     />
//                   </Col>
//                 </Row>
//               </Card>
//             </div>
//           </Col>
//         </Row>
//       </div>

//       {/* charts--close */}
//     </div>
//   );
// };
// export default Flightsalescount;


import React from "react";
import "../Flightsalescount/Flightsalescount.scss";
import { Card, Col, Row, Table } from "antd";
import { Link } from "react-router-dom";
// import { AntDesignOutlined, SettingFilled } from "@ant-design/icons";
// import { Link } from "react-router-dom";
// import Siteusers from "../../admin/Siteusers/Siteusers";
// import { Chart, LineAdvance } from "bizcharts";

const Flightsalescount = ({ flightsData }) => {
  const columnsUpcommingBookings = [
    {
      title: "ReferenceID",
      dataIndex: "orderId",
      render: (text, record) => renderReffrence(text, record),
    },
    {
      title: "Source",
      dataIndex: "origin",
    },
    {
      title: "Destination",
      dataIndex: "destination",
    },
    {
      title: "Amount ",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (type) => getStatus(type),
    },
  ];
  const columnsRecentBooking = [
    {
      title: "ReferenceID",
      dataIndex: "orderId",
      render: (text, record) => renderReffrence(text, record),
    },
    {
      title: "Source",
      dataIndex: "origin",
    },
    {
      title: "Destination",
      dataIndex: "destination",
    },
    {
      title: "Amount ",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (type) => getStatus(type),
    },
  ];

  const renderReffrence = (ref, rec) => {
    let url = "";

    if (ref.indexOf("OUTC-F") != -1) {
      url = "/admin/flight/ticket";
    }
    return <Link to={url + "?ref=" + ref}>{ref}</Link>;
  };

  const getStatus = (type) => {
    switch (type) {
      case 1:
        return "Created ";
      case 2:
        return "Blocked ";
      case 3:
        return "Confirmed ";
      case 4:
        return "Cancelled ";
      case 5:
        return "Partially Cancelled ";
      case 6:
        return "CancellationRequest ";
      // case 7:
      //   return "CancellationRequest ";
      // case 8:
      //   return "CancellationPending ";
      // case 9:
      //   return "CancellationInProgress ";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="order-count-cards">
        <Row>
          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-1">
                <span className="ad-span-col-1">
                  <i className="fa fa-bar-chart"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Profit Amount</p>
                  <h5>2,520</h5>
                </div>
              </div>
            </Col> */}

          <Col xs={24} md={4}>
            <div className="ad-hom-box-2">
              <span className="ad-span-col-2">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Booking Count</p>
                <h5>{Number(flightsData?.booking_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="ad-hom-box-3">
              <span className="ad-span-col-3">
                <i className="fa fa-ban" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Cancelled Count </p>
                <h5>{Number(flightsData?.cancelled_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-4">
                <span className="ad-span-col-4">
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Cancel requests </p>
                  <h5>{Number(flightsData?.cancel_request).toFixed(2)}</h5>
                </div>
              </div>
            </Col> */}

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-5">
                <span className="ad-span-col-5">
                  <i className="fa fa-retweet" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Reschedule Requests </p>
                  <h5>{Number(flightsData?.reschedule_request).toFixed(2)}</h5>
                </div>
              </div>
            </Col> */}

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-6">
                <span className="ad-span-col-6">
                  <i className="fa fa-bolt" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Refunded Count </p>
                  <h5>{Number(flightsData?.refund_count).toFixed(2)}</h5>
                </div>
              </div>
            </Col>*/}

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-7">
                <span className="ad-span-col-7">
                  <i className="fa fa-bar-chart"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Day refunds</p>
                  <h5>
                    {flightsData?.day_refunds
                      ? Number(flightsData?.day_refunds).toFixed(2)
                      : 0}
                  </h5>
                </div>
              </div>
            </Col> */}

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-8">
                <span className="ad-span-col-8">
                  <i className="fa fa-bookmark" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Yesterday ref</p>
                  <h5>
                    {flightsData?.yesterday_refunds
                      ? Number(flightsData?.yesterday_refunds).toFixed(2)
                      : 0}
                  </h5>
                </div>
              </div>
            </Col> */}

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-9">
                <span className="ad-span-col-9">
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Week refunds </p>
                  <h5>
                    {flightsData?.week_refunds
                      ? Number(flightsData?.week_refunds).toFixed(2)
                      : 0}
                  </h5>
                </div>
              </div>
            </Col> */}

          {/* <Col xs={24} md={4}>
              <div className="ad-hom-box-10">
                <span className="ad-span-col-10">
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>month refunds</p>
                  <h5>
                    {flightsData?.month_refunds
                      ? Number(flightsData?.month_refunds).toFixed(2)
                      : 0}
                  </h5>
                </div>
              </div>
            </Col> */}

          <Col xs={24} md={4}>
            <div className="ad-hom-box-11">
              <span className="ad-span-col-11">
                <i className="fa fa-random" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Day Sales </p>
                <h5>
                  {flightsData?.day_sales
                    ? Number(flightsData?.day_sales).toFixed(2)
                    : "0"}
                </h5>
              </div>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="ad-hom-box-12">
              <span className="ad-span-col-12">
                <i className="fa fa-calendar" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Yesterday Sales </p>
                <h5>
                  {flightsData?.yesterday_sales
                    ? Number(flightsData?.yesterday_sales).toFixed(2)
                    : "0"}
                </h5>
              </div>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="ad-hom-box-13">
              <span className="ad-span-col-13">
                <i className="fa fa-calendar" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Week Sales</p>
                <h5>
                  {flightsData?.week_sales
                    ? Number(flightsData?.week_sales).toFixed(2)
                    : "0"}
                </h5>
              </div>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="ad-hom-box-14">
              <span className="ad-span-col-14">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Month Wise</p>
                <h5>
                  {flightsData?.month_sales
                    ? Number(flightsData?.month_sales).toFixed(2)
                    : "0"}
                </h5>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="order-count-cards">
        <Row
          gutter={[
            { xs: 0, md: 16 },
            { xs: 8, md: 16 },
          ]}
          className="charts-bars card-count-balance "
        >
          <Col xs={24} md={12}>
            <Card className="side-bar-graph-1">
              <p className="month-wise-cart">Upcoming Bookings</p>
              {/* <Card className="side-bar-graph-1">
                <p className="month-wise-cart">Month wise (Show in Graph)</p>
                <Chart
                  padding={[10, 20, 50, 40]}
                  autoFit
                  height={300}
                  data={data}
                >
                  <LineAdvance
                    shape="smooth"
                    point
                    area
                    position="month*temperature"
                    color="city"
                  />
                </Chart>
              </Card> */}
              <Table
                className="table-scroll-none flight-new-pagenation"
                bordered
                dataSource={flightsData?.upcoming_bookings.filter(x=> x.status ===3)}
                columns={columnsUpcommingBookings}
              />{" "}
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <div className="site-card-border-less-wrapper">
              <Card className="side-bar-graph-new-flight">
                <p className="month-wise-cart">Recent Bookings</p>
                <Row className="boder-count">
                  <Col xs={24} md={24} className="total-cal-fl">
                    <Table
                      className="table-scroll-none flight-new-pagenation"
                      bordered
                      dataSource={flightsData?.recent_bookings}
                      columns={columnsRecentBooking}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div>

      {/* charts--close */}
    </div>
  );
};
export default Flightsalescount;
