// import React, { useRef, useEffect } from 'react';
// import './BusCarousal.scss'; // Import your CSS file for styling
// import * as ANTD from "antd";

// const BusCarousal = () => {
//     const carouselRef = useRef(null);


//     return (
//         <div className="bus-carousel-container">

//             <h5 className="tickets-book-trv">
//                 Book SRTC (State Road Transport Corporation) Bus Tickets
//             </h5>
//             <div className="bus-carousel-inner">


//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-1.png").default}
//                         alt=""
//                     />
//                     <p>APSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-2.png").default}
//                         alt=""
//                     />
//                     <p>TSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-3.png").default}
//                         alt=""
//                     />
//                     <p>KERELA RTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-4.png").default}
//                         alt=""
//                     />
//                     <p>HRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-5.png").default}
//                         alt=""
//                     />
//                     <p>GSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-6.png").default}
//                         alt=""
//                     />
//                     <p>OSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-7.png").default}
//                         alt=""
//                     />
//                     <p>MSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-8.png").default}
//                         alt=""
//                     />
//                     <p>RSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-9.png").default}
//                         alt=""
//                     />
//                     <p>OSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-10.png").default}
//                         alt=""
//                     />
//                     <p>BSRTC</p>
//                 </div>
//                 <div className="bus-carousel-item">
//                     <img
//                         className="buses-rtc-img"
//                         src={require("../../../assets/images/bus-trv-11.png").default}
//                         alt=""
//                     />
//                     <p>UPSRTC</p>
//                 </div>


//             </div>
//         </div>
//     );
// };

// export default BusCarousal;

import React, { useRef, useEffect, useState } from 'react';
import './BusCarousal.scss';

const images = [
    { src: require('../../../assets/images/bus-trv-1.png').default, label: 'APSRTC' },
    { src: require('../../../assets/images/bus-trv-2.png').default, label: 'TSRTC' },
    { src: require('../../../assets/images/bus-trv-3.png').default, label: 'KERELA RTC' },
    { src: require('../../../assets/images/bus-trv-4.png').default, label: 'HRTC' },
    { src: require('../../../assets/images/bus-trv-5.png').default, label: 'GSRTC' },
    { src: require('../../../assets/images/bus-trv-6.png').default, label: 'OSRTC' },
    { src: require('../../../assets/images/bus-trv-7.png').default, label: 'MSRTC' },
    { src: require('../../../assets/images/bus-trv-8.png').default, label: 'RSRTC' },
    { src: require('../../../assets/images/bus-trv-9.png').default, label: 'OSRTC' },
    { src: require('../../../assets/images/bus-trv-10.png').default, label: 'BSRTC' },
    { src: require('../../../assets/images/bus-trv-11.png').default, label: 'UPSRTC' }
];

const BusCarousal = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="bus-carousel-container">
            <h5 className="tickets-book-trv">
                Book SRTC (State Road Transport Corporation) Bus Tickets
            </h5>
            <div className="bus-carousel-inner">
                {images.map((image, index) => {
                    const position = (index - currentIndex + images.length) % images.length;
                    const isCenter = position === Math.floor(images.length / 3);
                    const isAdjacent = position === Math.floor(images.length / 3) - 1 || position === Math.floor(images.length / 3) + 1;

                    return (
                        <div
                            key={index}
                            className={`bus-carousel-item ${isCenter ? 'center' : ''} ${isAdjacent ? 'adjacent' : ''}`}
                            style={{ transform: `translateX(${position * 100}%)` }}
                        >
                            <img className="buses-rtc-img" src={image.src} alt={image.label} />
                            <p>{image.label}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BusCarousal;
