import React, { useState, useCallback, useEffect, useContext } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import defaultimage from "../../assets/images/Icons/defaultflagicon.png"

import queryString from "query-string";
import { GlobalStatesContext } from "../providers";
const { Option } = Select;
const TransferAutoCompleteSelect = (props) => {
  useEffect(() => {
    let value = props?.refName?.current?.props?.value;

    const citySearchParams = queryString?.parse(value);

    if (!value || value == "" || value == " ") {
      return;
    }
    fetchData(value, citySearchParams.CityId);
  }, [props?.refName?.current]);

  const { updateCarPersist } = useContext(GlobalStatesContext);

  const onSelect = (values) => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }

    if (details.data.length > 0) {
      updateCarPersist(
        details?.data.find((item) => item.DestinationId == values)
      );
    }
  };

  const [details, setDetails] = useState({
    data: [],
    fetching: false,
  });

  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityId) => {
    let arr = [];

    if (cityId != null) {
      results = results.filter((item) => item.DestinationId == cityId);
    }
    results.forEach((result) => {
      arr.push({
        cityId: `cityName=${result.CityName}&countryCode=${result.CountryCode}&destinationId=${result.DestinationId}`,
        cityName: result.CityName,
        DestinationId: result.DestinationId,
        CountryName: result.CountryName,
        result,
      });
    });

    return arr;
  };

  function fetchData(value, cityId = null) {
    if (value) {
      setDetails({ data: [], fetching: true });
      APIClient.get(`${props.api}${value}`)
        .then((res) => {
          if (res.status === 200 && res) {
            setDetails({
              data: createOptions(res.data, cityId),
              fetching: false,
            });

            return;
          }
          setDetails({ data: [], fetching: false });
        })
        .catch((error) => {
          console.error(error);
          setDetails({ data: [], fetching: false });
        });
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  const getflag = (code) => {
    // console.log(code, "codef");
    if (code === "yu" || code === "tp") {
      let codeflag = defaultimage;
      return codeflag;
    } else {
      if (code == "yu") {
        console.log("yu is comming");
      }
      let codeflag = require(`../../assets/country-flag-svg/${code}.svg`).default;
      return codeflag;
    }
  }


  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        disabled={props.disabled}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={onSelect}
      >
        {details.data.map((d) => (
          <Option value={d.DestinationId} key={"City" + d.DestinationId}>
            <div className="d-flex align-items-center justify-content-between  ">
              <i class="fa fa-car forplace-wor-dropdownflight " ></i>

              <div className="for-elepsis-work-dropdowncartrans ">
                {/* {d.cityName} */}
                <p className="for-elepsis-work-dropdowncartrans"  style={{marginLeft:21}} >
                  {d.cityName}, {d.CountryName}

                </p>
              </div>

              <div className="city-column">
                <p className="m-0" style={{ color: "#9B9B9B" }}>
                  <span> {d.AirportCode}</span>
                  &nbsp;
                  <img
                    src={d?.CountryCode ? getflag(d?.CountryCode?.toLowerCase()) : ""}
                    width="20"
                    alt={d?.Country}></img>
                </p>
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default TransferAutoCompleteSelect;
