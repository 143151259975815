export const getFareType = (num) => {
  

  switch (num) {
    case 1:
      return "General";
    case 2:
      return "Publish";
    case 3:
      return "Instantpur";
    case 4:
      return "SME";
    case 5:
      return "PPNR";
    default:
      return "";
      
  }
};

export const getCabinType = (num) => {
  switch (num) {
    case 1:
      return "Economy Premium";
    case 2:
      return "Business";
    case 3:
      return "First Class";
    case 4:
      return "Economy";
    default:
      return "";
  }
};

export const getRoleType = (num) => {
  if (num === 2) {
    return "Users";
  } else if (num === 4) {
    return "Guest";
  } else if (num === 5) {
    return "Agent";
  }
};

export const getTypeSign = (num) => {
  if (num === 1) {
    return "%";
  }
  return "";
};


export const getBusType = (num) => {
  if (num == 1) {
    return "RTC Buses";
  } else if (num == 2) {
    return " Private Travels";
  }
};
