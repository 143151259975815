import React,{Suspense} from "react";
import "../BusResultCards/BusResultCards.scss";
//import BusSort from "../../BusSort/BusSort";
//import BusCard from "./BusCard";
import { BusDates } from "../../../../components/SearchResults/FlightNearDates/FlightNearDates";
const BusSort = React.lazy(() => import('../../BusSort/BusSort'))
const BusCard = React.lazy(() => import('./BusCard'))
//const BusDates = React.lazy(() => import('../../../../components/SearchResults/FlightNearDates/FlightNearDates'))
//const BusDates=React.lazy(()=>import('./../../../components/SearchResults/FlightNearDates/FlightNearDates'))
const BusResultCards = ({
  updateSelectedBusData,
  trips,
  busSearchResultObj,
  setBusResultsList,
  showNetFare,
  setShowNetFare=()=>{},
  busSearchObject,
}) => {
  return (
    <div className="results-card-block">
      <Suspense fallback={<div>Loading...</div>}>
      {/* <BusDates 
      busSearchObject={busSearchObject} 
      /> */}
      <BusSort
        busSearchResultObj={busSearchResultObj}
        setBusResultsList={setBusResultsList}
        showNetFare={showNetFare}
        setShowNetFare={setShowNetFare}
        trips={trips}
      />

      <BusCard
        updateSelectedBusData={updateSelectedBusData}
        trips={trips}
        searchId={busSearchResultObj.data.searchId}
        otherData={{
          origin: busSearchResultObj.data.origin,
          destination: busSearchResultObj.data.destination,
        }}
        showNetFare={showNetFare}
        journeyDate={busSearchResultObj.data.journeyDate}
      />
      </Suspense>
    </div>
  );
};
export default BusResultCards;
