import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Form, Row, message, Radio, Collapse, Modal, } from "antd";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { CloseOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import { getFlightGrandTotalPrice, getFareTotal } from "./flightPriceHelper";
import kid from "../../assets/images/kid.png";
import Login from "../Login/Login";


const FlightGrandTotalCard = ({
  airPriceResp,
  PromoNotVisible = false,
  flightSearchObj,
  mealdetails,
  baggagedetails,
  location,
  seatdetails,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [promoform] = Form.useForm();
  // console.log(user, location, "usss");
  console.log(seatdetails,"dddd")
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  const [redeemData, setRedeemData] = useState({});
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCodeId, setpromoCodeId] = useState(null);

  // console.log(activepath, "active");
  const getFaredetails = (type) => {
    let adultfare = 0;
    let childfare = 0;
    let infantfare = 0;
    let alltax = 0;
    airPriceResp.flightDetails.map((flightDetObj) => {
      let { flightFares } = flightDetObj.fareFamilies[0];
      const {
        adultPrice,
        ChildPrice,
        infantPrice,
        totalBaseFare,
        tax,
        totalAmount,
      } = getFareTotal(flightSearchObj, flightFares);
      adultfare = Number(adultfare) + Number(adultPrice);
      childfare = Number(childfare) + Number(ChildPrice);
      infantfare = Number(infantfare) + Number(infantPrice);
      alltax = Number(alltax) + Number(tax);
    })
    if (type === 1)
      return adultfare;
    if (type === 2)
      return childfare;
    if (type === 3)
      return infantfare;
    if (type === 4)
      return alltax;
  }

  useEffect(() => {
    if (user) {
      getRedeemCoupon(user.UserID);
    }
  }, [user]);
  const getRedeemCoupon = (userID) => {
    setRedeemData({});
    APIClient.get("admin/getUserCouponsWalletAmt/" + userID)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };
  useEffect(() => {
    getFaredetails();
    getInsuranceByServiceType(1);
  }, []);

  useEffect(() => {
    if (!agent) {
      AddConvFee(1);
      getpromo();
    }
  }, [agent]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {

            let nPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3) &&
                (promo.airlineCode ===
                  airPriceResp.flightDetails[0].flightSegments[0]
                    .marketingAirline)
            );
            if (nPromo.length === 0) {
              nPromo = data?.filter(
                (promo) =>
                  promo.ServiceType === 1 &&
                  (promo.ApplicationType === 1 || promo.ApplicationType === 3) &&
                  (promo.airlineCode === "ALL")
              );
            }

            setPromoCode(nPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error, "err");
      });
  };

  const handleApply = (code) => {
    if (user != null) {
      setpromoCodeId(code)
      promoform.setFieldsValue({
        promo: code,
      });

      promoform.submit();
    } else {
      message.error("pls Sign-In to Avail Offers")
    }
  };

  const {
    grandTotal,
    convamount,
    discount,
    insuranceTotal,
    commission,
    redeemTotal,
    mealAmount,
    baggageAmount,
    seatAmount,
  } = getFlightGrandTotalPrice(
    airPriceResp,
    ConvFee,
    promoData,
    flightSearchObj,
    selectedInsuranceData,
    redeemAmount,
    mealdetails,
    baggagedetails,
    seatdetails,
  );
  useEffect(() => {
    // Close the panel when the key changes
    setActiveKey([]);
  }, []);
  // { console.log(mealAmount, mealdetails, baggageAmount, commission, "ffffamil") }
  const [activeKey, setActiveKey] = useState(['1']);
  const [active, setActive] = useState(['1']);
  const onChange = (key) => {


    setActiveKey(key);
  };
  const onSsr = (key) => {
    setActive(key);
  };
  console.log(seatAmount,"amount")
  let Netfare = grandTotal - commission;
  const item = [
    {
      key: '1',
      label: 'Add On',
      children: <div className="tot-fare-tab">
        {mealAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Meal :
            </p>
            <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(mealAmount)}</p>
          </div> : null}
        {baggageAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Baggage :
            </p>
            <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(baggageAmount)}</p>
          </div> : null}
          {seatAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Seat :
            </p>
            <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(seatAmount)}</p>
          </div> : null}
      </div>,
    },

  ];
  const items = [
    {
      key: '2',
      label: 'Add On',
      children: <div className="tot-fare-tab">
        {mealAmount > 0 || baggageAmount > 0 || seatAmount > 0 ?
          <>
            {mealAmount > 0 ?
              <div className="tab">
                <p className="amount-ta">
                  Meal :
                </p>
                <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(mealAmount)}</p>
              </div> : null}
            {baggageAmount > 0 ?
              <div className="tab">
                <p className="amount-ta">
                  Baggage :
                </p>
                <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(baggageAmount)}</p>
              </div> : null}
              {seatAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Seat :
            </p>
            <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(seatAmount)}</p>
          </div> : null}
          </> : "No Add On Services Added"}

      </div>,
    },

    {
      key: '1',
      label: 'Fare Summary',
      children: <div className="tot-fare-tab">
        <div className="tab">
          <p className="amount-tag">
            Grand Total :
          </p>
          <p className="amount" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(grandTotal)}</p>
        </div>

        <div className="tab">
          <p className="amount-tag">
            Commission :
          </p>
          <p className="amount"><span className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(commission)}</p>
        </div>
        <div className="tab">
          <p className="amount-tag">
            Net Fare :
          </p>
          <p className="amount"><span className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(Netfare)}</p>
        </div>
      </div>,
    },
    // {
    //   key: '2',
    //   label: 'Total Net Fare',
    //   // children: <p>{Pan}</p>,
    // },
    // {
    //   key: '3',
    //   label: 'Commission Earned',
    //   children: <p className="amount ml-1 pax-comm">
    //     {" "}
    //     {currencyValue(commission)}
    //   </p>,
    // },
  ];
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  return (
    <>
      <Card className="flight-cards-details">
        <div className="total-card">
          <div className="price-tag">

            <span><p>Price Summary</p></span>
            <span style={{ right: "32%", top: "5%", position: "absolute" }}><i class="fa fa-male" style={{ color: "black" }} aria-hidden="true"></i>  {flightSearchObj.adultCount}</span>
            <span style={{ right: "20%", top: "5%", position: "absolute" }}><i class="fa fa-child" style={{ color: "black" }} aria-hidden="true"></i>  {flightSearchObj.childCount}</span>
            <span style={{ right: "8%", top: "5%", position: "absolute" }}><img src={kid} height={"13px"} width={"13px"} alt="infant" />  {flightSearchObj.infantCount}</span>
          </div>

          {selectedInsuranceData.status === 1 &&
            selectedInsuranceData?.serviceType === 1 ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Insurance Amount</p>
              </Col>
              <Col>
                <p>
                  {activeCurrency} {currencyValue(insuranceTotal)}
                </p>
              </Col>
            </Row>
          ) : null}
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Adult x {flightSearchObj.adultCount}</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(getFaredetails(1))}
              </p>
            </Col>
          </Row>
          {flightSearchObj.childCount != 0 ? <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Child x {flightSearchObj.childCount}</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(getFaredetails(2))}
              </p>
            </Col>
          </Row> : null}
          {flightSearchObj.infantCount != 0 ? <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Infant x {flightSearchObj.infantCount}</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(getFaredetails(3))}
              </p>
            </Col>
          </Row> : null}

          {(baggageAmount > 0 || mealAmount > 0 || seatAmount > 0 ) ?
            <div className="info">


              <Collapse accordion ghost onChange={onSsr} activeKey={active}>
                {item.map((item) => (
                  <Collapse.Panel key={item.key} header={item.label}>
                    <p>{item.children}</p>
                  </Collapse.Panel>
                ))}
              </Collapse>

            </div> : null}
          {!agent && user?.Role?.RoleLevel !== 3 && (

            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Convenience Fee</p>
              </Col>
              <Col>
                <p>
                  {activeCurrency} {currencyValue(convamount)}
                </p>
              </Col>
            </Row>)}
          {promoData.status && (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Discount</p>
              </Col>
              <Col>
                <p>
                  - {activeCurrency} {currencyValue(discount)}
                </p>
              </Col>
            </Row>
          )}

          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Total Taxes</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(getFaredetails(4))}
              </p>

            </Col>
          </Row>
          <div className="pax-total-price">
            <div className="pax-type">
              <p>Grand Total:</p>
            </div>
            <div className="total">
              <p>{activeCurrency} </p>
              <p className="amount ml-1"> {currencyValue(grandTotal)}</p>
            </div>
          </div>
          {agent ? (
            <div className="pax-total-price">
              <div className="pax-type">
                <p style={{ fontSize: "18px", color: "#717171" }} className="pax-comm">Commission:</p>
              </div>
              <div style={{ color: "#2f004c" }} className="total">
                <p className="pax-comm">{activeCurrency} </p>
                <p className="amount ml-1 pax-comm">
                  {" "}
                  {currencyValue(commission)}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </Card>
      {!agent && user?.Role?.RoleLevel !== 3 && !PromoNotVisible ? (
        <div className="promo-card">
          {promoData.status == false ? (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p className="name">Have a Coupon code</p>
              </div>
              {user != null ?
                <div className="promo-input">
                  <Form
                    name="customized_form_controls"
                    layout="inline"
                    form={promoform}
                    onFinish={(d) => {
                      validatePromoCode({
                        ServiceType: 1,
                        Amount: Number(grandTotal),
                        PromoCode: d.promo,
                        UserId: user?.UserID ?? 1,
                        userMail: user?.Email,
                      });
                    }}
                  >
                    <Form.Item name="promo" className="promo-item">
                      <Input
                        className="promo-input-box"
                        placeholder="Enter Coupon code"
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="apply-promo"
                        type="primary"
                        htmlType="submit"
                      >
                        Apply
                      </Button>
                    </Form.Item>
                  </Form>
                </div> :
                <div>
                  <p>Please <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                </div>
              }
              <div className="line-l"></div>
              {promoCode.length ? (
                <Radio.Group value={promoCodeId}>{
                  promoCode.map((item, key) => {
                    return (
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-promo">
                          <Form>
                            <Form.Item>
                              <Radio value={item.Code}
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>
                        <div className="promo-percentage">
                          <p>{item.Description}</p>
                        </div>

                      </div>
                    );
                  })}</Radio.Group>
              ) : (
                <div className="promo-cp-coupons">
                  <div className="inline-promo">
                    <p className="promo-key">No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      setpromoCodeId(null)
                      RemovePromo();
                      promoform.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p>
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>


              </div>
            </Card>
          )}
        </div>
      ) : null}
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </>
  );
};

export default FlightGrandTotalCard;
