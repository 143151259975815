import React from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import "../subscribe/Subscribe.scss";
import APIClient from "../../../src/helpers/ApiClient";

const Subscribe = () => {
  const [subsribeForm] = Form.useForm();

  const subsribeData = {
    DeviceOS: 3,
    ApplicationType: 1,
    Email: "",
    Name: "",
    Status: 0,
    CreatedBy: 0,
    DeviceOSVersion: "string",
    DeviceToken: "string",
    FirBaseToken: "string",
  };
  const handleSubscibe = (value) => {
    let data = {
      ...subsribeData,
      ...value,
    };
    APIClient.post("admin/subscriberReport", data)
      .then((response) => {
        if (response.status === 200) {
          message.success("Subscibed SuccessFully", 3);
          subsribeForm.resetFields();
        } else if (response.status === 400) {
          message.error("This Email Address Has Already Subscribed.", 3);
        } else if (response.status === 404) {
          message.error("Failed", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section className="stay-best-email">
      <div className="container">
        <Row>
          <Col md={24} xs={24}>
            <h2 className="top-fl-route-email">Subscribe Today</h2>
          </Col>
        </Row>

        <Form
          form={subsribeForm}
          onFinish={handleSubscibe}
          className="home-from-sub"
        >
          <Row>
            <Col md={9} xs={24} className="sub-text-up">
              <img
                className="news-sub-tt"
                src={require("../../assets/images/news-sub-t.png").default}
                alt=""
              />
              <div className="date-news-travel">
                <p>Stay Up To Date</p>
                <span>Subscribe Now And Receive The Latest Travel News</span>
              </div>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="Name">
                <Input className="name-sub-date" placeholder="Your Name" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="Email"
                rules={[
                  { required: true, message: "Required" },
                  { type: "email", message: "Please enter valid email" },
                ]}
              >
                <Input className="name-sub-date" placeholder="Email ID" />
              </Form.Item>
            </Col>

            <Col md={3} xs={24}>
              <Button htmlType="submit" className="cribe-btn-email">
                Subscribe
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </section>
  );
};

export default Subscribe;
