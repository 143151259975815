import React, { useEffect, useState } from "react";
import ApiClient from "../../../helpers/ApiClient";
import { Card, Col, Row, Layout } from "antd";
import { Link } from "react-router-dom";
import "./PopularHolidays.scss";
import Slider from "react-slick";
import HolidayImage from "../../../assets/images/hotels/shopping-d-3.png";
import HolidayDestinations from "../HolidaysTourDetail/HolidayDestinations/HolidayDestinations";

const BASE = process.env.REACT_APP_BASE_URL;
const { Content } = Layout;
function PopularHolidays({}) {
  const [dataSource, setDataSource] = useState({
    sliderData: [],
    cityData: [],
  });

  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const getallHolidayRecords = () => {
    ApiClient.get("ToursExtranet/GetTourExPackages")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) => item.ShowHomepage == 1);
          let result = data.reduce(function (obj, key) {
            obj[key.CountryName] = obj[key.CountryName] || [];
            obj[key.CountryName].push(key);
            return obj;
          }, {});
          setDataSource({ sliderData: data, cityData: result });
        } else {
          setDataSource({ sliderData: [], cityData: [] });
        }
      })
      .catch((e) => {
        setDataSource({ sliderData: [], cityData: [] });
      });
  };
  useEffect(() => {
    getallHolidayRecords();
  }, []);

  return (
    <Content className="admin-container cms-pages-width">
      {Object.keys(dataSource.cityData).length > 0
        ? Object.keys(dataSource.cityData).map((item) => (
            <>
              <h4 className="holiday-poplar mt-3">
                Popular Holidays in {item}
              </h4>
              <Row>
                {dataSource.cityData[item].map((itemData, ind) => {
                 
                  return (
                    <Col md={6} xs={24} className="mb-3">
                     
                      <Link
                        // to={`/holidays/results?countryId=${itemData.CountryID}&countryName=${itemData.CountryName}&travelDate=${itemData.ValidtillDatetime}`}
                        to={`/holiday/tourdetail?isFrm=top&tourId=${itemData.ToursExPackagesID}&cityId=${itemData.CityID}`}
                      >
                        <Card
                          className="holiday-card orange-color"
                          bordered={false}
                        >
                          {itemData.TourImages.length > 0 ? (
                            <div className="holiday-image">
                              <img
                                src={BASE + itemData.TourImages[0].substring(1)}
                                className="w-100"
                                alt={itemData.PackageTitle}
                              />
                            </div>
                          ) : (
                            <div className="holiday-image">
                              <img
                                src={HolidayImage}
                                alt="holiday "
                                className="w-100"
                              />
                            </div>
                          )}
                          <div className="holiday-content mt-2">
                            <h3 className="holiday-pkg-name">
                              {itemData.PackageTitle}
                              <span>{itemData.CityName}</span>
                            </h3>
                            <p className="holiday-pkg-price">
                              ₹ {itemData.PerAdultCharge}/-
                            </p>
                            <div className="holiday-overview">
                              {itemData.flightCount != "0" && (
                                <p>
                                  {" "}
                                  <i className="fa fa-plane" />{" "}
                                  {itemData.flightCount}
                                </p>
                              )}
                              {itemData.hotelsCount != "0" && (
                                <p>
                                  {" "}
                                  <i className="fa fa-bed" />{" "}
                                  {itemData.hotelsCount}
                                </p>
                              )}
                              {itemData.activitiesCount != "0" && (
                                <p>
                                  {" "}
                                  <i className="fa fa-anchor" />{" "}
                                  {itemData.activitiesCount}
                                </p>
                              )}
                              {itemData.transfersCount != "0" && (
                                <p>
                                  {" "}
                                  <i className="fa fa-bus" />{" "}
                                  {itemData.transfersCount}
                                </p>
                              )}
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </>
          ))
        : null}

      <HolidayDestinations />
    </Content>
  );
}

export default PopularHolidays;
