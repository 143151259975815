export const getHotelBookingStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#FFA500" }}>FAILED</p>;

    case 2:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 3:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 4:
      return <p style={{ color: "#FF0000" }}>PENDING</p>;

    case 5:
      return <p style={{ color: "#f9e218" }}>REJECTED</p>;

    case 6:
      return <p style={{ color: "#FF0000" }}>HOLD</p>;

    case 7:
      return <p style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</p>;

    case 8:
      return <p style={{ color: "#FF0000" }}>CANCELLATION PENDING</p>;

    case 9:
      return <p style={{ color: "#FF0000" }}>CANCELLATION IN PROGRESS</p>;

    default:
      return "";
  }
};


export const getActivitiesBookingStatus = (status) => {
  switch (status) {
    case 0:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 1:
      return <p style={{ color: "#FF0000" }}>ALREADY CANCELLED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    default:
      return "";
  }
};

export const getFlightTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#f9e218" }}>CREATED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 4:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 5:
      return <p style={{ color: "#008000" }}>PARTIALLY CANCELLED</p>;

    case 6:
      return <p style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</p>;

    default:
      return "";
  }
};


export const getHolidayTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#FF0000" }}>ALREADY CANCELLED </p>;
    case 2:
      return <p style={{ color: "#FF0000" }}>BLOCKED </p>;
    case 3:
      return <p style={{ color: "#008000" }}>SUCCESS </p>;

    case 4:
      return <p style={{ color: "#FF0000" }}>CANCELLED </p>;

    case 5:
      return <p style={{ color: "#008000" }}>PARTIALLY CANCELLED</p>;
    case 6:
      return <p style={{ color: "#ff0303" }}> Cancelation Requested</p>;
    case 7:
      return <p style={{ color: "#ff0303" }}> Cancelation Rejected</p>;
    case 8:
      return <p style={{ color: "#ff0303" }}> Reschedule Requested</p>;
    case 9:
      return <p style={{ color: "#ff0303" }}> Reschedule Rejected</p>;
    case 10:
      return <p style={{ color: "#ff0303" }}> Reschedule Approved</p>;
    case 11:
      return <p style={{ color: "#ff0303" }}> Reschedule Success</p>;
    default:
      return "";
  }
};
export const getVisaTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#f9e218" }}>CREATED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    default:
      return "";
  }
};
export const getCarTrTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#f9e218" }}>CREATED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 4:
      return <p style={{ color: "#bd0c21" }}>FAILED</p>;
    case 5:
      return <p style={{ color: "#bd0c21" }}>CANCELLED</p>;

    case 6:
      return <p style={{ color: "#bd0c21" }}>CANCELLATION REQUESTED</p>;

    default:
      return "";
  }
};
export const getCarTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#f9e218" }}>CREATED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 4:
      return <p style={{ color: "#bd0c21" }}>CANCELLED</p>;

    case 6:
      return <p style={{ color: "#bd0c21" }}>CANCELLATION REQUESTED</p>;

    default:
      return "";
  }
};
