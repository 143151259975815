import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, message, Popconfirm, Radio, Row, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import search from "../../../src/assets/vector-icons/search.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";

import { getCabinType, getTypeSign} from "../../admin/AdminMarkup/commissionhelper";

import "./Agentmarkup.scss";

const { Option } = Select;

const getAgentFareType = (num) => {
  switch (num) {
    case 1:
      return "General";
    case 2:
      return "Publish";
    case 3:
      return "Instantpur";
    case 4:
      return "SMEFare";
    case 5:
      return "PPNR";
    default:
      return "";
  }

};

const Agentmarkup = () => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [serviceType, setServiceType] = useState(0);
  const [column, setColumn] = useState([]);
  const [Markuplist, setMarkuplist] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [flightradio, setflightradio] = useState(1);
  useEffect(() => {
    if (user && user?.UserID) {
      getAgentMarkup(user?.UserID);
    }
    getActivitiesCategory();
  }, [user]);

  useEffect(() => {
    GetOperators();

  }, [serviceType]);
  useEffect(() => { selectServiceType(1); }, [])

  const searchData = () => {
    setShowSearchBox(true);
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const getActivitiesCategory = () => {
    APIClient.get(`extranet/categories`)
      .then((resp) => {
        if (resp.status == 200) {
          setCategoryList(resp.data);
        }
      })
      .catch((error) => { });
  };

  /********** Filter Table Data *********************/

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Markuplist.filter((item) => {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(Markuplist);
    }
  };

  /************************ API CALLS **********************/

  const getAgentMarkup = (id, ServiceType = 1) => {
    setMarkuplist([]);
    setTableData([]);
    APIClient.get(`admin/agentMarkupByUserId/${id}`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              RoleTypeName: "Agent",
              CabinTypeName: getCabinType(item?.CabinType),
              FareTypeName: getAgentFareType(item?.FareType),
              OperatorName: item.Operator != null ? item?.Operator?.OperatorName : "",
              CategoryName: item.ServiceType === 4 ? item?.Category?.categoryName : "",
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setMarkuplist(data);
          if (ServiceType === 0) {
            setTableData(data);
          } else {
            filterDataByServiceType(data, ServiceType);
          }
        }
      })
      .catch((error) => { });
  };

  function filterDataByServiceType(data, type = false) {
    const serId = type ? type : serviceType;

    let filteredData = data.filter((item) => item.ServiceType === serId);

    setTableData(filteredData);
  }

  const GetOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter((item) => item.ServiceType === serviceType);
          setOperatorList(data);
        }
      })
      .catch((error) => { });
  };

  const deleteMarkup = (rec) => {
    APIClient.delete(`admin/agentMarkup/${rec.ID}`)

      .then((res) => {
        if (res.status == 200) {
          getAgentMarkup(user?.UserID, rec.ServiceType);
          message.success("Deleted  successfully", 3);
        }
      })
      .catch((error) => { });
  };

  const Updatemarkup = (currentData) => {
    setId(currentData.ID);
    if (currentData) {
      form.setFieldsValue({
        ...currentData,
        ServiceType: currentData.ServiceType,
        StarRating: currentData.StarRating,
        FareType: currentData.FareType,
        CabinType: currentData.CabinType,
        CategoryID: currentData.ServiceType === 4 ? currentData?.Category?.categoryId : "",
        OperatorID: currentData.Operator.OperatorServiceID,
        MarkupType: currentData.MarkupType,
        Amount: currentData.Markup,
      });
    }
    setIsEditMode(true);
  };
  const updatedetails = (formdata) => {
    APIClient.put(`admin/agentMarkup/${id}`, {}, formdata)
      .then((response) => {
        if (response.status == 200) {
          setId(-1);
          setIsEditMode(false);
          form.resetFields();
          form.setFieldsValue({
            ServiceType: formdata.ServiceType,
          });
          getAgentMarkup(user?.UserID, formdata.ServiceType);
          message.success("Markup  Updated SuccessFully", 3);
        } else if (response.status == 404 && response.status == 500) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };
  const Submitvalues = (values) => {
    const data = {
      UserId: user?.UserID ?? 1,
      ServiceType: values.ServiceType,
      RoleType: 5,
      StarRating: values.StarRating,
      FareType: values.FareType,
      CabinType: values.CabinType,
      BusType: "",
      CategoryID: values.CategoryID,
      OperatorID: values.OperatorID,
      Status: 0,
      MarkupType: values.MarkupType,
      Markup: values.Amount,
      DeviceOS: 1,
      ApplicationType: 1,
      CreatedBy: 5,
      ModifiedBy: 0,
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };
    isEditMode ? updatedetails(data) : postdata(data);
  };

  const postdata = (formMapData) => {
    APIClient.post("admin/agentMarkup", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Markup Added SuccessFully", 3);
          getAgentMarkup(user?.UserID, formMapData.ServiceType);
          form.resetFields();
          form.setFieldsValue({
            ServiceType: formMapData.ServiceType,
          });
        } else if (response.status == 201) {
          message.error("Markup Inserted Successfully", 3);
        } else if (response.status == 404 && response.status == 500) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /************************ SELCTED SERVICE *******************/

  const selectServiceType = (value) => {
    if (isEditMode) {
      setId(-1);
      setIsEditMode(false);
      form.resetFields();
      form.setFieldsValue({
        ServiceType: value,
      });
    }
    setServiceType(value);
    filterDataByServiceType(Markuplist, value);
    if (value == 1) {
      setflightradio(1)
      setColumn(Flightcolumn);
    } else if (value == 2) {
      setflightradio(2)
      setColumn(hotelcolumn);
    } else {
      setColumn(AllServices);
    }
  };

  /***************** Table Action ******************/

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined onClick={() => Updatemarkup(CurrentItem)} style={{ color: "#2e0000", fontSize: "20px", textAlign: "center" }} />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm title="Are you sure to delete?" onConfirm={() => deleteMarkup(CurrentItem)}>
            <DeleteOutlined style={{ color: "red", fontSize: "20px", textAlign: "center" }} />
          </Popconfirm>
        </div>
      </div>
    );
  };

  /**************************** SERVICE COLUMNS *****************************/
  
  const AllServices = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },
    {
      title: "StarRating",
      dataIndex: "StarRating",
    },

    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      title: "OperatorName",
      dataIndex: "OperatorName",
    },
    {
      title: "CabinType",
      dataIndex: "CabinTypeName",
    },
    {
      title: "Fare Type",
      dataIndex: "FareTypeName",
    },

    {
      title: "Category",
      dataIndex: "CategoryName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const hotelcolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },

    {
      title: "StarRating",
      dataIndex: "StarRating",
    },
    {
      title: "OperatorName",
      dataIndex: "OperatorName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const Flightcolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },

    {
      title: "OperatorName",
      dataIndex: "OperatorName",
    },

    {
      title: "CabinType",
      dataIndex: "CabinTypeName",
    },
    {
      title: "Fare Type",
      dataIndex: "FareTypeName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const Activitycolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },
    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },

    {
      title: "StarRating",
      dataIndex: "StarRating",
    },

    {
      title: "Category",
      dataIndex: "CategoryName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const selectCustomFilter = (input, option) => {
    let istext = option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <div className="card-add-heading">
              <h5 className="mb-3">Partner Markup</h5>
            </div>

            <Form form={form} layout={"vertical"} onFinish={Submitvalues}>
              <Row gutter={[8, 8]}>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item label="Service Type" name="ServiceType" rules={[{ required: true }]}>
                    <Select placeholder="Please Select" onSelect={selectServiceType}>
                      <Option value={1}>Flights</Option>
                      <Option value={2}>Hotel</Option>
                      {/* <Option value={3}>Holiday</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
                {serviceType === 1 && (
                  <>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item label="Operators" name="OperatorID" rules={[{ required: true }]}>
                        <Select showSearch placeholder="Select Operator" filterOption={(val, op) => selectCustomFilter(val, op)}>
                          {operatorList.map((item, index) => (
                            <option key={index} value={item.OperatorServiceID}>
                              {item.OperatorName}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item label="FareType" name="FareType" rules={[{ required: true }]}>
                        <Select placeholder="Flights">
                          
                          <Option value={1}>General</Option>
                          <Option value={2}>Publish</Option>
                          <Option value={3}>Instantpur</Option>
                          <Option value={4}>SME</Option>
                          <Option value={5}>PPNR</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item label="Select Cabin Type" name="CabinType" rules={[{ required: true }]}>
                        <Select placeholder="Select Cabin Type">
                          <Option value={1}>Economy Premium</Option>
                          <Option value={2}>Business</Option>
                          <Option value={3}>First Class</Option>
                          <Option value={4}>Economy</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
               

                {[2, 4].includes(serviceType) && (
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item name="StarRating" label="Rating" rules={[{ required: true }]}>
                      <Select placeholder="Rating">
                        <option value={1}>1 Star</option>
                        <option value={2}>2 Star</option>
                        <option value={3}>3 Star</option>
                        <option value={4}>4 Star</option>
                        <option value={5}>5 Star</option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                
                {serviceType != 0 && (
                  <>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Markup Type"
                        name="MarkupType"
                        rules={[
                          {
                            required: true,
                            message: "markup is required",
                          },
                        ]}
                      >
                        <Select placeholder="Please select">
                          <Option value={0}>Fixed</Option>
                          <Option value={1}>Percentage</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Markup Amount"
                        name="Amount"
                        rules={[
                          {
                            required: true,
                            message: "markup is required",
                          },
                        ]}
                      >
                        <Input placeholder="Amount" />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              {serviceType != 0 && (
                <Row className="reports-btns">
                  <Button type="primary" htmlType="submit">
                    {isEditMode ? "Update" : "Add"}
                  </Button>
                  {isEditMode ? (
                    <Button
                      type="danger"
                      onClick={() => {
                        form.resetFields();
                        setServiceType(0);
                        setIsEditMode(false);
                        setId(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      type="danger"
                      onClick={() => {
                        form.resetFields();
                        setServiceType(0);
                      }}
                    >
                      Reset
                    </Button>
                  )}
                </Row>
              )}
            </Form>
          </Card>
        </div>

        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <div className="card-add-heading">
              <div className="rows-count">

                <div>
                  <Radio.Group onChange={(e) => selectServiceType(e.target.value)}
                    rules={[{ required: true, message: "Please choose Status!" }]}
                    value={flightradio}
                  >
                    <Radio value={1} defaultChecked={true}>Flight</Radio>
                    <Radio value={2} checked={true}>Hotel</Radio>
                    
                  </Radio.Group>
                  <h5>View Report</h5>
                  <p>{tableData.length} rows found !</p>
                </div>
              </div>

              <div className="action-images">

                {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px", width: "250px" }} />}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={(e) => searchData()} />
              </div>
            </div>

            <Table
              scroll={{ x: true }}
              columns={column}
              dataSource={tableData}
              pagination={{
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100", "125"],
              }}
            />
          </Card>
        </div>

      </div>
    </div>
  );
};

export default UpdatedComponent(Agentmarkup);
