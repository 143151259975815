import React, { useContext, useState, useEffect, useReducer } from "react";
import {
  Card,
  Col,
  Input,
  message,
  Radio,
  Row,
  Form,
  Select,
  Spin,
  Skeleton,
  InputNumber,
  Button,
  Dropdown,
  Menu,
  Modal,
  Statistic,
} from "antd";

import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { getPassengerData } from "../../../helpers/PassegerData";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ApiClient from "../../../helpers/ApiClient";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import { useHistory } from "react-router-dom";
import CountryList from "../../../common/CountryList";
import { getHolidayPrice } from "../holidayHelper";
import "./HolidaysCheckout.scss";
import HolidayPackageCheckoutDoc from "../../../common/PdfDocuments/BuildPackage/HolidayPackageCheckoutDoc";
import PaymentComponent from "../../../helpers/CCavanuePayCheckout";
//import timeoutimg from "../../../assets/images/timeout.jpg"
import queryString from "query-string";
import ReactDOMServer from 'react-dom/server'

import HolidayTotalCard from "./HolidayTotalCard";
import HolidayBookPayCard from "./HolidayBookPayCard";
import HolidayDetailCard from "./HolidayDetailCard";
import HtmlParser from "react-html-parser";
import CashFreeNewVersionCheckout from "../../../helpers/CashFreeNewVersionCheckout";
const { Option } = Select;
const { TextArea } = Input;
const { Countdown } = Statistic;
const oriDateFormat = "YYYY-MM-DD";
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";
const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}

const HolidaysCheckout = ({location}) => {
  // useEffect(() => {
  //   getsessiontimeout();
  // }, [])
  let history = useHistory();
  const [gst_form] = Form.useForm();
  const param = queryString.parse(document.location.search);
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount },
      partPayment,
      holidayBookingData
    },
    getPartPayment,
    RemovePromoConvFee,
    updateSelectedholidayData,
  } = useContext(GlobalStatesContext);
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const [holidayCheckOutData, setHolidayCheckOutData] = useState(false);
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false);
  const [pgData, setPgData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPartPayment, setIsPartPayment] = useState(0);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [contact_form] = Form.useForm();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [contactData, setContactData] = useState({});
  const [gstRequired, setGSTRequired] = useState(false);
  const [showalldetails, setshowalldetails] = useState(false);

  const [calculateprice, setcalculateprice] = useState({});
  const [isCalculatedPriceLoaded, setisCalculatedPriceLoaded] = useState(false)
  const [handle, sethandle] = useState({ tourId: param.tourId, traceId: param.traceId, adultsCount: 1, childCount2_5: 0, childCount6_11: 0, infantCount: 0 })
  const [pdfdoc, setpdfdoc] = useState(null);
  const [passengerData, setpassengerData] = useState({});
  
  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)
  // const [sessiontimeout, setsessiontimeout] = useState([]);
  // const [status, setStatus] = useState('');
  // const getsessiontimeout = () => {
  //   ApiClient.get("admin/get_active_session_timeout/6")
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setsessiontimeout(new Date().getTime() + Number((res?.data?.sessionTime) * 60 * 1000));
  //         setStatus(res.data.status);
  //       }
  //       else {
  //         setsessiontimeout(new Date().getTime() + Number(0));
  //         setStatus(res.data.status);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            firstName: data.Name,
            mobile: data.Mobile,
            email: data.Email,
          });
        }
      });
    }
  };


 

  useEffect(() => {
    fetchHolidayData();
  }, [])
  const fetchHolidayData = () => {
    const param = queryString.parse(document.location.search);
    const ReqObj = {
      traceId: param.traceId,
      holidayPriceId: param?.TourPriceId,
      holidayId: param.tourId,
    };

    ApiClient.post("HolidayExtranet/holiday/price", ReqObj)
      .then((result) => result)
      .then((resp) => {
        setIsLoading(false);
        setLoadingSpin(false);

        if (resp.status == 200) {
          setHolidayCheckOutData(resp.data);


          if (resp.data.IsGST) setGSTRequired(resp.data.IsGST);
        } else {
          setIsLoading(true);
          message.error(resp.message, 3);
        }
      })
      .catch((err) => {
        setIsLoading(false);

      });
  };

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }
  const CashFreeNewVersionCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCashfreeNewVersionCheckout(true)
  }

  useEffect(() => {

    RemovePromoConvFee();
    loadpassengerData();
    getPartPayment(1);
    setIsLoading(true);
    setLoadingSpin(true);


  }, [])


  useEffect(() => {
    if (partPayment?.isPartPayment) {
      checkValidCategory(partPayment);
    }
  }, [partPayment]);

  const checkValidCategory = (partPayment) => {
    if (
      partPayment?.isPartPayment &&
      partPayment?.categories &&
      partPayment?.categories.length > 0
    ) {
      if (holidayCheckOutData?.TourDetails?.length > 0) {
        let activitiesCityTourNamesArray = holidayCheckOutData?.TourDetails.map(
          (item) => item.cityTourType
        );

        let unique = [...new Set(activitiesCityTourNamesArray)];
        let errors = [];
        if (unique?.length > 0) {
          unique.forEach((catName) => {
            if (
              partPayment?.categories.filter(
                (cat) => cat.categoryName == catName
              ).length == 0
            ) {
              errors.push(catName);
            }
          });
        }

        let todayDate = moment();
        holidayCheckOutData?.TourDetails.forEach((tour) => {
          let cancelDate = moment(tour?.cancellationDate, oriDateFormat);

          if (tour?.cancellationDate) {
            if (
              cancelDate.diff(todayDate, "days") <
              partPayment.MinDaysToEnablePartPay
            ) {
              errors.push(tour.tourId);
            }
          } else {
            errors.push(tour.cityTourType);
          }
        });

        if (errors.length > 0) {
          message.error(
            "Part Payment is unavailable. Please Choose other payment option.",
            5
          );
          setIsPartPayment(1);
          contact_form.setFieldsValue({ paymentOption: 0 });
          return;
        } else {
          setIsPartPayment(2);
        }
      }
    }
  };



  const backToResultPage = () => {
    if (holidayCheckOutData?.Request) {
      let cityCode = "";
      if (holidayCheckOutData.countryId && holidayCheckOutData.Country) {
        cityCode = `countryName=${holidayCheckOutData.Country}&&countryId=${holidayCheckOutData.countryId}`;

        if (holidayCheckOutData.cityId && holidayCheckOutData.CityName) {
          cityCode =
            cityCode +
            `&&cityName=${holidayCheckOutData.CityName}&&cityId=${holidayCheckOutData.cityId}`;
        }
      }
      let query = cityCode + `&travelDate=""`;
      history.push("/holidays/results?" + query);
    }
  };

  const backToDeailsPage = () => {
    let queryObj = {
      traceId: holidayCheckOutData?.Request?.traceId,
      TourPriceId: holidayCheckOutData?.price?.TourPriceId,
      tourId: holidayCheckOutData?.Request?.tourId,
    };
    const query = queryString.stringify(queryObj);
    history.push("/holiday/tourdetail?" + query);
  };


  const handleSendOTP = (contactDetails) => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: contactDetails?.mobile,
      DialingCode: "+91",
    })
      .then((res) => {
        if (res?.status === 200) {
          setContactData(contactDetails);
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const processPayGateway = (type, pg_Type) => {
    contact_form
      .validateFields()
      .then((val) => {
        let data = {
          holidayCheckOutData,
          addressInfo: val,
        };
        gst_form
          .validateFields()
          .then((gstDetails) => {
            data.gstDetails = gstDetails;
            setpassengerData(data);
            if (type != "BookOffline") {
              dispatchPgDetails({ type: PGDISPLAY, payload: true });
            } else {
              handleBookHoliday({ bookingData: data, pgType: pg_Type, blockType: true });
            }
          })
          .catch((e) => {
           });
      })

      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  const blockApiReq = (pgType) => {

    if (pgType === 2) {
      processPayGateway("BookOffline", pgType);
    }

    if (pgType === 1) {

      handleBookHoliday({ bookingData: passengerData, pgType: pgType });

    }
    else if (pgType === 3) {
      handleBookHoliday({
        pgType: pgType,
        bookingData: passengerData,
      });
    }
  };
  const handleBookHoliday = ({ bookingData, pgType = null, blockType = false }) => {
    const allAmount = getHolidayPrice(
      bookingData.holidayCheckOutData,
      ConvFee,
      promoData,
      redeemAmount
    );

    let blockObj = {
      pgType: pgType,
      TraceId: bookingData.holidayCheckOutData?.Request?.traceId,
      UserId: user?.UserID ?? 1,
      Role: user?.Role?.RoleId ?? 4,

      Name: bookingData.addressInfo.firstName,
      Email: bookingData.addressInfo.email,
      PhoneNo: bookingData.addressInfo.mobile,
      Address: bookingData.addressInfo.nationality,
      City: bookingData.addressInfo.nationality,
      Country: bookingData.addressInfo.nationality,
      HolidayPackageId: bookingData.holidayCheckOutData?.Request?.holidayId,

      AdditionalRequest: "",
      PromoCode: promoData.Code ?? "",
      ConvienceId: 0,

      Day: bookingData.holidayCheckOutData.Days,
      VendorID: 0,
      CreatedBy: 0,
      ModifiedBy: 0,
      TotalPrice: allAmount.grandTotal,
      isBookOffline: blockType,
      gstDetails: {
        gstNumber: bookingData.gstDetails.gstNumber,
        gstAddressLine1: bookingData.gstDetails.gstAddressLine1,
        gstEmailId: bookingData.gstDetails.gstEmailId,
        gstPhoneNo: bookingData.gstDetails.gstPhoneNo,
        gstCompanyName: bookingData.gstDetails.gstCompanyName,
      },
    };


    setLoadingSpin(true);

    ApiClient.post("HolidayExtranet/holiday/block", blockObj)
      .then((result) => result)
      .then((resp) => {
        setLoadingSpin(false);

        if (resp.status == 200) {
          // if (resp?.data?.pgType == 4) {
          //   ccavanueCheckoutData(resp.data.payment_link)
          // } 
          if (resp.data?.BookingStatus === "Blocked") {


            CashFreeNewVersionCheckoutData(resp.data);
          } 
          else {
            window.location.href = resp.data.payment_link;
          }


        } else {
          message.error(resp?.message, 5);
        }
      })
      .catch((err) => {


      });
  };
  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((val) => {
        let data = {
          holidayCheckOutData,
          addressInfo: val,
        };
        gst_form
          .validateFields()
          .then((gstDetails) => {
            data.gstDetails = gstDetails;
            setpassengerData(data);
            dispatchPgDetails({ type: PGDISPLAY, payload: true });
          })
          .catch((e) => {
           });
      })

      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };




  const MainMenu = (
    <Menu>
      <Menu.Item key="32">
        <PDFDownloadLink
          document={<HolidayPackageCheckoutDoc buildPackage={pdfdoc} price={holidayCheckOutData?.price} />}
          fileName="QuotationList.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? <>Loading document...</> : <>With Rate Breakup</>
          }
        </PDFDownloadLink>
      </Menu.Item>
      <Menu.Item key="33">
        <PDFDownloadLink
          document={
            <HolidayPackageCheckoutDoc buildPackage={pdfdoc} showPrice={false} />
          }
          fileName="QuotationList.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? <>Loading document...</> : <>Without Rate Breakup</>
          }
        </PDFDownloadLink>
      </Menu.Item>
    </Menu>
  );

  // const timeout = () => {
  //   return (

  //     <div>

  //       <div className="timeout-popup-main">
  //         <div className="timeout-popup-main1">
  //           <img className="timeout-img" src={timeoutimg} alt="time" />
  //         </div>
  //         <div className="timeout-popup-main2">
  //           <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
  //           <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
  //           <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
  //         </div>
  //       </div>

  //     </div>
  //   );
  // }
  // const handelCountdown = () => {

  //   //const query = queryString.stringify(searchhotelobj);
  //   let query =queryString.stringify(JSON.parse(localStorage.getItem('HolidaySearchBar')));

  //   Modal.warning({
  //     icon: <></>,
  //     //title: "",
  //     content: timeout(),
  //     onOk() {
  //       history.push("/holidays/results?" + query);

  //     },
  //   });
  // };



  return (
    <div>
      {isLoading ? (
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Skeleton paragraph={{ rows: 12 }} />
          </Col>
          <Col md={12} xs={24}>
            <Skeleton.Image style={{ height: "450px", width: "750px" }} />
          </Col>
          <Col md={6} xs={24}>
            <Skeleton paragraph={{ rows: 12 }} />
          </Col>
          <Col md={16} xs={24}>
            <Skeleton paragraph={{ rows: 5 }} />
          </Col>
          <Col md={8} xs={24}>
            <Skeleton paragraph={{ rows: 5 }} />
          </Col>
        </Row>
      ) : (
        <div className="promo providerapi">
          {/* {status !=0 &&
<div className="countdown3 checkout-countdown">
            <div className="countdown-main-box">
              <div className="countdown1">
                <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
              </div>
              <div className="countdown2">
                <Countdown
                  className="busCountdown"
                  onFinish={handelCountdown}
                  value={sessiontimeout}
                  format={"mm[ min]  ss[ sec]"}
                />
              </div>
            </div>
          </div>} */}
          <div className="line"></div>
          <div className="manage-markup-section">
            <div className="admin-container bar-admin-width">
              <Row className="holiday-pack">
                <Col md={16} xs={24} >

                  <HolidayDetailCard holidayData={holidayCheckOutData} />

                  <Form
                    layout="vertical"
                    form={contact_form}
                    scrollToFirstError={true}

                  >

                    <div style={{ marginTop: "20px" }}>
                      <h5 className="booking-option-cart">
                        Traveler
                      </h5>
                      <Card className="checkout-activity-card">

                        <Row gutter={(8, 8)} className="form-rules">
                          <Col md={4} xs={24}>
                            <Form.Item
                              label="Title"
                              rules={[{
                                required: true,
                                message: "Enter Title"
                              }]}
                              name="prefix"
                            >
                              <Select placeholder="Mr">
                                <Select.Option value="Mr">Mr</Select.Option>
                                <Select.Option value="MRS">Mrs.</Select.Option>
                                <Select.Option value="Ms">Ms.</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col md={10} xs={24}>
                            <Form.Item
                              label="Full Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter your Name",
                                }, {
                                  pattern: "^[a-zA-Z]*$",

                                  message: "Must be Alphabet",
                                },
                              ]}
                              name="firstName"
                            >
                              <Input
                                className="doument-name"
                                placeholder="As shown on passport"
                                size={"large"}
                              />
                            </Form.Item>
                          </Col>

                          <Col md={10} xs={24}>
                            <Form.Item
                              label="Last Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter your Last Name",
                                },
                              ]}
                              name="lastName"
                            >
                              <Input
                                className="doument-name"
                                placeholder="surname"
                                size={"-large"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                    <div>
                      <h5 style={{ marginTop: "20px" }} className="booking-option-cart">
                        Address Details
                      </h5>

                      <Card className="checkout-activity-card">
                        <Row gutter={(8, 8)} className="form-rules">
                          <Col md={12} xs={24}>
                            <Form.Item
                              label="Email"
                              rules={[
                                { required: true, message: "Please Enter proper Email!" },
                                { type: "email", message: "Invalid Email" },
                              ]}
                              name="email"
                            >
                              <Input
                                className="doument-name"
                                placeholder="Please Enter email"
                                size={"large"}
                              />
                            </Form.Item>
                          </Col>

                          <Col md={12} xs={24}>
                            <Form.Item
                              label="Mobile Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter 10 Digit Mobile Number",
                                },
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Invalid Phone Number",
                                },
                              ]}
                              name="mobile"
                            >
                              <Input
                                className="doument-name"
                                placeholder="Phone Number"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                size={"large"}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              label="Address"
                              rules={[{
                                required: true,
                                message: "Please Enter Your Address"
                              }]}
                              name="address"
                            >
                              <TextArea rows={1} placeholder="Current Address" />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              label="Country"
                              rules={[{
                                required: true,
                                message: "Please Enter Nationality"
                              }]}
                              name="nationality"
                            >
                              <Select
                                showSearch
                                placeholder="Select Country"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {CountryList.map((item) => (
                                  <Option key={item.name} value={item.name}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                      </Card>
                    </div>

                    <Col md={6} xs={24} className="holiday-total sectionn-work-for-mob-only">
                      {holidayCheckOutData && (
                        <HolidayTotalCard
                          holidayCheckOutData={holidayCheckOutData}
                          isPartPayment={isPartPayment}
                          isPromoVisible={true}
                          location={location}
                        />
                      )}
                    </Col>



                  </Form>

                  {showalldetails ? <Form
                    layout="vertical"
                    className="passenger-form"
                    form={gst_form}
                  >
                    {gstRequired ? (<div style={{ marginTop: "20px" }}>
                      <h5 className="booking-option-cart">GST Details</h5>
                      <Card className="checkout-activity-card">

                        <Row gutter={[16, 8]}>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Name"
                              name="gstCompanyName"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input className="contacts-value-de" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="GST Number"
                              name="gstNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                {
                                  pattern:
                                    "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",

                                  message: "Please Enter a Valid GST Number",
                                },
                              ]}
                            >
                              <Input
                                className="contacts-value-de"
                                placeholder="GST Number"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Contact Number"
                              name="gstPhoneNo"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input
                                className="contacts-value-de"
                                placeholder="Contact Number"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Email"
                              name="gstEmailId"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input
                                className="contacts-value-de"
                                placeholder="Company Email"
                                type="email"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Address"
                              name="gstAddressLine1"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input
                                className="contacts-value-de"
                                placeholder="Company Address"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </div>) : null}

                  </Form> : null}


                  <div className="payment-tc mobb-none">


                    <HolidayBookPayCard
                      blockApiReq={blockApiReq}
                      loadingSpin={loadingSpin}
                      bookpaycardinfo={"review"}
                      pgDisplay={pgDetails.pgDisplay}
                      processPayGateway={processPayGateway}
                      agent={false}

                    />
                  </div>


                </Col>
                <Col md={6} xs={24} className="holiday-total mob-none">
                  {holidayCheckOutData && (
                    <HolidayTotalCard
                      holidayCheckOutData={holidayCheckOutData}
                      isPartPayment={isPartPayment}
                      isPromoVisible={true}
                      location={location}
                    />
                  )}
                </Col>

              </Row>

            </div>
          </div>
        </div>
      )
      }

      {/* {
        openCCavanueCheckout && (
          <PaymentComponent
            pgData={pgData}

          />
        )
      } */}
      {
        openCashfreeNewVersionCheckout && (
          <CashFreeNewVersionCheckout
            pgData={pgData}

          />
        )
      }

    </div >
  );
};
export default HolidaysCheckout;
