import React, { useState, useEffect, useContext } from "react";
import "./Tax.scss";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Modal,
  Button,
  Col,
  Radio,
  Popover,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";

const Tax = (props) => {
  const { Option } = Select;
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const initialFormData = {
    taxType: 1,
  serviceType: 1,
  taxRate: 12,
  countryName: "India",
  stateName: "Ap",
  taxName: "string",
  status: 0,
  effectiveDate: "string",
  expirationDate: "string",
  createdDate: "",
  modifiedDate: "",
  createdBy: 0,
  modifiedBy: 0,
  };
  const { Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isServiceFieldVisible, setIsServiceFieldVisible] = useState(1);
  const [isEditMode, setIsEditMode] = useState(true);
  const [form] = ANTD.Form.useForm();
  const [formData, setFormData] = useState(initialFormData);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [formType, setFormType] = useState(-1);
  const [validtilldate, setvalidtilldate] = useState(moment());
  const [currentRecID, setCurrentRecID] = useState(-1);

  const { user } = useAuthContext();
  const {
    state: {
      serviceTypeList
    },
    getAllServiceType
  } = useContext(GlobalStatesContext);
  let dateFormat = "YYYY-MM-DD";
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinishFailed = (errorInfo) => {
   };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  /********** Uploading Image **********/

  const updatePromoModel = (rec) => {
    console.log(rec,"recs")
    setIsServiceFieldVisible(rec.ServiceType);
    setFormData({ ...formData, ...rec }); // Set form data based on the record being edited
    form.setFieldsValue({
      ServiceType: rec.serviceType,
      TaxName: rec.taxName,
      TaxAmount: rec.taxRate,
      ValidFrom: moment(rec.effectiveDate, dateFormat),
      ValidTill: moment(rec.expirationDate, dateFormat),
      
    });
    setIsEditMode(true);
    showModal();
  };

 


  const addNewPromoModel = () => {
    setIsEditMode(false);
    form.resetFields();
   
    setFormData(initialFormData);
    showModal();
  };

  const tableActions = (text, rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updatePromoModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <ANTD.Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => {
            setVerifyModalVisible(true);
            setCurrentRecID(text);
            setFormType(2);
          }}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </ANTD.Popconfirm>
      </div>
    );
  };

  const getOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {

        if (res.status == 200) {
          let filterOperators = res.data.filter((item) => item.ServiceType === 3);
          setOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: <span style={{ color: 'black' }}>Tax Name</span>,
      dataIndex: "taxName",
      key: "TaxName",
    },
    {
      title: <span style={{ color: 'black' }}>Tax Amount</span>,
      dataIndex: "taxRate",
      refno: "",
      key: "TaxAmount",
    },
    {
      title: <span style={{ color: 'black' }}>Service Type</span>,
      dataIndex: "serviceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },
    {
      title: <span style={{ color: 'black' }}>Valid From</span>,
      dataIndex: "effectiveDate",
      key: "ValidFrom",
    },
    {
      title: <span style={{ color: 'black' }}>Valid Till</span>,
      dataIndex: "expirationDate",
      key: "ValidTill",
    },
    {
      title: <span style={{ color: 'black' }}>Actions</span>,
      dataIndex: "taxID",
      render: (text, rec, index) => {
        return tableActions(text, rec);
      },
    },
  ];
  
    
 
    

  const getServiceType = (num) => {
    let type = "";
    serviceTypeList.map((item) => {
      if (item.ServiceType == num) {
        type = item.ServiceName;
      }
    });
    return type;
  };

  const getDiscountType = (num) => {
    if (num == 0) {
      return "Fixed";
    } else if (num == 1) {
      return "Percentage";
    } else {
      return "Invalid";
    }
  };

  /* =============== API calls ================*/

  useEffect(() => {
    getPromoCodes();
    getAllServiceType()
    getOperators();;
  }, []);

  // api call to get all avaialble promo Codes
  const getPromoCodes = () => {
    ApiClient.get("admin/taxes").then((res) => {
      if (res.status == 200) {
         setDataSource(res.data);
      }
    });
  };

  // Delete specific promo Codes
  const deletePromoCode = (id) => {
    ApiClient.delete("admin/tax/" + id).then((res) => {
      if (res.status == 200) {
        ANTD.message.success("PromoCode Deleted  successfully", 3);
        getPromoCodes();
      }
    });
  };

  const submitNewPromo = (data) => {
    console.log(data,"data")
    const newFormData = {
      ...initialFormData, // Use initialFormData to populate the default values
      ...data, // Override with form values
      createdDate: moment().format("YYYY-MM-DD"), // Set current date dynamically
      modifiedDate: moment().format("YYYY-MM-DD"),
    };
    ApiClient.post("admin/tax", newFormData).then((res) => {
      if (res.status == 200) {
        ANTD.message.success("Promo added successfully", 3);
        setModalVisible(false);
        setFormData(initialFormData);
        getPromoCodes();
      } else if (res.status == 400) {
        ANTD.message.error(" Duplicate Promo Code", 3);
      } else {
        ANTD.message.error(" Error Uploading Data", 3);
      }
    });
  };

  const submitUpdatePromo = (data) => {
   
    const updatedFormData = {
      ...formData,
      ...data,
      modifiedDate:moment().format("YYYY-MM-DD"),
    };
    ApiClient.put("admin/tax/" + formData.taxID, {}, updatedFormData).then(
      (res) => {
        if (res.status == 200) {
          ANTD.message.success("Promo Updated  successfully", 3);
          setModalVisible(false);
          setFormData(initialFormData);
          getPromoCodes();
        } else if (res.status == 400) {
          ANTD.message.error(" Duplicate Promo Code", 3);
        } else {
          ANTD.message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  // add new Promo Code api call
  const submitFormData = (formvalues) => {
   console.log(formvalues,"submit")
   
   const { ServiceType, TaxName, TaxAmount, ValidFrom, ValidTill } = formvalues;

  // Convert date strings to moment objects
  const effectiveDate = moment(ValidFrom).format("YYYY-MM-DD");
  const expirationDate = moment(ValidTill).format("YYYY-MM-DD");

  const newFormData = {
    ...initialFormData,
    serviceType: ServiceType,
    taxName: TaxName,
    taxRate: Number(TaxAmount),
    effectiveDate: effectiveDate,
    expirationDate: expirationDate,
  };
    if (isEditMode) {
      submitUpdatePromo(newFormData);
    } else {
      submitNewPromo(newFormData);
     }
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getPromoCodes();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    const excelCustomData = dataSource.map((item) => {
      return {
        Name: item.Name,
        Description: item.Description,
        Code: item.Code,
        Service_Type: getServiceType(item.ServiceType),
        Valid_From: item.ValidFrom,
        Valid_Till: item.ValidTill,
        Discount_Type: getDiscountType(item.DiscountType),
        Discount: item.Discount,
        From_Amount: item.FromAmount,
        To_Amount: item.ToAmount,
      };
    });

    props.exportExcel(excelCustomData, "PromoCodesExcel");
  };

  const handleVerifyPassword = (values) => {
    ApiClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            deletePromoCode(currentRecID);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const disabledOriginDate = (currentDate) => {
    //setvalidtilldate(moment(currentDate));
    return currentDate < moment().startOf("day");
  };
  const disabledDestDate = (currentDate) => {
    return currentDate < moment(validtilldate).startOf("day");
  }
  const onChangeOriginDate = (momentdate, _) => {
     let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setvalidtilldate(moment(momentdate));
  };
  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Tax{" "}
                          <HelpInfoHelper screenName={"/admin/taxmaster"} />
                        </h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(dataSource)}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getPromoCodes()}
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={() => {
                          addNewPromoModel();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <Modal
        title="Tax Master"
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[null]}
        width={"1100px"}
      >
        <Form
          {...layout}
          form={form}
          onFinish={submitFormData}
          onFinishFailed={onFinishFailed}
        >
          <Col md={12}>
           <Form.Item
            label="Service Type"
            name="ServiceType"
            rules={[{ required: true, message: "Please select Service Type!" }]}
          >
            <Select>
              {serviceTypeList &&
                serviceTypeList.map((service) => (
                  <Option key={service.ServiceType} value={service.ServiceType}>
                    {service.ServiceName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          </Col>
          <Col md={12}>
          <Form.Item
            label="Tax Name"
            name="TaxName"
            rules={[{ required: true, message: "Please input Tax Name!" }]}
          >
            <ANTD.Input />
          </Form.Item>
           </Col>
           <Col md={12}>
          <Form.Item
            label="Tax Amount"
            name="TaxAmount"
            rules={[{ required: true, message: "Please input Tax Amount!" }]}
          >
            <ANTD.Input />
          </Form.Item>
           </Col>
           <Col md={12} xs={24}>
              <Form.Item
                label="Valid From"
                name="ValidFrom"
                rules={[{ required: true, message: "Valid From is required" }]}
              >
                <DatePicker disabledDate={disabledOriginDate} onChange={(date, dateString) =>
                  onChangeOriginDate(date, dateString)
                } format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Valid Till"
                name="ValidTill"
                rules={[{ required: true, message: "Valid Till is required" }]}
              >
                <DatePicker disabledDate={disabledDestDate} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              onClick={() => {
                setVerifyModalVisible(true);
                setFormType(1);
              }}
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {verifyModalVisible && (
        <VerifyPasswordModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyPassword={handleVerifyPassword}
        />
      )}
    </div>
  );
};
export default UpdatedComponent(Tax);
